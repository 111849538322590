const translations = {
  language: "Sprache",
  login: {
    welcome: "Willkommen",
    ssoLogin: "Mit deiner Konto-E-Mail anmelden",
    detailsGeneric: "Details",
    customers: "Endnutzer",
    sendEmail: "E-Mail senden",
    otpLogin: "Anmelden per E-Mail",
    login: "Anmelden",
    changeEmail: "Andere E-Mail-Adresse verwenden",
    or: "ODER"
  },
  domains: {
    customerAdminDeletionConfirm: "Möchten Sie diesen Endnutzer löschen? Dieser Vorgang kann nicht rückgängig gemacht werden",
    customerAdminDeletionSuccess: "Endnutzer erfolgreich gelöscht",
    luckeyDomainsTitle: "Domains",
    domainName: "Domain-Name",
    domainDetails: "Domain-Details",
    domainConfigModifySuccess: "Domain-Konfiguration erfolgreich geändert und aktualisiert",
    domainConfigTitle: "Konfigurationen - Feature-Toggling",
    domainConfigDescription: "Domain-Konfiguration im JSON-Format. Verwenden Sie diese, um Domain-Funktionen zu aktivieren oder zu deaktivieren",
    domainConfigGuide: "Domain-Konfigurationsleitfaden",
    noUsersFoundTitle: "Benutzerliste leer",
    domainUsersTitle: "Domain-Manager",
    domainUsersDescription: "Imitiere einen der Benutzer-Manager für Wartungsoperationen in der Domain",
    domainUsersWarning: "ACHTUNG: Durch das Imitieren von Benutzern können Änderungen am System vorgenommen werden, auch irreversible. Bitte höchste Vorsicht walten lassen",
    noUsersFoundDescription: "Für diese Domain wurden keine Benutzer gefunden",
    noDomainsFoundTitle: "Keine Domains gefunden",
    noDomainFoundDescription: "Die Domain-Liste ist leer. Falls ein Suchfilter gesetzt ist, versuchen Sie diesen zurückzusetzen und erneut zu suchen",
    impersonateSuccess: "Benutzer-Imitation erfolgreich",
    domainSubsriptionTitle: "Domain-Abonnement",
    domainSubsriptionDescription: "Liste der Domain-Abonnements: Schlösser, Benutzer und Domain-Ablauf",
    expiration: "Ablauf",
    totalNumber: "Gesamtzahl",
    used: "Verwendete Elemente",
    tooMuchElements: "WARNUNG: DIE DOMAIN HAT DIE GESAMTANZAHL DER ELEMENTE ÜBERSCHRITTEN",
    newDomain: "Neue Domain",
    externalReference: "Externe Referenz",
    externalReferenceDetails: "Zum Beispiel \"date-purchaseId\"",
    productDescription: "Das Produkt repräsentiert die Lizenz, die für die neue Domain aktiviert wird",
    productTitle: "Abonnement-Typ",
    extendDomain: "Domain-Abonnement erweitern",
    extendDomainDescription: "Erweitern Sie Ihre Domain mit einem Add-on oder Produkt-Key",
    domainInstallersTitle: "Domain-Installer",
    domainInstallersDescription: "Liste der Installer-Benutzer auf den ausgewählten Standorten",
    domainDeletionTitle: "Domain löschen",
    domainDeletionDescription: "Senden Sie eine Anfrage, um diese Domain zu löschen",
    sendDeletionRequest: "Senden",
    domainDeletionConfirm: "Möchten Sie eine Anfrage zum Löschen dieser Domain senden?",
    domainDeletionSuccess: "Anfrage erfolgreich gesendet",
    customerDeletionTitle: "Endnutzer löschen",
    customerDeletionConfirm: "Möchten Sie eine Anfrage zum Löschen dieses Endnutzers senden?",
    customerDeletionSuccess: "Anfrage erfolgreich gesendet",
    walletDeletionTitle: "Wallet löschen",
    walletDeletionConfirm: "Möchten Sie eine Anfrage zum Löschen dieses Wallets senden?",
    walletDeletionSuccess: "Anfrage erfolgreich gesendet",
    verifyServiceStatus: "Service-Status",
    impersonateUser: "Benutzer imitieren",
    impersonate: "Imitieren",
    impersonateReadOnly: "Imitieren im Nur-Lesen-Modus",
    domainAdditionalInfo: "Domain-Informationen",
    domainAdditionalInfoDescription: "Zusätzliche Domain-Informationen hier angeben",
    additionalInfoSavedSuccessfully: "Zusätzliche Notizen erfolgreich gespeichert",
    EASY: "Nur Argo Locks",
    SMART: "Argo Locks + Gateway",
    ENTERPRISE: "V364 + Argo Locks",
    STANDARD: "Nur V364 Locks",
    numberOfArgoLocks: "Argo",
    numberOfVegaLocks: "V364",
    numberOfGatewaysLocks: "Gateways",
    numberOfUsers: "Benutzer",
    numberOfLocks: "Smart Locks",
    federatedDomain: "Domain verbunden mit: {{domain}}",
    federatedMasterDomain: "Hauptdomain einer Gruppe",
    domainFederatedLicense: "Föderiertes Standort-Abonnement: Dieses Abonnement ist für alle Domains, die zur Hauptdomain {{domain}} gehören, einheitlich",
    federatedDomains: "Domain-Gruppe",
    federatedDomainsDescription: "Domain gehört zu einer Gruppe",
    installationMode: "Installationsmodus",
    domainStandard: "Alte Domain",
    domainWithCloudCredits: "Domain mit Cloud-Guthaben",
    domainIrisRequired: "1RIS erforderlich",
    domainIrisEnabled: "1RIS aktiviert",
    domainIrisEnable: "1RIS aktivieren",
    domainIrisEnableConfirm: "Möchten Sie 1RIS für diese Domain als AKTIV setzen?",
    domainsWithCloudCredits: "Domains mit Cloud-Guthaben",
    domainsLegacy: "Alte Domains"
  },
  credits: {
    marketplaces: "Marktplätze",
    searchMarketplace: "Marktplatz suchen...",
    marketplaceName: "Marktplatzname",
    luckeyMarketplaceTitle: "Luckey-Marktplätze",
    noMarketplaceFoundTitle: "Kein Marktplatz gefunden",
    noMarketplaceFoundDescription: "Die Marktplatzliste ist leer. Wenn Sie einen Suchfilter gesetzt haben, versuchen Sie diesen zurückzusetzen und erneut zu suchen",
    newMarketplace: "Neuer Marktplatz",
    marketplaceCreationConfirm: "Möchten Sie den neuen Marktplatz erstellen?",
    createMarketplace: "Marktplatz erstellen",
    marketplaceDetails: "Marktplatz-Details",
    editMarketplaceCreationConfirm: "Sie sind dabei, die Daten des Marktplatzes zu bearbeiten. Sind Sie sicher, dass Sie fortfahren möchten?",
    editMarketplace: "Marktplatz bearbeiten",
    searchCustomer: "Endnutzer suchen...",
    customerName: "Name des Endnutzers",
    luckeyCustomersTitle: "Luckey-Endnutzer",
    noCustomerFindTitle: "Kein Endnutzer gefunden",
    noCustomerFindDescription: "Die Endnutzerliste ist leer. Wenn Sie einen Suchfilter gesetzt haben, versuchen Sie diesen zurückzusetzen und erneut zu suchen",
    newCustomer: "Neuer Endnutzer",
    customerCreationConfirm: "Möchten Sie den neuen Endnutzer erstellen?",
    newUser: "Neuer Benutzer",
    userCreationConfirm: "Möchten Sie den neuen Benutzer erstellen?",
    createCustomer: "Endnutzer erstellen",
    customerDetails: "Endnutzer-Details",
    editCreationConfirm: "Sie sind dabei, die Daten des Endnutzers zu bearbeiten. Sind Sie sicher, dass Sie fortfahren möchten?",
    editCustomer: "Endnutzer bearbeiten",
    editCreationUserConfirm: "Sie sind dabei, die Benutzerdaten zu bearbeiten. Sind Sie sicher, dass Sie fortfahren möchten?",
    editUser: "Benutzer bearbeiten",
    customerWallets: "Wallets von {{customerName}}",
    newWallet: "Neues Wallet",
    walletCreationConfirm: "Möchten Sie ein neues Wallet für den ausgewählten Endnutzer erstellen?",
    createWallet: "Wallet erstellen",
    editWallet: "Wallet bearbeiten",
    walletEditConfirm: "Möchten Sie das ausgewählte Wallet bearbeiten?",
    noWalletFoundTitle: "Kein Wallet gefunden",
    noWalletFoundDescription: "Für den ausgewählten Endnutzer wurde kein Wallet gefunden",
    walletDetails: "Wallet-Details",
    rechargeWallet: "Aufladen",
    walletRechargeHistory: "Aufladehistorie",
    rechargeRowTitle: "Geänderte Credits",
    rechargeCreditsDescription: "Das Wallet wurde mit {{credits}} Credits aufgeladen",
    rechargeCardDetails: "Auflade-Details",
    systemParameters: "Preisliste",
    systemSubscriptions: "Abonnements",
    systemSubscriptionsDescription: "Dies sind die Abonnements im System. Erstellen Sie neue oder weisen Sie sie einer bestehenden Domain zu",
    newSubscription: "Neues Abonnement",
    editSubscription: "Abonnement bearbeiten",
    noSystemSubscriptionFoundTitle: "Kein Abonnement gefunden",
    noSystemSubscriptionFoundDescription: "Es gibt kein Systemabonnement, das einer Domain zugewiesen werden kann",
    products: "Alte Abonnements",
    rechargeCards: "Aufladekarten",
    noSystemCardFoundTitle: "Keine Aufladekarte gefunden",
    noSystemCardFoundDescription: "Es gibt keine Aufladekarten im System. Erstellen Sie neue, um die Wallets der Endnutzer aufzuladen",
    newCard: "Neue Aufladekarte",
    cardStatusUsed: "Verwendet",
    rechargeCardCreditsDetails: "Diese Aufladekarte hat einen Wert von {{credits}} Credits",
    cardStatusUnused: "Nicht verwendet",
    cardStatusInactive: "Deaktiviert",
    enableCard: "Aktivieren",
    disabledCardTooltip: "Die Aufladekarte wird aktiviert, sobald Sie die zugehörige ISEO-Rechnung erhalten",
    newTierSubscription: "Neues Hauptabonnement",
    pluginAndService: "Add-on und Services",
    newPluginSubscription: "Neues Add-on oder Service",
    tierSubscription: "Hauptabonnement",
    walletSubscriptions: "Abonnements",
    noWalletSubscriptionFoundTitle: "Kein Abonnement gefunden",
    noWalletSubscriptionFoundDescription: "Dieses Wallet hat kein zugeordnetes Abonnement",
    newWalletSubscription: "Neues Abonnement zuweisen",
    editWalletSubscription: "Abonnement bearbeiten",
    newWalletPluginSubscription: "Neues Add-on oder Service zuweisen",
    walletConsumption: "Verbrauch",
    noWalletUsageFoundTitle: "Keine Daten gefunden",
    noWalletUsageFoundDescription: "Es liegen keine Verbrauchsdaten für dieses Wallet vor",
    ACTIVATION: "Aktivierungskosten der Domain oder des Add-ons",
    DAILY_USAGE: "Täglicher Verbrauch",
    creditConsumptionType: "Verbrauchstyp",
    consumptionCreditsDescription: "Das Wallet hat {{credits}} verbraucht",
    consumptionRowTitle: "Wallet-Verbrauch",
    walletPlants: "Domains",
    noWalletPlantFoundTitle: "Keine Domain gefunden",
    noWalletPlantFoundDescription: "Es sind keine Domains diesem Wallet zugeordnet",
    newWalletPlant: "Neue Domain",
    deleteWalletSubscription: "Abonnement entfernen",
    editCard: "Aufladekarte bearbeiten",
    rechargeDetails: "Auflade-Details",
    exportWalletsUsageCSV: "CSV des Credit-Verbrauchs exportieren",
    exportWalletsCreditUsageCSV: "Wallet-Credit exportieren",
    PENDING: "Ausstehend",
    ACTIVE: "Aktiv",
    READY_TO_ACTIVATE: "Bereit zur Aktivierung",
    walletStatus: "Wallet-Status",
    exportManagersCSV: "Manager exportieren",
    needCreditsToCreatePlant: "Sie benötigen Credits in diesem Wallet, um eine neue Domain zu erstellen"
  },
  sales: {
    hardwareSalesInfo: "Verkaufsinformationen",
    domain: "Domain",
    hardwareId: "Hardware-ID",
    hardwareName: "Hardware-Name",
    hardwareSerialNumber: "Hardware-Seriennummer",
    hardwareModel: "Hardware-Modell",
    hardwareVendor: "Hardware-Produktlinie",
    hardwareType: "Hardware-Typ",
    sfdcId: "SFDC-ID",
    iseoSelloutOrderId: "ISEO-Verkaufsauftrag-ID",
    iseoSelloutOrderDate: "ISEO-Verkaufsauftragsdatum",
    iseoSelloutInvoiceId: "ISEO-Verkaufsrechnungs-ID",
    iseoSelloutInvoiceDate: "ISEO-Verkaufsrechnungsdatum",
    sofiaCustRef: "Kunden-Referenz (Sofia-Prozess)",
    unreconciledHardware: "Nicht abgeglichene Hardware",
    hardwareWithSalesInfo: "Hardware mit Verkaufsinformationen",
    actions: "Aktionen",
    addSalesInfo: "Verkaufsinformationen hinzufügen",
    editSalesInfo: "Verkaufsinformationen bearbeiten",
    iseoSelloutOrder: "ISEO-Verkaufsauftrag",
    iseoSelloutInvoice: "ISEO-Verkaufsrechnung",
    selectedSalesInfoError: "Warnung! Die Auswahl ist nicht homogen. Möchten Sie fortfahren?"
  },
  errors: {
    deleteWalletSubscriptionError: "Beim Ändern des Abonnements ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie unseren Support",
    modifyVarOpportunityError: "Beim Bearbeiten des Angebots ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    error: "Fehler",
    loginFailed: "Die Sitzung ist abgelaufen. Bitte melden Sie sich erneut an",
    loginGoogleFailedTitle: "Google-Anmeldung fehlgeschlagen",
    loginGoogleFailed: "Stellen Sie sicher, dass Sie in Ihrem Google-Konto angemeldet sind oder versuchen Sie, diese Seite in einer Google Chrome-Sitzung mit Ihrem Sofia GSuite-Konto zu öffnen",
    loginJagoGoogleFailedTitle: "Jago Google-Anmeldung fehlgeschlagen",
    loginJagoGoogleFailed: "Stellen Sie sicher, dass Sie einen Benutzer in Jago haben, der Ihrem Sofia GSuite-Konto entspricht, und versuchen Sie es erneut",
    getDomainDetailsError: "Beim Abrufen der Domain-Konfiguration ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Sofia-Support",
    updateDomainConfigError: "Beim Speichern der Standort-Konfiguration ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Sofia-Support",
    updatePlantThemeError: "Beim Speichern des Standort-Themas ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Sofia-Support",
    impersonateDomainUserError: "Beim Imitieren des Benutzers ist ein Fehler aufgetreten. Bitte überprüfen Sie, ob der Benutzer aktiv ist, und versuchen Sie es erneut oder kontaktieren Sie den Sofia-Support",
    createDomainError: "Beim Erstellen der neuen Domain ist ein Fehler aufgetreten",
    addDomainPluginError: "Beim Erweitern der Domain ist ein Fehler aufgetreten",
    addUserDomainError: "Benutzer konnte nicht erstellt werden. Überprüfen Sie, ob der Benutzer bereits in der Domain existiert oder ob die Domain ein gültiges Abonnement hat",
    errorSaveAdditionalInfo: "Die Informationen in der ausgewählten Domain konnten nicht aktualisiert werden",
    createCustomerError: "Der Endnutzer konnte nicht erstellt werden. Überprüfen Sie, ob der Endnutzer bereits existiert, und versuchen Sie es erneut",
    createUserError: "Der Benutzer konnte nicht erstellt werden. Überprüfen Sie, ob der Benutzer bereits existiert, und versuchen Sie es erneut",
    createVarUserError: "Der VAR-Benutzer konnte nicht erstellt werden. Überprüfen Sie, ob der Benutzer bereits existiert, und versuchen Sie es erneut",
    createPlantThemeError: "Das Standort-Thema konnte nicht erstellt werden. Überprüfen Sie die Daten und versuchen Sie es erneut",
    updateVarError: "Die Aktualisierung ist fehlgeschlagen. Überprüfen Sie die Daten und versuchen Sie es erneut",
    getCustomerDetailsError: "Die Endnutzer-Details konnten nicht abgerufen werden. Bitte versuchen Sie es erneut",
    getVarDetailsError: "Die VAR-Details konnten nicht abgerufen werden. Bitte versuchen Sie es erneut",
    getPlantThemeDetailsError: "Die Details des Standort-Themas konnten nicht abgerufen werden. Bitte versuchen Sie es erneut",
    getProductInfoPostDetailsError: "Der Artikel konnte nicht abgerufen werden. Bitte versuchen Sie es erneut",
    required: "Erforderlich",
    deleteDomainRequestError: "Die Anfrage konnte nicht gesendet werden. Bitte versuchen Sie es erneut",
    deleteWalletRequestError: "Die Anfrage konnte nicht gesendet werden. Bitte versuchen Sie es erneut",
    deleteCustomerRequestError: "Die Anfrage konnte nicht gesendet werden. Bitte versuchen Sie es erneut",
    enableIrisDomainRequestError: "Die Anfrage konnte nicht gesendet werden. Bitte versuchen Sie es erneut",
    createCustomerWalletError: "Das neue Wallet konnte nicht erstellt werden. Bitte versuchen Sie es erneut",
    editCustomerWalletError: "Das Wallet konnte nicht bearbeitet werden. Bitte versuchen Sie es erneut",
    getWalletDetailsError: "Die Wallet-Details konnten nicht abgerufen werden. Bitte versuchen Sie es erneut",
    openSystemParametersError: "Die Systemparameter konnten nicht geöffnet werden. Bitte versuchen Sie es erneut",
    createSubscriptionError: "Das Abonnement konnte nicht erstellt werden. Bitte versuchen Sie es erneut",
    createCardError: "Beim Erstellen der Aufladekarte ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    createVarOpportunityError: "Beim Erstellen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    editVarOpportunityError: "Beim Vorgang ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    rechargeWalletError: "Beim Aufladen des Wallets ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    deleteCardError: "Beim Löschen der Aufladekarte ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    enableCardError: "Beim Aktualisieren der Aufladekarte ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    deletePlantThemeError: "Beim Löschen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    deleteVarUserError: "Beim Löschen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    deleteVarUserErrorOpportunities: "Dieser Benutzer ist Eigentümer mindestens eines Angebots, daher kann er nicht gelöscht werden. Bitte weisen Sie die diesem Benutzer zugeordneten Angebote neu zu und versuchen Sie es erneut.",
    deleteVarOpportunityError: "Beim Löschen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    rejectVarOpportunityError: "Beim Aktualisieren des Angebots ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    approveVarOpportunityError: "Beim Aktualisieren des Angebots ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    syncsfdcidVarOpportunityError: "Beim Aktualisieren des Angebots ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    upgradeVarOpportunityError: "Beim Aktualisieren des Angebots ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    winVarOpportunityError: "Beim Aktualisieren des Angebots ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    loseVarOpportunityError: "Beim Aktualisieren des Angebots ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    deleteUserError: "Beim Löschen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    createWalletSubscriptionError: "Beim Zuweisen des Abonnements zum Wallet ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    createWalletPlantError: "Beim Erstellen der Domain für das Wallet ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    walletActionErrorNotEnoughCreditsTitle: "Nicht genügend Guthaben",
    walletActionErrorNotEnoughCredits: "Das Wallet hat nicht genügend Guthaben, um den Vorgang durchzuführen. Überprüfen Sie den Vorgang und versuchen Sie es erneut",
    warningMinCreditsCard: "Warnung! Mindestanzahl erforderlicher Credits = {{credits}}",
    loginJagoOTPEmailFailedTitle: "E-Mail-Versandfehler",
    loginJagoOTPEmailFailed: "Beim Versenden der E-Mail ist ein Fehler aufgetreten, versuchen Sie es in 5 Minuten erneut",
    loginJagoOTPFailedTitle: "Code-Verifizierungsfehler",
    loginJagoOTPFailed: "Die Verifizierung des Zugangscodes war nicht erfolgreich. Bitte überprüfen Sie den Code oder versuchen Sie es in 5 Minuten erneut.",
    addonV364missing: "Das V364-Add-on konnte im System nicht gefunden werden, bitte melden Sie diesen Fehler, um Unterstützung zu erhalten."
  },
  gateways: {
    createdBy: "Erstellt von",
    noGatewayFoundTitle: "Keine HyperGates gefunden",
    noGatewayFoundDescription: "Die Liste der HyperGates ist leer. Wenn Sie einen Suchfilter gesetzt haben, versuchen Sie diesen zu ändern und es erneut zu versuchen",
    gatewayName: "HyperGate-Name",
    luckeyGatewaysTitle: "HyperGates des Systems",
    lastConnectionDate: "Letzte Verbindung: {{ date }}",
    gatewaysDevices: "Geräte",
    gatewaysArtifacts: "Artefakte",
    gatewaysDeployments: "Bereitstellungen",
    HYPERGATE_PRO: "HyperGate Pro",
    HYPERGATE_ISEO: "HyperGate Lite",
    DONE: "Abgeschlossen",
    PENDING: "Bereitstellung ausstehend",
    IN_PROGRESS: "Bereitstellung in Bearbeitung",
    ERROR: "Fehler",
    deploymentDetail: "Bereitstellungs-Detail",
    noDeploymentDetailedFound: "Keine Gateways für die Bereitstellung gefunden",
    noDeploymentDetailedFoundDescription: "Die Gateway-Liste für diese Bereitstellung ist leer",
    deviceId: "Geräte-ID",
    name: "Name",
    gatewayVersion: "Version",
    iseoSdkVersion: "ISEO SDK",
    kernelVersion: "Kernel-Version",
    serialNumber: "Seriennummer",
    noGatewaysFound: "Keine Gateways gefunden",
    noGatewaysFoundDescription: "Die Gateway-Liste ist leer. Versuchen Sie, Ihre Suchfilter zu ändern und es erneut zu versuchen",
    noDeploymentFound: "Keine Bereitstellung gefunden",
    noArtifactFound: "Kein Artefakt gefunden",
    noDeploymentFoundDescription: "Die Liste der Bereitstellungen für diese Bereitstellung ist leer. Falls ein Suchfilter gesetzt ist, versuchen Sie diesen zu ändern und es erneut zu versuchen",
    noArtifactFoundDescription: "Die Liste der Artefakte für diese Bereitstellung ist leer. Falls ein Suchfilter gesetzt ist, versuchen Sie diesen zu ändern und es erneut zu versuchen"
  },
  forms: {
    otp: 'OTP',
    notVisible: "Nicht sichtbar",
    visible: "Sichtbar",
    exportAllUsers: "Benutzer exportieren",
    montlyBurnRate: "{{credits}}/Monat",
    nextRechargeForecast: "Nächste Aufladung erwartet mit aktuellem Verbrauch ~{{date}}",
    lastUpdate: "Letzte Aktualisierung {{time}}",
    rowsPerPage: "Zeilen pro Seite",
    rolePermissions: "Rollenberechtigungen",
    permissionsDetails: "Berechtigungsdetails",
    clusterAndIseoCompany: "Cluster und ISEO-Unternehmen",
    createCardSuccess: "Aufladekarte erfolgreich erstellt",
    cardDetails: "Kartendetails",
    status: "Status",
    customerInfo: "Kundeninformationen",
    details: "Details",
    costs: "Kosten",
    hostName: "Hostname",
    domainType: "Typ",
    user: "Benutzer",
    logoutConfirmation: "Sind Sie sicher, dass Sie sich abmelden möchten?",
    send: "Senden",
    askQuestion: "Kontaktieren Sie uns",
    ticketOpenSuccessfully: "Ihre Anfrage wurde an unser Team gesendet! Ein Teammitglied wird sich in Kürze mit Ihnen in Verbindung setzen.",
    ticketOpenError: "Beim Senden der Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    writeUsAQuestionDescription: "Haben Sie eine Frage? Schreiben Sie uns gerne, und einer unserer Produktspezialisten wird Ihnen bei allen Anfragen zur Seite stehen.",
    deviceType: "Gerätetyp",
    createNewMarketplace: "Neuen Marktplatz erstellen",
    createNewCustomer: "Neu",
    firstName: "Vorname",
    lastName: "Nachname",
    email: "E-Mail",
    contactEmail: "Kontakt-E-Mail",
    open: "Öffnen",
    add: "Hinzufügen",
    actions: "Aktionen",
    search: "Suchen",
    remove: "Entfernen",
    error: "Fehler",
    copy: "Kopieren",
    cancel: "Abbrechen",
    confirm: "Bestätigen",
    warning: "Warnung",
    reset: "Zurücksetzen",
    close: "Schließen",
    save: "Speichern",
    delete: "Löschen",
    deleteRequest: "Löschanfrage senden",
    deleteAdminOnly: "Löschen (Admin)",
    export: "Exportieren",
    spinnerLoading: "Laden...",
    createNewDomain: "Neue Domain erstellen",
    walletName: "Wallet-Name",
    domainName: "Domain-Name",
    domainNamePreview: "Vorschau",
    domainFormTitle: "Neue Luckey-Domain erstellen",
    invalidDomainName: "Ungültiger Domain-Name. Keine Leerzeichen, nur Kleinbuchstaben, Zahlen und Bindestriche erlaubt",
    selectProduct: "Abonnement-Typ auswählen",
    name: "Vorname",
    lastname: "Nachname",
    createNewUser: "Neuen Manager erstellen",
    selectRole: "Benutzerrolle auswählen",
    createUserForDomain: "Neuen Benutzer für die ausgewählte Domain erstellen",
    newUser: "Neuer Benutzer",
    password: "Passwort",
    letUserChoosePassword: "Benutzer das Passwort wählen lassen: Passwort-Einstell-Link an die Benutzer-E-Mail senden",
    domainCreateSuccess: "Domain erfolgreich erstellt",
    domainCreateUserAfterCreation: "Möchten Sie einen Benutzer für die neue Domain erstellen?",
    domainCreationConfirm: "Möchten Sie die neue Domain erstellen?",
    createDomain: "Domain erstellen",
    username: "Benutzername",
    domainExtendsConfirm: "Möchten Sie die Domain mit dem ausgewählten Produkt erweitern?",
    createNewInstaller: "Neuen Installer erstellen",
    createInstallerForDomain: "Neuen Installer-Benutzer für die Domain erstellen",
    deleteDomain: "Domain-Löschung anfordern",
    active: "Aktiv",
    disabled: "Deaktiviert",
    minutes: "{{minutes}} Minuten",
    selectSessionDurationPlaceholder: "Sitzungsdauer",
    impersonateUserDescription: "Bevor Sie den Benutzer im System imitieren, informieren wir Sie, dass:",
    impersonateUserDescriptionOne: "Der Beginn der Sitzung und deren Dauer in den Domain-Protokollen erscheinen werden",
    impersonateUserDescriptionTwo: "Jede Aktion, die Sie durchführen, wird protokolliert und erscheint in den Systemprotokollen",
    impersonateUserDescriptionThree: "Jede Aktion, die Sie am Implantat durchführen, hat sofortige und in manchen Fällen irreversible Auswirkungen",
    selectSessionDuration: "Sitzungsdauer der Support-Sitzung auswählen",
    impersonateReadOnly: "Nur-Lesen-Modus imitieren",
    impersonateReadOnlyDescription: "Greifen Sie im Nur-Lesen-Modus auf das System zu, Sie können die Systemdaten sehen, aber nicht ändern",
    impersonateReadOnlyWarning: "Achtung: Die Support-Sitzung und deren Dauer bleiben in den Systemprotokollen sichtbar",
    installationModeActive: "Aktiv",
    installationModeDisabled: "Deaktiviert",
    customerFormTitle: "Luckey-Endnutzer",
    varFormTitle: "Value Added Reseller (VAR)",
    varFormAdminSection: "Admin-Bereich",
    varFormTitleVar: "Firmeninformationen",
    plantThemeFormTitle: "Luckey-Plant-Thema",
    customerName: "Endnutzername",
    invalidCustomerName: "Ungültiger Endnutzername",
    createNewWallet: "Neues Wallet",
    whatsNewTitle: "Luckey Neuigkeiten!",
    whatsNewSubtitle: "Entdecken Sie die neuesten Nachrichten über Luckey und Sofia, Teil von ISEO",
    notes: "Zusätzliche Notizen",
    cardCode: "Aufladecode",
    recharge: "Aufladen",
    rechargeWalletFormDescription: "Laden Sie die Credits dieses Wallets auf, indem Sie den Aufladecode eingeben. Die Aufladung wird sofort wirksam",
    subscriptionName: "Abonnement-Name",
    subscriptionFixedPrice: "Aktivierungskosten (Credits)",
    subscriptionBurnRate: "Wiederkehrende Kosten (Credits)",
    subscriptionVisible: "Auf dem Marktplatz sichtbar",
    createNewSubscriptionDescription: "Erstellen Sie ein neues Abonnement im System. Das Abonnement steht allen Domains zur Verfügung",
    subscriptionLogicDescription: "Gibt an, welche Elemente des Systems gezählt werden, um die Systembeschränkungen zu bestimmen",
    FIXED: "Keine Beschränkungen für Elemente",
    COUNT_USERS_LOCKS: "Begrenzung der Anzahl von Benutzern und Domain-Schlössern",
    COUNT_LOCKS: "Nur Begrenzung der Anzahl von Domain-Schlössern",
    COUNT_ELEMENTS: "Begrenzung der Summe von Schlössern und Domain-Benutzern",
    maxLocks: "Maximale Anzahl an Smart Locks",
    maxUsers: "Maximale Anzahl an Benutzern",
    maxElements: "Maximale Anzahl an Elementen (Benutzer + Schlösser)",
    subscriptionLogic: "Elementbegrenzungslogik",
    selectLogic: "Auswählen",
    subscriptionMaxLocksDescription: "Geben Sie die maximale Anzahl an Schlössern ein, die mit diesem Abonnement installiert werden können",
    subscriptionMaxUsersDescription: "Geben Sie die maximale Anzahl an Benutzern ein, die mit diesem Abonnement aktiviert werden können",
    subscriptionMaxElementsDescription: "Geben Sie die maximale Anzahl an Elementen mit diesem Abonnement ein",
    subscriptionResource: "Ressource",
    cardQuantity: "Anzahl der Credits",
    cardQuantityDescription: "Gibt an, wie viele Credits bei Verwendung der Aufladekarte aufgeladen werden",
    cardVar: "Aufladekarte dem VAR zuweisen",
    cardWallet: "Aufladekarte einem bestimmten Wallet zuweisen",
    cardNotes: "Zusätzliche Notizen",
    createCardConfirm: "Sind Sie sicher, dass Sie die Aufladekarte erstellen möchten?",
    createVarOpportunityConfirm: "Sind Sie sicher, dass Sie dieses neue Angebot erstellen möchten?",
    createSubscrptionConfirm: "Sind Sie sicher, dass Sie das neue Abonnement erstellen möchten?",
    rechargeWalletConfirm: "Sie sind dabei, das Wallet dieses Endnutzers mit neuen Credits aufzuladen, möchten Sie fortfahren? Dieser Vorgang kann nicht rückgängig gemacht werden",
    createdAt: "Erstellungsdatum",
    copiedSuccess: "Kopiert!",
    logout: "Abmelden",
    deleteCardConfirm: "Sind Sie sicher, dass Sie diese Aufladekarte löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden",
    enableCardConfirm: "Sind Sie sicher, dass Sie diese Aufladekarte aktivieren möchten? Sobald sie aktiviert ist, kann diese Karte verwendet werden. Dieser Vorgang kann nicht rückgängig gemacht werden",
    subscriptionTierSelectionTitle: "Hauptabonnement",
    subscriptionTierSelectionDescription: "Durch die Auswahl des Hauptabonnements erbt das neue Add-on automatisch die Zähllogiken des Hauptabonnements",
    selectTier: "Abonnement auswählen",
    createPluginSubscriptionConfirm: "Sind Sie sicher, dass Sie dieses neue Add-on/Service-Abonnement erstellen möchten?",
    createWalletSubscriptionConfirm: "Sind Sie sicher, dass Sie dieses Abonnement dem ausgewählten Wallet zuweisen möchten? Das Abonnement wird für alle über dieses Wallet verwalteten Standorte aktiviert",
    deleteWalletSubscriptionConfirm: "Sind Sie sicher, dass Sie dieses Abonnement aus dem ausgewählten Wallet entfernen möchten? Das Abonnement wird von allen über dieses Wallet verwalteten Standorten entfernt",
    editSubscriptionConfirm: "Sind Sie sicher, dass Sie das Abonnement bearbeiten möchten?",
    walletSelectSubscriptionTitle: "Zu aktivierendes Abonnement",
    walletSelectSubscriptionDescription: "Wählen Sie das Abonnement aus, das in diesem Wallet aktiviert werden soll. Denken Sie daran, dass das Abonnement auch in allen über dieses Wallet verwalteten Systemen aktiviert wird",
    createWalletPlantConfirm: "Sind Sie sicher, dass Sie die neue Domain für das ausgewählte Wallet erstellen möchten?",
    enableEmailForPlant: "E-Mail-Dienst aktivieren",
    enableEmailForPlantDescription: "Aktivieren Sie den Versand von E-Mails für dieses System (Aktivierungs-E-Mail, Benachrichtigungen etc.)",
    enableIrisRequiredForPlant: "1RIS-Dienst erforderlich",
    enableIrisRequiredForPlantDescription: "Wenn ausgewählt, wird die Domain darauf vorbereitet, den 1RIS-Dienst zu integrieren",
    cardSubscriptionsLinked: "Verknüpfte Abonnements",
    cardSubscriptionsLinkedDescription: "Verknüpfen Sie mehrere Add-ons oder Services mit dieser Aufladekarte, um sie zu aktivieren, sobald die Karte verwendet wird",
    mainTierDescription: "Verknüpfen Sie ein Hauptabonnement mit dieser Aufladekarte, um es zu aktivieren, sobald die Karte verwendet wird",
    cardMarketplacesLinked: "Marktplatz zur Lizenzzuweisung",
    cardMarketplacesLinkedDescription: "Wählen Sie einen Marktplatz aus, um die Abonnements auszuwählen, die mit dieser Aufladekarte verknüpft werden sollen",
    sku: "SKU",
    customerData: "Kundendaten",
    customerVat: "USt.",
    customerFiscalCode: "Steuercode",
    customerCountry: "Land",
    customerAddress: "Adresse",
    customerCity: "Stadt",
    customerEmail: "E-Mail",
    customerPhone: "Telefon",
    customerCrmId: "SFDC ACCOUNT ID",
    customerMarketplace: "Marktplatz",
    customerCertifiedEmail: "PEC (Zertifizierte E-Mail)",
    customerCluster: "Cluster",
    customerVar: "VAR",
    customerCompany: "ISEO-Unternehmen",
    customerPostalCode: "Postleitzahl",
    customerPrincipalSubDivision: "Bezirk, Bundesland, Kreis, etc.",
    iseoSalesOrderReference: "ISEO Verkaufsauftrag (ISEO -> Kunde)",
    iseoSalesOrderDate: "ISEO Verkaufsauftragsdatum",
    iseoPurchaseOrderReference: "ISEO Einkaufsauftrag (ISEO -> Sofia)",
    iseoPurchaseOrderDate: "ISEO Einkaufsauftragsdatum",
    sofiaSalesOrderReference: "Sofia Verkaufsauftrag (Sofia -> ISEO)",
    sofiaSalesOrderDate: "Sofia Verkaufsauftragsdatum",
    pickDate: "Datum auswählen",
    crmSource: "CRM Opportunity Quelle",
    crmId: "Eindeutiger CRM Opportunity Identifier",
    sofiaProjectId: "Sofia Projekt Referenz-Identifier",
    crmLink: "URL zur CRM Opportunity",
    kamReference: "KAM-Referenz",
    edit: "Bearbeiten",
    editCardConfirm: "Sind Sie sicher, dass Sie die Aufladekarte bearbeiten möchten?",
    editVarOpportunityConfirm: "Sind Sie sicher, dass Sie dieses Angebot bearbeiten möchten?",
    insertEmailOTPLoginForm: "Geben Sie Ihre Konto-E-Mail ein, um den OTP-Code zu erhalten und auf die Plattform zuzugreifen",
    insertEmailOTPLoginFormDisclaimer: "Wenn die E-Mail autorisiert ist, erhalten Sie einen OTP-Code zum Zugriff",
    insertOTPOTPLoginForm: "Geben Sie den an Ihre Konto-E-Mail gesendeten OTP-Code ein",
    insertOTPOTPLoginFormDisclaimer: "Falls Sie den Code nach 5 Minuten nicht erhalten, wiederholen Sie den Vorgang",
    pickDateTo: "Datum auswählen bis:",
    pickDateFrom: "Datum auswählen von",
    AUTOMATIC: "Automatische Aktivierung",
    MANUAL: "Manuelle Aktivierung",
    SEMI_AUTOMATIC: "Halbautomatische Aktivierung",
    AUTOMATIC_Description: "Die Erweiterung kann unabhängig vom Shop vom Benutzer aktiviert werden. Die Aktivierungscredits werden sofort abgezogen",
    MANUAL_Description: "Erweiterung, die nur nach einer ausdrücklichen Anfrage des Endnutzers aktiviert werden kann. Die Credits werden manuell nach Genehmigung der Anfrage abgezogen",
    SEMI_AUTOMATIC_Description: "Erweiterung, die nur nach einer ausdrücklichen Anfrage des Endnutzers aktiviert werden kann. Die Aktivierungscredits werden im Moment der Anfrage abgezogen",
    requestToQuote: "Angebot angefordert",
    requestToQuoteDescription: "Geben Sie an, ob die Erweiterung einem Angebot unterliegt, anstatt Credits direkt zu verwenden (z.B. bei Integrationen und Anpassungen)",
    subscriptionCanBeDeactivated: "Kann vom Manager deaktiviert werden",
    subscriptionCanBeDeactivatedDescription: "Geben Sie an, ob der Manager das Abonnement im Luckey Store deaktivieren kann",
    selectSegmentType: "Segment auswählen",
    sofiaInvoiceNumber: "Sofia Rechnungsnummer",
    sofiaInvoiceAmount: "Sofia Rechnungsbetrag",
    sofiaInvoiceYear: "Sofia Rechnungsjahr",
    iseoInvoiceNumber: "ISEO Rechnungsnummer",
    iseoInvoiceAmount: "ISEO Rechnungsbetrag",
    iseoInvoiceYear: "ISEO Rechnungsjahr",
    freeOfCharge: "Kostenlos",
    freeOfChargeReason: "Grund",
    currency: "Währung",
    sofiaInvoiceDate: "Rechnungsdatum",
    iseoInvoiceDate: "Rechnungsdatum",
    sofiaInvoiceRecipient: "Rechnungsempfänger",
    iseoInvoiceRecipient: "Rechnungsempfänger",
    serviceSupplementaryInvoice: "Service-Nachrechnung",
    serviceSupplementaryInvoiceNumber: "Rechnungsnummer",
    serviceSupplementaryInvoiceAmount: "Rechnungsbetrag",
    serviceSupplementaryInvoiceDate: "Rechnungsdatum",
    serviceSupplementaryInvoiceRecipient: "Rechnungsempfänger",
    iseoInvoiceIssuer: "Rechnungsaussteller",
    backdropClosing: "Sind Sie sicher, dass Sie das Formular ohne Speichern schließen möchten?",
    roles: "Rollen",
    selectBotType: 'Assistenten Typ auswählen',
    helperBot: 'Produktassistent',
    commercialBot: 'Handelsassistent',
    talkWithUs: 'Talk mit uns',
    noBotTypeSelected: 'Kein Assistenttyp ausgewählt',
  },
  activations: {
    getInfo: "(Mehr Informationen zum Prozess erhalten)",
    createNewCustomer: "Endnutzer erstellen",
    createNewWallet: "Wallet erstellen",
    createNewScratchcard: "Aufladekarte erstellen",
    yearsString: "Jahre",
    monthsString: "Monate",
    daysString: "Tage",
    addNewActivationButton: "Systemaktivierung hinzufügen",
    home: "OD-Startseite",
    activationRequests: "Systemaktivierungen",
    rechargeRequests: "Wallet-Aufladungen",
    walletStatus: "Auftragsstatus",
    noActivationsFoundTitle: "Keine Systemaktivierungen gefunden",
    noActivationsFoundDescription: "Es sind keine Systemaktivierungen im System vorhanden. Erstellen Sie neue, um zu beginnen",
    selloutOrder: "Sell-out-Auftrag",
    intragroupOrder: "Intragruppen-Auftrag",
    custref: "Kunden-Referenz",
    rejectedReason: "Ablehnungsgrund",
    changeStatusToUnderReview: "Zur Prüfung senden",
    changeStatusToInvoiced: "Auf Rechnung setzen",
    changeStatusToCreated: "Als erstellt markieren",
    changeStatusToRejected: "Anfrage ablehnen",
    changeStatusToProcessing: "Anfrage akzeptieren",
    changeStatusAdmin: "Status festlegen (Admin)",
    changeCustRef: "Kunden-Referenz festlegen",
    search: "Suchen...",
    resetFilters: "Filter zurücksetzen",
    statusSelection: "Jeder Status",
    clusterSelection: "Jeder Cluster",
    statusDRAFT: "Entwurf",
    statusUNDER_REVIEW: "In Prüfung",
    statusREJECTED: "Abgelehnt",
    statusPROCESSING: "In Bearbeitung",
    statusCREATED: "Erstellt",
    statusINVOICED: "Rechnungs gestellt",
    statusACTIVE: "Aktiv",
    statusDELETED: "Gelöscht",
    promote: "Löschen",
    saveDraft: "Als Entwurf speichern",
    customMarketplaceWarning: "Der Endnutzer ist nicht dem Standard-Marktplatz zugeordnet. Sie müssen das angeforderte Basisabonnement und die Add-ons manuell im Bereich 'Notizen' angeben",
    subscriptionNotFoundError: "Abonnement nicht gefunden",
    deleteActivationConfirm: "Sind Sie sicher, dass Sie diese Systemaktivierung löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden",
    deleteActivationError: "Beim Löschen der Systemaktivierung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    promoteActivationConfirm: "Sind Sie sicher, dass Sie diese Systemaktivierung zur Prüfung senden möchten? Die Anfrage kann in der Prüfung nicht bearbeitet werden.",
    promoteActivationError: "Beim Senden der Systemaktivierung zur Prüfung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    createdActivationConfirm: "Sind Sie sicher, dass Sie diese Systemaktivierung auf den Status ERSTELLT setzen möchten?",
    createdActivationError: "Beim Aktualisieren des Status der Systemaktivierung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    invoicedActivationConfirm: "Sind Sie sicher, dass Sie diese Systemaktivierung als Rechnungs gestellt markieren möchten?",
    invoicedActivationError: "Beim Aktualisieren des Status der Systemaktivierung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    editStatusActivationConfirm: "Sind Sie sicher, dass Sie den Status dieser Systemaktivierung bearbeiten möchten? Dieser Vorgang kann nicht rückgängig gemacht werden",
    editStatusActivationError: "Beim Aktualisieren des Status der Systemaktivierung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    rejectActivationConfirm: "Geben Sie den Ablehnungsgrund für diese Anfrage an.",
    rejectActivationError: "Beim Ablehnen der Systemaktivierung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    processingActivationConfirm: "Sind Sie sicher, dass Sie diese Systemaktivierung akzeptieren möchten?",
    processingActivationError: "Beim Akzeptieren der Systemaktivierung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    newFileName: "Geben Sie den Dateinamen an",
    newFileExtensionError: "Die Datei hat eine nicht unterstützte Erweiterung",
    newFileDuplicateNameError: "Dieser Name wurde bereits für eine andere Datei verwendet. Ändern Sie den Namen und versuchen Sie es erneut.",
    editCustrefConfirm: "Geben Sie die Kunden-Referenz für diese Anfrage an.",
    editCustrefError: "Beim Aktualisieren der Kunden-Referenz ist ein Fehler aufgetreten, bitte versuchen Sie es erneut",
    editActivation: "Systemaktivierung bearbeiten",
    newActivation: "Systemaktivierung hinzufügen",
    viewActivation: "Systemaktivierung anzeigen",
    newActivationError: "Beim Erstellen dieser Systemaktivierung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    editActivationError: "Beim Aktualisieren dieser Systemaktivierung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    customerSearch: "Unter den vorhandenen Endnutzern suchen",
    customerSearchEnterName: "Nach Name suchen...",
    customerSearchNoMatch: "Keine Endnutzer gefunden...",
    customerProceedWithSelected: "Ausgewählten Endnutzer verwenden",
    customerProceedWithNew: "Neuen Endnutzer erstellen",
    customerConfirmData: "Daten des Endnutzers bestätigen",
    customerData: "Kundendaten",
    varData: "VAR-Daten",
    selectCustomerOrVar: "Wählen Sie das Ziel für diese Anfrage (Endnutzer oder VAR)",
    varSfdcId: "SFDC ACCOUNT ID",
    varName: "Name",
    varSearch: "Unter den vorhandenen VARs suchen",
    varSearchEnterName: "Nach Name suchen...",
    varSearchNoMatch: "Kein VAR gefunden...",
    varCustomerSearch: "Unter den Endnutzern des VAR suchen",
    varProceedWithSelected: "Ausgewählten VAR verwenden",
    customerFormTitle: "Luckey Endnutzer",
    customerName: "Name des Endnutzers",
    customerVat: "USt.",
    customerFiscalCode: "Steuercode",
    customerCountry: "Land",
    customerAddress: "Adresse",
    customerCity: "Stadt",
    customerEmail: "E-Mail",
    customerPhone: "Telefon",
    customerCrmId: "SFDC ACCOUNT ID",
    customerMarketplace: "Marktplatz",
    customerCertifiedEmail: "PEC (Zertifizierte E-Mail)",
    customerCluster: "Cluster",
    customerVar: "VAR",
    customerCompany: "ISEO-Unternehmen",
    customerPostalCode: "Postleitzahl",
    customerPrincipalSubDivision: "Bezirk, Bundesland, Kreis, etc.",
    iseoSalesOrderReference: "ISEO Verkaufsauftrag (ISEO -> Kunde)",
    iseoSalesOrderDate: "ISEO Verkaufsauftragsdatum",
    iseoPurchaseOrderReference: "ISEO Einkaufsauftrag (ISEO -> Sofia)",
    iseoPurchaseOrderDate: "ISEO Einkaufsauftragsdatum",
    sofiaSalesOrderReference: "Sofia Verkaufsauftrag (Sofia -> ISEO)",
    sofiaSalesOrderDate: "Sofia Verkaufsauftragsdatum",
    pickDate: "Datum auswählen",
    crmSource: "CRM Opportunity Quelle",
    crmId: "Eindeutiger CRM Opportunity Identifier",
    sofiaProjectId: "Sofia Projekt Referenz-Identifier",
    crmLink: "URL zur CRM Opportunity",
    kamReference: "KAM-Referenz",
    edit: "Bearbeiten",
    editCardConfirm: "Sind Sie sicher, dass Sie die Aufladekarte bearbeiten möchten?",
    editVarOpportunityConfirm: "Sind Sie sicher, dass Sie dieses Angebot bearbeiten möchten?",
    insertEmailOTPLoginForm: "Geben Sie Ihre Konto-E-Mail ein, um den OTP-Code zu erhalten und auf die Plattform zuzugreifen",
    insertEmailOTPLoginFormDisclaimer: "Wenn die E-Mail autorisiert ist, erhalten Sie einen OTP-Code zum Zugriff",
    insertOTPOTPLoginForm: "Geben Sie den an Ihre Konto-E-Mail gesendeten OTP-Code ein",
    insertOTPOTPLoginFormDisclaimer: "Falls Sie den Code nach 5 Minuten nicht erhalten, wiederholen Sie den Vorgang",
    pickDateTo: "Datum auswählen bis:",
    pickDateFrom: "Datum auswählen von",
    AUTOMATIC: "Automatische Aktivierung",
    MANUAL: "Manuelle Aktivierung",
    SEMI_AUTOMATIC: "Halbautomatische Aktivierung",
    AUTOMATIC_Description: "Die Erweiterung kann unabhängig vom Shop vom Benutzer aktiviert werden. Die Aktivierungscredits werden sofort abgezogen",
    MANUAL_Description: "Erweiterung, die nur nach einer ausdrücklichen Anfrage des Endnutzers aktiviert werden kann. Die Credits werden manuell nach Genehmigung der Anfrage abgezogen",
    SEMI_AUTOMATIC_Description: "Erweiterung, die nur nach einer ausdrücklichen Anfrage des Endnutzers aktiviert werden kann. Die Aktivierungscredits werden im Moment der Anfrage abgezogen",
    requestToQuote: "Angebot angefordert",
    requestToQuoteDescription: "Geben Sie an, ob die Erweiterung einem Angebot unterliegt, anstatt Credits direkt zu verwenden (z. B. bei Integrationen und Anpassungen)",
    subscriptionCanBeDeactivated: "Kann vom Manager deaktiviert werden",
    subscriptionCanBeDeactivatedDescription: "Geben Sie an, ob der Manager das Abonnement im Luckey Store deaktivieren kann",
    selectSegmentType: "Segment auswählen",
    sofiaInvoiceNumber: "Sofia Rechnungsnummer",
    sofiaInvoiceAmount: "Sofia Rechnungsbetrag",
    sofiaInvoiceYear: "Sofia Rechnungsjahr",
    iseoInvoiceNumber: "ISEO Rechnungsnummer",
    iseoInvoiceAmount: "ISEO Rechnungsbetrag",
    iseoInvoiceYear: "ISEO Rechnungsjahr",
    freeOfCharge: "Kostenlos",
    freeOfChargeReason: "Grund",
    currency: "Währung",
    sofiaInvoiceDate: "Rechnungsdatum",
    iseoInvoiceDate: "Rechnungsdatum",
    sofiaInvoiceRecipient: "Rechnungsempfänger",
    iseoInvoiceRecipient: "Rechnungsempfänger",
    serviceSupplementaryInvoice: "Service-Nachrechnung",
    serviceSupplementaryInvoiceNumber: "Rechnungsnummer",
    serviceSupplementaryInvoiceAmount: "Rechnungsbetrag",
    serviceSupplementaryInvoiceDate: "Rechnungsdatum",
    serviceSupplementaryInvoiceRecipient: "Rechnungsempfänger",
    iseoInvoiceIssuer: "Rechnungsaussteller",
    backdropClosing: "Sind Sie sicher, dass Sie das Formular ohne Speichern schließen möchten?",
    roles: "Rollen"
  },
  recharges: {
    addNewRechargeButton: "Neue Wallet-Aufladung hinzufügen",
    noRechargesFoundTitle: "Keine Aufladungen gefunden",
    noRechargesFoundDescription: "Es sind keine Wallet-Aufladungen im System vorhanden. Erstellen Sie neue, um zu beginnen",
    customerAndWallet: "Endnutzer und Wallet",
    seeCustomer: "Infos",
    seeCustomerDetails: "Endnutzer-Details anzeigen",
    selloutOrder: "Sell-out-Auftrag",
    intragroupOrder: "Intragruppen-Auftrag",
    custref: "Kunden-Referenz",
    mode: "Vertragsverlängerungstyp:",
    creditsSuggested: "Vorgeschlagene Credits",
    creditsConfirmed: "Bestätigte Credits",
    rejectedReason: "Ablehnungsgrund",
    deletedReason: "Löschgrund",
    createdAt: "Erstellt am",
    updatedAt: "Aktualisiert am",
    customerSearch: "Unter den vorhandenen Endnutzern suchen",
    customerSearchEnterName: "Nach Name suchen...",
    customerSearchNoMatch: "Keine Endnutzer gefunden...",
    varSearch: "Unter den vorhandenen VARs suchen",
    varSearchEnterName: "Nach Name suchen...",
    varSearchNoMatch: "Kein VAR gefunden...",
    varCustomerSearch: "Unter den Endnutzern des VAR suchen",
    plantSearch: "Wählen Sie den gewünschten Standort",
    plantSearchEnterName: "Standort auswählen",
    plantSearchNoMatch: "Kein Standort gefunden...",
    plantProceedWithSelected: "Ausgewählten Standort/Wallet verwenden",
    dataMissingTakenCharge: "Bestätigte Credits und Sell-out angeben, um fortzufahren",
    dataMissingScratchcardCreated: "Rechnungsnummer und PDF angeben, um fortzufahren",
    changeStatusToSelloutOrderGenerated: "Zur Prüfung senden",
    changeStatusToInvoiced: "Als Rechnungs gestellt markieren",
    changeStatusToCreated: "Als erstellt markieren",
    changeStatusToRejected: "Anfrage ablehnen",
    changeStatusToScratchcardCreated: "Aufladung erstellt",
    changeStatusToWalletRecharged: "Wallet aufgeladen",
    changeStatusAdmin: "Status festlegen (Admin)",
    createCard: "Aufladekarte erstellen",
    changeCustRef: "Kunden-Referenz festlegen",
    search: "Suchen...",
    resetFilters: "Filter zurücksetzen",
    statusSelection: "Jeder Status",
    clusterSelection: "Jeder Cluster",
    statusTO_BE_MANAGED: "Zu verwalten",
    statusTAKEN_CHARGE: "In Bearbeitung",
    statusREQUEST_TO_DELETE: "Löschanfrage",
    statusSELLOUT_ORDER_GENERATED: "In Prüfung",
    statusSCRATCHCARD_CREATED: "Aufladung erstellt",
    statusWALLET_RECHARGED: "Aufgeladen",
    statusREJECTED: "Abgelehnt",
    statusDELETED: "Gelöscht",
    statusINVOICED: "Rechnungs gestellt",
    promote: "Löschen",
    saveRequest: "Anfrage speichern",
    createRequest: "Wallet-Aufladung erstellen",
    takeCharge: "Übernehmen",
    AUTO_RENEWABLE: "Automatisch verlängerbar",
    NOT_AUTO_RENEWABLE: "Nicht automatisch verlängerbar",
    deleteRechargeConfirm: "Sind Sie sicher, dass Sie diese Anfrage löschen möchten? Bitte geben Sie den Grund an. Dieser Vorgang kann nicht rückgängig gemacht werden",
    deleteAdminRechargeConfirm: "Sind Sie sicher, dass Sie diese Anfrage dauerhaft löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden",
    deleteRechargeError: "Beim Löschen der Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    takechargeRechargeConfirm: "Bitte geben Sie den Verlängerungstyp an, um mit dieser Anfrage fortzufahren",
    takechargeRechargeDirectConfirm: "Sind Sie sicher, dass Sie diese Anfrage übernehmen möchten?",
    takechargeRechargeError: "Beim Übernehmen der Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    promoteRechargeConfirm: "Sind Sie sicher, dass Sie diese Anfrage zur Prüfung senden möchten? Die Anfrage kann in der Prüfung nicht bearbeitet werden.",
    promoteRechargeError: "Beim Senden der Anfrage zur Prüfung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    createdRechargeConfirm: "Sind Sie sicher, dass Sie diese Anfrage auf den Status ERSTELLT setzen möchten?",
    createdRechargeError: "Beim Aktualisieren des Status der Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    invoicedRechargeConfirm: "Sind Sie sicher, dass Sie diese Anfrage als Rechnungs gestellt markieren möchten?",
    invoicedRechargeError: "Beim Aktualisieren des Status der Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    editStatusRechargeConfirm: "Sind Sie sicher, dass Sie den Status dieser Anfrage bearbeiten möchten? Dieser Vorgang kann nicht rückgängig gemacht werden",
    editStatusRechargeError: "Beim Aktualisieren des Status der Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    rejectRechargeConfirm: "Bitte geben Sie den Grund für die Ablehnung dieser Anfrage an.",
    rejectRechargeError: "Beim Ablehnen der Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    scratchcardCreatedRechargeConfirm: "Sind Sie sicher, dass Sie diese Anfrage akzeptieren und bestätigen möchten, dass die Aufladekarte erstellt wurde?",
    scratchcardCreatedRechargeError: "Beim Akzeptieren der Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    walletRechargedRechargeConfirm: "Sind Sie sicher, dass Sie diese Anfrage als abgeschlossen markieren und bestätigen möchten, dass das Wallet aufgeladen wurde?",
    walletRechargedRechargeError: "Beim Ändern des Status der Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    newFileName: "Bitte geben Sie den Dateinamen an",
    newFileExtensionError: "Die Datei hat eine nicht unterstützte Erweiterung",
    newFileDuplicateNameError: "Dieser Name wurde bereits für eine andere Datei verwendet. Ändern Sie den Namen und wiederholen Sie den Vorgang.",
    editCustrefConfirm: "Bitte geben Sie die Kunden-Referenz für diese Anfrage an.",
    editCustrefError: "Beim Aktualisieren der Kunden-Referenz ist ein Fehler aufgetreten, bitte versuchen Sie es erneut",
    editRecharge: "Wallet-Aufladung bearbeiten",
    newRecharge: "Neue Wallet-Aufladung",
    viewRecharge: "Wallet-Aufladung anzeigen",
    newRechargeError: "Beim Erstellen dieser Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    editRechargeError: "Beim Aktualisieren dieser Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
    exportRechargesCSV: "CSV exportieren",
    basicData: "Parameter der Wallet-Aufladung",
    ordersData: "Aufträge",
    invoiceData: "Rechnung",
    formEnterAnswer: "Ihre Antwort",
    formEnterAnswerGT0: "Zahl größer als 0",
    sfdcId: "Opportunity-ID (SFDC)",
    salesOrderConfirmationNumber: "Bestellbestätigungsnummer (Sell-out)",
    salesOrderConfirmationNumber_desc: "",
    selloutFiles: "Sell-out-Auftrag PDF (Hardware + Software + professionelle Dienstleistungen)",
    selloutFiles_desc: "Erforderlich, um die Blossom-Einnahmen korrekt zu verfolgen.",
    selloutFiles_desc2: "Dateibegrenzung: 5  ~  Maximale Dateigröße: 10MB  ~  Erlaubte Dateierweiterungen: ",
    selloutFilesAdd: "Datei hochladen",
    selloutFilesDownload: "Herunterladen",
    selloutFilesReplace: "Ersetzen",
    invoiceFile: "Rechnung PDF *",
    invoiceFile_desc: "Laden Sie die Rechnung im PDF-Format hoch.",
    invoiceFile_desc2: "Dateibegrenzung: 1  ~  Maximale Dateigröße: 10MB",
    invoiceNumber: "Rechnungsnummer (für den Endnutzer) *",
    purchaseOrderNumberSofia: "Bestellnummer an Sofia",
    orderContactEmail: "Kontakt-E-Mail der Bestellung"
  },
  vars: {
    varLogo: "Logo",
    setTrainingDate: "Schulungstermin festlegen",
    missingLastTrainingDateForCertificate: "Dieser VAR hat kein festgelegtes Schulungsdatum. Bitte legen Sie ein Schulungsdatum fest und versuchen Sie, das Zertifikat erneut zu generieren",
    varLastTrainingData: "Letztes Schulungsdatum",
    varShortDescription: "Kurzbeschreibung",
    varCity: "Stadt",
    varCountry: "Land",
    varWebsite: "Webseite",
    varVisibleOnSofiaWebsite: "Sichtbar auf der Partnerseite von Sofia",
    downloadCertificate: "Zertifikat herunterladen",
    opportunityChangeOwnerMessage: "Bitte geben Sie den Besitzer dieses Angebots an",
    noOpportunityHistoryFound: "Keine Angebots-Historie gefunden",
    noOpportunityHistoryFoundDescription: "Dieses Angebot hat keine Historieneinträge",
    customers: "Kunden",
    varsManagement: "VARs",
    varSettings: "Einstellungen",
    varInfoPage: "Schulung & Ressourcen",
    varUsersPage: "Benutzer",
    vars: "VAR",
    plantThemes: "Standort-Themen",
    editVarPersonalConfirm: "Sie sind dabei, Ihre Firmeninformationen zu bearbeiten. Sind Sie sicher, dass Sie fortfahren möchten?",
    editVarConfirm: "Sie sind dabei, die VAR-Daten zu bearbeiten. Sind Sie sicher, dass Sie fortfahren möchten?",
    editVar: "VAR bearbeiten",
    newVar: "Neuer VAR",
    varCreationConfirm: "Möchten Sie den neuen VAR erstellen?",
    createVar: "VAR erstellen",
    noVarFindTitle: "Kein VAR gefunden",
    noVarFindDescription: "Die VAR-Liste ist leer. Falls ein Suchfilter gesetzt wurde, versuchen Sie diesen zurückzusetzen und erneut zu suchen",
    noPlantThemeFindTitle: "Kein Standort-Thema gefunden",
    noPlantThemeFindDescription: "Die Standort-Themen-Liste ist leer. Falls ein Suchfilter gesetzt wurde, versuchen Sie diesen zurückzusetzen und erneut zu suchen",
    clusterSelection: "Jeder Cluster",
    varSelection: "Jeder VAR",
    varName: "Name",
    varAddress: "Adresse",
    varEmail: "Primäre Kontakt-E-Mail",
    varPhone: "Telefon",
    varCluster: "Cluster",
    varVat: "USt.",
    varTechContact: "Technischer Kontakt",
    varCommercialContact: "Kaufmännischer Kontakt",
    varNameTech: "Name des technischen Kontakts",
    varEmailTech: "E-Mail des technischen Kontakts",
    varPhoneTech: "Telefon des technischen Kontakts",
    varNameComm: "Name des kaufmännischen Kontakts",
    varEmailComm: "E-Mail des kaufmännischen Kontakts",
    varPhoneComm: "Telefon des kaufmännischen Kontakts",
    varSfdcid: "SFDC ACCOUNT ID",
    varSupportEmail: "Support-E-Mail",
    varSupportHours: "Support-Öffnungszeiten",
    varCompanyName: "Firmenname",
    varCustomers: "VAR-Endnutzer",
    varUsers: "VAR-Benutzer",
    varOpportunities: "VAR-Angebote",
    editPlantThemeConfirm: "Sie sind dabei, die Daten des Standort-Themas zu bearbeiten. Sind Sie sicher, dass Sie fortfahren möchten?",
    editPlantTheme: "Standort-Thema bearbeiten",
    newPlantTheme: "Neues Standort-Thema",
    plantThemeCreationConfirm: "Möchten Sie das neue Standort-Thema erstellen?",
    editVarPersonal: "Firmeninformationen bearbeiten",
    plantThemeName: "Name",
    plantThemeColour: "Farbe *",
    plantThemeColourDescription: "Themenfarbe",
    plantThemeLogo: "Logo",
    plantThemeDefault: "Als Standardthema verwenden",
    logoFile: "Logo-Bild *",
    logoFile_desc: "Erlaubte Dateierweiterungen: ",
    logoFileAdd: "Datei hochladen",
    logoFileDownload: "Herunterladen",
    logoFileReplace: "Ersetzen",
    deletePlantThemeConfirm: "Sind Sie sicher, dass Sie dieses Standort-Thema löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden",
    plantThemeSearchEnterName: "Thema auswählen",
    plantThemeSearch: "Wählen Sie das gewünschte Thema aus",
    domainThemeTitle: "Benutzerdefiniertes Domain-Thema",
    domainThemeDescription: "Legen Sie Ihr benutzerdefiniertes Thema für diese Domain fest",
    plantThemeModifySuccess: "Standort-Thema erfolgreich geändert und aktualisiert",
    cannotDeleteAssociatedTheme: "Ein mit einem Standort verknüpftes Thema kann nicht gelöscht werden.",
    plantThemeAssociatedPlants: "Standorte, die mit diesem Thema verknüpft sind",
    newVarUser: "Neuer VAR-Benutzer",
    varUserCreationConfirm: "Möchten Sie den neuen VAR-Benutzer erstellen?",
    createVarUser: "VAR-Benutzer erstellen",
    varUserFormTitle: "VAR-Benutzer",
    createNewVarUser: "Neuen VAR-Benutzer erstellen",
    noVarUserFindTitle: "Keine VAR-Benutzer gefunden",
    noVarUserFindDescription: "Die VAR-Benutzerliste ist leer",
    deleteVarUserConfirm: "Sind Sie sicher, dass Sie diesen VAR-Benutzer löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden",
    deleteVarOpportunityConfirm: "Sind Sie sicher, dass Sie dieses Angebot löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden",
    rejectVarOpportunityConfirm: "Sind Sie sicher, dass Sie dieses Angebot ablehnen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden",
    approveVarOpportunityConfirm: "Sind Sie sicher, dass Sie dieses Angebot genehmigen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden",
    syncsfdcidVarOpportunityConfirm: "Sind Sie sicher, dass Sie diesem Angebot eine SFDC-ID zuweisen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden",
    upgradeVarOpportunityConfirm: "Sind Sie sicher, dass Sie dieses Angebot zur Überarbeitung senden möchten? Dieser Vorgang kann nicht rückgängig gemacht werden",
    winVarOpportunityConfirm: "Sind Sie sicher, dass Sie dieses Angebot als GEWONNEN markieren möchten? Dieser Vorgang kann nicht rückgängig gemacht werden",
    lostVarOpportunityConfirm: "Sind Sie sicher, dass Sie dieses Angebot als VERLOREN markieren möchten? Dieser Vorgang kann nicht rückgängig gemacht werden",
    deleteUserConfirm: "Sind Sie sicher, dass Sie diesen Benutzer löschen möchten? Dieser Vorgang kann nicht rückgängig gemacht werden",
    varUserUsername: "Benutzername",
    varUserFirstname: "Vorname",
    varUserLastname: "Nachname",
    varUserEmail: "E-Mail",
    varUsersList: "Benutzer",
    newFileExtensionError: "Die Datei hat eine nicht unterstützte Erweiterung",
    agreementFile: "Vertrag",
    opportunities: "Angebote",
    opportunityManagement: "Angebotsinformationen",
    opportunityID: "ID",
    opportunityOpty: "SFDC OPTY ID",
    opportunityVar: "VAR",
    opportunityCustomer: "Endnutzer",
    opportunityOwner: "Angebotsbesitzer",
    opportunityName: "Angebotsname",
    opportunityValue: "Wert",
    opportunityStatus: "Status",
    opportunityActions: "Aktionen",
    opportunityDescription: "Beschreibung",
    opportunityNotes: "Notizen",
    opportunityCrm: "CRM",
    noOpportunityFindTitle: "Keine Angebote gefunden",
    noOpportunityFindDescription: "Die Angebotsliste ist leer",
    opportunityStatus_DRAFT: "Entwurf",
    opportunityStatus_ON_APPROVAL: "In Überprüfung",
    opportunityStatus_APPROVED: "Genehmigt",
    opportunityStatus_CANCELLED: "Abgebrochen",
    opportunityStatus_REJECTED: "Abgelehnt",
    opportunityStatus_WON: "Gewonnen",
    opportunityStatus_LOST: "Verloren",
    opportunityFiles: "Anhänge",
    newOpportunity: "Neues Angebot",
    editOpportunity: "Angebot bearbeiten",
    opportunitySyncSFDC: "Mit SFDC synchronisieren",
    exportOpportunities: "CSV exportieren",
    upgradeOpportunity: "Zur Überprüfung senden",
    approveOpportunity: "Genehmigen",
    rejectOpportunity: "Ablehnen",
    wonOpportunity: "Als GEWONNEN markieren",
    lostOpportunity: "Als VERLOREN markieren",
    CREATED: "Angebot erstellt",
    VAR_USER_UPDATED: "VAR-Benutzer des Angebots aktualisiert",
    DESCRIPTION_UPDATED: "Angebotsbeschreibung aktualisiert",
    NAME_UPDATED: "Angebotsname aktualisiert",
    VALUE_UPDATED: "Angebotswert aktualisiert",
    CURRENCY_UPDATED: "Angebotswährung aktualisiert",
    OPTY_ID_UPDATED: "Angebot Opty ID aktualisiert",
    ISEO_CRM_ID_UPDATED: "Angebot SFDC ID aktualisiert",
    DOCUMENTS_UPDATED: "Angebotsdokumente aktualisiert",
    STATUS_ON_APPROVAL: "Angebot zur Genehmigung gesendet",
    STATUS_APPROVED: "Angebot genehmigt",
    STATUS_WON: "Angebot als gewonnen markiert",
    STATUS_LOST: "Angebot als verloren markiert",
    STATUS_DRAFT: "Angebot als Entwurf markiert",
    STATUS_CANCELLED: "Angebot als abgebrochen markiert",
    CANCELLED: "Angebot abgebrochen",
    STATUS_REJECTED: "Angebot als abgelehnt markiert",
    showOpportunityHistory: "Historie anzeigen",
    opportunityHistory: "Angebotshistorie"
  },
  save: "Speichern",
  languages: {
    it: "Italienisch",
    en: "Englisch",
    de: "Deutsch",
    fr: "Französisch",
    es: "Spanisch",
    nl: "Niederländisch",
    ITALIAN: "Italienisch",
    ENGLISH: "Englisch",
    GERMAN: "Deutsch",
    FRENCH: "Französisch",
    SPANISH: "Spanisch",
    DUTCH: "Niederländisch",
    menuHeading: "Sprache auswählen"
  },
  productInfo: {
    software: "Software",
    salesMaterial: "Verkaufsmaterial",
    hardware: "Hardware",
    marketingMaterial: "Marketingmaterial"
  },
  users: {
    usersManagement: "Benutzerverwaltung",
    users: "Benutzer",
    noUserFindTitle: "Keine Benutzer gefunden",
    noUserFindDescription: "Die Benutzerliste ist leer. Versuchen Sie, Ihre Suchfilter zurückzusetzen, falls vorhanden",
    search: "Suchen...",
    roleSelection: "Rolle",
    clusterSelection: "Cluster",
    varSelection: "VAR",
    newUser: "Hinzufügen",
    userFirstname: "Vorname",
    userLastname: "Nachname",
    userEmail: "E-Mail",
    userFormTitle: "Benutzerdaten",
    editUserForm: "Benutzer bearbeiten",
    newUserForm: "Benutzer erstellen"
  },
  permissions: {
    NAVIGATION: "Menü-Sichtbarkeit",
    CLUSTER: "Cluster",
    VAR: "VAR",
    CUSTOMER: "Endnutzer",
    OPPORTUNITY: "Angebote",
    WALLET: "Endnutzer-Wallets",
    DOMAIN: "Endnutzer-Systeme",
    GATEWAYS: "Gateway",
    CARD: "Aufladekarten",
    PLANT_ACTIVATION: "Systemaktivierungen",
    RECHARGE_REQUEST: "System-Aufladungen",
    ACTIVATION_REQUEST: "Aktivierungsanfragen",
    EXPORT: "Entitäten exportieren",
    CREATE: "Neue Entität erstellen",
    UPDATE: "Entität aktualisieren",
    MARK_LOST: "Entität als verloren markieren",
    MARK_WON: "Entität als gewonnen markieren",
    CANCEL: "Entität als storniert markieren",
    CANCEL_DRAFT: "Entität als storniert markieren, wenn es sich um einen Entwurf handelt",
    CANCEL_ON_APPROVAL: "Entität als storniert markieren, wenn sie in der Prüfung ist",
    CANCEL_APPROVED: "Entität als storniert markieren, wenn sie genehmigt ist",
    MARK_TO_BE_APPROVED: "Entität zur Genehmigung markieren",
    RECHARGE: "Aufladen",
    SUBSCRIPTION_UPDATE: "Abonnements aktualisieren",
    DELETE_REQUEST: "Löschanfrage senden",
    CREATION_REQUEST: "Anfrage zur Erstellung einer neuen Entität senden",
    MANAGER_CREATE: "Administratoren erstellen",
    MANAGER_UPDATE: "Administratoren aktualisieren",
    INSTALLER_CREATE: "Installer erstellen",
    INSTALLER_UPDATE: "Installer aktualisieren",
    IMPERSONATE_WRITE: "Administratoren imitieren",
    IMPERSONATE_SUPPORT: "Support-Konto imitieren",
    ADDITIONAL_INFO_UPDATE: "Zusätzliche Informationen bearbeiten",
    CUSTOMERS: "Endnutzer",
    PLANT_ACTIVATIONS: "Systemaktivierungen",
    RECHARGE_REQUESTS: "System-Aufladungen",
    ACTIVATION_REQUESTS: "Aktivierungsanfragen",
    CARDS: "Aufladekarten",
    VAR_USERS: "VAR-Benutzer",
    VAR_SETTINGS: "VAR-Einstellungen",
    OPPORTUNITIES: "Angebote",
    PRODUCTINFO: "Produktinformationen",
    USER_READ: "Benutzer anzeigen",
    USER_UPDATE: "Benutzer aktualisieren",
    USER_CREATE: "Neuen Benutzer erstellen",
    PLANT_THEME: "Domain-Thema ändern",
    READ: "Entitätswerte lesen",
    ORDERS_STATUS: "Auftragsstatus",
    FILTER: "Entitäten filtern",
    VAR_MANAGEMENT: "VAR-Verwaltung",
    MARKETPLACES: "Marktplätze",
    DOMAINS: "Endnutzer-Systeme",
    PRICELIST: "Preisliste",
    SALESINFO: "Verkaufsinformationen",
    PLATFORM_USERS: "Benutzerverwaltung",
    APPROVE: "Entität als genehmigt markieren",
    REJECT: "Entität als abgelehnt markieren",
    EXTEND: "Erweitern",
    CONFIG: "Entitätskonfigurationen lesen",
    IRIS_ENABLE: "1RIS für die Domain aktivieren",
    MARK_TO_PROMOTE: "Entität zur Förderung markieren",
    MARK_TO_INVOCE: "Entität zur Rechnungsstellung markieren",
    DELETE: "Entitäten löschen",
    EDIT: "Entitäten bearbeiten",
    SUBSCRIPTION_DELETE: "Abonnements löschen"
  },
  segmentTypes: {
    WORKING_SPACES_name: "Flexibler Arbeitsbereich",
    LIVING_name: "Flexibles Wohnen",
    RETAIL_name: "Flexibler Einzelhandel",
    UNIVERSITIES_name: "Universitäten",
    LEISURE_name: "Freizeit und Gastgewerbe",
    HOSPITAL_name: "Krankenhaus",
    WORKING_SPACES_description: "Büros, Coworking, Business Center",
    LIVING_description: "Wohnung, Co-Housing, Co-Living, Studentenwohnheim",
    RETAIL_description: "Multistandort Einzelhandel, Einkaufszentren, Ladenketten",
    LEISURE_description: "Kleine und mittelgroße Hotels, Unterkünfte, Sportzentren",
    HOSPITAL_description: "Krankenhäuser, Seniorenresidenzen und Altenpflege"
  }
};
 
export default translations; 