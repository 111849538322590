import { PaginatedDataDTO, PaginationDTO } from "@bottega52/commons-pagination";
import { Button, Card } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { DeleteOutline, SecurityRounded } from "@material-ui/icons";
import PersonAddIcon from '@material-ui/icons/PersonAddOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import { GridColumns } from "@mui/x-data-grid";
import _ from 'lodash';
import * as React from "react";
import { ConnectedProps, connect } from 'react-redux';
import * as UserDecoder from '../../codec/userInDTODecoder';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import * as UserActions from '../../redux/user/user.actions';
import * as VarsActions from '../../redux/vars/vars.actions';
import { IUserFormDTO } from "../../repository/jago/model/input/IUserFormDTO";
import { IUserInDTO } from "../../repository/jago/model/input/IUserInDTO";
import { IVarInDTO } from "../../repository/jago/model/input/IVarInDTO";
import AbilityProvider from "../../services/PermissionService/AbilityProvider";
import { PERMISSIONS } from "../../services/PermissionService/PermissionConstants";
import translations from "../../translations/i18next";
import { getRoleReadableName } from "../../utils/Utils";
import VarUserForm from "../Forms/VarUserForm";
import { ModalTypes } from "../Modals/ModalTypes";
import Permission from "../Permission/Permission";
import ReduxLanguage from "../ReduxLanguage";
import UsersTable from "../Users/UsersTable";

const styles = createStyles({
  container: {
    display: 'flex',
    marginTop: 20,
    flexDirection: 'column',
  },
  cardContainer: {
    padding: 10,
    borderLeft: '3px solid #5AC0B1',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginRight: 5,
    fontSize: 25,
  },
  title: {
    fontWeight: 'bold'
  },
  tableContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: 'calc(100vh - 350px)',
    width: '100%',
    marginTop: 10
  }
});

export interface IVarUsersSectionState {
  rowsPerPage: number;
}

type IReduxProps = ConnectedProps<typeof connector>;

export interface IVarUsersSectionProps extends WithStyles<typeof styles> {
  language: string;
  varUsers: PaginatedDataDTO<IUserInDTO>;
  VAR: IVarInDTO;
}

export type ComponentProps = IVarUsersSectionProps & IReduxProps;

class VarUsersSection extends React.Component<ComponentProps, IVarUsersSectionState> {
  private usersColumns: GridColumns<IUserInDTO>;

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      rowsPerPage: 100,
    }
  }

  onDeleteVarUserRequest(varUser: IUserInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`DELETE_VARUSER_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'vars.deleteVarUserConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onDeleteVarUserConfirm(varUser),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`DELETE_VARUSER_CONFIRM_MODAL`)),
      }
    }));
  }

  async onDeleteVarUserConfirm(varUser: IUserInDTO) {
    const { VAR, dispatch, varUsers } = this.props;
    const { rowsPerPage } = this.state;
    const pagination = varUsers.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const deleteResponse: any = await dispatch<any>(VarsActions.deleteVarUser(VAR.id, varUser.id));
      if (deleteResponse==="OPPORTUNITIES_ERROR") {
        dispatch<any>(ModalsActions.showModal(`ERROR_VARUSER_DELETE`, {
          modalType: ModalTypes.ERROR_MODAL,
          modalProps: {
            titleMessageKey: 'errors.error',
            errorMessageKey: 'errors.deleteVarUserErrorOpportunities',
          }
        }));
      }
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('DELETE_VARUSER_CONFIRM_MODAL'));
      await dispatch<any>(VarsActions.fetchVarUsers(VAR.id, { page: pagination.number, pageSize: rowsPerPage  }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_VARUSER_DELETE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.deleteVarUserError',
        }
      }));
    }
  }

  public onCreateNewVarUserRequest(newVarUserData: IUserFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATE_VAR_USER_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'vars.newVarUser',
        successMessageKey: 'vars.varUserCreationConfirm',
        confirmMessageKey: 'vars.createVarUser',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreateNewVarUserConfirm(newVarUserData),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_VAR_USER_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onCreateNewVarUserConfirm(newVarUserData: IUserFormDTO) {
    const { dispatch, varUsers, VAR } = this.props;
    const { rowsPerPage } = this.state;
    const pagination = varUsers.pagination as PaginationDTO;
    try {
      dispatch<any>(ModalsActions.hideModal(`CREATE_VAR_USER_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(VarsActions.createNewVarUser(VAR.id,newVarUserData));
      await dispatch<any>(VarsActions.fetchVarUsers(VAR.id, { page: pagination.number, pageSize: rowsPerPage }));
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_CREATE_VAR_USER_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_VAR_USER_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createVarUserError',
        }
      }));
    }
  }

  async onOpenNewVarUserForm() {
    const { dispatch, VAR } = this.props;
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_CREATE_VAR_USER_MODAL`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL_BACKDROP,
      modalProps: {
        content: (
          <VarUserForm
            onCreateEditVarUser={(newVarUserData: IUserFormDTO) => this.onCreateNewVarUserRequest(newVarUserData)}
          />
        ),
        titleMessageKey: 'vars.varUserFormTitle',
      }
    }));
  }

  public async onRowClick(user: IUserInDTO) {
    const { dispatch } = this.props
    try {
      const userParsed = UserDecoder.decodeUserContentToForm(user)
      dispatch<any>(ModalsActions.showModal(`OPERATIONAL_USER_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          onClose: () => {},
          content: (
            <VarUserForm
              isEditing={true}
              varUser={userParsed}
              onCreateEditVarUser={(newVarUserData: IUserFormDTO) => this.onEditUserRequest(user.id, newVarUserData)}
            />
          ),
          titleMessageKey: 'users.editUserForm',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_USER_DETAILS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.openSystemParametersError',
        }
      }));
    }
  }

  public onEditUserRequest(userId: number, newUserData: IUserFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATE_USER_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.editUser',
        successMessageKey: 'credits.editCreationUserConfirm',
        confirmMessageKey: 'credits.editUser',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onEditUserConfirm(userId, newUserData),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_USER_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onEditUserConfirm(userId: number, newUserData: IUserFormDTO) {
    const { VAR, dispatch, varUsers } = this.props;
    const { rowsPerPage } = this.state;
    const pagination = varUsers.pagination as PaginationDTO;
    try {
      dispatch<any>(ModalsActions.hideModal(`CREATE_USER_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(UserActions.editUser(userId, newUserData));
      await dispatch<any>(VarsActions.fetchVarUsers(VAR.id, { page: pagination.number, pageSize: rowsPerPage}));
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_USER_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      console.log(error)
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_USER_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createUserError',
        }
      }));
    }
  }

  async onPageSizeChange(pageSize) {
    const { VAR, dispatch } = this.props;
    this.setState({ rowsPerPage: pageSize });
    await dispatch<any>(VarsActions.fetchVarUsers(VAR.id, { page: 0, pageSize }));
  }

  async onFetchCustomersOnPage(page) {
    const { VAR, dispatch } = this.props;
    const { rowsPerPage } = this.state;
    await dispatch<any>(VarsActions.fetchVarUsers(VAR.id, { page, pageSize: rowsPerPage }));
  }
  
  public render() {
    const { varUsers, classes } = this.props;
    const { rowsPerPage } = this.state;

    this.usersColumns = [
      {
        field: 'firstname',
        headerName: translations.t('forms.user'),
        valueGetter: (params) => `${params.row?.firstname} ${params.row?.lastname}`,
        renderCell: (params: any) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <h4 style={{ fontWeight: 'bold', margin: 0 }}>{params.row?.firstname + " " + params.row?.lastname}</h4>
              <h4 style={{ fontWeight: 'normal', margin: 0 }}>{params.row?.email}</h4>
              {params.row?.currentVar?(
                <div style={{marginLeft:0}}>
                  <h3 style={{fontStyle:'italic',fontWeight:'bold',fontSize:'0.8em',display:'inline',border:'1px solid grey',borderRadius:5,marginRight:-2}}>
                    {"VAR "}
                  </h3>
                  <h3 style={{fontSize:'0.8em',display:'inline', marginLeft:-10,paddingLeft:20,paddingRight:5}}>
                    {params.row?.currentVar.name}
                  </h3>
                </div>
              ) : null}
            </div>
          )
        },
        type: 'string',
        sortable: false,
        width: 250,
      },
      {
        field: 'role',
        headerName: translations.t('forms.roles'),
        renderCell: (params: any) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {_.map(params.row.roles, role => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <SecurityRounded style={{ fontSize: 15 }}/>
                  <h4 style={{ padding:6, marginLeft: 0, fontSize:'0.9em', fontWeight:'bold'}} key={role.id}>
                    {getRoleReadableName(role.name)}
                  </h4>
                </div>
              ))}
            </div>
          )
        },
        type: 'string',
        flex: 1,
        minWidth: 150,
        resizable: true,
      },
      {
        field: 'actions',
        headerName: translations.t('forms.actions'),
        renderCell: (params: any) => {
          const user = params.row;
          const canDeleteUser = AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.VAR_USER_UPDATE);
          if (!canDeleteUser) return null;
          return (
            <Button
              variant="contained"
              style={{
                backgroundColor: 'darkred',
                flexShrink: 0,
                marginRight: 20,
                width: 'fit-content',
                color: 'white',
                padding: 4,
                paddingLeft: 10,
                paddingRight: 10,
                fontWeight: 'bold'}}
              onClick={(e) => {e.stopPropagation(); this.onDeleteVarUserRequest(params.row)}}
            >
              <DeleteOutline style={{ fontSize: 15 }}/>
              <h5 style={{ fontWeight: 'bold', margin: 0, marginLeft: 5 }} ><ReduxLanguage languageKey={"forms.delete"} /></h5>
            </Button>
          )
        },
        type: 'string',
        sortable: false,
        minWidth: 150,
      },
    ]

    return (
      <div className={classes.container}>
        <Card className={classes.cardContainer}>
          <div className={classes.header}>
            <div className={classes.headerInner}>
              <PersonAddIcon className={classes.icon} />
              <h3 className={classes.title}>{<ReduxLanguage languageKey="vars.varUsers"/>}</h3>
            </div>
            <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.VAR_USER_CREATE}>
              <Button
                variant="contained"
                style={{ fontWeight: 'bold', backgroundColor: '#5AC0B1', fontSize: 11, color: 'white' }}
                onClick={() => {this.onOpenNewVarUserForm()}}
              >
                <ReduxLanguage languageKey="vars.createNewVarUser" />
              </Button>
            </Permission>
          </div>
          <div className={classes.tableContainer}>
            <UsersTable
              users={varUsers}
              tableColumns={this.usersColumns}
              onFetchElementsOnPage={page => this.onFetchCustomersOnPage(page)}
              onPageSizeChange={pageSize => this.onPageSizeChange(pageSize)}
              rowsPerPage={rowsPerPage}
              onRowClick={user => this.onRowClick(user)}
              onDeleteUserRequest={(varUser) => {this.onDeleteVarUserRequest(varUser)}}
            />
          </div>
        </Card>
      </div>
    );
  }
}


function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    vars: state.vars.vars.data,
    userData: state.user.userData,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles (styles)(VarUsersSection as any));