import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { Button, IconButton, Snackbar } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import CopyToClipboard from 'react-copy-to-clipboard';
import { DeleteOutline, FileCopyOutlined, PublicOutlined, SearchOutlined, SecurityRounded } from '@material-ui/icons';
import AssignmentIndIcon from '@material-ui/icons/AssignmentIndOutlined';
import { Stack } from '@mui/system';
import { DataGrid, GridCloseIcon, GridColumns } from '@mui/x-data-grid';
import _ from 'lodash';
import * as React from "react";
import { IUserInDTO } from '../../repository/jago/model/input/IUserInDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import translations from '../../translations/i18next';
import { getRoleReadableName } from '../../utils/Utils';
import ReduxLanguage from '../ReduxLanguage';
import CopyToClipboardWrapper from '../CopyToClipboardWrapper/CopyToClipboardWrapper';
import { PERMISSIONS } from '../../services/PermissionService/PermissionConstants';
import { connect, ConnectedProps } from 'react-redux';
import { IState } from '../../redux/store';
import { RouteComponentProps } from 'react-router-dom';

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column'
  },
  rowBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  logo: {
    width: '20%',
  },
});


export interface IUsersTableState {
}

type IReduxProps = ConnectedProps<typeof connector>;

export interface IUsersTableProps extends WithStyles<typeof styles>{
  users: PaginatedDataDTO<IUserInDTO>;
  vars?: PaginatedDataDTO<IVarInDTO>;
  rowsPerPage: number;
  isLoading?: boolean;
  onRowClick: (customer: IUserInDTO) => void;
  onFetchElementsOnPage: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onDeleteUserRequest?: (user: IUserInDTO) => void;
  onVarClick?: (VAR: IVarInDTO) => void;
  tableColumns?: GridColumns<IUserInDTO>;
}

export type ComponentProps = IUsersTableProps & IReduxProps;

class UsersTable extends React.Component<ComponentProps, IUsersTableState> {
  private defaultColumns: GridColumns<IUserInDTO>;


  public render() {
    const { users, vars, rowsPerPage, onVarClick, onRowClick, onPageSizeChange, onFetchElementsOnPage, onDeleteUserRequest, tableColumns, userData } = this.props;
    this.defaultColumns = [
      {
        field: 'id',
        headerName: 'Id',
        type: 'string',
        sortable: false,
        filterable: false,
        width: 60,
        renderCell: (params) => {
          const user = params.row;
          return (
            <div>
              <h3 style={{ margin: 0, fontSize: 13, whiteSpace: 'normal' }}>{user.id}</h3>
            </div>
        )}
      },
      {
        field: 'firstname',
        headerName: translations.t('forms.user'),
        valueGetter: (params) => `${params.row?.firstname} ${params.row?.lastname}`,
        renderCell: (params: any) => {
          return (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h3 style={{ fontWeight: 'bold', margin: 0, fontSize: 14 }}>{params.row?.firstname + " " + params.row?.lastname}</h3>
                <div style={{ display: 'flex', flexDirection: 'row'}}>
                  <h3 style={{ fontWeight: 'normal', margin: 0, fontSize: 14 }}>{params.row?.email}</h3>
                  <CopyToClipboardWrapper textToCopy={params.row?.email} />
                </div>
              </div>
          )
        },
        type: 'string',
        sortable: false,
        minWidth: 350,
        flex: 1,
      },
      {
        field: 'role',
        headerName: translations.t('forms.roles'),
        renderCell: (params: any) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {_.map(params.row.roles, role => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <SecurityRounded style={{ fontSize: 15 }}/>
                  <h4 style={{ padding:6, marginLeft: 0, fontSize:'0.9em', fontWeight:'bold'}} key={role.id}>
                    {getRoleReadableName(role.name)}
                  </h4>
                </div>
              ))}
            </div>
          )
        },
        type: 'string',
        flex: 1,
        minWidth: 150,
        resizable: true,
      },
      {
        field: 'varId',
        headerName: 'VAR',
        renderCell: (params: any) => {
          const user = params.row;
          const varsData = (vars as PaginatedDataDTO<IVarInDTO>);
          const customerVar = varsData && _.find(varsData.content, { id: user.varId });
          return (
            <div>
              {customerVar ? (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <AssignmentIndIcon style={{ fontSize: '1em', fontWeight: 'bold', color: 'slateblue'  }}/>
                  <h4
                    className="linkLabel"
                    style={{ padding: 6, marginLeft: 0, fontSize: '0.9em', fontWeight: 'bold', color: 'slateblue' }}
                    onClick={(e) => { e.stopPropagation(); onVarClick(customerVar); }}
                  >
                    {customerVar.name}
                  </h4>
                </div>
              ) : null}
            </div>
          )
        },
        type: 'string',
        sortable: false,
        minWidth: 220,
      },
      {
        field: 'clusters',
        headerName: translations.t('activations.cluster'),
        renderCell: (params: any) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {_.map(params.row.clusters, cluster => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <PublicOutlined style={{ fontSize: 15 }}/>
                  <h4 style={{ padding:6, marginLeft: 0, fontSize:'0.9em', fontWeight:'bold', whiteSpace: 'normal' }} key={cluster.id}>
                    {cluster.name}
                  </h4>
                </div>
              ))}
            </div>
          )
        },
        type: 'string',
        sortable: false,
        flex: 1,
        minWidth: 250,
      },
      {
        field: 'actions',
        headerName: translations.t('forms.actions'),
        renderCell: (params: any) => {
          const user = params.row;
          const canDeleteUser = user && userData && user.id !== userData.userId;
          if (!canDeleteUser) return null;
          return (
            <Button
              variant="contained"
              style={{
                backgroundColor: 'darkred',
                flexShrink: 0,
                marginRight: 20,
                width: 'fit-content',
                color: 'white',
                padding: 4,
                paddingLeft: 10,
                paddingRight: 10,
                fontWeight: 'bold'
              }}
              onClick={(e) => {e.stopPropagation(); onDeleteUserRequest(params.row)}}
            >
              <DeleteOutline style={{ fontSize: 15 }}/>
              <h5 style={{ fontWeight: 'bold', margin: 0, marginLeft: 5 }} ><ReduxLanguage languageKey={"forms.delete"} /></h5>
            </Button>
          )
        },
        type: 'string',
        sortable: false,
        minWidth: 150,
      },
    ]

    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <DataGrid
          getRowId={(d) => d.id}
          rows={users.content || []}
          columns={tableColumns ? tableColumns: this.defaultColumns}
          rowHeight={65}
          loading={this.props.isLoading}
          onPageSizeChange={(pageSize) => onPageSizeChange(pageSize)}
          onPageChange={page => onFetchElementsOnPage(page)}
          page={(users?.pagination as PaginationDTO)?.number}
          rowCount={(users?.pagination as PaginationDTO)?.totalElements}
          pageSize={rowsPerPage}
          onRowClick={row => onRowClick(row.row)}
          paginationMode="server"
          componentsProps={{
            pagination: {
              labelRowsPerPage: translations.t('forms.rowsPerPage')
            }
          }}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  <SearchOutlined style={{ alignSelf: 'center' }} />
                  <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='users.noUserFindTitle' /></h3>
                  <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='users.noUserFindDescription' /></h4>
                </div>
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='users.noUserFindTitle' /></h3>
                <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='users.noUserFindDescription' /></h4>
              </Stack>
            )
          }}
          sx={{
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    userData: state.user.userData,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(UsersTable));