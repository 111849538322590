import { PaginationRestDTO } from '@bottega52/commons-pagination';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'query-string';
import { JagoAxiosInstance } from './JagoAxiosInstance';
import { ICardInDTO } from './model/input/ICardInDTO';
import IClusterInDTO from './model/input/IClusterInDTO';
import { ICreditsInDTO } from './model/input/ICreditsInDTO';
import { ICustomerInDTO } from './model/input/ICustomerInDTO';
import { IDomainAdditionalInfoOutDTO } from './model/input/IDomainAdditionalInfoInDTO';
import { IDomainConfigInDTO } from './model/input/IDomainConfigInDTO';
import { IDomainInDTO } from './model/input/IDomainInDTO';
import { IDomainInfoInDTO } from './model/input/IDomainInfoInDTO';
import { IDomainUserImpersonateDTO } from './model/input/IDomainUserImpersonateDTO';
import { IDomainUserInDTO } from './model/input/IDomainUserInDTO';
import IMarketplaceInDTO from './model/input/IMarketplaceInDTO';
import { ISubscriptionInDTO } from './model/input/ISubscriptionInDTO';
import { IWalletAvailableCreditsDTO } from './model/input/IWalletAvailableCreditsDTO';
import { IWalletInDTO } from './model/input/IWalletInDTO';
import { IWalletSubscriptionInDTO } from './model/input/IWalletSubscriptionInDTO';
import { IWalletUsageInDTO } from './model/input/IWalletUsageInDTO';
import { ICardOutDTO, IEditCardOutDTO, IEnableCardOutDTO } from './model/output/ICardOutDTO';
import { ICustomerOutDTO } from './model/output/ICustomerOutDTO';
import { IDomainConfigOutDTO } from './model/output/IDomainConfigOutDTO';
import { IDomainUserOutDTO } from './model/output/IDomainUserOutDTO';
import IMarketplaceOutDTO from './model/output/IMarketplaceOutDTO';
import { IEditSubscriptionOutDTO, ISubscriptionOutDTO } from './model/output/ISubscriptionOutDTO';
import { IWalletActivateOutDTO } from './model/output/IWalletActivateOutDTO';
import { IEditWalletOutDTO, IWalletOutDTO } from './model/output/IWalletOutDTO';
import { IWalletPlantOutDTO } from './model/output/IWalletPlantOutDTO';
import { IWalletRequestParamsDTO } from './model/output/IWalletRequestParamsDTO';
import { IWalletSubscriptionOutDTO } from './model/output/IWalletSubscriptionOutDTO';
import { ICardRequestParamsDTO, IClusterRequestParamsDTO, ICustomerPlantsRequestParamsDTO, ICustomerRequestParamsDTO, IDomainUsersRequestParamsDTO, IExportWalletUsageCSVParamsDTO, IMarketplacesRequestParamsDTO, ISubscriptionsRequestParamsDTO, IThemePlantsRequestParamsDTO, IWalletCreditsRequestParamsDTO, IWalletPlantsRequestParamsDTO, IWalletSubscriptionsRequestParamsDTO, IWalletUsageRequestParamsDTO } from './model/output/RequestParamsDTOs';
import { IPlantInDTO } from './model/input/IPlantInDTO';

export function fetchMarketplaces(params?: IMarketplacesRequestParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IMarketplaceInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get('api/v2/credits/marketplaces', config);
}

export function createMarketplace(data: IMarketplaceOutDTO): Promise<AxiosResponse<IMarketplaceInDTO>> {
  return JagoAxiosInstance.post('api/v2/credits/marketplaces', data);
}

export function fetchMarketplaceSubscriptions(marketplaceId: number, params?: ISubscriptionsRequestParamsDTO): Promise<AxiosResponse<PaginationRestDTO<ISubscriptionInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get(`api/v2/credits/marketplaces/${marketplaceId}/subscriptions`, config);
}

export function createMarketplaceSubscription(marketplaceId: number, data: ISubscriptionOutDTO): Promise<AxiosResponse<ISubscriptionInDTO>> {
  return JagoAxiosInstance.post(`api/v2/credits/marketplaces/${marketplaceId}/subscriptions`, data);
}

export function editMarketplaceSubscription(marketplaceId: number, subscriptionId: number, data: IEditSubscriptionOutDTO): Promise<AxiosResponse<ISubscriptionInDTO>> {
  return JagoAxiosInstance.put(`api/v2/credits/marketplaces/${marketplaceId}/subscriptions/${subscriptionId}`, data);
}

export function fetchCustomers(params?: ICustomerRequestParamsDTO): Promise<AxiosResponse<PaginationRestDTO<ICustomerInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get('api/v2/credits/customers', config);
}

export function gestCustomerById(customerId: number): Promise<AxiosResponse<ICustomerInDTO>> {
  return JagoAxiosInstance.get(`api/v2/credits/customers/${customerId}`);
}

export function createNewCustomer(data: ICustomerOutDTO): Promise<AxiosResponse<ICustomerInDTO>> {
  return JagoAxiosInstance.post('api/v2/credits/customers', data);
}

export function editCustomer(customerId: number, data: ICustomerOutDTO): Promise<AxiosResponse<ICustomerInDTO>> {
  return JagoAxiosInstance.put(`api/v2/credits/customers/${customerId}`, data);
}

export function deleteCustomer(customerId: number): Promise<AxiosResponse<ICustomerInDTO>> {
  return JagoAxiosInstance.delete(`api/v2/credits/customers/${customerId}`);
}

export function fetchWallets(params?: IWalletRequestParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IWalletInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get('api/v2/credits/wallets', config);
}

export function createNewCustomerWallet(data: IWalletOutDTO): Promise<AxiosResponse<IWalletInDTO>> {
  return JagoAxiosInstance.post('api/v2/credits/wallets', data);
}

export function editCustomerWallet(walletId: number, data: IEditWalletOutDTO): Promise<AxiosResponse<IWalletInDTO>> {
  return JagoAxiosInstance.put(`api/v2/credits/wallets/${walletId}`, data);
}

export function deleteCustomerWallet(walletId: number): Promise<AxiosResponse<IWalletInDTO>> {
  return JagoAxiosInstance.delete(`api/v2/credits/wallets/${walletId}`);
}

export function fetchWalletCredits(params: IWalletCreditsRequestParamsDTO): Promise<AxiosResponse<PaginationRestDTO<ICreditsInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get(`api/v2/credits/wallets/${params?.walletId}/credits`, config);
}

export function fetchWalletSubscription(params: IWalletSubscriptionsRequestParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IWalletSubscriptionInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get(`api/v2/credits/wallets/${params?.walletId}/subscriptions`, config);
}


export function fetchWalletUsage(params: IWalletUsageRequestParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IWalletUsageInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
    timeout: 600000,
  };
  return JagoAxiosInstance.get(`api/v2/credits/wallets/${params?.walletId}/credits/usages`, config);
}

export function exportWalletsUsageCSV(params?: IExportWalletUsageCSVParamsDTO): Promise<AxiosResponse<Blob>> {
  return JagoAxiosInstance.get(`api/v2/credits/wallets/credits/usages/csv`, { responseType: 'blob', params });
}

export function exportWalletCreditUsageCSV() : Promise<AxiosResponse<Blob>> {
  return JagoAxiosInstance.get(`api/v2/credits/wallets/credits/csv`, { responseType: 'blob' });
}

export function fetchWalletPlants(params: IWalletPlantsRequestParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IDomainInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get(`api/v2/credits/plants`, config);
}

export function createCustomerWalletPlant(plant: IWalletPlantOutDTO): Promise<AxiosResponse<IWalletInDTO>> {
  return JagoAxiosInstance.post(`api/v2/credits/plants`, plant);
}

export function deleteCustomerWalletPlant(plantId: number): Promise<AxiosResponse<IDomainInDTO>> {
  return JagoAxiosInstance.delete(`api/v2/credits/plants/${plantId}/`);
}

export function fetchCustomerPlants(params: ICustomerPlantsRequestParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IPlantInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get(`api/v2/credits/plants`, config);
}

export function fetchThemePlants(params: IThemePlantsRequestParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IPlantInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get(`api/v2/credits/plants`, config);
}

export function fetchWalletAvailableCredits(walletId: number): Promise<AxiosResponse<IWalletAvailableCreditsDTO>> {
  return JagoAxiosInstance.get(`api/v2/credits/wallets/${walletId}/credits/available`);
}

export function activateCustomerWallet(walletId: number, data: IWalletActivateOutDTO): Promise<AxiosResponse<IWalletInDTO>> {
  return JagoAxiosInstance.post(`api/v2/credits/wallets/${walletId}/activate`, data);
}

export function createCustomerWalletSubscription(walletId: number, data: IWalletSubscriptionOutDTO): Promise<AxiosResponse<IWalletInDTO>> {
  return JagoAxiosInstance.post(`api/v2/credits/wallets/${walletId}/subscriptions`, data);
}

export function editCustomerWalletMainTierSubscription(walletId: number, data: IWalletSubscriptionOutDTO): Promise<AxiosResponse<IWalletInDTO>> {
  return JagoAxiosInstance.post(`api/v2/credits/wallets/${walletId}/subscriptions/changeMainTier`, data);
}

export function deleteCustomerWalletSubscription(walletId: number, walletSubscriptionId: number): Promise<AxiosResponse<ISubscriptionInDTO>> {
  return JagoAxiosInstance.delete(`api/v2/credits/wallets/${walletId}/subscriptions/${walletSubscriptionId}`);
}

export function fetchSystemCards(params?: ICardRequestParamsDTO): Promise<AxiosResponse<PaginationRestDTO<ICardInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get(`api/v2/credits/cards`, config);
}

export function createSystemCard(data: ICardOutDTO): Promise<AxiosResponse<ICardInDTO>> {
  return JagoAxiosInstance.post('api/v2/credits/cards', data);
}

export function editSystemCard(cardId: number, data: IEditCardOutDTO): Promise<AxiosResponse<ICardInDTO>> {
  return JagoAxiosInstance.put(`api/v2/credits/cards/${cardId}`, data);
}

export function enableSystemCard(cardId: number, data: IEnableCardOutDTO): Promise<AxiosResponse<ICardInDTO>> {
  return JagoAxiosInstance.put(`api/v2/credits/cards/${cardId}`, data);
}

export function deleteSystemCard(cardId: number): Promise<AxiosResponse<ICardInDTO>> {
  return JagoAxiosInstance.delete(`api/v2/credits/cards/${cardId}`);
}

export function fetchWalletPlantConfiguration(domainHostname: string): Promise<AxiosResponse<IDomainConfigInDTO>> {
  return JagoAxiosInstance.get(`api/v2/support/domains/${domainHostname}/config`);
}

export function fetchWalletPlantUsers(domainHostname: string, params?: IDomainUsersRequestParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IDomainUserInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
    paramsSerializer: (params: any) => qs.stringify(params),
  }
  return JagoAxiosInstance.get(`api/v2/support/domains/${domainHostname}/users`, config);
}

export function updateWalletPlantConfiguration(domainHostname: string, data: IDomainConfigOutDTO): Promise<AxiosResponse<IDomainConfigInDTO>> {
  return JagoAxiosInstance.put(`api/v2/support/domains/${domainHostname}/config`, data) ;
}

export function impersonatePlantUser(domainHostname: string, userEmail: string, clientId: string, duration?: number): Promise<AxiosResponse<IDomainUserImpersonateDTO>> {
  return JagoAxiosInstance.get(`api/v2/support/domains/${domainHostname}/users/${userEmail}/impersonate?clientId=${clientId}&duration=${duration}`) ;
}

export function createUserForPlant(domainHostname: string, data: IDomainUserOutDTO): Promise<AxiosResponse<IDomainUserInDTO>> {
  return JagoAxiosInstance.post(`api/v2/support/domains/${domainHostname}/users`, data);
}

export function updateWalletPlantAdditionalInfo(domainHostname: string, data: IDomainAdditionalInfoOutDTO): Promise<AxiosResponse<IDomainAdditionalInfoOutDTO>> {
  return JagoAxiosInstance.post(`api/v2/support/domains/${domainHostname}/additionalInfo`, data) ;
}

export function fetchWalletPlantAdditionalInfo(domainHostname: string): Promise<AxiosResponse<IDomainAdditionalInfoOutDTO>> {
  return JagoAxiosInstance.get(`api/v2/support/domains/${domainHostname}/additionalInfo`) ;
}

export function fetchWalletPlantInfo(domainHostname: string): Promise<AxiosResponse<IDomainInfoInDTO>> {
  return JagoAxiosInstance.get(`api/v2/support/domains/${domainHostname}/info`) ;
}

export function fetchClusters(params: IClusterRequestParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IClusterInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get('api/v2/credits/clusters', config);
}