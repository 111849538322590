import i18next from "i18next";
import en from './languages/en';
import it from './languages/it';
import es from './languages/es';
import de from './languages/de';
import fr from './languages/fr';
import nl from './languages/nl';

i18next
  .init({
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: "en",
    resources: {
      en: {
        translation: en,
      },
      it: {
        translation: it,
      },
      es: {
        translation: es,
      },
      de: {
        translation: de,
      },
      fr: {
        translation: fr,
      },
      nl: {
        translation: nl,
      },
    },
  });

export default i18next;
