import * as moment from 'moment';
import i18next from "i18next";
import _ from 'lodash';
import { LanguageTypes } from './LanguageTypes';

export function setLanguageWithMoment(language: string): void {
  i18next.changeLanguage(language);
  moment.locale(language);
}

export function detectBrowserLanguage() {
  const browserLanguage = window.navigator.language;
  if (_.includes(browserLanguage, LanguageTypes.ENGLISH)) return LanguageTypes.ENGLISH;
  if (_.includes(browserLanguage, LanguageTypes.ITALIAN)) return LanguageTypes.ITALIAN;
  if (_.includes(browserLanguage, LanguageTypes.GERMAN)) return LanguageTypes.GERMAN;
  if (_.includes(browserLanguage, LanguageTypes.FRENCH)) return LanguageTypes.FRENCH;
  if (_.includes(browserLanguage, LanguageTypes.SPANISH)) return LanguageTypes.SPANISH;
  if (_.includes(browserLanguage, LanguageTypes.DUTCH)) return LanguageTypes.DUTCH;
  return LanguageTypes.ENGLISH;
}

