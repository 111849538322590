import _ from 'lodash';
import { fetchProductInfoArticle, fetchProductInfoPosts } from '../../repository/jago/luckeyHelpCenterAPI';
import { IProductInfoPostInDTO } from '../../repository/jago/model/input/IProductInfoPostInDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import { IState } from '../store';
import { ActionsUnion, IThunkAction, createAction } from "../utils";
import ProductInfoActionTypesEnum from "./model/ProductInfoActionTypesEnum";
import { ProductInfoArticlesMap } from './model/ProductInfoArticlesMap';


export const ProductInfoActions = {
  savePosts: createAction<typeof ProductInfoActionTypesEnum.SAVE_POSTS, IProductInfoPostInDTO[]>(ProductInfoActionTypesEnum.SAVE_POSTS),
  setSelectedPost: createAction<typeof ProductInfoActionTypesEnum.SET_SELECTED_POST, IProductInfoPostInDTO | {}>(ProductInfoActionTypesEnum.SET_SELECTED_POST),
};

export type ProductInfoActionsType = ActionsUnion<typeof ProductInfoActions>;

export function fetchPosts(languageKey: string = 'en', category: string): IThunkAction<Promise<IProductInfoPostInDTO[]>, IState> {
  const postCollection = _.find(ProductInfoArticlesMap, e=> e.name === category);
  const postIds = postCollection?.postIds[languageKey];
  return async (dispatch, getState) => {
    try {
      const response = await fetchProductInfoArticle(postIds,languageKey)
      if (response && response.data) {
        const decodedData = response.data;
        dispatch(ProductInfoActions.savePosts(decodedData));
        return decodedData;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function appendPosts(languageKey: string, tagId: number): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { posts: { data } } = getState().productInfo;
      if (_.isEmpty(data)) return;
      const statePosts = data as IProductInfoPostInDTO[];
      const postsResponse = await fetchProductInfoPosts(tagId,languageKey)
      if (postsResponse && postsResponse.data) {
        const decodedData = postsResponse.data;
        const postsToSave : IProductInfoPostInDTO[] = [
          ...statePosts,
          ...decodedData
        ]
        dispatch(ProductInfoActions.savePosts(postsToSave));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function setSelectedPost(post: IVarInDTO | {}): IThunkAction<void, IState> {
  return (dispatch ) => {
    dispatch(ProductInfoActions.setSelectedPost(post));
  }
}