const translations = {
  language: "Language",
  login: {
    welcome: 'Welcome',
    ssoLogin: 'Access with your account email',
    detailsGeneric: 'Details',
    customers: 'End-users',
    sendEmail: 'Send Email',
    otpLogin: 'Login with email',
    login: 'Login',
    changeEmail: 'Use different email address',
    or: 'OR'
  },
  domains: {
    customerAdminDeletionConfirm: 'Do you want to delete this end-user? The operation cannot be undone',
    customerAdminDeletionSuccess: 'End-User deleted successfully',
    luckeyDomainsTitle: 'Domains',
    domainName: 'Domain name',
    domainDetails: 'Domain details',
    domainConfigModifySuccess: 'Domain Config modified and updated',
    domainConfigTitle: 'Configurations - Feature Toggling',
    domainConfigDescription: 'Domain configuration JSON. Use it to enable/disable domain features',
    domainConfigGuide: 'Domain Configurations Guide',
    noUsersFoundTitle: 'User List empty',
    domainUsersTitle: 'Domain Managers',
    domainUsersDescription: 'Impersonate one of the user managers for maintenance operations on the domain',
    domainUsersWarning: 'ATTENTION: By impersonating users you can make changes to the system, even irreversible ones, use the utmost caution',
    noUsersFoundDescription: 'No user has been found in this domain',
    noDomainsFoundTitle: 'No Domains Found',
    noDomainFoundDescription: 'The domain list is empty. If you have set a search filter try to reset it and try again',
    impersonateSuccess: 'User impersonate success',
    domainSubsriptionTitle: 'Domain\'s subscription',
    domainSubsriptionDescription: 'List of domain\'s subscription: locks, users and domain expiration',
    expiration: 'Expiration',
    totalNumber: 'Total number',
    used: 'Used elements',
    tooMuchElements: 'WARNING: DOMAIN HAS OVERCOME THE LIMIT OF TOTAL ELEMENTS',
    newDomain: 'New domain',
    externalReference: 'External reference',
    externalReferenceDetails: 'For example "date-purchaseId"',
    productDescription: 'The product represents the licence that will be activated for the new domain',
    productTitle: 'Subscription type',
    extendDomain: 'Extends Domain Subscription',
    extendDomainDescription: 'Extend your domain with a add-on or product key',
    domainInstallersTitle: 'Domain Installers',
    domainInstallersDescription: 'List of the installer users on the selected plants',
    domainDeletionTitle: 'Delete domain',
    domainDeletionDescription: 'Send a request to delete this domain',
    sendDeletionRequest: 'Send',
    domainDeletionConfirm: 'Do you want to send a request to delete this domain?',
    domainDeletionSuccess: 'Request sent successfully',
    customerDeletionTitle: 'Delete end-user',
    customerDeletionConfirm: 'Do you want to send a request to delete this end-user?',
    customerDeletionSuccess: 'Request sent successfully',
    walletDeletionTitle: 'Delete wallet',
    walletDeletionConfirm: 'Do you want to send a request to delete this wallet?',
    walletDeletionSuccess: 'Request sent successfully',
    verifyServiceStatus: 'Service Status',
    impersonateUser: 'Impersonate user',
    impersonate: 'Impersonate',
    impersonateReadOnly: 'Impersonate in read only mode',
    domainAdditionalInfo: 'Domain info',
    domainAdditionalInfoDescription: 'Provide additional domain information here',
    additionalInfoSavedSuccessfully: 'Additional notes saved successfully',
    EASY: 'Only Argo Locks',
    SMART: 'Argo Locks + Gateway',
    ENTERPRISE: 'V364 + Argo Locks',
    STANDARD: 'Only V364 Locks',
    numberOfArgoLocks: 'Argo',
    numberOfVegaLocks: 'V364',
    numberOfGatewaysLocks: 'Gateways',
    numberOfUsers: 'Users',
    numberOfLocks: 'Smart Locks',
    federatedDomain: 'Domain linked to: {{domain}}',
    federatedMasterDomain: 'Master domain of a group',
    domainFederatedLicense: 'Federated plant subscription: this subscription is unified for all domains belonging to the main domain {{domain}}',
    federatedDomains: 'Group of domains',
    federatedDomainsDescription: 'Domain belongs to a group',
    installationMode: 'Installation Mode',
    domainStandard: 'Legacy domain',
    domainWithCloudCredits: 'Cloud credits domain',
    domainIrisRequired: '1RIS Required',
    domainIrisEnabled: '1RIS Enabled',
    domainIrisEnable: 'Enable 1RIS',
    domainIrisEnableConfirm: 'Do you want to set 1RIS as ENABLED on this domain?',
    domainsWithCloudCredits: 'Cloud credits domains',
    domainsLegacy: 'Legacy domains',
  },
  credits: {
    marketplaces: 'Marketplaces',
    searchMarketplace: 'Search marketplace...',
    marketplaceName: 'Marketplace name',
    luckeyMarketplaceTitle: 'Marketplaces Luckey',
    noMarketplaceFoundTitle: 'No marketplace found',
    noMarketplaceFoundDescription: 'The marketplace list is empty. If you have set a search filter try to reset it and try again',
    newMarketplace: 'New marketplace',
    marketplaceCreationConfirm: 'Do you want to create the new marketplace?',
    createMarketplace: 'Create marketplace',
    marketplaceDetails: 'Marketplace details',
    editMarketplaceCreationConfirm: 'You\'re about to edit the marketplace data. Are you sure you want to proceed?',
    editMarketplace: 'Edit marketplace',
    searchCustomer: 'Search end-user...',
    customerName: 'End-user\'s name',
    luckeyCustomersTitle: 'Luckey end-users',
    noCustomerFindTitle: 'No end-users Found',
    noCustomerFindDescription: 'The end-users list is empty. If you have set a search filter try to reset it and try again',
    newCustomer: 'New end-user',
    customerCreationConfirm: 'Do you want to create the new end-user?',
    newUser: 'New user',
    userCreationConfirm: 'Do you want to create the new user?',
    createCustomer: 'Create end-user',
    customerDetails: 'End-user details',
    editCreationConfirm: 'You\'re about to edit the end-user data. Are you sure you want to proceed?',
    editCustomer: 'Edit end-user',
    editCreationUserConfirm: 'You\'re about to edit the user data. Are you sure you want to proceed?',
    editUser: 'Edit user',
    customerWallets: '{{customerName}}\'s Wallets',
    newWallet: 'New Wallet',
    walletCreationConfirm: 'Do you want to create a new wallet for the selected end-user?',
    createWallet: 'Create wallet',
    editWallet: 'Edit wallet',
    walletEditConfirm: 'Do you want to edit the selected wallet?',
    noWalletFoundTitle: 'No wallet found',
    noWalletFoundDescription: 'No wallet found for the selected end-user',
    walletDetails: 'Wallet details',
    rechargeWallet: 'Top-up',
    walletRechargeHistory: 'Top-ups History',
    rechargeRowTitle: 'Credits modified',
    rechargeCreditsDescription: 'Wallet has been recharge with {{credits}} credits',
    rechargeCardDetails: 'Top-up details',
    systemParameters: 'Price list',
    systemSubscriptions: 'Subscriptions',
    systemSubscriptionsDescription: 'These are the subscriptions in the system. Create new ones or assign them to an existing domain',
    newSubscription: 'New subscription',
    editSubscription: 'Edit subscription',
    noSystemSubscriptionFoundTitle: 'No subscription found',
    noSystemSubscriptionFoundDescription: 'There is no system subscription that can be assigned to a domain',
    products: 'Legacy Subscriptions',
    rechargeCards: 'Top-up cards',
    noSystemCardFoundTitle: 'No top-up cards found',
    noSystemCardFoundDescription: 'There are no top-up cards in the system. Create new ones to start recharge end-user\'s wallets',
    newCard: 'New top-up card',
    cardStatusUsed: 'Used',
    rechargeCardCreditsDetails: 'This top-up card is worth {{credits}} credits',
    cardStatusUnused: 'Not used',
    cardStatusInactive: 'Disabled',
    enableCard: 'Enable',
    disabledCardTooltip: 'The top-up card will be enabled as soon as you receive the associated ISEO invoice',
    newTierSubscription: 'New main subscription',
    pluginAndService: 'Add-on and services',
    newPluginSubscription: 'New add-on or service',
    tierSubscription: 'Main Subscription',
    walletSubscriptions: 'Subscriptions',
    noWalletSubscriptionFoundTitle: 'No subscription found',
    noWalletSubscriptionFoundDescription: 'This wall has no subscription associated to it',
    newWalletSubscription: 'Associate new subscription',
    editWalletSubscription: 'Edit subscription',
    newWalletPluginSubscription: 'Associate new add-on or service',
    walletConsumption: 'Consumpion',
    noWalletUsageFoundTitle: 'No data found',
    noWalletUsageFoundDescription: 'There are no consumption data for this wallet',
    ACTIVATION: 'Domain or add-on activation cost',
    DAILY_USAGE: 'Daily consumption',
    creditConsumptionType: 'Consumption type',
    consumptionCreditsDescription: 'The wallet has consumed {{credits}}',
    consumptionRowTitle: 'Wallet consumption',
    walletPlants: 'Domains',
    noWalletPlantFoundTitle: 'No domain found',
    noWalletPlantFoundDescription: 'There are no domains associated to this wallet',
    newWalletPlant: 'New domain',
    deleteWalletSubscription: 'Remove subscription',
    editCard: 'Edit top-up card',
    rechargeDetails: 'Top-up details',
    exportWalletsUsageCSV: 'Export credit usage CSV',
    exportWalletsCreditUsageCSV: 'Export Wallet Credit',
    PENDING: 'Pending',
    ACTIVE: 'Active',
    READY_TO_ACTIVATE: 'Ready to activate',
    walletStatus: 'Wallet status',
    exportManagersCSV: 'Export Managers',
    needCreditsToCreatePlant: 'You need credits in this wallet to create a new domain',
  },
  sales: {
    hardwareSalesInfo: 'Sales Info',
    domain: 'Domain',
    hardwareId: 'HW ID',
    hardwareName: 'HW Name',
    hardwareSerialNumber: 'HW Serial Number',
    hardwareModel: 'HW Model',
    hardwareVendor: 'HW Product Line',
    hardwareType: 'HW Type',
    sfdcId: 'SFDC-ID',
    iseoSelloutOrderId: 'ISEO Sell-out Order ID',
    iseoSelloutOrderDate: 'ISEO Sell-out Order Date',
    iseoSelloutInvoiceId: 'ISEO Sell-out Invoice ID',
    iseoSelloutInvoiceDate: 'ISEO Sell-out Invoice Date',
    sofiaCustRef: 'Cust. Ref. (Sofia process)',
    unreconciledHardware: 'Not reconciled hardware',
    hardwareWithSalesInfo: 'Hardware with sales info',
    actions: 'Actions',
    addSalesInfo: 'Add sales info',
    editSalesInfo: 'Edit sales info',
    iseoSelloutOrder: 'ISEO sell-out order',
    iseoSelloutInvoice: 'ISEO sell-out invoice',
    selectedSalesInfoError: 'Warning! The selection is not omogeneous. Are you sure you want to proceed?',
  },
  errors: {
    deleteWalletSubscriptionError: 'An error occurred while modifying the subscription. Please try again or contact our support team',
    modifyVarOpportunityError: 'An error occurred while editing the quotation. Please try again',
    error: 'Error',
    loginFailed: 'Section expired. Please log in again',
    loginGoogleFailedTitle: 'Google Login Failed', 
    loginGoogleFailed: 'Make sure you\'re logged in your Google account or try to open this page in a Google Chrome session with your Sofia GSuite Account',
    loginJagoGoogleFailedTitle: 'Jago Login Failed',
    loginJagoGoogleFailed: 'Make sure that you have a user in Jago that corresponds to your Sofia GSuite Account and try again',
    getDomainDetailsError: 'An error occurred while getting the Domains configuration. Please try again or contact Sofia Support',
    updateDomainConfigError: 'An error occurred while saving the plant configuration. Please try again or contact Sofia Support',
    updatePlantThemeError: 'An error occurred while saving the plant theme. Please try again or contact Sofia Support',
    impersonateDomainUserError: 'An error occurred while impersonate the user. Please verify that the user is Active and try again or contact Sofia Support',
    createDomainError: 'An error occurred while creating the new domain',
    addDomainPluginError: 'An error occurred while extending the domain',
    addUserDomainError: 'Unable to create user. Check if the user already exists in the domain or if the domain has a valid subscription ',
    errorSaveAdditionalInfo: 'Unable to update info on the selected domain',
    createCustomerError: 'Unable to create end-user. Check if the end-user already exists and try again',
    createUserError: 'Unable to create user. Check if the user already exists and try again',
    createVarUserError: 'Unable to create VAR user. Check if the user already exists and try again',
    createPlantThemeError: 'Unable to create plant theme. Check data and try again',
    updateVarError: 'Unable to update. Check data and try again',
    getCustomerDetailsError: 'Unable to get end-user details. Please try again',
    getVarDetailsError: 'Unable to get VAR details. Please try again',
    getPlantThemeDetailsError: 'Unable to get Plant Theme details. Please try again',
    getProductInfoPostDetailsError: 'Unable to get Article. Please try again',
    required: 'Required',
    deleteDomainRequestError: 'Unable to send the request. Please try again',
    deleteWalletRequestError: 'Unable to send the request. Please try again',
    deleteCustomerRequestError: 'Unable to send the request. Please try again',
    enableIrisDomainRequestError: 'Unable to send the request. Please try again',
    createCustomerWalletError: 'Unable to create the new wallet. Please try again',
    editCustomerWalletError: 'Unable to edit the new wallet. Please try again',
    getWalletDetailsError: 'Unable to retrieve the wallet details. Please try again',
    openSystemParametersError: 'Unable to open system parameters. Please try again',
    createSubscriptionError: 'Unable to create the subscription. Please try again',
    createCardError: 'An error occurred while creating the top-up card. Please try again',
    createVarOpportunityError: 'An error occurred during the creation. Please try again',
    editVarOpportunityError: 'An error occurred during the operation. Please try again',
    rechargeWalletError: 'An error occurre while recharging the wallet. Please try again',
    deleteCardError: 'An error occurred while deleting the top-up card. Please try again',
    enableCardError: 'An error occurred while updating the top-up card. Please try again',
    deletePlantThemeError: 'An error occurred while deleting. Please try again',
    deleteVarUserError: 'An error occurred while deleting. Please try again',
    deleteVarUserErrorOpportunities: 'This user is the owner of at least one quotation, so it cannot be deleted. Please, re-assign the quotations associated to this user and try again.',
    deleteVarOpportunityError: 'An error occurred while deleting. Please try again',
    rejectVarOpportunityError: 'An error occurred while updating the quotation. Please try again',
    approveVarOpportunityError: 'An error occurred while updating the quotation. Please try again',
    syncsfdcidVarOpportunityError: 'An error occurred while updating the quotation. Please try again',
    upgradeVarOpportunityError: 'An error occurred while updating the quotation. Please try again',
    winVarOpportunityError: 'An error occurred while updating the quotation. Please try again',
    loseVarOpportunityError: 'An error occurred while updating the quotation. Please try again',
    deleteUserError: 'An error occurred while deleting. Please try again',
    createWalletSubscriptionError: 'An error occurred while associating the subscription to the wallet. Please try again',
    createWalletPlantError: 'An error occurred while creating the domain for the wallet. Please try again',
    walletActionErrorNotEnoughCreditsTitle: 'Not enough credit',
    walletActionErrorNotEnoughCredits: 'The wallet has not enough credit to perform the operation. Verify the operation you\'re performing and try again',
    warningMinCreditsCard: 'Warning! Minimum number of credits required = {{credits}}',
    loginJagoOTPEmailFailedTitle: 'Send email error',
    loginJagoOTPEmailFailed: 'An error occurred while sending the email, try again after 5 minutes',
    loginJagoOTPFailedTitle: 'Code verification error',
    loginJagoOTPFailed: 'Verification of the access code was unsuccessful. Please check the code or try the login again after 5 minutes.',
    addonV364missing: 'The V364 add-on could not be found in the system, please report this error to receive assistance.',
  },
  gateways: {
    createdBy: 'Created by',
    noGatewayFoundTitle: 'No HyperGates found',
    noGatewayFoundDescription: 'The list of HyperGates is empty. If you have set a search filter try changing it and try again',
    gatewayName: 'HyperGate Name',
    luckeyGatewaysTitle: 'HyerGates of the system',
    lastConnectionDate: 'Last connection: {{ date }}',
    gatewaysDevices: 'Devices',
    gatewaysArtifacts: 'Artifacts',
    gatewaysDeployments: 'Deployments',
    HYPERGATE_PRO: 'HyperGate Pro',
    HYPERGATE_ISEO: 'HyperGate Lite',
    DONE: 'Completed',
    PENDING: 'Deployment Pending',
    IN_PROGRESS: 'Deployment in progress',
    ERROR: 'Error',
    deploymentDetail: 'Deployment Detail',
    noDeploymentDetailedFound: 'No Gateways found for deployment',
    noDeploymentDetailedFoundDescription: 'The gateway list for this deployment is empty',
    deviceId: 'Device Id',
    name: 'Name',
    gatewayVersion: 'Version',
    iseoSdkVersion: 'ISEO SDK',
    kernelVersion: 'Kernel Version',
    serialNumber: 'Serial Number',
    noGatewaysFound: 'No Gateways found',
    noGatewaysFoundDescription: 'The gateway list is empty. Try changing your search filters and try again',
    noDeploymentFound: 'No Deployment found',
    noArtifactFound: 'No Artifact found',
    noDeploymentFoundDescription: 'The deployment list for this deployment is empty. If you have set a search filter try changing it and try again',
    noArtifactFoundDescription: 'The artifact list for this deployment is empty. If you have set a search filter try changing it and try again',
  },
  forms: {
    notVisible: 'Not visible',
    visible: 'Visible',
    exportAllUsers: 'Export users',
    montlyBurnRate: '{{credits}}/month',
    nextRechargeForecast: 'Expected next charge with current consumption ~{{date}}',
    lastUpdate: 'Last update {{time}}',
    rowsPerPage: 'Rows per page',
    rolePermissions: 'Role permissions',
    permissionsDetails: 'Permission details',
    clusterAndIseoCompany: 'Cluster and ISEO Company',
    createCardSuccess: 'Top-up Card created successfully',
    cardDetails: 'Card details',
    status: 'Status',
    customerInfo: 'Customer info',
    details: 'Details',
    costs: 'Costs',
    hostName: 'Hostname',
    domainType: 'Type',
    user: 'User',
    logoutConfirmation: 'Are you sure you want to logout?',
    send: 'Send',
    askQuestion: 'Contact us',
    ticketOpenSuccessfully: 'Your question has been submitted to our team! One of our members will be in touch with you shortly.',
    ticketOpenError: 'An error occurred while sending the request. Please try again',
    writeUsAQuestionDescription: 'Have a question? Feel free to write to us, and one of our Product Specialists will be ready to assist you with any inquiries you might have.',
    deviceType: 'Device Type',
    createNewMarketplace: 'New marketplace',
    createNewCustomer: 'New',
    firstName: 'First Name',
    lastName: 'Last name',
    email: 'E-mail',
    contactEmail: 'Contact Email',
    open: 'Open',
    add: 'Add',
    actions: 'Actions',
    search: 'Search',
    remove: 'Remove',
    error: 'Error',
    copy: 'Copy',
    cancel: 'Cancel',
    confirm: 'Confirm',
    warning: 'Warning',
    reset: 'Reset',
    close: 'Close',
    save: 'Save',
    delete: 'Delete',
    deleteRequest: 'Send deletion request',
    deleteAdminOnly: 'Delete (admin)',
    export: 'Export',
    spinnerLoading: 'Loading...',
    createNewDomain: 'Create new domain',
    walletName: 'Wallet name',
    domainName: 'Domain name',
    domainNamePreview: 'Preview',
    domainFormTitle: 'Create a new Luckey domain',
    invalidDomainName: 'Invalid domain name. No spaces, only lower case characters, numbers and dash allowed',
    selectProduct: 'Select a subscription type',
    name: 'First name',
    lastname: 'Last name',
    createNewUser: 'Create new Manager',
    selectRole: 'Select user role',
    createUserForDomain: 'Create a new user for the selected domain',
    newUser: 'New user',
    password: 'Password',
    letUserChoosePassword: 'Let user choose password: send password setting link to user email',
    domainCreateSuccess: 'Domain created successfully',
    domainCreateUserAfterCreation: 'Do you want to create a user for the new domain?',
    domainCreationConfirm: 'Do you want to create the new domain?',
    createDomain: 'Create domain',
    username: 'Username',
    domainExtendsConfirm: 'Do you want to extend the domain with the selected product?',
    createNewInstaller: 'Create new installer',
    createInstallerForDomain: 'Create a new installer user for the domain',
    deleteDomain: 'Request domain deletion',
    active: 'Active',
    disabled: 'Disabled',
    minutes: '{{minutes}} minutes',
    selectSessionDurationPlaceholder: 'Session duration',
    impersonateUserDescription: 'Before impersonating the user inside the system, we inform you that:',
    impersonateUserDescriptionOne: 'The start of the session and its duration will appear in the domain logs',
    impersonateUserDescriptionTwo: 'Your every action will be recorded and will appear in the system logs',
    impersonateUserDescriptionThree: 'Any action you perform on the implant will have an immediate and in some cases irreversible effect',
    selectSessionDuration: 'Select the duration of the support session',
    impersonateReadOnly: 'Read-only mode',
    impersonateReadOnlyDescription: 'Access the system in read-only mode, you will be able to view the system data but without being able to modify them',
    impersonateReadOnlyWarning: 'Attention: The support session and its duration will still be visible in the system logs',
    installationModeActive: 'Active',
    installationModeDisabled: 'Disabled',
    customerFormTitle: 'Luckey End-user',
    varFormTitle: 'Value Added Reseller (VAR)',
    varFormAdminSection: 'Admin section',
    varFormTitleVar: 'Company info',
    plantThemeFormTitle: 'Luckey Plant Theme',
    customerName: 'End-user name',
    invalidCustomerName: 'End-user name not valid',
    createNewWallet: 'New Wallet',
    whatsNewTitle: 'Luckey what\'s new!',
    whatsNewSubtitle: 'Discover the latest news about Luckey and Sofia part of ISEO',
    notes: 'Additional notes',
    cardCode: 'Recharge Code',
    recharge: 'Top-up',
    rechargeWalletFormDescription: 'Top up the credits of this wallet by entering the top up code. The recharge will take effect immediately',
    subscriptionName: 'Subscription name',
    subscriptionFixedPrice: 'Activation cost (credits)',
    subscriptionBurnRate: 'Recurrent cost (credits)',
    subscriptionVisible: 'Visible in marketplace',
    createNewSubscriptionDescription: 'Create a new subscription in the system. The subscription will be available for all the domains',
    subscriptionLogicDescription: 'Indicates which elements of the system will be counted to determine the system constraints',
    FIXED: 'Do not place constraints on elements',
    COUNT_USERS_LOCKS: 'Limit the number of users and domain locks',
    COUNT_LOCKS: 'Only limit the number of domain locks',
    COUNT_ELEMENTS: 'Limit the sum of locks and domain users',
    maxLocks: 'Maximum number of smart locks',
    maxUsers: 'Maximum number of users',
    maxElements: 'Maximum number of elements (users+locks)',
    subscriptionLogic: 'Element limit logic',
    selectLogic: 'Select',
    subscriptionMaxLocksDescription: 'Insert the maximum number of locks that can be installed with this subscription',
    subscriptionMaxUsersDescription: 'Insert the maximum number of users that can be activated with this subscription',
    subscriptionMaxElementsDescription: 'Insert the maximum number of elements with this subscription',
    subscriptionResource: 'Resource',
    cardQuantity: 'Number of credits',
    cardQuantityDescription: 'Indicates the number of credits that will be recharged when using the top-up card',
    cardVar: 'Assign top-up card to VAR',
    cardWallet: 'Assign top-up card to specific wallet',
    cardNotes: 'Additional Notes',
    createCardConfirm: 'Are you sure you want to create the top-up card?',
    createVarOpportunityConfirm: 'Are you sure you want to create this new quotation?',
    createSubscrptionConfirm: 'Are you sure you want to create the new subscription?',
    rechargeWalletConfirm: 'Your\'re about to recharge the wallet of this end-user with new credits, do you want to proceed? Operation cannot be undone',
    createdAt: 'Creation date',
    copiedSuccess: 'Copied!',
    logout: 'Logout',
    deleteCardConfirm: 'Are you sure you want to delete this top-up card? Operation cannot be undone',
    enableCardConfirm: 'Are you sure you want to enable this top-up card? Once enabled, this card can be used. Operation cannot be undone',
    subscriptionTierSelectionTitle: 'Master subscription',
    subscriptionTierSelectionDescription: 'By selecting the main subscription, the new add-on will automatically inherit the counting logics of the main subscription',
    selectTier: 'Select subscription',
    createPluginSubscriptionConfirm: 'Are you sure you want to create this new add-on/service subscription?',
    createWalletSubscriptionConfirm: 'Are you sure you want to associate this subscription to the selected wallet? Subscription will be activated for all the plants managed by this wallet',
    deleteWalletSubscriptionConfirm: 'Are you sure you want to remove this subscription from the selected wallet? Subscription will be removed from all the plants managed by this wallet',
    editSubscriptionConfirm: 'Are you sure you want to edit the subscription?',
    walletSelectSubscriptionTitle: 'Subscription to activate',
    walletSelectSubscriptionDescription: 'Select the subscription to activate on this wallet. Remember that the subscription will also be activated in all systems managed through this wallet',
    createWalletPlantConfirm: 'Are you sure you want to create the new domain for the selected wallet?',
    enableEmailForPlant: 'Enable email service',
    enableEmailForPlantDescription: 'Enable the sending of emails for this system (activation email, notification, etc.)',
    enableIrisRequiredForPlant: 'Require 1ris Service',
    enableIrisRequiredForPlantDescription: 'If selected, the domain will be prepared to include 1ris Service',
    cardSubscriptionsLinked: 'Associated Subscriptions',
    cardSubscriptionsLinkedDescription: 'Associate multiple add-ons or services to this top-up card to activate them once the card is been used',
    mainTierDescription: 'Associate a main subscription to this top-up card to activate it once the card is been used',
    cardMarketplacesLinked: 'Marketplace for licence association',
    cardMarketplacesLinkedDescription: 'Select a marketplace to choose the subscriptions to associate to this top-up card',
    sku: 'SKU',
    customerData: 'End-user\'s data',
    customerVat: 'VAT',
    customerFiscalCode: 'Fiscal Code',
    customerCountry: 'Country',
    customerAddress: 'Address',
    customerCity: 'City',
    customerEmail: 'Email',
    customerPhone: 'Phone',
    customerCrmId: 'SFDC ACCOUNT ID',
    customerMarketplace: 'Marketplace',
    customerCertifiedEmail: 'PEC (Certified email)',
    customerCluster: 'Cluster',
    customerVar: 'VAR',
    customerCompany: 'ISEO Company',
    customerPostalCode: 'Postal Code',
    customerPrincipalSubDivision: 'Provice, State, County, etc.',
    iseoSalesOrderReference: 'ISEO Sales Order (ISEO -> Client)',
    iseoSalesOrderDate: 'ISEO Sales Order Date',
    iseoPurchaseOrderReference: 'ISEO Purchase Order (ISEO -> Sofia)',
    iseoPurchaseOrderDate: 'ISEO Purchase Order Date',
    sofiaSalesOrderReference: 'Sofia Sales Order (Sofia -> ISEO)',
    sofiaSalesOrderDate: 'Sofia Sales Order Date',
    pickDate: 'Choose date',
    crmSource: 'CRM Opportunity Source',
    crmId: 'CRM Opportunity Unique Identifier',
    sofiaProjectId: 'Sofia Project Reference Identifier',
    crmLink: 'URL to CRM Opportunity',
    kamReference: 'KAM reference',
    edit: 'Edit',
    editCardConfirm: 'Are you sure you want to edit the top-up card?',
    editVarOpportunityConfirm: 'Are you sure you want to edit this quotation?',
    insertEmailOTPLoginForm: 'Insert your account email to receive the OTP code and access the platform',
    insertEmailOTPLoginFormDisclaimer: 'If the email is authorized you will receive an OTP code to access',
    insertOTPOTPLoginForm: 'Insert the OTP code received to your account email',
    insertOTPOTPLoginFormDisclaimer: 'In case you have not received the code after 5 minutes, repeat the operation',
    pickDateTo: 'Pick date to:',
    pickDateFrom: 'Pick date from',
    AUTOMATIC: 'Automatic activation',
    MANUAL: 'Manual activation',
    SEMI_AUTOMATIC: 'Semi-automatic activation',
    AUTOMATIC_Description: 'The extension can be activated independently from the store by the user. Activation credits will be deducted immediately',
    MANUAL_Description: 'Extension that can be activated only following an explicit request from the end-user. The credits will be deducted manually following the approval of the request',
    SEMI_AUTOMATIC_Description: 'Extension that can be activated only following an explicit request from the end-user. The activation credits will be deducted at the time of the request',
    requestToQuote: 'Requested quote',
    requestToQuoteDescription: 'Indicate if the extension is subject to quotation instead of direct use of credits (e.g. integrations and customizations)',
    subscriptionCanBeDeactivated: 'Can be disabled from the manager',
    subscriptionCanBeDeactivatedDescription: 'Indicate if the manager can disable the subscription from Luckey Store',
    selectSegmentType: 'Select segment',
    sofiaInvoiceNumber: 'Sofia Invoice Number',
    sofiaInvoiceAmount: 'Sofia Invoice Amount',
    sofiaInvoiceYear: 'Sofia Invoice Year',
    iseoInvoiceNumber: 'ISEO Invoice Number',
    iseoInvoiceAmount: 'ISEO Invoince Amount',
    iseoInvoiceYear: 'ISEO Invoice Year',
    freeOfCharge: 'Free of Charge',
    freeOfChargeReason: 'Reason',
    currency: 'Currency',
    sofiaInvoiceDate: 'Invoice Date',
    iseoInvoiceDate: 'Invoice Date',
    sofiaInvoiceRecipient: 'Invoice Recipient',
    iseoInvoiceRecipient: 'Invoice Recipient',
    serviceSupplementaryInvoice: 'Service Supplementary Invoice',
    serviceSupplementaryInvoiceNumber: 'Invoice Number',
    serviceSupplementaryInvoiceAmount: 'Invoice Amount',
    serviceSupplementaryInvoiceDate: 'Invoice Date',
    serviceSupplementaryInvoiceRecipient: 'Invoice Recipient',
    iseoInvoiceIssuer: 'Invoice Issuer',
    backdropClosing: 'Are you sure you want to close the form without saving?',
    roles: 'Roles',
    otp: 'OTP',
    selectBotType: 'Select Assistant Type',
    helperBot: 'Product Assistant',
    commercialBot: 'Commercial Assistant',
    talkWithUs: 'Talk with us',
    noBotTypeSelected: 'No assistant type selected',
  },
  activations: {
    getInfo: '(Get more info on the process)',
    createNewCustomer: 'Create end-user',
    createNewWallet: 'Create wallet',
    createNewScratchcard: 'Create top-up card',
    yearsString: 'years',
    monthsString: 'months',
    daysString: 'days',
    addNewActivationButton: 'Add system activation',
    home: 'OD Home',
    activationRequests: 'System activation',
    rechargeRequests: 'Wallet top-ups',
    walletStatus: 'Orders status',
    noActivationsFoundTitle: 'No system activations found',
    noActivationsFoundDescription: 'There are no system activations in the system. Create new ones to start',
    selloutOrder: 'Sell-out order',
    intragroupOrder: 'Intra-group order',
    custref: 'Cust. Ref.',
    rejectedReason: 'Rejected reason',
    changeStatusToUnderReview: 'Send to review',
    changeStatusToInvoiced: 'Send to invoiced',
    changeStatusToCreated: 'Send to created',
    changeStatusToRejected: 'Reject request',
    changeStatusToProcessing: 'Accept request',
    changeStatusAdmin: 'Set status (admin)',
    changeCustRef: 'Set Cust. Ref.',
    search: 'Search...',
    resetFilters: 'Reset filters',
    statusSelection: 'Any state',
    clusterSelection: 'Any cluster',
    statusDRAFT: 'Draft',
    statusUNDER_REVIEW: 'Under review',
    statusREJECTED: 'Rejected',
    statusPROCESSING: 'Processing',
    statusCREATED: 'Created',
    statusINVOICED: 'Invoiced',
    statusACTIVE: 'Active',
    statusDELETED: 'Deleted',
    promote: 'Deleted',
    saveDraft: 'Save as draft',
    customMarketplaceWarning: 'The end-user is not associated to the default marketplace. You will need to specify manually the requested baseline subscription and addons in the \'Notes\' section',
    subscriptionNotFoundError: 'Subscription not found',
    deleteActivationConfirm: 'Are you sure you want to delete this system activation? Operation cannot be undone',
    deleteActivationError: 'An error occurred while deleting the system activation. Please try again',
    promoteActivationConfirm: 'Are you sure you want to send this system activation to rewiew? The system activation request cannot be edited while in review.',
    promoteActivationError: 'An error occurred while promoting the system activation. Please try again',
    createdActivationConfirm: 'Are you sure you want to send this system activation to the status CREATED?',
    createdActivationError: 'An error occurred while updating the status the system activation. Please try again',
    invoicedActivationConfirm: 'Are you sure you want to set this system activation as invoiced?',
    invoicedActivationError: 'An error occurred while updating the status of the system activation. Please try again',
    editStatusActivationConfirm: 'Are you sure you want to edit the status of this system activation? Operation cannot be undone',
    editStatusActivationError: 'An error occurred while updating the status of the system activation. Please try again',
    rejectActivationConfirm: 'Specify the reason for rejecting this request.',
    rejectActivationError: 'An error occurred while rejecting the system activation. Please try again',
    processingActivationConfirm: 'Are you sure you want to accept this system activation?',
    processingActivationError: 'An error occurred while accepting the system activation. Please try again',
    newFileName: 'Specify the name of the file',
    newFileExtensionError: 'The file has an unsupported extension',
    newFileDuplicateNameError: 'This name has already been used for another file. Change the name and repeat the operation.',
    editCustrefConfirm: 'Specify the Cust. Ref. for this request.',
    editCustrefError: 'An error occurred while updating the Cust. Ref., please try again',
    editActivation: 'Edit system activation',
    newActivation: 'Add system activation',
    viewActivation: 'View system activation',
    newActivationError: 'An error occurred while creating this system activation. Please try again',
    editActivationError: 'An error occurred while updating this system activation. Please try again',
    customerSearch: 'Search among existing end-users',
    customerSearchEnterName: 'Search by name...',
    customerSearchNoMatch: 'No end-users found...',
    customerProceedWithSelected: 'Use selected end-user',
    customerProceedWithNew: 'Create new end-user',
    customerConfirmData: 'Confirm end-user\'s data',
    customerData: 'Customer\'s data',
    varData: 'VAR\'s data',
    selectCustomerOrVar: 'Select the target for this request (end-user or VAR)',
    varSfdcId: 'SFDC ACCOUNT ID',
    varName: 'Name',
    varSearch: 'Search among existing VARs',
    varSearchEnterName: 'Search by name...',
    varSearchNoMatch: 'No VAR found...',
    varCustomerSearch: 'Search among existing VAR\'s end-users',
    varProceedWithSelected: 'Use selected VAR',
    customerFormTitle: 'Luckey End-user',
    customerName: 'End-user name',
    customerVat: 'VAT',
    customerFiscalCode: 'Fiscal Code',
    customerCountry: 'Country',
    customerAddress: 'Address',
    customerCity: 'City',
    customerEmail: 'Email',
    customerPhone: 'Phone',
    customerCrmId: 'SFDC ACCOUNT ID',
    customerMarketplace: 'Marketplace',
    customerCertifiedEmail: 'PEC (Certified email)',
    customerCluster: 'Cluster',
    customerCompany: 'ISEO Company',
    customerPostalCode: 'Postal Code',
    customerPrincipalSubDivision: 'Provice, State, County, etc.',
    exportActivationsCSV: 'Export CSV',
    salesAndOrdersData: 'Sales & Orders',
    basicData: 'Plant information',
    formEnterAnswer: 'Your answer',
    formEnterAnswerGT0: 'Number greater than zero',
    formEnterAnswerFrom0To600: 'Number between 0 and 600',
    formEnterAnswerOwnerEmail: 'Owner email',
    formEnterAnswerKamEmail: 'Key Account Manager email',
    formEnterAnswerPmEmail: 'PM email',
    formEnterAnswerSystemEngineerEmail: 'System Engineer email',
    optId: 'Opportunity ID (SFDC)',
    salesOrderConfirmationNumber: 'Sales Order Confirmation Number (sell-out)',
    salesOrderConfirmationNumber_desc: '',
    selloutFiles: 'Sell-out order PDF (hardware + software + professional services)',
    selloutFiles_desc: 'Needed to correctly track Blossom revenues.',
    selloutFiles_desc2: 'File number limit: 5  ~  Single file size limit: 10MB  ~  Allowed file extensions: ',
    selloutFilesAdd: 'Upload file',
    selloutFilesDownload: 'Download',
    selloutFilesReplace: 'Replace',
    quotationToolFile: 'Quotation tool (not mandatory)',
    quotationToolFile_desc: 'Upload the quotation tool file used for this request.',
    quotationToolFile_desc2: 'File number limit: 1  ~  Single file size limit: 10MB',
    purchaseOrderNumberSofia: 'Purchase Order Number to Sofia',
    baselineSubscription: 'Baseline Luckey Subscription',
    baselineSubscription_BASIC: 'Luckey Basic',
    baselineSubscription_ENTERPRISE: 'Luckey Enterprise (Standard)',
    baselineSubscription_ENGINE: 'Luckey Engine',
    baselineSubscription_undefined: 'Use custom subscription',
    subscriptionData: 'Subscription info',
    noSubscriptionFound: 'No subscription found yet.',
    noSubscriptionFoundPleaseCheck: ' Please check the fields ',
    noSubscriptionFoundDescription: 'Subscription list is empty.  If you have set a search filter try to reset it and try again',
    selectedSubscription: 'Selected subscription: ',
    plantName: 'System Name',
    plantName_desc: 'Lowercase, without spaces and, if needed, separated by dashes - e.g example-plant-name',
    keyAccountManager: 'Key Account Manager',
    keyAccountManager_desc: 'Email address of the Key Account Manager',
    systemManagerEmails: 'System Administrator Email (one or more than one)',
    systemManagerEmails_desc: 'Email address of the person in charge of managing access control permissions for users from the web management platform',
    installerEmail: 'Installer email (not mandatory)',
    installerEmail_desc: '',
    pmEmail: 'PM email (not mandatory)',
    pmEmail_desc: 'Must belong to @sofialocks.com or @iseo.com',
    systemEngineerEmail: 'System Engineer email (not mandatory)',
    systemEngineerEmail_desc: 'Must belong to @sofialocks.com or @iseo.com',
    numberOfElements: 'Number of elements to manage',
    numberOfElements_desc: 'Elements are the sum of: users, locks and any active invitations',
    V364orF9000: 'V364/1RIS locks or F9000 cylinders?',
    V364orF9000_desc: 'Are there V364/1RIS locks or F9000 cylinders in the system? If so, the associated add-on will be added automatically.',
    addons: 'Add-ons & Plug-ins',
    addons_desc: 'Select the add-ons to add to the baseline subscription',
    credits: 'Number of credits',
    credits_desc: 'Credits sold with this system activation',
    expectedEnd: 'According to your selection, you will run out of credits in: ',
    finalData: 'Other information',
    notes: 'Notes',
    notes_desc: 'Write here any additional note concerning this request',
    notifications: 'Notifications',
    notifications_desc: 'People to notify for events; by default, the request owner and the associated Key Account Manager will be notified',
    editBaselineSubscriptionWarning: 'Are you sure you want to change the baseline subscription? This will reset the selected add-ons of the form.',
    editBaselineSubscriptionError: 'An error occurred, please try again',
    editNumberOfElementsWarning: 'Are you sure you want to change the number of elements? This will reset the available add-ons of the form.',
    editNumberOfElementsError: 'An error occurred, please try again',
    customerMarketplaceError: 'The selected end-user has no marketplace associated. Please contact support to fix the problem.',
    customerClusterError: 'The selected end-user has no cluster associated. Please contact support to fix the problem.',
    invoiceData: 'Invoice',
    invoiceFile: 'Invoice PDF *',
    invoiceFile_desc: 'Upload the invoice PDF.',
    invoiceFile_desc2: 'File number limit: 1  ~  Single file size limit: 10MB',
    invoiceNumber: 'Sell-out invoice number and date (XXXXXX - DD/MM/YYYY)',
    invoiceNumberPlaceholder: 'XXXXXX - DD/MM/YYYY',
    setInvoiceData: 'Upload invoice',
    dataMissingCreated: 'Specify invoice number and PDF to proceed',
  },
  recharges: {
    addNewRechargeButton: 'Add wallet top-up',
    noRechargesFoundTitle: 'No top-ups found',
    noRechargesFoundDescription: 'There are no wallet top-ups in the system. Create new ones to start',
    customerAndWallet: 'End-user and wallet',
    seeCustomer: 'Info',
    seeCustomerDetails: 'See end-user details',
    selloutOrder: 'Sell-out order',
    intragroupOrder: 'Intra-group order',
    custref: 'Cust. Ref.',
    mode:'Contract renewal type:',
    creditsSuggested:'Credits suggested',
    creditsConfirmed:'Credits confirmed',
    rejectedReason: 'Rejected reason',
    deletedReason: 'Deleted reason',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
    customerSearch: 'Search among existing end-users',
    customerSearchEnterName: 'Search by name...',
    customerSearchNoMatch: 'No end-users found...',
    varSearch: 'Search among existing VARs',
    varSearchEnterName: 'Search by name...',
    varSearchNoMatch: 'No VAR found...',
    varCustomerSearch: 'Search among existing VAR\'s end-users',
    plantSearch: 'Select the desired plant',
    plantSearchEnterName: 'Select plant',
    plantSearchNoMatch: 'No plant found...',
    plantProceedWithSelected: 'Use selected plant/wallet',
    dataMissingTakenCharge: 'Specify confirmed credits and sell-out to proceed',
    dataMissingScratchcardCreated: 'Specify invoice number and PDF to proceed',
    changeStatusToSelloutOrderGenerated: 'Send to review',
    changeStatusToInvoiced: 'Send to invoiced',
    changeStatusToCreated: 'Send to created',
    changeStatusToRejected: 'Reject request',
    changeStatusToScratchcardCreated: 'Top-up created',
    changeStatusToWalletRecharged: 'Wallet recharged',
    changeStatusAdmin: 'Set status (admin)',
    createCard: 'Create top-up card',
    changeCustRef: 'Set Cust. Ref.',
    search: 'Search...',
    resetFilters: 'Reset filters',
    statusSelection: 'Any state',
    clusterSelection: 'Any cluster',
    statusTO_BE_MANAGED: 'To be managed',
    statusTAKEN_CHARGE: 'Taken charge',
    statusREQUEST_TO_DELETE: 'Request to delete',
    statusSELLOUT_ORDER_GENERATED: 'Under review',
    statusSCRATCHCARD_CREATED: 'Top-up created',
    statusWALLET_RECHARGED: 'Recharged',
    statusREJECTED: 'Rejected',
    statusDELETED: 'Deleted',
    statusINVOICED: 'Invoiced',
    promote: 'Deleted',
    saveRequest: 'Save request',
    createRequest: 'Create wallet top-up',
    takeCharge: 'Take in charge',
    AUTO_RENEWABLE: 'Renewable automatically',
    NOT_AUTO_RENEWABLE: 'NON Renewable automatically',
    deleteRechargeConfirm: 'Are you sure you want to delete this request? Please, specify the reason. Operation cannot be undone',
    deleteAdminRechargeConfirm: 'Are you sure you want to permanently delete this request? Operation cannot be undone',
    deleteRechargeError: 'An error occurred while deleting the request. Please try again',
    takechargeRechargeConfirm: 'Please specify the type of renewal to proceed with this request',
    takechargeRechargeDirectConfirm: 'Are you sure you want to take in charge this request?',
    takechargeRechargeError: 'An error occurred while taking charge of the request. Please try again',
    promoteRechargeConfirm: 'Are you sure you want to send this request to rewiew? The request cannot be edited while in review.',
    promoteRechargeError: 'An error occurred while promoting the request. Please try again',
    createdRechargeConfirm: 'Are you sure you want to send this request to the status CREATED?',
    createdRechargeError: 'An error occurred while updating the status the request. Please try again',
    invoicedRechargeConfirm: 'Are you sure you want to set this request as invoiced?',
    invoicedRechargeError: 'An error occurred while updating the status of the request. Please try again',
    editStatusRechargeConfirm: 'Are you sure you want to edit the status of this request? Operation cannot be undone',
    editStatusRechargeError: 'An error occurred while updating the status of the request. Please try again',
    rejectRechargeConfirm: 'Specify the reason for rejecting this request.',
    rejectRechargeError: 'An error occurred while rejecting the request. Please try again',
    scratchcardCreatedRechargeConfirm: 'Are you sure you want to accept this request and confirm that the top-up card has been created?',
    scratchcardCreatedRechargeError: 'An error occurred while accepting the request. Please try again',
    walletRechargedRechargeConfirm: 'Are you sure you want to set this request as completed and confirm that the wallet has been recharged?',
    walletRechargedRechargeError: 'Si è verificato un errore nel cambio stato della richiesta. Per favore riprova',
    newFileName: 'Specify the name of the file',
    newFileExtensionError: 'The file has an unsupported extension',
    newFileDuplicateNameError: 'This name has already been used for another file. Change the name and repeat the operation.',
    editCustrefConfirm: 'Specify the Cust. Ref. for this request.',
    editCustrefError: 'An error occurred while updating the Cust. Ref., please try again',
    editRecharge: 'Edit wallet top-up',
    newRecharge: 'Add wallet top-up',
    viewRecharge: 'View wallet top-up',
    newRechargeError: 'An error occurred while creating this request. Please try again',
    editRechargeError: 'An error occurred while updating this request. Please try again',
    exportRechargesCSV: 'Export CSV',
    basicData: 'Wallet top-up parameters',
    ordersData: 'Orders',
    invoiceData: 'Invoice',
    formEnterAnswer: 'Your answer',
    formEnterAnswerGT0: 'Number greater than zero',
    sfdcId: 'Opportunity ID (SFDC)',
    salesOrderConfirmationNumber: 'Sales Order Confirmation Number (sell-out)',
    salesOrderConfirmationNumber_desc: '',
    selloutFiles: 'Sell-out order PDF (hardware + software + professional services)',
    selloutFiles_desc: 'Needed to correctly track Blossom revenues.',
    selloutFiles_desc2: 'File number limit: 5  ~  Single file size limit: 10MB  ~  Allowed file extensions: ',
    selloutFilesAdd: 'Upload file',
    selloutFilesDownload: 'Download',
    selloutFilesReplace: 'Replace',
    invoiceFile: 'Invoice PDF *',
    invoiceFile_desc: 'Upload the invoice PDF.',
    invoiceFile_desc2: 'File number limit: 1  ~  Single file size limit: 10MB',
    invoiceNumber: 'Sell-out invoice number (to the end-user) *',
    purchaseOrderNumberSofia: 'Purchase Order Number to Sofia',
    orderContactEmail: 'Order contact email',
  },
  vars: {
    varLogo: 'Logo',
    setTrainingDate: 'Set training date',
    missingLastTrainingDateForCertificate: 'This VAR has no training date set. Please set a training date and try to generate the certificate again',
    varLastTrainingData: 'Last training date',
    varShortDescription: 'Short description',
    varCity: 'City',
    varCountry: 'Country',
    varWebsite: 'Website',
    varVisibleOnSofiaWebsite: 'Visible on Sofia website partners page',
    downloadCertificate: 'Download certificate',
    opportunityChangeOwnerMessage: 'Please specify the owner of this quotation',
    noOpportunityHistoryFound: 'No quotation history found',
    noOpportunityHistoryFoundDescription: 'This quotation has no history records',
    customers: 'Customers',
    varsManagement: 'VARs',
    varSettings: 'Settings',
    varInfoPage: 'Training & Resources',
    varUsersPage: 'Users',
    vars: 'VAR',
    plantThemes: 'System themes',
    editVarPersonalConfirm: 'You\'re about to edit your company info. Are you sure you want to proceed?',
    editVarConfirm: 'You\'re about to edit the VAR data. Are you sure you want to proceed?',
    editVar: 'Edit VAR',
    newVar: 'New VAR',
    varCreationConfirm: 'Do you want to create the new VAR?',
    createVar: 'Create VAR',
    noVarFindTitle: 'No VAR Found',
    noVarFindDescription: 'The VAR list is empty. If you have set a search filter try to reset it and try again',
    noPlantThemeFindTitle: 'No plant theme Found',
    noPlantThemeFindDescription: 'The plant theme list is empty. If you have set a search filter try to reset it and try again',
    clusterSelection: 'Any cluster',
    varSelection: 'Any VAR',
    varName: 'Name',
    varAddress: 'Address',
    varEmail: 'Principal contact email',
    varPhone: 'Phone',
    varCluster: 'Cluster',
    varVat: 'VAT',
    varTechContact: 'Technical contact',
    varCommercialContact: 'Commercial contact',
    varNameTech: 'Technical contact name',
    varEmailTech: 'Technical contact email',
    varPhoneTech: 'Technical contact phone',
    varNameComm: 'Commercial contact name',
    varEmailComm: 'Commercial contact email',
    varPhoneComm: 'Commercial contact phone',
    varSfdcid: 'SFDC ACCOUNT ID',
    varSupportEmail: 'Support email',
    varSupportHours: 'Support opening hours',
    varCompanyName: 'Company name',
    varCustomers: 'VAR end-users',
    varUsers: 'VAR users',
    varOpportunities: 'VAR quotations',
    editPlantThemeConfirm: 'You\'re about to edit the plant theme data. Are you sure you want to proceed?',
    editPlantTheme: 'Edit plant theme',
    newPlantTheme: 'New plant theme',
    plantThemeCreationConfirm: 'Do you want to create the new plant theme?',
    editVarPersonal: 'Edit company info',
    plantThemeName: 'Name',
    plantThemeColour: 'Color *',
    plantThemeColourDescription: 'Theme color',
    plantThemeLogo: 'Logo',
    plantThemeDefault: 'Use as default theme',
    logoFile: 'Logo image *',
    logoFile_desc: 'Allowed file extensions: ',
    logoFileAdd: 'Upload file',
    logoFileDownload: 'Download',
    logoFileReplace: 'Replace',
    deletePlantThemeConfirm: 'Are you sure you want to delete this plant theme? Operation cannot be undone',
    plantThemeSearchEnterName: 'Select theme',
    plantThemeSearch: 'Select the desired theme',
    domainThemeTitle: 'Custom domain theme',
    domainThemeDescription: 'Set your custom theme on this domain',
    plantThemeModifySuccess: 'Plant theme modified and updated',
    cannotDeleteAssociatedTheme: 'Cannot delete a theme that is associated to a plant.',
    plantThemeAssociatedPlants: 'Plants associated to this theme',
    newVarUser: 'New VAR user',
    varUserCreationConfirm: 'Do you want to create the new VAR user?',
    createVarUser: 'Create VAR user',
    varUserFormTitle: 'VAR user',
    createNewVarUser: 'Create new VAR user',
    noVarUserFindTitle: 'No VAR users Found',
    noVarUserFindDescription: 'The VAR users list is empty',
    deleteVarUserConfirm: 'Are you sure you want to delete this VAR user? Operation cannot be undone',
    deleteVarOpportunityConfirm: 'Are you sure you want to delete this quotation? Operation cannot be undone',
    rejectVarOpportunityConfirm: 'Are you sure you want to reject this quotation? Operation cannot be undone',
    approveVarOpportunityConfirm: 'Are you sure you want to approve this quotation? Operation cannot be undone',
    syncsfdcidVarOpportunityConfirm: 'Are you sure you want to associate a SFDC ID to this quotation? Operation cannot be undone',
    upgradeVarOpportunityConfirm: 'Are you sure you want to send this quotation to revision? Operation cannot be undone',
    winVarOpportunityConfirm: 'Are you sure you want to set this quotation as WON? Operation cannot be undone',
    lostVarOpportunityConfirm: 'Are you sure you want to set this quotation as LOST? Operation cannot be undone',
    deleteUserConfirm: 'Are you sure you want to delete this user? Operation cannot be undone',
    varUserUsername: 'Username',
    varUserFirstname: 'First name',
    varUserLastname: 'Last name',
    varUserEmail: 'Email',
    varUsersList: 'Users',
    newFileExtensionError: 'The file has an unsupported extension',
    agreementFile: 'Agreement',
    opportunities: 'Quotations',
    opportunityManagement: 'Quotation info',
    opportunityID: 'ID',
    opportunityOpty: 'SFDC OPTY ID',
    opportunityVar: 'VAR',
    opportunityCustomer: 'End-user',
    opportunityOwner: 'Quotation Owner',
    opportunityName: 'Quotation name',
    opportunityValue: 'Value',
    opportunityStatus: 'Status',
    opportunityActions: 'Actions',
    opportunityDescription: 'Description',
    opportunityNotes: 'Notes',
    opportunityCrm: 'CRM',
    noOpportunityFindTitle: 'No quotations',
    noOpportunityFindDescription: 'The quotations list is empty',
    opportunityStatus_DRAFT: 'Draft',
    opportunityStatus_ON_APPROVAL: 'Under revision',
    opportunityStatus_APPROVED: 'Approved',
    opportunityStatus_CANCELLED: 'Cancelled',
    opportunityStatus_REJECTED: 'Rejected',
    opportunityStatus_WON: 'Won',
    opportunityStatus_LOST: 'Lost',
    opportunityFiles: 'Attachments',
    newOpportunity: 'New quotation',
    editOpportunity: 'Edit quotation',
    opportunitySyncSFDC: 'Sync with SFDC',
    exportOpportunities: 'Export CSV',
    upgradeOpportunity: 'Send to review',
    approveOpportunity: 'Approve',
    rejectOpportunity: 'Reject',
    wonOpportunity: 'Set as WON',
    lostOpportunity: 'Set as LOST',
    CREATED: 'Quotation created',
    VAR_USER_UPDATED: 'Quotation VAR assigned user updated',
    DESCRIPTION_UPDATED: 'Quotation Description Updated',
    NAME_UPDATED: 'Quotation name updated',
    VALUE_UPDATED: 'Quotation value updated',
    CURRENCY_UPDATED: 'Quotation currency updated',
    OPTY_ID_UPDATED: 'Quotation Opty Id updated',
    ISEO_CRM_ID_UPDATED: 'Quotation SFDC Id updated',
    DOCUMENTS_UPDATED: 'Quotation documents updated',
    STATUS_ON_APPROVAL: 'Quotation sent to approval',
    STATUS_APPROVED: 'Quotation approved',
    STATUS_WON: 'Quotation marked as won',
    STATUS_LOST: 'Quotation marked as lost',
    STATUS_DRAFT: 'Quotation marked as draft',
    STATUS_CANCELLED: 'Quotation marked as cancelled',
    CANCELLED: 'Quotation cancelled',
    STATUS_REJECTED: 'Quotation marked as rejected',
    showOpportunityHistory: 'Show history',
    opportunityHistory: 'Quotation history'
  },
  save: "Save",
  languages: {
    it: "Italian",
    en: "English",
    de: "German",
    fr: "French",
    es: "Spanish",
    nl: "Dutch",
    ITALIAN: "Italian",
    ENGLISH: "English",
    GERMAN: "German",
    FRENCH: "French",
    SPANISH: "Spanish",
    DUTCH: "Dutch",
    menuHeading: "Choose a language",
  },
  productInfo: {
    software: 'Software',
    salesMaterial: 'Sales Material',
    hardware: 'Hardware',
    marketingMaterial: 'Marketing Material',
  },
  users: {
    usersManagement: 'Users management',
    users: 'Users',
    noUserFindTitle: 'No users Found',
    noUserFindDescription: 'The users list is empty. Try reset your search filters if any',
    search: 'Search...',
    roleSelection: 'Role',
    clusterSelection: 'Cluster',
    varSelection: 'VAR',
    newUser: 'Add',
    userFirstname: 'First name',
    userLastname: 'Last name',
    userEmail: 'Email',
    userFormTitle: 'User data',
    editUserForm: 'Edit user',
    newUserForm: 'Create user',
  },
  permissions: {
    NAVIGATION: 'Menu visibility',
    CLUSTER: 'Cluster',
    VAR: 'VAR',
    CUSTOMER: 'End-users',
    OPPORTUNITY: 'Quotations',
    WALLET: 'End-users wallets',
    DOMAIN: 'End-users systems',
    GATEWAYS: 'Gateway',
    CARD: 'Top-up Cards',
    PLANT_ACTIVATION: 'System activations',
    RECHARGE_REQUEST: 'System recharges',
    ACTIVATION_REQUEST: 'Activation requests',
    EXPORT: 'Export entities',
    CREATE: 'Create new entity',
    UPDATE: 'Update an entity',
    MARK_LOST: 'Mark entity as Lost',
    MARK_WON: 'Mark entity as Won',
    CANCEL: 'Mark entity as Cancel',
    CANCEL_DRAFT: 'Mark entity as Cancelled when draft',
    CANCEL_ON_APPROVAL: 'Mark entity as Cancelled when under revision',
    CANCEL_APPROVED: 'Mark entity as Cancelled when approved',
    MARK_TO_BE_APPROVED: 'Mark entity as Approved',
    RECHARGE: 'Recharge',
    SUBSCRIPTION_UPDATE: 'Update subscriptions',
    DELETE_REQUEST: 'Send request to deletion',
    CREATION_REQUEST: 'Send request for create new entity',
    MANAGER_CREATE: 'Create administators',
    MANAGER_UPDATE: 'Update administators',
    INSTALLER_CREATE: 'Create installers',
    INSTALLER_UPDATE: 'Update installers',
    IMPERSONATE_WRITE: 'Impersonate administrators',
    IMPERSONATE_SUPPORT: 'Impersonate support account',
    ADDITIONAL_INFO_UPDATE: 'Edit additional information',
    CUSTOMERS: 'End-users',
    PLANT_ACTIVATIONS: 'System activations',
    RECHARGE_REQUESTS: 'System recharges',
    ACTIVATION_REQUESTS: 'Activation requests',
    CARDS: 'Top-up Cards',
    VAR_USERS: 'VAR Users',
    VAR_SETTINGS: 'VAR Settings',
    OPPORTUNITIES: 'Quotations',
    PRODUCTINFO: 'Product information',
    USER_READ: 'View users',
    USER_UPDATE: 'Update a user',
    USER_CREATE: 'Create new user',
    PLANT_THEME: 'Change domain theme',
    READ: 'Read entity values',
    ORDERS_STATUS: 'Order status',
    FILTER: 'Filter entities',
    VAR_MANAGEMENT: 'VAR management',
    MARKETPLACES: 'Marketplaces',
    DOMAINS: 'End-users systems',
    PRICELIST: 'Price list',
    SALESINFO: 'Sales info',
    PLATFORM_USERS: 'Users management',
    APPROVE: 'Mark entity as Approved',
    REJECT: 'Mark entity as Rejected',
    EXTEND: 'Extend',
    CONFIG: 'Read entity configurations',
    IRIS_ENABLE: 'Enable 1RIS for domain',
    MARK_TO_PROMOTE: 'Mark entity to Promote',
    MARK_TO_INVOCE: 'Mark entity to Invoice',
    DELETE: 'Delete entities',
    EDIT: 'Edit entities',
    SUBSCRIPTION_DELETE: 'Delete subscriptions',
  },
  segmentTypes: {
    WORKING_SPACES_name: 'Flexible Working Space',
    LIVING_name: 'Flexible Living',
    RETAIL_name: 'Flexible Retail',
    UNIVERSITIES_name: 'Universities',
    LEISURE_name: 'Leisure and Hospitality',
    HOSPITAL_name: 'Hospital',
    WORKING_SPACES_description: 'Offices, Coworking, Business centers',
    LIVING_description: 'Residential, Co-housing, Co-living, Student housing',
    RETAIL_description: 'Multi-site retail, Shopping malls, Shops chains',
    LEISURE_description: 'Small and Medium Hotels, Accommodation, Sport centers',
    HOSPITAL_description: 'Hospitals, Senior Living and elderly care',
  }
}

export default translations;