import MomentUtils from '@date-io/moment';
import _ from 'lodash';
import { Accordion, AccordionDetails, AccordionSummary, Button, TextField } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import * as React from "react";
import { ConnectedProps, connect } from 'react-redux';
import { IState } from '../../redux/store';
import { IUserFormDTO } from '../../repository/jago/model/input/IUserFormDTO';
import ReduxLanguage from "../ReduxLanguage";
import Select from 'react-select';
import { groupPermissionsByEntity, VAR_USERS_ROLES } from '../../utils/Utils';
import { RolesPermissionMap } from '../../services/PermissionService/PermissionsParser';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import translations from '../../translations/i18next';
import { LANGUAGE_DETAILS } from '../../utils/LanguageUtils';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    flex: 1,
  },
  formTitle: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  },
  customerDataColumn: {
    flex: 1,
  }
});

export interface IVarUserFormState {
  varUser: IUserFormDTO;
  errors: Record<keyof IUserFormDTO, boolean>;
};

export interface IVarUserFormProps extends WithStyles<typeof styles>{
  onCreateEditVarUser: (varUser: IUserFormDTO) => void;
  varUser?: IUserFormDTO;
  isEditing?: boolean;
}

type IReduxProps = ConnectedProps<typeof connector>;

export type ComponentProps = IVarUserFormProps & IReduxProps;
class VarUserForm extends React.Component<ComponentProps, IVarUserFormState> {

  constructor(props: ComponentProps) {
    super(props);
    const defaultLanguage = _.find(LANGUAGE_DETAILS, lang => lang.lang === props.language)?.name ? _.find(LANGUAGE_DETAILS, lang => lang.lang === props.language)?.name : 'ENGLISH';
    this.state = {
      varUser: props.varUser ? props.varUser : {
        id: null,
        firstName: '',
        lastName: '',
        email: '',
        roleId: null,
        varId: null,
        clusterIds: [],
        languageType: props.isEditing ? (props.varUser?.languageType || props.language) : defaultLanguage,
      },
      errors: {
        id: false,
        firstName: false,
        lastName: false,
        email: false,
        roleId: false,
        varId: false,
        clusterIds: false,
        languageType: false,
      },
    };
  }

  onChangeValue(field: keyof IUserFormDTO, mandatory: boolean, value?: any) {
    const { varUser, errors } = this.state;
    this.setState({
      errors: { ...errors, [field]: !value && mandatory ? true : false }
    });
    (varUser as any)[field] = value;
    this.setState({ varUser });
  }

  onSubmitForm() {
    const { varUser } = this.state;
    if (!this.canSubmitForm()) {
      return;
    }
    const { onCreateEditVarUser } = this.props;
    onCreateEditVarUser(varUser);
  }

  canSubmitForm() {
    const { varUser } = this.state;
    return varUser.firstName && varUser.lastName && varUser.email && varUser.roleId;
  }

  public render() {
    const { classes, isEditing, language } = this.props;
    const { varUser, errors } = this.state;
    const selectedRole = varUser && varUser.roleId && _.find(VAR_USERS_ROLES, role => role.id === varUser.roleId);
    const permissions = selectedRole && selectedRole.name ? RolesPermissionMap[selectedRole.name] : [];
    const groupedPermissions = groupPermissionsByEntity(permissions);
    const selectedLanguage = varUser && varUser.languageType && _.find(LANGUAGE_DETAILS, lang => lang.name === varUser.languageType) || _.find(LANGUAGE_DETAILS, lang => lang.lang === language);
    
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <h2 className={classes.formTitle}>{<ReduxLanguage languageKey={'vars.varUserFormTitle'} />}</h2>
          <div style={{ flex: 0, marginRight: 10 }}>
            <TextField
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.varUserFirstname'} />}
              variant="outlined"
              fullWidth
              required
              error={errors.firstName}
              value={varUser.firstName}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('firstName', true, e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.varUserLastname'} />}
              variant="outlined"
              fullWidth
              required
              error={errors.lastName}
              value={varUser.lastName}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('lastName', true, e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.varUserEmail'} />}
              variant="outlined"
              fullWidth
              required
              disabled={isEditing}
              error={errors.email}
              value={varUser.email}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('email', true, e.target.value)}
            />
            <Select
              key={varUser.languageType ? varUser.languageType : 'default-language'}
              isClearable
              isSearchable={false}
              value={selectedLanguage || null}
              options={LANGUAGE_DETAILS}
              placeholder={<><ReduxLanguage languageKey={'users.languageSelection'} /></>}
              formatOptionLabel={(o) => <>{translations.t(`languages.${o.lang}`)}</>}
              isOptionSelected={(o) => varUser.languageType === o.name}
              onChange={(e) => this.onChangeValue('languageType', false, e && e.name ? e.name : undefined)}
              styles={{
                menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                control: (styles) => ({ ...styles, minHeight: 55, minWidth: 300, marginLeft: 0, marginRight: 0, marginTop: 20 }),
              }} 
            />
            <Select
              key={varUser.roleId?varUser.roleId:-1}
              isClearable
              required
              isSearchable={false}
              value={varUser.roleId && selectedRole ? selectedRole : null}
              options={VAR_USERS_ROLES}
              placeholder={<><ReduxLanguage languageKey={'users.roleSelection'} /></>}
              formatOptionLabel={(o) => <>{o.label}</>}
              isOptionSelected={(o) => varUser.roleId===o.id}
              onChange={(e) => this.onChangeValue('roleId', true, e&&e.id?e.id:undefined)}
              styles={{
                menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                control: (styles) => ({ ...styles, minHeight: 55, minWidth: 300, marginLeft: 0, marginRight: 0, marginTop: 20 }),
              }} 
            />
          </div>
          {selectedRole && groupedPermissions && !_.isEmpty(groupedPermissions) ? (
            <Accordion style={{ marginTop: 15, marginBottom: 10, marginRight: 10 }}>
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                id="activationCustomerDataAccordion"
              >
              <div>
                <h3 style={{ fontWeight: 'bold', color: 'gray' }}>{<ReduxLanguage languageKey="forms.permissionsDetails" />}</h3>
              </div>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                {_.map(_.keys(groupedPermissions), (permissionKey) => (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4 style={{ fontWeight: 'bold', marginTop: 10 }}>{translations.t(`permissions.${permissionKey}`)}</h4>
                    {_.map(groupedPermissions[permissionKey], permission => (
                      <h4 style={{ marginLeft: 20 }}>• {translations.t(`permissions.${permission}`)}</h4>
                    ))}
                  </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          ) : null}
          <div style={{position:'sticky',bottom:'0',paddingTop:'20px',backgroundColor:'white',marginLeft:'-5px',marginRight:'-5px',zIndex:1}}>
            <Button
              variant="contained"
              disabled={!this.canSubmitForm()}
              style={{ width: '100%', opacity: this.canSubmitForm() ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', borderRadius: 0, height: 50, fontWeight: 'bold'  }}
              onClick={() => this.onSubmitForm()}
            >
              <ReduxLanguage languageKey="forms.save" />
            </Button>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(VarUserForm));