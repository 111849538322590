import { LanguageTypes } from '../translations/LanguageTypes';

export interface ILanguageDetailEntry {
  flag: string;
  lang: string;
  name: string;
}

export const LANGUAGE_DETAILS = [
  { flag: 'United-Kingdom', lang: LanguageTypes.ENGLISH, name: 'ENGLISH' },
  { flag: 'Italy', lang: LanguageTypes.ITALIAN, name: 'ITALIAN' },
  { flag: 'Germany', lang: LanguageTypes.GERMAN, name: 'GERMAN' },
  { flag: 'France', lang: LanguageTypes.FRENCH, name: 'FRENCH' },
  { flag: 'Spain', lang: LanguageTypes.SPANISH, name: 'SPANISH' },
  { flag: 'Netherland', lang: LanguageTypes.DUTCH, name: 'DUTCH' },
]; 