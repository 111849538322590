import { PaginatedDataDTO, PaginationDTO, PaginationQueryParamsDTO } from '@bottega52/commons-pagination';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import _ from 'lodash';
import moment from 'moment';
import * as CustomerCodec from '../../codec/customerDTOCodec';
import * as PlantThemeDecoder from '../../codec/plantThemeInDTODecoder';
import * as UserDecoder from '../../codec/userInDTODecoder';
import * as VarDecoder from '../../codec/varInDTODecoder';
import * as VarOpportunityDecoder from '../../codec/varOpportunityInDTODecoder';
import * as CreditsAPI from '../../repository/jago/creditsAPI';
import { ICustomerInDTO } from '../../repository/jago/model/input/ICustomerInDTO';
import { IPlantInDTO } from '../../repository/jago/model/input/IPlantInDTO';
import { IPlantThemeFormDTO } from '../../repository/jago/model/input/IPlantThemeFormDTO';
import { IPlantThemeInDTO } from '../../repository/jago/model/input/IPlantThemeInDTO';
import { IUserFormDTO } from '../../repository/jago/model/input/IUserFormDTO';
import { IUserInDTO } from '../../repository/jago/model/input/IUserInDTO';
import { IVarFormDTO } from '../../repository/jago/model/input/IVarFormDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import { IVarOpportunityInDTO } from '../../repository/jago/model/input/IVarOpportunityInDTO';
import { IPlantThemeOutDTO } from '../../repository/jago/model/output/IPlantThemeOutDTO';
import { IUserOutDTO } from '../../repository/jago/model/output/IUserOutDTO';
import { IVarOpportunityOutDTO } from '../../repository/jago/model/output/IVarOpportunityOutDTO';
import { IVarOutDTO } from '../../repository/jago/model/output/IVarOutDTO';
import { ICustomerRequestParamsDTO, IMarketplacesRequestParamsDTO, IThemePlantsRequestParamsDTO, IVarOpportunitiesRequestParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import * as VarsAPI from '../../repository/jago/varsAPI';
import { IState } from '../store';
import { ActionsUnion, IThunkAction, createAction } from "../utils";
import VarsActionTypesEnum from "./model/VarsActionTypesEnum";
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';


export const VarsActions = {
  saveVars: createAction<typeof VarsActionTypesEnum.SAVE_VARS, PaginatedDataDTO<IVarInDTO>>(VarsActionTypesEnum.SAVE_VARS),
  setSelectedVar: createAction<typeof VarsActionTypesEnum.SET_SELECTED_VAR, IVarInDTO | {}>(VarsActionTypesEnum.SET_SELECTED_VAR),
  saveVarCustomers: createAction<typeof VarsActionTypesEnum.SAVE_VAR_CUSTOMERS, PaginatedDataDTO<ICustomerInDTO>>(VarsActionTypesEnum.SAVE_VAR_CUSTOMERS),
  saveVar: createAction<typeof VarsActionTypesEnum.SAVE_VAR, IVarInDTO>(VarsActionTypesEnum.SAVE_VAR),
  savePlantThemes: createAction<typeof VarsActionTypesEnum.SAVE_PLANT_THEMES, PaginatedDataDTO<IPlantThemeInDTO>>(VarsActionTypesEnum.SAVE_PLANT_THEMES),
  setSelectedPlantTheme: createAction<typeof VarsActionTypesEnum.SET_SELECTED_PLANT_THEME, IPlantThemeInDTO | {}>(VarsActionTypesEnum.SET_SELECTED_PLANT_THEME),
  saveThemePlants: createAction<typeof VarsActionTypesEnum.SAVE_THEME_PLANTS, PaginatedDataDTO<IPlantInDTO>| {}>(VarsActionTypesEnum.SAVE_THEME_PLANTS),
  saveVarUsers: createAction<typeof VarsActionTypesEnum.SAVE_VAR_USERS, PaginatedDataDTO<IUserInDTO>>(VarsActionTypesEnum.SAVE_VAR_USERS),
  saveVarOpportunities: createAction<typeof VarsActionTypesEnum.SAVE_VAR_OPPORTUNITIES, PaginatedDataDTO<IVarOpportunityInDTO>>(VarsActionTypesEnum.SAVE_VAR_OPPORTUNITIES),
  saveCustomersSearch: createAction<typeof VarsActionTypesEnum.SAVE_CUSTOMERS_SEARCH, PaginatedDataDTO<ICustomerInDTO> | {}>(VarsActionTypesEnum.SAVE_CUSTOMERS_SEARCH),
};

export type VarsActionsType = ActionsUnion<typeof VarsActions>;

export function fetchVars(params: IMarketplacesRequestParamsDTO = { page: 0, pageSize: 500 }): IThunkAction<Promise<PaginatedDataDTO<IVarInDTO>>, IState> {
  return async (dispatch, getState) => {
    try {
      const response = await VarsAPI.fetchVars(params);
      if (response && response.data) {
        const decodedData = VarDecoder.decode(response.data);
        dispatch(VarsActions.saveVars(decodedData));
        return decodedData;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function appendVars(params?: IMarketplacesRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { vars: { data } } = getState().vars;
      if (_.isEmpty(data)) return;
      const stateVars = data as PaginatedDataDTO<IVarInDTO>;
      const varsResponse = await VarsAPI.fetchVars(params);
      if (varsResponse && varsResponse.data) {
        const decodedData = VarDecoder.decode(varsResponse.data);
        const varsToSave : PaginatedDataDTO<IVarInDTO> = {
          pagination: decodedData.pagination,
          content: [
            ...stateVars.content,
            ...decodedData.content
          ],
        }
        dispatch(VarsActions.saveVars(varsToSave));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function setSelectedVar(VAR: IVarInDTO | {}): IThunkAction<void, IState> {
  return (dispatch ) => {
    dispatch(VarsActions.setSelectedVar(VAR));
  }
}

export function editVar(varId: number, newVarData: IVarFormDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const updatedVar: IVarOutDTO = VarDecoder.encodeVarFromForm(newVarData);
      const updateVarResponse = await VarsAPI.editVar(varId,updatedVar);
      if (updateVarResponse && updateVarResponse.data) {
        return updateVarResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function editVarAdmin(varId: number, newVarData: IVarFormDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const updatedVar: IVarOutDTO = VarDecoder.encodeVarFromForm(newVarData);
      const updateVarResponse = await VarsAPI.editVarAdmin(varId, updatedVar);
      if (updateVarResponse && updateVarResponse.data) {
        return updateVarResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function createNewVar(newVarData: IVarFormDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const newVar: IVarOutDTO = VarDecoder.encodeVarFromForm(newVarData);
      const createVarResponse = await VarsAPI.createNewVar(newVar);
      if (createVarResponse && createVarResponse.data) {
        return createVarResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function fetchVarCustomers(varId: number, params: PaginationQueryParamsDTO ): IThunkAction<void, IState> {
  const paramsToSend: ICustomerRequestParamsDTO = {
    ...params,
    varId: varId ? varId.toString() : undefined,
  }
  return async (dispatch, getState) => {
    try {
      const customersResponse = await CreditsAPI.fetchCustomers(paramsToSend);
      if (customersResponse && customersResponse.data) {
        const decodedData = CustomerCodec.decode(customersResponse.data);
        dispatch(VarsActions.saveVarCustomers(decodedData));
        return decodedData
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchVar(): IThunkAction<Promise<IVarInDTO>, IState> {
  return async (dispatch, getState) => {
    try {
      const response = await VarsAPI.fetchVar();
      if (response && response.data) {
        dispatch(VarsActions.saveVar(response.data));
        return response.data;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function setVar(VAR: IVarInDTO): IThunkAction<void, IState> {
  return (dispatch ) => {
    dispatch(VarsActions.saveVar(VAR));
  }
}

export function fetchPlantThemes(params: IMarketplacesRequestParamsDTO = { page: 0, pageSize: 500 }): IThunkAction<Promise<PaginatedDataDTO<IPlantThemeInDTO>>, IState> {
  return async (dispatch, getState) => {
    try {
      const response = await VarsAPI.fetchPlantThemes(params);
      if (response && response.data) {
        const decodedData = PlantThemeDecoder.decode(response.data);
        dispatch(VarsActions.savePlantThemes(decodedData));
        return decodedData;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function appendPlantThemes(params?: IMarketplacesRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { plantThemes: { data } } = getState().vars;
      if (_.isEmpty(data)) return;
      const statePlantThemes = data as PaginatedDataDTO<IPlantThemeInDTO>;
      const plantThemesResponse = await VarsAPI.fetchPlantThemes(params);
      if (plantThemesResponse && plantThemesResponse.data) {
        const decodedData = PlantThemeDecoder.decode(plantThemesResponse.data);
        const plantThemesToSave : PaginatedDataDTO<IPlantThemeInDTO> = {
          pagination: decodedData.pagination,
          content: [
            ...statePlantThemes.content,
            ...decodedData.content
          ],
        }
        dispatch(VarsActions.savePlantThemes(plantThemesToSave));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function setSelectedPlantTheme(VAR: IPlantThemeInDTO | {}): IThunkAction<void, IState> {
  return (dispatch ) => {
    dispatch(VarsActions.setSelectedPlantTheme(VAR));
  }
}

export function editPlantTheme(plantThemeId: number, newPlantThemeData: IPlantThemeFormDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const updatedPlantTheme: IPlantThemeOutDTO = PlantThemeDecoder.encodePlantThemeFromForm(newPlantThemeData);
      const updatedPlantThemeResponse = await VarsAPI.editPlantTheme(plantThemeId, updatedPlantTheme);
      if (updatedPlantThemeResponse && updatedPlantThemeResponse.data) {
        return updatedPlantThemeResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function createNewPlantTheme(newPlantThemeData: IPlantThemeFormDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const newPlantTheme: IPlantThemeOutDTO = PlantThemeDecoder.encodePlantThemeFromForm(newPlantThemeData);
      const createPlantThemeResponse = await VarsAPI.createNewPlantTheme(newPlantTheme);
      if (createPlantThemeResponse && createPlantThemeResponse.data) {
        return createPlantThemeResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function deletePlantTheme(plantThemeId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const deletePlantThemeResponse = await VarsAPI.deletePlantTheme(plantThemeId);
      if (deletePlantThemeResponse && deletePlantThemeResponse.data) {
        return deletePlantThemeResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function addPlantThemeToPlant(plantId: number, plantThemeId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const updatedPlantThemeResponse = await VarsAPI.addPlantThemeToPlant(plantId, plantThemeId);
      if (updatedPlantThemeResponse && updatedPlantThemeResponse.data) {
        return updatedPlantThemeResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function removePlantThemeFromPlant(plantId: number, plantThemeId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const deletePlantThemeResponse = await VarsAPI.removePlantThemeFromPlant(plantId, plantThemeId);
      if (deletePlantThemeResponse && deletePlantThemeResponse.data) {
        return deletePlantThemeResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function fetchThemePlants(params?: IThemePlantsRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const plantsResponse = await CreditsAPI.fetchThemePlants(params);
      if (plantsResponse && plantsResponse.data) {
        const pagination: PaginationDTO = _.omit(plantsResponse.data, 'content');
        const paginatedData: PaginatedDataDTO<IPlantInDTO> = {
          content: plantsResponse.data.content,
          pagination,
        };
        dispatch(VarsActions.saveThemePlants(paginatedData));
        return plantsResponse.data.content;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function resetThemePlants(): IThunkAction<void, IState> {
  return (dispatch ) => {
    dispatch(VarsActions.saveThemePlants({}));
  }
}

export function fetchVarUsers(varId: number, params: IMarketplacesRequestParamsDTO = { page: 0, pageSize: 500 }): IThunkAction<Promise<PaginatedDataDTO<IUserInDTO>>, IState> {
  return async (dispatch, getState) => {
    try {
      const response = await VarsAPI.fetchVarUsers(varId,params);
      if (response && response.data) {
        const decodedData = UserDecoder.decode(response.data);
        dispatch(VarsActions.saveVarUsers(decodedData));
        return decodedData;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function createNewVarUser(varId: number, newVarUserData: IUserFormDTO): IThunkAction<void, IState> {
  return async () => {
    debugger
    try {
      const newVarUser: IUserOutDTO = {
        ...UserDecoder.encodeUserFromForm(newVarUserData),
        varId: undefined,
        clusterIds: undefined,
      };
      const createVarUserResponse = await VarsAPI.createNewVarUser(varId, newVarUser);
      if (createVarUserResponse && createVarUserResponse.data) {
        return createVarUserResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function editVarUser(varId: number, varUserId: number, newVarUserData: IUserFormDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const newVarUser: IUserOutDTO = {
        ...UserDecoder.encodeUserFromForm(newVarUserData),
        varId: undefined,
        clusterIds: undefined,
      };
      const updateVarUserResponse = await VarsAPI.editVarUser(varId, varUserId, newVarUser);
      if (updateVarUserResponse && updateVarUserResponse.data) {
        return updateVarUserResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function deleteVarUser(varId: number, varUserId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const deleteVarUserResponse: any = await VarsAPI.deleteVarUser(varId,varUserId);
      if (deleteVarUserResponse && deleteVarUserResponse.data) {
        if (deleteVarUserResponse.data.code && deleteVarUserResponse.data.code==12607) {
          return "OPPORTUNITIES_ERROR"
        }
        return deleteVarUserResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function fetchOpportunities(params: IVarOpportunitiesRequestParamsDTO = { page: 0, pageSize: 500 }): IThunkAction<Promise<PaginatedDataDTO<IVarOpportunityInDTO>>, IState> {
  return async (dispatch, getState) => {
    try {
      const response = await VarsAPI.fetchOpportunities(params)
      if (response && response.data) {
        const decodedData = VarOpportunityDecoder.decode(response.data);
        dispatch(VarsActions.saveVarOpportunities(decodedData));
        return decodedData;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function appendOpportunities( params: IMarketplacesRequestParamsDTO = { page: 0, pageSize: 1000 }): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { opportunities: { data } } = getState().vars;
      if (_.isEmpty(data)) return;
      const stateVarOpportunities = data as PaginatedDataDTO<IVarOpportunityInDTO>;
      const varOpportunitiesResponse = await VarsAPI.fetchOpportunities(params)
      if (varOpportunitiesResponse && varOpportunitiesResponse.data) {
        const decodedData = VarOpportunityDecoder.decode(varOpportunitiesResponse.data);
        const varOpportunitiesToSave : PaginatedDataDTO<IVarOpportunityInDTO> = {
          pagination: decodedData.pagination,
          content: [
            ...stateVarOpportunities.content,
            ...decodedData.content
          ],
        }
        dispatch(VarsActions.saveVarOpportunities(varOpportunitiesToSave));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function createNewVarOpportunity(customerId: number, newData: IVarOpportunityOutDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const createResponse = await VarsAPI.createVarOpportunity(customerId, newData);
      if (createResponse && createResponse.data) {
        return createResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function editVarOpportunity(customerId: number, opportunityId: number, newData: IVarOpportunityOutDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const updateResponse = await VarsAPI.editVarOpportunity(customerId, opportunityId, newData);
      if (updateResponse && updateResponse.data) {
        return updateResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function editVarOpportunityAdmin(customerId: number, opportunityId: number, newData: IVarOpportunityOutDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const updateResponse = await VarsAPI.editVarOpportunityAdmin(customerId, opportunityId, newData);
      if (updateResponse && updateResponse.data) {
        return updateResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function upgradeVarOpportunity(customerId: number, opportunityId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const updateResponse = await VarsAPI.upgradeVarOpportunity(customerId, opportunityId);
      if (updateResponse && updateResponse.data) {
        return updateResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function rejectVarOpportunity(customerId: number, opportunityId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const updateResponse = await VarsAPI.rejectVarOpportunity(customerId, opportunityId);
      if (updateResponse && updateResponse.data) {
        return updateResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function approveVarOpportunity(customerId: number, opportunityId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const updateResponse = await VarsAPI.approveVarOpportunity(customerId, opportunityId);
      if (updateResponse && updateResponse.data) {
        return updateResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function syncSfdcidVarOpportunity(customerId: number, opportunityId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const updateResponse = await VarsAPI.syncSfdcidVarOpportunity(customerId, opportunityId);
      if (updateResponse && updateResponse.data) {
        return updateResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function concludeVarOpportunity(customerId: number, opportunityId: number, status: string): IThunkAction<void, IState> {
  return async () => {
    try {
      const updateResponse = await VarsAPI.concludeVarOpportunity(customerId, opportunityId, status);
      if (updateResponse && updateResponse.data) {
        return updateResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function deleteVarOpportunity(customerId: number, opportunityId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const updateResponse = await VarsAPI.deleteVarOpportunity(customerId, opportunityId);
      if (updateResponse && updateResponse.data) {
        return updateResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function changeVarOpportunityOwner(customerId: number, opportunityId: number, varUserId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const updateResponse = await VarsAPI.changeVarOpportunityOwner(customerId, opportunityId, varUserId);
      if (updateResponse && updateResponse.data) {
        return updateResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function fetchCustomersSearch(params?: ICustomerRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const customersResponse = await CreditsAPI.fetchCustomers(params);
      if (customersResponse && customersResponse.data) {
        const decodedData = CustomerCodec.decode(customersResponse.data);
        dispatch(VarsActions.saveCustomersSearch(decodedData));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function resetCustomersSearch(): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      dispatch(VarsActions.saveCustomersSearch({}));
    } catch (error) {
      throw error;
    }
  };
}

export function exportOpportunitiesCSV(params: IMarketplacesRequestParamsDTO = {}) {
  return async () => {
    const response = await VarsAPI.exportOpportunitiesCSV(params);
    FileSaver.saveAs(response.data, `export-opportunities-${moment().toISOString()}.csv`);
  }
}

export function onExportVars() {
  return async (dispatch, getState) => {
    const clusters = getState().credits.clusters.data;
    const clustersData = clusters as PaginatedDataDTO<IClusterInDTO>;
    const clustersContent = clustersData && clustersData.content ? clustersData.content:[]
    let params = {
      page: 0,
      pageSize: 100,
    };
    let varResponse = await VarsAPI.fetchVars(params);
    if (varResponse && varResponse.data) {
      let vars = varResponse.data.content;
      let pagination = _.omit(varResponse.data, 'content');
      while (pagination.number + 1 !== pagination.totalPages) {
        params = {
          ...params,
          page: pagination.number + 1,
        };
      varResponse = await  VarsAPI.fetchVars(params);
      vars.push(...varResponse.data.content);
      pagination = _.omit(varResponse.data, 'content');
    }
      const exportData: any[] = [];
      const fileName = `VARs List`;
      _.map(vars, VAR => {
        const varCluster = VAR.clusterId && _.find(clustersContent, cluster => cluster.id === VAR.clusterId);
        debugger
        exportData.push({
          id: VAR.id,
          name: VAR.name,
          'Key account manager': VAR.kamEmail,
          'ISEO Company': VAR.company,
          'Market': varCluster && varCluster.market ? varCluster.market : '---',
          'Cluster': varCluster && varCluster.name ? varCluster.name : '---',

        });
      });
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = `.xlsx`;
      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = { Sheets: { VARs: ws }, SheetNames: ['VARs'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  }
}


export function onExportVarsUsers() {
  return async (dispatch, getState) => {
    const clusters = getState().credits.clusters.data;
    const clustersData = clusters as PaginatedDataDTO<IClusterInDTO>;
    const clustersContent = clustersData && clustersData.content ? clustersData.content:[]
    let users = [];
    let params = {
      page: 0,
      pageSize: 100,
    };
    let varResponse = await VarsAPI.fetchVars(params);
    if (varResponse && varResponse.data) {
      let vars = varResponse.data.content;
      let pagination = _.omit(varResponse.data, 'content');
      while (pagination.number + 1 !== pagination.totalPages) {
        params = {
          ...params,
          page: pagination.number + 1,
        };
      varResponse = await  VarsAPI.fetchVars(params);
      vars.push(...varResponse.data.content);
      pagination = _.omit(varResponse.data, 'content');
    }
    for (const VAR of vars) {
      let varUsersResponse = await dispatch(fetchVarUsers(VAR.id));
      if (varUsersResponse && varUsersResponse.content) {
        const usersResponse = varUsersResponse.content;
        const usersResponseFormatted = _.map(usersResponse, user => {
          const varCluster = VAR.clusterId && _.find(clustersContent, cluster => cluster.id === VAR.clusterId);
          return ({
            ...user,
            company: VAR.companyName,
            cluster: varCluster && varCluster.name,
          })
        });
        users.push(...usersResponseFormatted);
      }
    }
    const exportData: any[] = [];
    const fileName = `VAR Users List`;
    _.map(users, user => {
      const userToExport = user;
      exportData.push({
        id: user.id,
        name: userToExport.firstname,
        lastname: userToExport.lastname,
        email: userToExport.email,
        company: userToExport.company,
        cluster: userToExport.cluster,
      });
    });
    debugger
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = `.xlsx`;
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = { Sheets: { Users: ws }, SheetNames: ['Users'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    }
  }
}