import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import logoSofia from '../assets/logos/logoSodiaIseoWhite.png';
import logo from '../assets/logos/logoVashoIconDark.png';
import backgroundImage from '../assets/loginBackground.jpg';
import OTPEmailLoginForm from '../components/Forms/OTPEmailLoginForm';
import { ModalTypes } from '../components/Modals/ModalTypes';
import ReduxLanguage from '../components/ReduxLanguage';
import * as ModalsActions from '../redux/modals/modals.actions';
import * as SettingsActions from '../redux/settings/settings.actions';
import { IState } from '../redux/store';
import * as UserActions from '../redux/user/user.actions';
import { LocalStorageRepository } from '../services/StorageService/LocalStorageRepository';
import { LanguageTypes } from '../translations/LanguageTypes';
import { detectBrowserLanguage } from '../translations/utils';
import { Divider } from '@material-ui/core';


const styles = createStyles({
  mainContainer: {
    backgroundColor: '#282c34',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'calc(10px + 2vmin)',
    color: 'white',
    minHeight: '100vh',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    margin: 0,
    marginBottom: 15,
  },
  logo: {
    width: 200,
    margin: 'auto',
    marginBottom: 45,
  },
  loginButtonsContainer: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 45,
    paddingLeft: 50,
    paddingRight: 50,
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
  },
  otpModalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  welcomeText: {
    color: '#3f3f3f',
    fontWeight: 'bold',
    textAlign: 'left',
    alignSelf: 'flex-start',
    fontSize: 20
  }
});

type IReduxProps = ConnectedProps<typeof connector>;

export type ILoginPageProps = WithStyles<typeof styles> & RouteComponentProps<any> & IReduxProps;

enum OTPLoginPhase {
  START = 'START',
  EMAIL_SENT = 'EMAIL_SENT',
}

interface ILoginPageState {
  otpLoginPhase: OTPLoginPhase;
  otpLoginModalVisible: boolean;
}

export class LoginPage extends React.Component<ILoginPageProps, ILoginPageState> {

  constructor(props: ILoginPageProps) {
    super(props);
    this.state = {
      otpLoginPhase: OTPLoginPhase.START,
      otpLoginModalVisible: false,
    };
  }

  public componentDidMount() {
    const { dispatch } = this.props;
    const storage = new LocalStorageRepository();
    let language = storage.readKey<string>('language', undefined);
    if (language === undefined){
      language = detectBrowserLanguage();
      storage.storeKey('language', language);
    }
    dispatch<any>(SettingsActions.saveLanguageSettings(language));
  }

  public async onGoogleLoginSuccess(res: CredentialResponse) {
    const { history, dispatch } = this.props;

    const tokenId = res.credential;
    dispatch<any>(SettingsActions.setSpinnerVisible(true));
    try {
      if (!tokenId) {
        throw new Error();
      }
      await dispatch<any>(UserActions.userJagoLoginWithGoogle(tokenId));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      history.push('/home');
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_MODAL_LOGIN`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.loginJagoGoogleFailedTitle',
          errorMessageKey: 'errors.loginJagoGoogleFailed',
        }
      }));
    }
  }

  async onGoogleLoginFail() {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`ERROR_MODAL_LOGIN`, {
      modalType: ModalTypes.ERROR_MODAL,
      modalProps: {
        titleMessageKey: 'errors.loginGoogleFailedTitle',
        errorMessageKey: 'errors.loginGoogleFailed',
      }
    }));
  }

  openOTPLoginModal() {
    this.setState({ otpLoginModalVisible: true });
  }

  closingOTPLoginModal() {
    const { otpLoginPhase } = this.state;
    if (otpLoginPhase===OTPLoginPhase.EMAIL_SENT)
      return
    this.closeOTPLoginModal()
  }

  closeOTPLoginModal() {
    this.setState({ otpLoginModalVisible: false });
  }

  async sendOTPEmail(email: string) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(UserActions.sendOTPEmail(email));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      this.setState({ otpLoginPhase: OTPLoginPhase.EMAIL_SENT });
    } catch (err) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      this.setState({ otpLoginPhase: OTPLoginPhase.START, otpLoginModalVisible: false });
      dispatch<any>(ModalsActions.showModal(`ERROR_MODAL_LOGIN`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.loginJagoOTPEmailFailedTitle',
          errorMessageKey: 'errors.loginJagoOTPEmailFailed',
        }
      }));
    }
    
  }

  async loginWithOTP(code: string) {
    const { history, dispatch } = this.props;

    dispatch<any>(SettingsActions.setSpinnerVisible(true));
    try {
      await dispatch<any>(UserActions.userJagoLoginWithOTP(code));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      history.push('/home');
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_MODAL_LOGIN`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.loginJagoOTPFailedTitle',
          errorMessageKey: 'errors.loginJagoOTPFailed',
        }
      }));
    }
  }

  async onResetOtpLoginPhase() {
    this.setState({ otpLoginPhase: OTPLoginPhase.START });
  }

  render() {
    const { classes, language } = this.props
    const { otpLoginPhase } = this.state;
    return (
      <div>
        <header className={classes.mainContainer} style={{ position: 'relative', height: '100vh', paddingTop: 0, background: 'linear-gradient(#009688, #025b54)', backgroundSize: 'cover', backgroundImage: `url(${backgroundImage})`, }}>
          <div className={classes.container}>
            <div className={classes.loginButtonsContainer}>
              <img src={logo} className={classes.logo} alt="logo" />
              <h5 className={classes.welcomeText}><ReduxLanguage languageKey="login.welcome" /></h5>
              <OTPEmailLoginForm
                otpLoginPhase={otpLoginPhase}
                onSubmitEmail={(email) => this.sendOTPEmail(email)}
                onSubmitCode={(code) => this.loginWithOTP(code)}
                onResetOtpLoginPhase={() => this.onResetOtpLoginPhase()}
              />
              <div style={{ alignItems: 'center', margin: 'auto', marginTop: 20}}>
              <GoogleLogin
                onSuccess={(res) => this.onGoogleLoginSuccess(res)}
                onError={() => this.onGoogleLoginFail()}
                useOneTap
                locale={language}
                shape='pill'
                size="large"
              />
              </div>
            </div>
          </div>
            <img src={logoSofia} style={{ width: 150, position: 'absolute', bottom: 10 }} alt="logo" />
        </header>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
  };
}

const connector = connect(mapStateToProps);

export default connector(withRouter(withStyles(styles)(LoginPage)));