import { AppBar, Card, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Tab, Tabs, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { BusinessCenterOutlined, ChatBubbleOutline, ChatOutlined, ContactSupportOutlined, DevicesOutlined } from '@material-ui/icons';
import 'moment/locale/it';
import React, { ReactElement } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import LearningTicketForm from '../../components/Forms/LearningTicketForm';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import ReduxLanguage from '../../components/ReduxLanguage';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as ProductInfoActions from '../../redux/productinfo/productinfo.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import * as UserActions from '../../redux/user/user.actions';
import * as VarActions from '../../redux/vars/vars.actions';
import { ILearningTicketFormDTO } from '../../repository/jago/model/input/ILearningTicketFormDTO';
import { IProductInfoPostInDTO } from '../../repository/jago/model/input/IProductInfoPostInDTO';
import { decodeUnicode } from '../../repository/utils';
import translations from "../../translations/i18next";
import { ICreditsInterface } from '../../redux/credits/credits.reducers';

const styles = createStyles({
  header: {
    backgroundColor: 'white',
    width: '100%',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
});

type IReduxProps = ConnectedProps<typeof connector>;

export interface IProductInfoProps extends WithStyles<typeof styles> {
  language: string;
  credits: ICreditsInterface;
}

export type ComponentProps = IProductInfoProps & IReduxProps;


export interface IProductInfoCategory {
  tabIndex: number;
  category: string;
  label: string;
  icon: ReactElement<any,any>;
};

export const productInfoCategories: IProductInfoCategory[] = [
  {
    tabIndex: 0,
    category: 'MARKETING_MATERIAL_DEFAULT',
    label: translations.t('productInfo.marketingMaterial'),
    icon: <DevicesOutlined style={{ fontSize: 25 }} />,
  },
  {
    tabIndex: 1,
    category: 'SALES_MATERIAL_DEFAULT',
    label: translations.t('productInfo.salesMaterial'),
    icon: <BusinessCenterOutlined style={{ fontSize: 25 }} />,
  },
]

interface IBotConfig {
  p_id: string;
  p_key: string;
  translationKey: string;
}

const BOT_CONFIGS: { [key: string]: IBotConfig } = {
  helper: {
    p_id: '60655',
    p_key: '4fa9ef04569ea620af429bbd5b51e950',
    translationKey: 'forms.helperBot'
  },
  commercial: {
    p_id: '62238',
    p_key: 'dbe3e4edaf0214164dce94aba4ea5eba',
    translationKey: 'forms.commercialBot'
  }
};

export interface IProductInfoState {
  activeTab: number;
  chatScriptLoaded: boolean;
  selectedBot: string | null;
};

class ProductInfo extends React.Component<ComponentProps,IProductInfoState> {

  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      activeTab: 0,
      chatScriptLoaded: false,
      selectedBot: null
    };
  }

  async componentDidMount() {
    const { dispatch, language } = this.props;
    try {
      await dispatch<any>(ProductInfoActions.fetchPosts(language, productInfoCategories[0].category));
      dispatch<any>(VarActions.fetchVar());
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPEN_PARAMETERS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.openSystemParametersError',
        }
      }));
    }
  }

  componentDidUpdate(prevProps: ComponentProps, prevState: IProductInfoState) {
    if (prevState.activeTab !== this.state.activeTab) {
      if (prevState.activeTab === 2) {
        this.cleanupCustomGPTScript();
      }
    }

    if (this.state.activeTab === 2 && 
        prevState.selectedBot !== this.state.selectedBot && 
        this.state.selectedBot) {
      this.loadCustomGPTScript();
    }
  }

  componentWillUnmount() {
    if (this.state.chatScriptLoaded) {
      this.cleanupCustomGPTScript();
    }
  }

  loadCustomGPTScript = () => {
    this.cleanupCustomGPTScript(); // Clean up any existing script first

    const selectedConfig = this.state.selectedBot ? BOT_CONFIGS[this.state.selectedBot] : null;
    if (!selectedConfig) return;

    const script = document.createElement('script');
    script.src = 'https://cdn.customgpt.ai/js/embed.js';
    script.defer = true;
    script.setAttribute('div_id', 'customgpt_chat');
    script.setAttribute('p_id', selectedConfig.p_id);
    script.setAttribute('p_key', selectedConfig.p_key);
    script.id = 'customgpt-script';
    
    script.onload = () => {
      this.setState({ chatScriptLoaded: true });
    };
    
    document.body.appendChild(script);
  }

  cleanupCustomGPTScript = () => {
    const script = document.getElementById('customgpt-script');
    if (script) {
      script.remove();
    }
    const chatDiv = document.getElementById('customgpt_chat');
    if (chatDiv) {
      chatDiv.innerHTML = '';
    }
    this.setState({ chatScriptLoaded: false });
  }

  public onTabChange(index: number) {
    const { dispatch, language, clusters } = this.props;
    this.setState({ activeTab: index });
    if (index !== 3 && index !== 2) {    
      const foundCategory = productInfoCategories.find(e => e.tabIndex === index);
      let category = foundCategory ? foundCategory.category : '';
      
      // Check if user has exactly one cluster and it's France
      if ('content' in clusters && clusters.content.length === 1 && 
          clusters.content[0].name === 'France' && 
          category === 'SALES_MATERIAL_DEFAULT') {
        category = 'SALES_MATERIAL_FRANCE';
      }
      
      dispatch<any>(ProductInfoActions.fetchPosts(language, category));
    }
  }

  public async onRowClick(post: IProductInfoPostInDTO) {
    const { dispatch } = this.props;
    try {
      await dispatch<any>(ProductInfoActions.setSelectedPost(post));
      this.onOpenPost()
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_PRODUCTINFOPOST_DETAILS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.getProductInfoPostDetailsError',
        }
      }));
    }
  }
  
  async onOpenPost() {
    const { dispatch, selectedPost } = this.props;
    const post = (selectedPost as IProductInfoPostInDTO)
    const postTitle = decodeUnicode(post.title.rendered);
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_EDIT_PLANTTHEME_MODAL`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL_WP,
      modalProps: {
        content: (
          <div style={{ paddingBottom:50 }} >
            <div className="productInfoContentContainer" 
              style={{ maxHeight: 600, padding: 25, paddingLeft: 20, paddingTop: 10, marginBottom: 0 }} 
              dangerouslySetInnerHTML={{ __html: post.content.rendered }} 
            />
          </div>
        ),
        titleMessageKey: postTitle,
      }
    }));
  }

  async onOpenLearningTicket(ticketData: ILearningTicketFormDTO) {
    
    const { dispatch } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(UserActions.openLearingTicket(ticketData.description));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_WALLET_CREATION`, {
        modalType: ModalTypes.SUCCESS_MODAL,
        modalProps: {
          titleMessageKey: 'forms.ticketOpenSuccessfully',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_WALLET_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'forms.ticketOpenError',
        }
      }));
    }
  }

  handleBotChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
    this.setState({ selectedBot: event.target.value });
  }

  renderBotSelector = () => {
    return (
      <FormControl component="fieldset" style={{ margin: '20px' }}>
        <FormLabel component="legend" style={{ marginBottom: 10, fontWeight: 'bold', color: '#3f3f3f', fontSize: 18 }}>{translations.t('forms.selectBotType')}</FormLabel>
        <RadioGroup
          aria-label="bot-type"
          name="bot-type"
          value={this.state.selectedBot || ''}
          onChange={this.handleBotChange}
        >
          {Object.entries(BOT_CONFIGS).map(([key, config]) => (
            <FormControlLabel
              key={key}
              value={key}
              control={<Radio />}
              label={translations.t(config.translationKey)}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }

  render() {
    const { classes, posts } = this.props;
    const { activeTab, selectedBot } = this.state;
    return (
      <div className={classes.container}>
        <AppBar position="sticky" style={{ backgroundColor: 'white' }}>
          <Tabs
            value={activeTab}
            centered
            indicatorColor="primary"
            textColor="primary"
            style={{ backgroundColor: 'white', width: '100%' }}
            onChange={(e, index) => this.onTabChange(index)}
          >
            <Tab key={0} icon={<DevicesOutlined />} label={<h5 style={{ margin: 0 }}>{translations.t('productInfo.marketingMaterial')}</h5>} />
            <Tab key={1} icon={<BusinessCenterOutlined />} label={<h5 style={{ margin: 0 }}>{translations.t('productInfo.salesMaterial')}</h5>} />
            <Tab key={2} icon={<ChatOutlined />} label={<h5 style={{ margin: 0 }}>{<ReduxLanguage languageKey='forms.talkWithUs' />}</h5>} />
            <Tab key={3} icon={<ContactSupportOutlined />} label={<h5 style={{ margin: 0 }}>{<ReduxLanguage languageKey='forms.askQuestion' />}</h5>} />
          </Tabs>
        </AppBar>
        {activeTab !== 2 && activeTab !== 3 ? (
          <div style={{ backgroundColor: 'white'}}>
            {posts.map((post,index) => (
              <div
                style={{ minHeight: '600', padding: 25, paddingLeft: 20, paddingTop: 10, marginBottom: 0, backgroundColor: 'white' }} 
                dangerouslySetInnerHTML={{ __html: post.content.rendered }} 
              />
            ))}
          </div>
        ) : null}
        {activeTab === 3 ? (
          <div>
            <LearningTicketForm
              onCreateTicket={ticketData => this.onOpenLearningTicket(ticketData)}
            />
            </div>
        ): null}
        {activeTab === 2 ? (
          <div style={{ height: '120vh', padding: 25, paddingLeft: 20, paddingTop: 10, marginBottom: 0, backgroundColor: 'white' }}>
            {this.renderBotSelector()}
            <Card style={{ height: 500 }}>
              {selectedBot ? <div id="customgpt_chat"></div> : (
                <div style={{ height: 500, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                  <ChatBubbleOutline style={{ fontSize: 30 }} />
                  <h5>{translations.t('forms.noBotTypeSelected')}</h5>
                </div>
              )}
            </Card>
          </div>
        ): null}
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    posts: state.productInfo.posts.data,
    selectedPost: state.productInfo.selectedPost.data,
    clusters: state.credits.clusters.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(ProductInfo as any));