import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { JagoAxiosInstance } from './JagoAxiosInstance';
import { PaginationQueryParamsDTO } from '@bottega52/commons-pagination';
import { PaginationRestDTO } from '@bottega52/commons-pagination';
import { IUserInDTO } from './model/input/IUserInDTO';
import { IUserRoleInDTO } from './model/input/IUserRoleInDTO';
import qs from 'query-string';
import { IUserOutDTO } from './model/output/IUserOutDTO';
import { IUserDataOutDTO } from './model/output/IUserDataOutDTO';

export function fetchUsersRoles(params?: PaginationQueryParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IUserRoleInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get('api/v2/support/roles', config);
}

export function fetchCurrentUserData(): Promise<AxiosResponse<IUserInDTO>> {
  return JagoAxiosInstance.get('api/v2/support/me');
}

export function updateCurrentUserData(data: IUserDataOutDTO): Promise<AxiosResponse<IUserInDTO>> {
  return JagoAxiosInstance.put('api/v2/support/me', data);
}

export function fetchUsers(params?: PaginationQueryParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IUserInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
    paramsSerializer: params => qs.stringify(params),
  };
  return JagoAxiosInstance.get('api/v2/support/users', config);
}

export function deleteUser(userId: number): Promise<AxiosResponse<IUserInDTO>> {
  return JagoAxiosInstance.delete(`api/v2/support/users/${userId}`);
}

export function createNewUser(data: IUserOutDTO): Promise<AxiosResponse<IUserInDTO>> {
  return JagoAxiosInstance.post(`api/v2/support/users`, data);
}

export function editUser(userId: number, data: IUserOutDTO): Promise<AxiosResponse<IUserInDTO>> {
  return JagoAxiosInstance.put(`api/v2/support/users/${userId}`, data);
}



