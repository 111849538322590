
import { PaginatedDataDTO, PaginationDTO, PaginationRestDTO } from '@bottega52/commons-pagination';
import _ from 'lodash';
import { IUserInDTO } from '../repository/jago/model/input/IUserInDTO';
import { IUserRoleInDTO } from '../repository/jago/model/input/IUserRoleInDTO';
import { IUserFormDTO } from '../repository/jago/model/input/IUserFormDTO';
import { IUserOutDTO } from '../repository/jago/model/output/IUserOutDTO';

export function decode(data: PaginationRestDTO<IUserInDTO>): PaginatedDataDTO<IUserInDTO> {
    const pagination: PaginationDTO = _.omit(data, 'content');
    return {
      content: data.content,
      pagination,
    };
}

export function decodeRoles(data: PaginationRestDTO<IUserRoleInDTO>): PaginatedDataDTO<IUserRoleInDTO> {
  const pagination: PaginationDTO = _.omit(data, 'content');
  return {
    content: data.content,
    pagination,
  };
}

export function decodeUserContentToForm(data: IUserInDTO): IUserFormDTO {
  return {
    id: data.id,
    firstName: data.firstname,
    lastName: data.lastname,
    email: data.email,
    roleId: data.roles&&data.roles.length?data.roles[0].id:null,
    varId: data.varId,
    clusterIds: data.clusters?data.clusters.map(e=>e.id):[],
    languageType: data.languageType,
  };
}

export function encodeUserFromForm(data: IUserFormDTO): IUserOutDTO {
    let retVal: IUserOutDTO = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      language: data.languageType,
      roleId: data.roleId,
      varId: data.varId?data.varId:undefined,
      clusterIds: data.clusterIds?data.clusterIds:[],
    };
    return retVal;
}