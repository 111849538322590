import { LanguageTypes } from "../../../translations/LanguageTypes";

export const PRODUCT_INFO_ARTICLES_CATEGORIES = {
  MARKETING_MATERIAL_DEFAULT: 'MARKETING_MATERIAL_DEFAULT',
  SALES_MATERIAL_DEFAULT: 'SALES_MATERIAL_DEFAULT',
  SALES_MATERIAL_FRANCE: 'SALES_MATERIAL_FRANCE',
}

export const ProductInfoArticlesMap = [
  {
    name: PRODUCT_INFO_ARTICLES_CATEGORIES.MARKETING_MATERIAL_DEFAULT,
    categoryId: 124,
    postIds: {
      [LanguageTypes.DUTCH]: 7348,
      [LanguageTypes.ENGLISH]: 7313,
      [LanguageTypes.FRENCH]: 7336,
      [LanguageTypes.GERMAN]: 7338,
      [LanguageTypes.ITALIAN]: 7340,
      [LanguageTypes.SPANISH]: 7342,
    },
  },
  {
    name: PRODUCT_INFO_ARTICLES_CATEGORIES.SALES_MATERIAL_DEFAULT,
    categoryId: 107,
    postIds: {
      [LanguageTypes.DUTCH]: 7358,
      [LanguageTypes.ENGLISH]: 7317,
      [LanguageTypes.FRENCH]: 7360,
      [LanguageTypes.GERMAN]: 7362,
      [LanguageTypes.ITALIAN]: 7364,
      [LanguageTypes.SPANISH]: 7366,
    },
  },
  {
    name: PRODUCT_INFO_ARTICLES_CATEGORIES.SALES_MATERIAL_FRANCE,
    categoryId: 107,
    postIds: {
      [LanguageTypes.DUTCH]: 7348,
      [LanguageTypes.ENGLISH]: 7344,
      [LanguageTypes.FRENCH]: 7350,
      [LanguageTypes.GERMAN]: 7352,
      [LanguageTypes.ITALIAN]: 7356,
      [LanguageTypes.SPANISH]: 7354,
    },
  },
]