const translations = {
  language: "Idioma",
  login: {
    welcome: "Bienvenido",
    ssoLogin: "Accede con el correo de tu cuenta",
    detailsGeneric: "Detalles",
    customers: "Usuarios finales",
    sendEmail: "Enviar correo electrónico",
    otpLogin: "Iniciar sesión con correo electrónico",
    login: "Iniciar sesión",
    changeEmail: "Usar dirección de correo diferente",
    or: "O"
  },
  domains: {
    customerAdminDeletionConfirm: "¿Desea eliminar este usuario final? La operación no se puede deshacer",
    customerAdminDeletionSuccess: "Usuario final eliminado con éxito",
    luckeyDomainsTitle: "Dominios",
    domainName: "Nombre de dominio",
    domainDetails: "Detalles del dominio",
    domainConfigModifySuccess: "Configuración del dominio modificada y actualizada",
    domainConfigTitle: "Configuraciones - Alternancia de funciones",
    domainConfigDescription: "Configuración del dominio en formato JSON. Utilícela para habilitar/deshabilitar funciones del dominio",
    domainConfigGuide: "Guía de configuraciones de dominio",
    noUsersFoundTitle: "Lista de usuarios vacía",
    domainUsersTitle: "Administradores del dominio",
    domainUsersDescription: "Personifica a uno de los administradores de usuarios para operaciones de mantenimiento en el dominio",
    domainUsersWarning: "ATENCIÓN: Al personificar usuarios, puedes realizar cambios en el sistema, incluso irreversibles. Usa la máxima precaución",
    noUsersFoundDescription: "No se ha encontrado ningún usuario en este dominio",
    noDomainsFoundTitle: "No se encontraron dominios",
    noDomainFoundDescription: "La lista de dominios está vacía. Si has aplicado un filtro de búsqueda, intenta restablecerlo y vuelve a intentarlo",
    impersonateSuccess: "Impersonación de usuario exitosa",
    domainSubsriptionTitle: "Suscripción del dominio",
    domainSubsriptionDescription: "Lista de suscripción del dominio: bloqueos, usuarios y vencimiento del dominio",
    expiration: "Vencimiento",
    totalNumber: "Número total",
    used: "Elementos usados",
    tooMuchElements: "ADVERTENCIA: EL DOMINIO HA SUPERADO EL LÍMITE DE ELEMENTOS TOTALES",
    newDomain: "Nuevo dominio",
    externalReference: "Referencia externa",
    externalReferenceDetails: "Por ejemplo \"date-purchaseId\"",
    productDescription: "El producto representa la licencia que se activará para el nuevo dominio",
    productTitle: "Tipo de suscripción",
    extendDomain: "Extender la suscripción del dominio",
    extendDomainDescription: "Extiende tu dominio con un complemento o clave de producto",
    domainInstallersTitle: "Instaladores del dominio",
    domainInstallersDescription: "Lista de usuarios instaladores en los dominios seleccionados",
    domainDeletionTitle: "Eliminar dominio",
    domainDeletionDescription: "Enviar una solicitud para eliminar este dominio",
    sendDeletionRequest: "Enviar",
    domainDeletionConfirm: "¿Desea enviar una solicitud para eliminar este dominio?",
    domainDeletionSuccess: "Solicitud enviada con éxito",
    customerDeletionTitle: "Eliminar usuario final",
    customerDeletionConfirm: "¿Desea enviar una solicitud para eliminar este usuario final?",
    customerDeletionSuccess: "Solicitud enviada con éxito",
    walletDeletionTitle: "Eliminar billetera",
    walletDeletionConfirm: "¿Desea enviar una solicitud para eliminar esta billetera?",
    walletDeletionSuccess: "Solicitud enviada con éxito",
    verifyServiceStatus: "Estado del servicio",
    impersonateUser: "Personificar usuario",
    impersonate: "Personificar",
    impersonateReadOnly: "Personificar en modo solo lectura",
    domainAdditionalInfo: "Información adicional del dominio",
    domainAdditionalInfoDescription: "Proporcione información adicional del dominio aquí",
    additionalInfoSavedSuccessfully: "Notas adicionales guardadas con éxito",
    EASY: "Solo Argo Locks",
    SMART: "Argo Locks + Gateway",
    ENTERPRISE: "V364 + Argo Locks",
    STANDARD: "Solo V364 Locks",
    numberOfArgoLocks: "Argo",
    numberOfVegaLocks: "V364",
    numberOfGatewaysLocks: "Gateways",
    numberOfUsers: "Usuarios",
    numberOfLocks: "Cerraduras inteligentes",
    federatedDomain: "Dominio vinculado a: {{domain}}",
    federatedMasterDomain: "Dominio principal de un grupo",
    domainFederatedLicense: "Suscripción de dominio federado: esta suscripción es unificada para todos los dominios pertenecientes al dominio principal {{domain}}",
    federatedDomains: "Grupo de dominios",
    federatedDomainsDescription: "El dominio pertenece a un grupo",
    installationMode: "Modo de instalación",
    domainStandard: "Dominio legado",
    domainWithCloudCredits: "Dominio con créditos en la nube",
    domainIrisRequired: "Se requiere 1RIS",
    domainIrisEnabled: "1RIS habilitado",
    domainIrisEnable: "Habilitar 1RIS",
    domainIrisEnableConfirm: "¿Desea configurar 1RIS como HABILITADO en este dominio?",
    domainsWithCloudCredits: "Dominios con créditos en la nube",
    domainsLegacy: "Dominios legados"
  },
  credits: {
    marketplaces: "Mercados",
    searchMarketplace: "Buscar en el mercado...",
    marketplaceName: "Nombre del mercado",
    luckeyMarketplaceTitle: "Mercados Luckey",
    noMarketplaceFoundTitle: "No se encontró ningún mercado",
    noMarketplaceFoundDescription: "La lista de mercados está vacía. Si has aplicado un filtro de búsqueda, intenta restablecerlo y vuelve a intentarlo",
    newMarketplace: "Nuevo mercado",
    marketplaceCreationConfirm: "¿Desea crear el nuevo mercado?",
    createMarketplace: "Crear mercado",
    marketplaceDetails: "Detalles del mercado",
    editMarketplaceCreationConfirm: "Estás a punto de editar los datos del mercado. ¿Estás seguro de que deseas continuar?",
    editMarketplace: "Editar mercado",
    searchCustomer: "Buscar usuario final...",
    customerName: "Nombre del usuario final",
    luckeyCustomersTitle: "Usuarios finales Luckey",
    noCustomerFindTitle: "No se encontraron usuarios finales",
    noCustomerFindDescription: "La lista de usuarios finales está vacía. Si has aplicado un filtro de búsqueda, intenta restablecerlo y vuelve a intentarlo",
    newCustomer: "Nuevo usuario final",
    customerCreationConfirm: "¿Desea crear el nuevo usuario final?",
    newUser: "Nuevo usuario",
    userCreationConfirm: "¿Desea crear el nuevo usuario?",
    createCustomer: "Crear usuario final",
    customerDetails: "Detalles del usuario final",
    editCreationConfirm: "Estás a punto de editar los datos del usuario final. ¿Estás seguro de que deseas continuar?",
    editCustomer: "Editar usuario final",
    editCreationUserConfirm: "Estás a punto de editar los datos del usuario. ¿Estás seguro de que deseas continuar?",
    editUser: "Editar usuario",
    customerWallets: "Billeteras de {{customerName}}",
    newWallet: "Nueva billetera",
    walletCreationConfirm: "¿Desea crear una nueva billetera para el usuario final seleccionado?",
    createWallet: "Crear billetera",
    editWallet: "Editar billetera",
    walletEditConfirm: "¿Desea editar la billetera seleccionada?",
    noWalletFoundTitle: "No se encontró ninguna billetera",
    noWalletFoundDescription: "No se encontró ninguna billetera para el usuario final seleccionado",
    walletDetails: "Detalles de la billetera",
    rechargeWallet: "Recargar",
    walletRechargeHistory: "Historial de recargas",
    rechargeRowTitle: "Créditos modificados",
    rechargeCreditsDescription: "La billetera se ha recargado con {{credits}} créditos",
    rechargeCardDetails: "Detalles de la recarga",
    systemParameters: "Lista de precios",
    systemSubscriptions: "Suscripciones",
    systemSubscriptionsDescription: "Estas son las suscripciones en el sistema. Crea nuevas o asígnalas a un dominio existente",
    newSubscription: "Nueva suscripción",
    editSubscription: "Editar suscripción",
    noSystemSubscriptionFoundTitle: "No se encontró ninguna suscripción",
    noSystemSubscriptionFoundDescription: "No hay ninguna suscripción del sistema que se pueda asignar a un dominio",
    products: "Suscripciones legadas",
    rechargeCards: "Tarjetas de recarga",
    noSystemCardFoundTitle: "No se encontraron tarjetas de recarga",
    noSystemCardFoundDescription: "No hay tarjetas de recarga en el sistema. Crea nuevas para comenzar a recargar las billeteras de los usuarios finales",
    newCard: "Nueva tarjeta de recarga",
    cardStatusUsed: "Usada",
    rechargeCardCreditsDetails: "Esta tarjeta de recarga tiene un valor de {{credits}} créditos",
    cardStatusUnused: "No usada",
    cardStatusInactive: "Deshabilitada",
    enableCard: "Habilitar",
    disabledCardTooltip: "La tarjeta de recarga se habilitará tan pronto como recibas la factura ISEO asociada",
    newTierSubscription: "Nueva suscripción principal",
    pluginAndService: "Complemento y servicios",
    newPluginSubscription: "Nuevo complemento o servicio",
    tierSubscription: "Suscripción principal",
    walletSubscriptions: "Suscripciones",
    noWalletSubscriptionFoundTitle: "No se encontró ninguna suscripción",
    noWalletSubscriptionFoundDescription: "Esta billetera no tiene ninguna suscripción asociada",
    newWalletSubscription: "Asociar nueva suscripción",
    editWalletSubscription: "Editar suscripción",
    newWalletPluginSubscription: "Asociar nuevo complemento o servicio",
    walletConsumption: "Consumo",
    noWalletUsageFoundTitle: "No se encontraron datos",
    noWalletUsageFoundDescription: "No hay datos de consumo para esta billetera",
    ACTIVATION: "Costo de activación del dominio o complemento",
    DAILY_USAGE: "Consumo diario",
    creditConsumptionType: "Tipo de consumo",
    consumptionCreditsDescription: "La billetera ha consumido {{credits}}",
    consumptionRowTitle: "Consumo de la billetera",
    walletPlants: "Dominios",
    noWalletPlantFoundTitle: "No se encontró ningún dominio",
    noWalletPlantFoundDescription: "No hay dominios asociados a esta billetera",
    newWalletPlant: "Nuevo dominio",
    deleteWalletSubscription: "Eliminar suscripción",
    editCard: "Editar tarjeta de recarga",
    rechargeDetails: "Detalles de la recarga",
    exportWalletsUsageCSV: "Exportar CSV de uso de créditos",
    exportWalletsCreditUsageCSV: "Exportar crédito de la billetera",
    PENDING: "Pendiente",
    ACTIVE: "Activo",
    READY_TO_ACTIVATE: "Listo para activar",
    walletStatus: "Estado de la billetera",
    exportManagersCSV: "Exportar administradores",
    needCreditsToCreatePlant: "Necesitas créditos en esta billetera para crear un nuevo dominio"
  },
  sales: {
    hardwareSalesInfo: "Información de ventas",
    domain: "Dominio",
    hardwareId: "ID de HW",
    hardwareName: "Nombre de HW",
    hardwareSerialNumber: "Número de serie de HW",
    hardwareModel: "Modelo de HW",
    hardwareVendor: "Línea de productos de HW",
    hardwareType: "Tipo de HW",
    sfdcId: "SFDC-ID",
    iseoSelloutOrderId: "ID de orden de venta ISEO",
    iseoSelloutOrderDate: "Fecha de orden de venta ISEO",
    iseoSelloutInvoiceId: "ID de factura de venta ISEO",
    iseoSelloutInvoiceDate: "Fecha de factura de venta ISEO",
    sofiaCustRef: "Ref. Cliente (proceso Sofia)",
    unreconciledHardware: "Hardware no conciliado",
    hardwareWithSalesInfo: "Hardware con información de ventas",
    actions: "Acciones",
    addSalesInfo: "Agregar información de ventas",
    editSalesInfo: "Editar información de ventas",
    iseoSelloutOrder: "Orden de venta ISEO",
    iseoSelloutInvoice: "Factura de venta ISEO",
    selectedSalesInfoError: "¡Advertencia! La selección no es homogénea. ¿Estás seguro de que deseas continuar?"
  },
  errors: {
    deleteWalletSubscriptionError: "Ocurrió un error al modificar la suscripción. Por favor, inténtalo de nuevo o contacta a nuestro equipo de soporte",
    modifyVarOpportunityError: "Ocurrió un error al editar la cotización. Por favor, inténtalo de nuevo",
    error: "Error",
    loginFailed: "La sesión ha expirado. Por favor, inicia sesión nuevamente",
    loginGoogleFailedTitle: "Error de inicio de sesión con Google",
    loginGoogleFailed: "Asegúrate de haber iniciado sesión en tu cuenta de Google o intenta abrir esta página en una sesión de Google Chrome con tu cuenta de GSuite de Sofia",
    loginJagoGoogleFailedTitle: "Error de inicio de sesión con Jago Google",
    loginJagoGoogleFailed: "Asegúrate de tener un usuario en Jago que corresponda a tu cuenta de GSuite de Sofia e inténtalo de nuevo",
    getDomainDetailsError: "Ocurrió un error al obtener la configuración de los dominios. Por favor, inténtalo de nuevo o contacta al soporte de Sofia",
    updateDomainConfigError: "Ocurrió un error al guardar la configuración del dominio. Por favor, inténtalo de nuevo o contacta al soporte de Sofia",
    updatePlantThemeError: "Ocurrió un error al guardar el tema del dominio. Por favor, inténtalo de nuevo o contacta al soporte de Sofia",
    impersonateDomainUserError: "Ocurrió un error al personificar el usuario. Por favor, verifica que el usuario esté activo e inténtalo de nuevo o contacta al soporte de Sofia",
    createDomainError: "Ocurrió un error al crear el nuevo dominio",
    addDomainPluginError: "Ocurrió un error al extender el dominio",
    addUserDomainError: "No se pudo crear el usuario. Verifica si el usuario ya existe en el dominio o si el dominio tiene una suscripción válida",
    errorSaveAdditionalInfo: "No se pudo actualizar la información en el dominio seleccionado",
    createCustomerError: "No se pudo crear el usuario final. Verifica si el usuario final ya existe e inténtalo de nuevo",
    createUserError: "No se pudo crear el usuario. Verifica si el usuario ya existe e inténtalo de nuevo",
    createVarUserError: "No se pudo crear el usuario VAR. Verifica si el usuario ya existe e inténtalo de nuevo",
    createPlantThemeError: "No se pudo crear el tema del dominio. Verifica los datos e inténtalo de nuevo",
    updateVarError: "No se pudo actualizar. Verifica los datos e inténtalo de nuevo",
    getCustomerDetailsError: "No se pudieron obtener los detalles del usuario final. Por favor, inténtalo de nuevo",
    getVarDetailsError: "No se pudieron obtener los detalles del VAR. Por favor, inténtalo de nuevo",
    getPlantThemeDetailsError: "No se pudieron obtener los detalles del tema del dominio. Por favor, inténtalo de nuevo",
    getProductInfoPostDetailsError: "No se pudo obtener el Artículo. Por favor, inténtalo de nuevo",
    required: "Requerido",
    deleteDomainRequestError: "No se pudo enviar la solicitud. Por favor, inténtalo de nuevo",
    deleteWalletRequestError: "No se pudo enviar la solicitud. Por favor, inténtalo de nuevo",
    deleteCustomerRequestError: "No se pudo enviar la solicitud. Por favor, inténtalo de nuevo",
    enableIrisDomainRequestError: "No se pudo enviar la solicitud. Por favor, inténtalo de nuevo",
    createCustomerWalletError: "No se pudo crear la nueva billetera. Por favor, inténtalo de nuevo",
    editCustomerWalletError: "No se pudo editar la nueva billetera. Por favor, inténtalo de nuevo",
    getWalletDetailsError: "No se pudieron recuperar los detalles de la billetera. Por favor, inténtalo de nuevo",
    openSystemParametersError: "No se pudieron abrir los parámetros del sistema. Por favor, inténtalo de nuevo",
    createSubscriptionError: "No se pudo crear la suscripción. Por favor, inténtalo de nuevo",
    createCardError: "Ocurrió un error al crear la tarjeta de recarga. Por favor, inténtalo de nuevo",
    createVarOpportunityError: "Ocurrió un error durante la creación. Por favor, inténtalo de nuevo",
    editVarOpportunityError: "Ocurrió un error durante la operación. Por favor, inténtalo de nuevo",
    rechargeWalletError: "Ocurrió un error al recargar la billetera. Por favor, inténtalo de nuevo",
    deleteCardError: "Ocurrió un error al eliminar la tarjeta de recarga. Por favor, inténtalo de nuevo",
    enableCardError: "Ocurrió un error al actualizar la tarjeta de recarga. Por favor, inténtalo de nuevo",
    deletePlantThemeError: "Ocurrió un error al eliminar. Por favor, inténtalo de nuevo",
    deleteVarUserError: "Ocurrió un error al eliminar. Por favor, inténtalo de nuevo",
    deleteVarUserErrorOpportunities: "Este usuario es el propietario de al menos una cotización, por lo que no se puede eliminar. Por favor, reasigna las cotizaciones asociadas a este usuario e inténtalo de nuevo.",
    deleteVarOpportunityError: "Ocurrió un error al eliminar. Por favor, inténtalo de nuevo",
    rejectVarOpportunityError: "Ocurrió un error al actualizar la cotización. Por favor, inténtalo de nuevo",
    approveVarOpportunityError: "Ocurrió un error al actualizar la cotización. Por favor, inténtalo de nuevo",
    syncsfdcidVarOpportunityError: "Ocurrió un error al actualizar la cotización. Por favor, inténtalo de nuevo",
    upgradeVarOpportunityError: "Ocurrió un error al actualizar la cotización. Por favor, inténtalo de nuevo",
    winVarOpportunityError: "Ocurrió un error al actualizar la cotización. Por favor, inténtalo de nuevo",
    loseVarOpportunityError: "Ocurrió un error al actualizar la cotización. Por favor, inténtalo de nuevo",
    deleteUserError: "Ocurrió un error al eliminar. Por favor, inténtalo de nuevo",
    createWalletSubscriptionError: "Ocurrió un error al asociar la suscripción a la billetera. Por favor, inténtalo de nuevo",
    createWalletPlantError: "Ocurrió un error al crear el dominio para la billetera. Por favor, inténtalo de nuevo",
    walletActionErrorNotEnoughCreditsTitle: "Crédito insuficiente",
    walletActionErrorNotEnoughCredits: "La billetera no tiene suficientes créditos para realizar la operación. Verifica la operación que estás realizando e inténtalo de nuevo",
    warningMinCreditsCard: "¡Advertencia! Número mínimo de créditos requeridos = {{credits}}",
    loginJagoOTPEmailFailedTitle: "Error al enviar el correo",
    loginJagoOTPEmailFailed: "Ocurrió un error al enviar el correo, inténtalo de nuevo después de 5 minutos",
    loginJagoOTPFailedTitle: "Error de verificación de código",
    loginJagoOTPFailed: "La verificación del código de acceso no fue exitosa. Por favor, verifica el código o intenta el inicio de sesión de nuevo después de 5 minutos.",
    addonV364missing: "No se pudo encontrar el complemento V364 en el sistema, por favor, reporta este error para recibir asistencia."
  },
  gateways: {
    createdBy: "Creado por",
    noGatewayFoundTitle: "No se encontraron HyperGates",
    noGatewayFoundDescription: "La lista de HyperGates está vacía. Si has aplicado un filtro de búsqueda, intenta cambiarlo y vuelve a intentarlo",
    gatewayName: "Nombre de HyperGate",
    luckeyGatewaysTitle: "HyperGates del sistema",
    lastConnectionDate: "Última conexión: {{ date }}",
    gatewaysDevices: "Dispositivos",
    gatewaysArtifacts: "Artefactos",
    gatewaysDeployments: "Despliegues",
    HYPERGATE_PRO: "HyperGate Pro",
    HYPERGATE_ISEO: "HyperGate Lite",
    DONE: "Completado",
    PENDING: "Despliegue pendiente",
    IN_PROGRESS: "Despliegue en curso",
    ERROR: "Error",
    deploymentDetail: "Detalle del despliegue",
    noDeploymentDetailedFound: "No se encontraron Gateways para el despliegue",
    noDeploymentDetailedFoundDescription: "La lista de Gateways para este despliegue está vacía",
    deviceId: "ID del dispositivo",
    name: "Nombre",
    gatewayVersion: "Versión",
    iseoSdkVersion: "ISEO SDK",
    kernelVersion: "Versión del kernel",
    serialNumber: "Número de serie",
    noGatewaysFound: "No se encontraron HyperGates",
    noGatewaysFoundDescription: "La lista de HyperGates está vacía. Intenta cambiar tus filtros de búsqueda e inténtalo de nuevo",
    noDeploymentFound: "No se encontró ningún despliegue",
    noArtifactFound: "No se encontró ningún artefacto",
    noDeploymentFoundDescription: "La lista de despliegues para este despliegue está vacía. Si has aplicado un filtro de búsqueda, intenta cambiarlo y vuelve a intentarlo",
    noArtifactFoundDescription: "La lista de artefactos para este despliegue está vacía. Si has aplicado un filtro de búsqueda, intenta cambiarlo y vuelve a intentarlo"
  },
  forms: {
    otp: 'OTP',
    notVisible: "No visible",
    visible: "Visible",
    exportAllUsers: "Exportar usuarios",
    montlyBurnRate: "{{credits}}/mes",
    nextRechargeForecast: "Próxima recarga esperada con el consumo actual ~{{date}}",
    lastUpdate: "Última actualización {{time}}",
    rowsPerPage: "Filas por página",
    rolePermissions: "Permisos de rol",
    permissionsDetails: "Detalles de permisos",
    clusterAndIseoCompany: "Cluster y empresa ISEO",
    createCardSuccess: "Tarjeta de recarga creada con éxito",
    cardDetails: "Detalles de la tarjeta",
    status: "Estado",
    customerInfo: "Información del cliente",
    details: "Detalles",
    costs: "Costos",
    hostName: "Nombre del host",
    domainType: "Tipo",
    user: "Usuario",
    logoutConfirmation: "¿Estás seguro de que deseas cerrar sesión?",
    send: "Enviar",
    askQuestion: "Contáctanos",
    ticketOpenSuccessfully: "¡Tu pregunta ha sido enviada a nuestro equipo! Uno de nuestros miembros se pondrá en contacto contigo en breve.",
    ticketOpenError: "Ocurrió un error al enviar la solicitud. Por favor, inténtalo de nuevo",
    writeUsAQuestionDescription: "¿Tienes alguna pregunta? Siéntete libre de escribirnos, y uno de nuestros Especialistas de Producto estará listo para asistirte con cualquier consulta que puedas tener.",
    deviceType: "Tipo de dispositivo",
    createNewMarketplace: "Nuevo mercado",
    createNewCustomer: "Nuevo",
    firstName: "Nombre",
    lastName: "Apellido",
    email: "Correo electrónico",
    contactEmail: "Correo de contacto",
    open: "Abrir",
    add: "Agregar",
    actions: "Acciones",
    search: "Buscar",
    remove: "Eliminar",
    error: "Error",
    copy: "Copiar",
    cancel: "Cancelar",
    confirm: "Confirmar",
    warning: "Advertencia",
    reset: "Restablecer",
    close: "Cerrar",
    save: "Guardar",
    delete: "Eliminar",
    deleteRequest: "Enviar solicitud de eliminación",
    deleteAdminOnly: "Eliminar (admin)",
    export: "Exportar",
    spinnerLoading: "Cargando...",
    createNewDomain: "Crear nuevo dominio",
    walletName: "Nombre de la billetera",
    domainName: "Nombre de dominio",
    domainNamePreview: "Vista previa",
    domainFormTitle: "Crear un nuevo dominio Luckey",
    invalidDomainName: "Nombre de dominio inválido. No se permiten espacios, solo caracteres en minúscula, números y guion",
    selectProduct: "Selecciona un tipo de suscripción",
    name: "Nombre",
    lastname: "Apellido",
    createNewUser: "Crear nuevo administrador",
    selectRole: "Selecciona el rol del usuario",
    createUserForDomain: "Crear un nuevo usuario para el dominio seleccionado",
    newUser: "Nuevo usuario",
    password: "Contraseña",
    letUserChoosePassword: "Permitir que el usuario elija su contraseña: enviar enlace para configurar la contraseña al correo del usuario",
    domainCreateSuccess: "Dominio creado con éxito",
    domainCreateUserAfterCreation: "¿Desea crear un usuario para el nuevo dominio?",
    domainCreationConfirm: "¿Desea crear el nuevo dominio?",
    createDomain: "Crear dominio",
    username: "Nombre de usuario",
    domainExtendsConfirm: "¿Desea extender el dominio con el producto seleccionado?",
    createNewInstaller: "Crear nuevo instalador",
    createInstallerForDomain: "Crear un nuevo usuario instalador para el dominio",
    deleteDomain: "Solicitar eliminación del dominio",
    active: "Activo",
    disabled: "Deshabilitado",
    minutes: "{{minutes}} minutos",
    selectSessionDurationPlaceholder: "Duración de la sesión",
    impersonateUserDescription: "Antes de personificar al usuario dentro del sistema, te informamos que:",
    impersonateUserDescriptionOne: "El inicio de la sesión y su duración aparecerán en los registros del dominio",
    impersonateUserDescriptionTwo: "Cada acción que realices será registrada y aparecerá en los registros del sistema",
    impersonateUserDescriptionThree: "Cualquier acción que realices en el implante tendrá un efecto inmediato y, en algunos casos, irreversible",
    selectSessionDuration: "Selecciona la duración de la sesión de soporte",
    impersonateReadOnly: "Modo solo lectura",
    impersonateReadOnlyDescription: "Accede al sistema en modo solo lectura, podrás ver los datos del sistema pero sin poder modificarlos",
    impersonateReadOnlyWarning: "Atención: La sesión de soporte y su duración seguirán siendo visibles en los registros del sistema",
    installationModeActive: "Activo",
    installationModeDisabled: "Deshabilitado",
    customerFormTitle: "Usuario final Luckey",
    varFormTitle: "Distribuidor de valor agregado (VAR)",
    varFormAdminSection: "Sección de administración",
    varFormTitleVar: "Información de la empresa",
    plantThemeFormTitle: "Tema de dominio Luckey",
    customerName: "Nombre del usuario final",
    invalidCustomerName: "Nombre del usuario final no válido",
    createNewWallet: "Nueva billetera",
    whatsNewTitle: "¡Novedades de Luckey!",
    whatsNewSubtitle: "Descubre las últimas noticias sobre Luckey y Sofia, parte de ISEO",
    notes: "Notas adicionales",
    cardCode: "Código de recarga",
    recharge: "Recargar",
    rechargeWalletFormDescription: "Recarga los créditos de esta billetera ingresando el código de recarga. La recarga se aplicará de inmediato",
    subscriptionName: "Nombre de la suscripción",
    subscriptionFixedPrice: "Costo de activación (créditos)",
    subscriptionBurnRate: "Costo recurrente (créditos)",
    subscriptionVisible: "Visible en el mercado",
    createNewSubscriptionDescription: "Crea una nueva suscripción en el sistema. La suscripción estará disponible para todos los dominios",
    subscriptionLogicDescription: "Indica qué elementos del sistema se contarán para determinar las restricciones del sistema",
    FIXED: "No imponer restricciones a los elementos",
    COUNT_USERS_LOCKS: "Limitar el número de usuarios y cerraduras del dominio",
    COUNT_LOCKS: "Solo limitar el número de cerraduras del dominio",
    COUNT_ELEMENTS: "Limitar la suma de cerraduras y usuarios del dominio",
    maxLocks: "Número máximo de cerraduras inteligentes",
    maxUsers: "Número máximo de usuarios",
    maxElements: "Número máximo de elementos (usuarios+cerraduras)",
    subscriptionLogic: "Lógica de límite de elementos",
    selectLogic: "Seleccionar",
    subscriptionMaxLocksDescription: "Inserte el número máximo de cerraduras que se pueden instalar con esta suscripción",
    subscriptionMaxUsersDescription: "Inserte el número máximo de usuarios que se pueden activar con esta suscripción",
    subscriptionMaxElementsDescription: "Inserte el número máximo de elementos con esta suscripción",
    subscriptionResource: "Recurso",
    cardQuantity: "Número de créditos",
    cardQuantityDescription: "Indica el número de créditos que se recargarán al usar la tarjeta de recarga",
    cardVar: "Asignar tarjeta de recarga al VAR",
    cardWallet: "Asignar tarjeta de recarga a una billetera específica",
    cardNotes: "Notas adicionales",
    createCardConfirm: "¿Estás seguro de que deseas crear la tarjeta de recarga?",
    createVarOpportunityConfirm: "¿Estás seguro de que deseas crear esta nueva cotización?",
    createSubscrptionConfirm: "¿Estás seguro de que deseas crear la nueva suscripción?",
    rechargeWalletConfirm: "Estás a punto de recargar la billetera de este usuario final con nuevos créditos, ¿deseas continuar? La operación no se puede deshacer",
    createdAt: "Fecha de creación",
    copiedSuccess: "¡Copiado!",
    logout: "Cerrar sesión",
    deleteCardConfirm: "¿Estás seguro de que deseas eliminar esta tarjeta de recarga? La operación no se puede deshacer",
    enableCardConfirm: "¿Estás seguro de que deseas habilitar esta tarjeta de recarga? Una vez habilitada, esta tarjeta podrá ser utilizada. La operación no se puede deshacer",
    subscriptionTierSelectionTitle: "Suscripción principal",
    subscriptionTierSelectionDescription: "Al seleccionar la suscripción principal, el nuevo complemento heredará automáticamente las lógicas de conteo de la suscripción principal",
    selectTier: "Seleccionar suscripción",
    createPluginSubscriptionConfirm: "¿Estás seguro de que deseas crear esta nueva suscripción de complemento/servicio?",
    createWalletSubscriptionConfirm: "¿Estás seguro de que deseas asociar esta suscripción a la billetera seleccionada? La suscripción se activará para todos los dominios gestionados a través de esta billetera",
    deleteWalletSubscriptionConfirm: "¿Estás seguro de que deseas eliminar esta suscripción de la billetera seleccionada? La suscripción se eliminará de todos los dominios gestionados a través de esta billetera",
    editSubscriptionConfirm: "¿Estás seguro de que deseas editar la suscripción?",
    walletSelectSubscriptionTitle: "Suscripción a activar",
    walletSelectSubscriptionDescription: "Selecciona la suscripción a activar en esta billetera. Recuerda que la suscripción también se activará en todos los sistemas gestionados a través de esta billetera",
    createWalletPlantConfirm: "¿Estás seguro de que deseas crear el nuevo dominio para la billetera seleccionada?",
    enableEmailForPlant: "Habilitar servicio de correo",
    enableEmailForPlantDescription: "Habilita el envío de correos para este sistema (correo de activación, notificaciones, etc.)",
    enableIrisRequiredForPlant: "Requerir servicio 1ris",
    enableIrisRequiredForPlantDescription: "Si se selecciona, el dominio se preparará para incluir el servicio 1ris",
    cardSubscriptionsLinked: "Suscripciones asociadas",
    cardSubscriptionsLinkedDescription: "Asocia múltiples complementos o servicios a esta tarjeta de recarga para activarlos una vez que la tarjeta sea usada",
    mainTierDescription: "Asocia una suscripción principal a esta tarjeta de recarga para activarla una vez que la tarjeta sea usada",
    cardMarketplacesLinked: "Mercado para la asociación de licencias",
    cardMarketplacesLinkedDescription: "Selecciona un mercado para elegir las suscripciones a asociar a esta tarjeta de recarga",
    sku: "SKU",
    customerData: "Datos del usuario final",
    customerVat: "IVA",
    customerFiscalCode: "Código fiscal",
    customerCountry: "País",
    customerAddress: "Dirección",
    customerCity: "Ciudad",
    customerEmail: "Correo electrónico",
    customerPhone: "Teléfono",
    customerCrmId: "ID DE CUENTA SFDC",
    customerMarketplace: "Mercado",
    customerCertifiedEmail: "PEC (Correo certificado)",
    customerCluster: "Cluster",
    customerVar: "VAR",
    customerCompany: "Empresa ISEO",
    customerPostalCode: "Código postal",
    customerPrincipalSubDivision: "Provincia, Estado, Condado, etc.",
    iseoSalesOrderReference: "Orden de venta ISEO (ISEO -> Cliente)",
    iseoSalesOrderDate: "Fecha de orden de venta ISEO",
    iseoPurchaseOrderReference: "Orden de compra ISEO (ISEO -> Sofia)",
    iseoPurchaseOrderDate: "Fecha de orden de compra ISEO",
    sofiaSalesOrderReference: "Orden de venta Sofia (Sofia -> ISEO)",
    sofiaSalesOrderDate: "Fecha de orden de venta Sofia",
    pickDate: "Elegir fecha",
    crmSource: "Fuente de oportunidad CRM",
    crmId: "Identificador único de oportunidad CRM",
    sofiaProjectId: "Identificador de referencia del proyecto Sofia",
    crmLink: "URL a la oportunidad CRM",
    kamReference: "Referencia KAM",
    edit: "Editar",
    editCardConfirm: "¿Estás seguro de que deseas editar la tarjeta de recarga?",
    editVarOpportunityConfirm: "¿Estás seguro de que deseas editar esta cotización?",
    insertEmailOTPLoginForm: "Introduce el correo de tu cuenta para recibir el código OTP y acceder a la plataforma",
    insertEmailOTPLoginFormDisclaimer: "Si el correo está autorizado, recibirás un código OTP para acceder",
    insertOTPOTPLoginForm: "Introduce el código OTP recibido en el correo de tu cuenta",
    insertOTPOTPLoginFormDisclaimer: "En caso de que no hayas recibido el código después de 5 minutos, repite la operación",
    pickDateTo: "Elegir fecha hasta:",
    pickDateFrom: "Elegir fecha desde",
    AUTOMATIC: "Activación automática",
    MANUAL: "Activación manual",
    SEMI_AUTOMATIC: "Activación semiautomática",
    AUTOMATIC_Description: "La extensión se puede activar de forma independiente desde la tienda por el usuario. Los créditos de activación se deducirán de inmediato",
    MANUAL_Description: "Extensión que solo se puede activar tras una solicitud explícita del usuario final. Los créditos se deducirán manualmente tras la aprobación de la solicitud",
    SEMI_AUTOMATIC_Description: "Extensión que solo se puede activar tras una solicitud explícita del usuario final. Los créditos de activación se deducirán en el momento de la solicitud",
    requestToQuote: "Cotización solicitada",
    requestToQuoteDescription: "Indica si la extensión está sujeta a cotización en lugar de uso directo de créditos (por ejemplo, integraciones y personalizaciones)",
    subscriptionCanBeDeactivated: "Se puede desactivar desde el administrador",
    subscriptionCanBeDeactivatedDescription: "Indica si el administrador puede desactivar la suscripción desde Luckey Store",
    selectSegmentType: "Selecciona el segmento",
    sofiaInvoiceNumber: "Número de factura de Sofia",
    sofiaInvoiceAmount: "Monto de la factura de Sofia",
    sofiaInvoiceYear: "Año de la factura de Sofia",
    iseoInvoiceNumber: "Número de factura ISEO",
    iseoInvoiceAmount: "Monto de la factura ISEO",
    iseoInvoiceYear: "Año de la factura ISEO",
    freeOfCharge: "Gratuito",
    freeOfChargeReason: "Motivo",
    currency: "Moneda",
    sofiaInvoiceDate: "Fecha de factura",
    iseoInvoiceDate: "Fecha de factura",
    sofiaInvoiceRecipient: "Destinatario de la factura",
    iseoInvoiceRecipient: "Destinatario de la factura",
    serviceSupplementaryInvoice: "Factura suplementaria del servicio",
    serviceSupplementaryInvoiceNumber: "Número de factura",
    serviceSupplementaryInvoiceAmount: "Monto de la factura",
    serviceSupplementaryInvoiceDate: "Fecha de factura",
    serviceSupplementaryInvoiceRecipient: "Destinatario de la factura",
    iseoInvoiceIssuer: "Emisor de la factura",
    backdropClosing: "¿Estás seguro de que deseas cerrar el formulario sin guardar?",
    roles: "Roles",
    selectBotType: 'Seleccionar tipo de asistente',
    helperBot: 'Asistente de producto',
    commercialBot: 'Asistente comercial',
    talkWithUs: 'Habla con nosotros',
    noBotTypeSelected: 'No se ha seleccionado ningún tipo de asistente',
  },
  activations: {
    getInfo: "(Obtener más información sobre el proceso)",
    createNewCustomer: "Crear usuario final",
    createNewWallet: "Crear billetera",
    createNewScratchcard: "Crear tarjeta de recarga",
    yearsString: "años",
    monthsString: "meses",
    daysString: "días",
    addNewActivationButton: "Agregar activación del sistema",
    home: "Inicio OD",
    activationRequests: "Activaciones del sistema",
    rechargeRequests: "Recargas de billetera",
    walletStatus: "Estado de las órdenes",
    noActivationsFoundTitle: "No se encontraron activaciones del sistema",
    noActivationsFoundDescription: "No hay activaciones del sistema en el sistema. Crea nuevas para comenzar",
    selloutOrder: "Orden de venta",
    intragroupOrder: "Orden intra-grupo",
    custref: "Ref. Cliente",
    rejectedReason: "Motivo de rechazo",
    changeStatusToUnderReview: "Enviar a revisión",
    changeStatusToInvoiced: "Enviar a facturado",
    changeStatusToCreated: "Enviar a creado",
    changeStatusToRejected: "Rechazar solicitud",
    changeStatusToProcessing: "Aceptar solicitud",
    changeStatusAdmin: "Establecer estado (admin)",
    changeCustRef: "Establecer Ref. Cliente",
    search: "Buscar...",
    resetFilters: "Restablecer filtros",
    statusSelection: "Cualquier estado",
    clusterSelection: "Cualquier cluster",
    statusDRAFT: "Borrador",
    statusUNDER_REVIEW: "Bajo revisión",
    statusREJECTED: "Rechazado",
    statusPROCESSING: "En proceso",
    statusCREATED: "Creado",
    statusINVOICED: "Facturado",
    statusACTIVE: "Activo",
    statusDELETED: "Eliminado",
    promote: "Eliminar",
    saveDraft: "Guardar como borrador",
    customMarketplaceWarning: "El usuario final no está asociado al mercado predeterminado. Necesitarás especificar manualmente la suscripción base solicitada y los complementos en la sección 'Notas'",
    subscriptionNotFoundError: "No se encontró la suscripción",
    deleteActivationConfirm: "¿Estás seguro de que deseas eliminar esta activación del sistema? La operación no se puede deshacer",
    deleteActivationError: "Ocurrió un error al eliminar la activación del sistema. Por favor, inténtalo de nuevo",
    promoteActivationConfirm: "¿Estás seguro de que deseas enviar esta activación del sistema a revisión? La solicitud de activación del sistema no se puede editar mientras esté en revisión.",
    promoteActivationError: "Ocurrió un error al promover la activación del sistema. Por favor, inténtalo de nuevo",
    createdActivationConfirm: "¿Estás seguro de que deseas enviar esta activación del sistema al estado CREADO?",
    createdActivationError: "Ocurrió un error al actualizar el estado de la activación del sistema. Por favor, inténtalo de nuevo",
    invoicedActivationConfirm: "¿Estás seguro de que deseas marcar esta activación del sistema como facturada?",
    invoicedActivationError: "Ocurrió un error al actualizar el estado de la activación del sistema. Por favor, inténtalo de nuevo",
    editStatusActivationConfirm: "¿Estás seguro de que deseas editar el estado de esta activación del sistema? La operación no se puede deshacer",
    editStatusActivationError: "Ocurrió un error al actualizar el estado de la activación del sistema. Por favor, inténtalo de nuevo",
    rejectActivationConfirm: "Especifica el motivo para rechazar esta solicitud.",
    rejectActivationError: "Ocurrió un error al rechazar la activación del sistema. Por favor, inténtalo de nuevo",
    processingActivationConfirm: "¿Estás seguro de que deseas aceptar esta activación del sistema?",
    processingActivationError: "Ocurrió un error al aceptar la activación del sistema. Por favor, inténtalo de nuevo",
    newFileName: "Especifica el nombre del archivo",
    newFileExtensionError: "El archivo tiene una extensión no soportada",
    newFileDuplicateNameError: "Este nombre ya ha sido utilizado para otro archivo. Cambia el nombre y repite la operación.",
    editCustrefConfirm: "Especifica la Ref. Cliente para esta solicitud.",
    editCustrefError: "Ocurrió un error al actualizar la Ref. Cliente, por favor, inténtalo de nuevo",
    editActivation: "Editar activación del sistema",
    newActivation: "Agregar activación del sistema",
    viewActivation: "Ver activación del sistema",
    newActivationError: "Ocurrió un error al crear esta activación del sistema. Por favor, inténtalo de nuevo",
    editActivationError: "Ocurrió un error al actualizar esta activación del sistema. Por favor, inténtalo de nuevo",
    customerSearch: "Buscar entre usuarios finales existentes",
    customerSearchEnterName: "Buscar por nombre...",
    customerSearchNoMatch: "No se encontraron usuarios finales...",
    customerProceedWithSelected: "Usar usuario final seleccionado",
    customerProceedWithNew: "Crear nuevo usuario final",
    customerConfirmData: "Confirmar datos del usuario final",
    customerData: "Datos del cliente",
    varData: "Datos del VAR",
    selectCustomerOrVar: "Selecciona el objetivo para esta solicitud (usuario final o VAR)",
    varSfdcId: "ID DE CUENTA SFDC",
    varName: "Nombre",
    varSearch: "Buscar entre los VAR existentes",
    varSearchEnterName: "Buscar por nombre...",
    varSearchNoMatch: "No se encontró ningún VAR...",
    varCustomerSearch: "Buscar entre los usuarios finales del VAR",
    varProceedWithSelected: "Usar VAR seleccionado",
    customerFormTitle: "Usuario final Luckey",
    customerName: "Nombre del usuario final",
    customerVat: "IVA",
    customerFiscalCode: "Código fiscal",
    customerCountry: "País",
    customerAddress: "Dirección",
    customerCity: "Ciudad",
    customerEmail: "Correo electrónico",
    customerPhone: "Teléfono",
    customerCrmId: "ID DE CUENTA SFDC",
    customerMarketplace: "Mercado",
    customerCertifiedEmail: "PEC (Correo certificado)",
    customerCluster: "Cluster",
    customerVar: "VAR",
    customerCompany: "Empresa ISEO",
    customerPostalCode: "Código postal",
    customerPrincipalSubDivision: "Provincia, Estado, Condado, etc.",
    exportActivationsCSV: "Exportar CSV",
    salesAndOrdersData: "Ventas y Órdenes",
    basicData: "Información del dominio",
    formEnterAnswer: "Tu respuesta",
    formEnterAnswerGT0: "Número mayor que cero",
    formEnterAnswerFrom0To600: "Número entre 0 y 600",
    formEnterAnswerOwnerEmail: "Correo del propietario",
    formEnterAnswerKamEmail: "Correo del Key Account Manager",
    formEnterAnswerPmEmail: "Correo del PM",
    formEnterAnswerSystemEngineerEmail: "Correo del Ingeniero de Sistemas",
    optId: "ID de oportunidad (SFDC)",
    salesOrderConfirmationNumber: "Número de confirmación de orden de venta (venta)",
    salesOrderConfirmationNumber_desc: "",
    selloutFiles: "Orden de venta PDF (hardware + software + servicios profesionales)",
    selloutFiles_desc: "Necesario para rastrear correctamente los ingresos de Blossom.",
    selloutFiles_desc2: "Límite de archivos: 5  ~  Tamaño máximo de cada archivo: 10MB  ~  Extensiones permitidas: ",
    selloutFilesAdd: "Subir archivo",
    selloutFilesDownload: "Descargar",
    selloutFilesReplace: "Reemplazar",
    quotationToolFile: "Herramienta de cotización (no obligatoria)",
    quotationToolFile_desc: "Sube el archivo de la herramienta de cotización utilizado para esta solicitud.",
    quotationToolFile_desc2: "Límite de archivos: 1  ~  Tamaño máximo de cada archivo: 10MB",
    purchaseOrderNumberSofia: "Número de Orden de Compra a Sofia",
    baselineSubscription: "Suscripción base Luckey",
    baselineSubscription_BASIC: "Luckey Básico",
    baselineSubscription_ENTERPRISE: "Luckey Enterprise (Estándar)",
    baselineSubscription_ENGINE: "Luckey Engine",
    baselineSubscription_undefined: "Usar suscripción personalizada",
    subscriptionData: "Información de la suscripción",
    noSubscriptionFound: "No se encontró ninguna suscripción todavía.",
    noSubscriptionFoundPleaseCheck: " Por favor, verifica los campos ",
    noSubscriptionFoundDescription: "La lista de suscripciones está vacía. Si has aplicado un filtro de búsqueda, intenta restablecerlo y vuelve a intentarlo",
    selectedSubscription: "Suscripción seleccionada: ",
    plantName: "Nombre del sistema",
    plantName_desc: "En minúsculas, sin espacios y, si es necesario, separado por guiones - ej. nombre-dominio-ejemplo",
    keyAccountManager: "Key Account Manager",
    keyAccountManager_desc: "Correo electrónico del Key Account Manager",
    systemManagerEmails: "Correo electrónico del Administrador del Sistema (uno o más)",
    systemManagerEmails_desc: "Correo electrónico de la persona encargada de gestionar los permisos de acceso de los usuarios en la plataforma web",
    installerEmail: "Correo del instalador (no obligatorio)",
    installerEmail_desc: "",
    pmEmail: "Correo del PM (no obligatorio)",
    pmEmail_desc: "Debe pertenecer a @sofialocks.com o @iseo.com",
    systemEngineerEmail: "Correo del Ingeniero de Sistemas (no obligatorio)",
    systemEngineerEmail_desc: "Debe pertenecer a @sofialocks.com o @iseo.com",
    numberOfElements: "Número de elementos a gestionar",
    numberOfElements_desc: "Los elementos son la suma de: usuarios, cerraduras y cualquier invitación activa",
    V364orF9000: "¿Cerraduras V364/1RIS o cilindros F9000?",
    V364orF9000_desc: "¿Existen cerraduras V364/1RIS o cilindros F9000 en el sistema? En ese caso, el complemento asociado se agregará automáticamente.",
    addons: "Complementos y Plug-ins",
    addons_desc: "Selecciona los complementos a agregar a la suscripción base",
    credits: "Número de créditos",
    credits_desc: "Créditos vendidos con esta activación del sistema",
    expectedEnd: "Según tu selección, se agotarán los créditos en: ",
    finalData: "Otra información",
    notes: "Notas",
    notes_desc: "Escribe aquí cualquier nota adicional relacionada con esta solicitud",
    notifications: "Notificaciones",
    notifications_desc: "Personas a notificar para eventos; por defecto, se notificará al propietario de la solicitud y al Key Account Manager asociado",
    editBaselineSubscriptionWarning: "¿Estás seguro de que deseas cambiar la suscripción base? Esto restablecerá los complementos seleccionados en el formulario.",
    editBaselineSubscriptionError: "Ocurrió un error, por favor, inténtalo de nuevo",
    editNumberOfElementsWarning: "¿Estás seguro de que deseas cambiar el número de elementos? Esto restablecerá los complementos disponibles en el formulario.",
    editNumberOfElementsError: "Ocurrió un error, por favor, inténtalo de nuevo",
    customerMarketplaceError: "El usuario final seleccionado no tiene ningún mercado asociado. Por favor, contacta a soporte para solucionar el problema.",
    customerClusterError: "El usuario final seleccionado no tiene ningún cluster asociado. Por favor, contacta a soporte para solucionar el problema.",
    invoiceData: "Factura",
    invoiceFile: "Factura PDF *",
    invoiceFile_desc: "Sube la factura en formato PDF.",
    invoiceFile_desc2: "Límite de archivos: 1  ~  Tamaño máximo de cada archivo: 10MB",
    invoiceNumber: "Número de factura de venta y fecha (XXXXXX - DD/MM/AAAA)",
    invoiceNumberPlaceholder: "XXXXXX - DD/MM/AAAA",
    setInvoiceData: "Subir factura",
    dataMissingCreated: "Especifica el número de factura y el PDF para continuar"
  },
  recharges: {
    addNewRechargeButton: "Agregar recarga de billetera",
    noRechargesFoundTitle: "No se encontraron recargas",
    noRechargesFoundDescription: "No hay recargas de billetera en el sistema. Crea nuevas para comenzar",
    customerAndWallet: "Usuario final y billetera",
    seeCustomer: "Información",
    seeCustomerDetails: "Ver detalles del usuario final",
    selloutOrder: "Orden de venta",
    intragroupOrder: "Orden intra-grupo",
    custref: "Ref. Cliente",
    mode: "Tipo de renovación de contrato:",
    creditsSuggested: "Créditos sugeridos",
    creditsConfirmed: "Créditos confirmados",
    rejectedReason: "Motivo de rechazo",
    deletedReason: "Motivo de eliminación",
    createdAt: "Creado el",
    updatedAt: "Actualizado el",
    customerSearch: "Buscar entre usuarios finales existentes",
    customerSearchEnterName: "Buscar por nombre...",
    customerSearchNoMatch: "No se encontraron usuarios finales...",
    varSearch: "Buscar entre los VAR existentes",
    varSearchEnterName: "Buscar por nombre...",
    varSearchNoMatch: "No se encontró ningún VAR...",
    varCustomerSearch: "Buscar entre los usuarios finales del VAR",
    plantSearch: "Selecciona el dominio deseado",
    plantSearchEnterName: "Selecciona dominio",
    plantSearchNoMatch: "No se encontró ningún dominio...",
    plantProceedWithSelected: "Usar dominio/billetera seleccionada",
    dataMissingTakenCharge: "Especifica los créditos confirmados y la orden de venta para continuar",
    dataMissingScratchcardCreated: "Especifica el número de factura y el PDF para continuar",
    changeStatusToSelloutOrderGenerated: "Enviar a revisión",
    changeStatusToInvoiced: "Enviar a facturado",
    changeStatusToCreated: "Enviar a creado",
    changeStatusToRejected: "Rechazar solicitud",
    changeStatusToScratchcardCreated: "Recarga creada",
    changeStatusToWalletRecharged: "Billetera recargada",
    changeStatusAdmin: "Establecer estado (admin)",
    createCard: "Crear tarjeta de recarga",
    changeCustRef: "Establecer Ref. Cliente",
    search: "Buscar...",
    resetFilters: "Restablecer filtros",
    statusSelection: "Cualquier estado",
    clusterSelection: "Cualquier cluster",
    statusTO_BE_MANAGED: "Por gestionar",
    statusTAKEN_CHARGE: "En proceso",
    statusREQUEST_TO_DELETE: "Solicitud de eliminación",
    statusSELLOUT_ORDER_GENERATED: "Bajo revisión",
    statusSCRATCHCARD_CREATED: "Recarga creada",
    statusWALLET_RECHARGED: "Recargada",
    statusREJECTED: "Rechazada",
    statusDELETED: "Eliminada",
    statusINVOICED: "Facturada",
    promote: "Eliminar",
    saveRequest: "Guardar solicitud",
    createRequest: "Crear recarga de billetera",
    takeCharge: "Tomar en cuenta",
    AUTO_RENEWABLE: "Renovable automáticamente",
    NOT_AUTO_RENEWABLE: "No renovable automáticamente",
    deleteRechargeConfirm: "¿Estás seguro de que deseas eliminar esta solicitud? Por favor, especifica el motivo. La operación no se puede deshacer",
    deleteAdminRechargeConfirm: "¿Estás seguro de que deseas eliminar permanentemente esta solicitud? La operación no se puede deshacer",
    deleteRechargeError: "Ocurrió un error al eliminar la solicitud. Por favor, inténtalo de nuevo",
    takechargeRechargeConfirm: "Por favor, especifica el tipo de renovación para continuar con esta solicitud",
    takechargeRechargeDirectConfirm: "¿Estás seguro de que deseas tomar en cuenta esta solicitud?",
    takechargeRechargeError: "Ocurrió un error al tomar en cuenta la solicitud. Por favor, inténtalo de nuevo",
    promoteRechargeConfirm: "¿Estás seguro de que deseas enviar esta solicitud a revisión? La solicitud no se puede editar mientras esté en revisión.",
    promoteRechargeError: "Ocurrió un error al promover la solicitud. Por favor, inténtalo de nuevo",
    createdRechargeConfirm: "¿Estás seguro de que deseas enviar esta solicitud al estado CREADO?",
    createdRechargeError: "Ocurrió un error al actualizar el estado de la solicitud. Por favor, inténtalo de nuevo",
    invoicedRechargeConfirm: "¿Estás seguro de que deseas marcar esta solicitud como facturada?",
    invoicedRechargeError: "Ocurrió un error al actualizar el estado de la solicitud. Por favor, inténtalo de nuevo",
    editStatusRechargeConfirm: "¿Estás seguro de que deseas editar el estado de esta solicitud? La operación no se puede deshacer",
    editStatusRechargeError: "Ocurrió un error al actualizar el estado de la solicitud. Por favor, inténtalo de nuevo",
    rejectRechargeConfirm: "Especifica el motivo para rechazar esta solicitud.",
    rejectRechargeError: "Ocurrió un error al rechazar la solicitud. Por favor, inténtalo de nuevo",
    scratchcardCreatedRechargeConfirm: "¿Estás seguro de que deseas aceptar esta solicitud y confirmar que la tarjeta de recarga ha sido creada?",
    scratchcardCreatedRechargeError: "Ocurrió un error al aceptar la solicitud. Por favor, inténtalo de nuevo",
    walletRechargedRechargeConfirm: "¿Estás seguro de que deseas marcar esta solicitud como completada y confirmar que la billetera ha sido recargada?",
    walletRechargedRechargeError: "Ocurrió un error al cambiar el estado de la solicitud. Por favor, inténtalo de nuevo",
    newFileName: "Especifica el nombre del archivo",
    newFileExtensionError: "El archivo tiene una extensión no soportada",
    newFileDuplicateNameError: "Este nombre ya ha sido utilizado para otro archivo. Cambia el nombre y repite la operación.",
    editCustrefConfirm: "Especifica la Ref. Cliente para esta solicitud.",
    editCustrefError: "Ocurrió un error al actualizar la Ref. Cliente, por favor, inténtalo de nuevo",
    editRecharge: "Editar recarga de billetera",
    newRecharge: "Agregar recarga de billetera",
    viewRecharge: "Ver recarga de billetera",
    newRechargeError: "Ocurrió un error al crear esta solicitud. Por favor, inténtalo de nuevo",
    editRechargeError: "Ocurrió un error al actualizar esta solicitud. Por favor, inténtalo de nuevo",
    exportRechargesCSV: "Exportar CSV",
    basicData: "Parámetros de recarga de billetera",
    ordersData: "Órdenes",
    invoiceData: "Factura",
    formEnterAnswer: "Tu respuesta",
    formEnterAnswerGT0: "Número mayor que cero",
    sfdcId: "ID de oportunidad (SFDC)",
    salesOrderConfirmationNumber: "Número de confirmación de orden de venta (venta)",
    salesOrderConfirmationNumber_desc: "",
    selloutFiles: "Orden de venta PDF (hardware + software + servicios profesionales)",
    selloutFiles_desc: "Necesario para rastrear correctamente los ingresos de Blossom.",
    selloutFiles_desc2: "Límite de archivos: 5  ~  Tamaño máximo de cada archivo: 10MB  ~  Extensiones permitidas: ",
    selloutFilesAdd: "Subir archivo",
    selloutFilesDownload: "Descargar",
    selloutFilesReplace: "Reemplazar",
    invoiceFile: "Factura PDF *",
    invoiceFile_desc: "Sube la factura en formato PDF.",
    invoiceFile_desc2: "Límite de archivos: 1  ~  Tamaño máximo de cada archivo: 10MB",
    invoiceNumber: "Número de factura de venta (para el usuario final) *",
    purchaseOrderNumberSofia: "Número de Orden de Compra a Sofia",
    orderContactEmail: "Correo de contacto de la orden"
  },
  vars: {
    varLogo: "Logo",
    setTrainingDate: "Establecer fecha de capacitación",
    missingLastTrainingDateForCertificate: "Este VAR no tiene una fecha de capacitación establecida. Por favor, establece una fecha de capacitación e intenta generar el certificado de nuevo",
    varLastTrainingData: "Última fecha de capacitación",
    varShortDescription: "Descripción corta",
    varCity: "Ciudad",
    varCountry: "País",
    varWebsite: "Sitio web",
    varVisibleOnSofiaWebsite: "Visible en la página de socios de Sofia",
    downloadCertificate: "Descargar certificado",
    opportunityChangeOwnerMessage: "Por favor, especifica el propietario de esta cotización",
    noOpportunityHistoryFound: "No se encontró historial de cotizaciones",
    noOpportunityHistoryFoundDescription: "Esta cotización no tiene registros históricos",
    customers: "Clientes",
    varsManagement: "VARs",
    varSettings: "Configuraciones",
    varInfoPage: "Capacitación y Recursos",
    varUsersPage: "Usuarios",
    vars: "VAR",
    plantThemes: "Temas de dominio",
    editVarPersonalConfirm: "Estás a punto de editar la información de tu empresa. ¿Estás seguro de que deseas continuar?",
    editVarConfirm: "Estás a punto de editar los datos del VAR. ¿Estás seguro de que deseas continuar?",
    editVar: "Editar VAR",
    newVar: "Nuevo VAR",
    varCreationConfirm: "¿Deseas crear el nuevo VAR?",
    createVar: "Crear VAR",
    noVarFindTitle: "No se encontró ningún VAR",
    noVarFindDescription: "La lista de VAR está vacía. Si has aplicado un filtro de búsqueda, intenta restablecerlo y vuelve a intentarlo",
    noPlantThemeFindTitle: "No se encontró ningún tema de dominio",
    noPlantThemeFindDescription: "La lista de temas de dominio está vacía. Si has aplicado un filtro de búsqueda, intenta restablecerlo y vuelve a intentarlo",
    clusterSelection: "Cualquier cluster",
    varSelection: "Cualquier VAR",
    varName: "Nombre",
    varAddress: "Dirección",
    varEmail: "Correo de contacto principal",
    varPhone: "Teléfono",
    varCluster: "Cluster",
    varVat: "IVA",
    varTechContact: "Contacto técnico",
    varCommercialContact: "Contacto comercial",
    varNameTech: "Nombre del contacto técnico",
    varEmailTech: "Correo del contacto técnico",
    varPhoneTech: "Teléfono del contacto técnico",
    varNameComm: "Nombre del contacto comercial",
    varEmailComm: "Correo del contacto comercial",
    varPhoneComm: "Teléfono del contacto comercial",
    varSfdcid: "ID DE CUENTA SFDC",
    varSupportEmail: "Correo de soporte",
    varSupportHours: "Horario de atención de soporte",
    varCompanyName: "Nombre de la empresa",
    varCustomers: "Usuarios finales del VAR",
    varUsers: "Usuarios del VAR",
    varOpportunities: "Cotizaciones del VAR",
    editPlantThemeConfirm: "Estás a punto de editar los datos del tema del dominio. ¿Estás seguro de que deseas continuar?",
    editPlantTheme: "Editar tema de dominio",
    newPlantTheme: "Nuevo tema de dominio",
    plantThemeCreationConfirm: "¿Deseas crear el nuevo tema de dominio?",
    editVarPersonal: "Editar información de la empresa",
    plantThemeName: "Nombre",
    plantThemeColour: "Color *",
    plantThemeColourDescription: "Color del tema",
    plantThemeLogo: "Logo",
    plantThemeDefault: "Usar como tema predeterminado",
    logoFile: "Imagen del logo *",
    logoFile_desc: "Extensiones de archivo permitidas: ",
    logoFileAdd: "Subir archivo",
    logoFileDownload: "Descargar",
    logoFileReplace: "Reemplazar",
    deletePlantThemeConfirm: "¿Estás seguro de que deseas eliminar este tema de dominio? La operación no se puede deshacer",
    plantThemeSearchEnterName: "Selecciona el tema",
    plantThemeSearch: "Selecciona el tema deseado",
    domainThemeTitle: "Tema de dominio personalizado",
    domainThemeDescription: "Establece tu tema personalizado en este dominio",
    plantThemeModifySuccess: "Tema de dominio modificado y actualizado",
    cannotDeleteAssociatedTheme: "No se puede eliminar un tema que está asociado a un dominio.",
    plantThemeAssociatedPlants: "Dominios asociados a este tema",
    newVarUser: "Nuevo usuario VAR",
    varUserCreationConfirm: "¿Deseas crear el nuevo usuario VAR?",
    createVarUser: "Crear usuario VAR",
    varUserFormTitle: "Usuario VAR",
    createNewVarUser: "Crear nuevo usuario VAR",
    noVarUserFindTitle: "No se encontraron usuarios VAR",
    noVarUserFindDescription: "La lista de usuarios VAR está vacía",
    deleteVarUserConfirm: "¿Estás seguro de que deseas eliminar este usuario VAR? La operación no se puede deshacer",
    deleteVarOpportunityConfirm: "¿Estás seguro de que deseas eliminar esta cotización? La operación no se puede deshacer",
    rejectVarOpportunityConfirm: "¿Estás seguro de que deseas rechazar esta cotización? La operación no se puede deshacer",
    approveVarOpportunityConfirm: "¿Estás seguro de que deseas aprobar esta cotización? La operación no se puede deshacer",
    syncsfdcidVarOpportunityConfirm: "¿Estás seguro de que deseas asociar un ID SFDC a esta cotización? La operación no se puede deshacer",
    upgradeVarOpportunityConfirm: "¿Estás seguro de que deseas enviar esta cotización a revisión? La operación no se puede deshacer",
    winVarOpportunityConfirm: "¿Estás seguro de que deseas marcar esta cotización como GANADA? La operación no se puede deshacer",
    lostVarOpportunityConfirm: "¿Estás seguro de que deseas marcar esta cotización como PERDIDA? La operación no se puede deshacer",
    deleteUserConfirm: "¿Estás seguro de que deseas eliminar este usuario? La operación no se puede deshacer",
    varUserUsername: "Nombre de usuario",
    varUserFirstname: "Nombre",
    varUserLastname: "Apellido",
    varUserEmail: "Correo electrónico",
    varUsersList: "Usuarios",
    newFileExtensionError: "El archivo tiene una extensión no soportada",
    agreementFile: "Acuerdo",
    opportunities: "Cotizaciones",
    opportunityManagement: "Información de la cotización",
    opportunityID: "ID",
    opportunityOpty: "ID OPTY de SFDC",
    opportunityVar: "VAR",
    opportunityCustomer: "Usuario final",
    opportunityOwner: "Propietario de la cotización",
    opportunityName: "Nombre de la cotización",
    opportunityValue: "Valor",
    opportunityStatus: "Estado",
    opportunityActions: "Acciones",
    opportunityDescription: "Descripción",
    opportunityNotes: "Notas",
    opportunityCrm: "CRM",
    noOpportunityFindTitle: "No se encontraron cotizaciones",
    noOpportunityFindDescription: "La lista de cotizaciones está vacía",
    opportunityStatus_DRAFT: "Borrador",
    opportunityStatus_ON_APPROVAL: "Bajo revisión",
    opportunityStatus_APPROVED: "Aprobada",
    opportunityStatus_CANCELLED: "Cancelada",
    opportunityStatus_REJECTED: "Rechazada",
    opportunityStatus_WON: "Ganada",
    opportunityStatus_LOST: "Perdida",
    opportunityFiles: "Archivos adjuntos",
    newOpportunity: "Nueva cotización",
    editOpportunity: "Editar cotización",
    opportunitySyncSFDC: "Sincronizar con SFDC",
    exportOpportunities: "Exportar CSV",
    upgradeOpportunity: "Enviar a revisión",
    approveOpportunity: "Aprobar",
    rejectOpportunity: "Rechazar",
    wonOpportunity: "Marcar como GANADA",
    lostOpportunity: "Marcar como PERDIDA",
    CREATED: "Cotización creada",
    VAR_USER_UPDATED: "Usuario VAR asignado a la cotización actualizado",
    DESCRIPTION_UPDATED: "Descripción de la cotización actualizada",
    NAME_UPDATED: "Nombre de la cotización actualizado",
    VALUE_UPDATED: "Valor de la cotización actualizado",
    CURRENCY_UPDATED: "Moneda de la cotización actualizada",
    OPTY_ID_UPDATED: "ID Opty de la cotización actualizado",
    ISEO_CRM_ID_UPDATED: "ID SFDC de la cotización actualizado",
    DOCUMENTS_UPDATED: "Documentos de la cotización actualizados",
    STATUS_ON_APPROVAL: "Cotización enviada a revisión",
    STATUS_APPROVED: "Cotización aprobada",
    STATUS_WON: "Cotización marcada como ganada",
    STATUS_LOST: "Cotización marcada como perdida",
    STATUS_DRAFT: "Cotización marcada como borrador",
    STATUS_CANCELLED: "Cotización marcada como cancelada",
    CANCELLED: "Cotización cancelada",
    STATUS_REJECTED: "Cotización marcada como rechazada",
    showOpportunityHistory: "Mostrar historial",
    opportunityHistory: "Historial de cotización"
  },
  save: "Guardar",
  languages: {
    it: "Italiano",
    en: "Inglés",
    de: "Alemán",
    fr: "Francés",
    es: "Español",
    nl: "Holandés",
    ITALIAN: "Italiano",
    ENGLISH: "Inglés",
    GERMAN: "Alemán",
    FRENCH: "Francés",
    SPANISH: "Español",
    DUTCH: "Holandés",
    menuHeading: "Elige un idioma"
  },
  productInfo: {
    software: "Software",
    salesMaterial: "Material de ventas",
    hardware: "Hardware",
    marketingMaterial: "Material de marketing"
  },
  users: {
    usersManagement: "Gestión de usuarios",
    users: "Usuarios",
    noUserFindTitle: "No se encontraron usuarios",
    noUserFindDescription: "La lista de usuarios está vacía. Intenta restablecer tus filtros de búsqueda si hay alguno",
    search: "Buscar...",
    roleSelection: "Rol",
    clusterSelection: "Cluster",
    varSelection: "VAR",
    newUser: "Agregar",
    userFirstname: "Nombre",
    userLastname: "Apellido",
    userEmail: "Correo electrónico",
    userFormTitle: "Datos del usuario",
    editUserForm: "Editar usuario",
    newUserForm: "Crear usuario"
  },
  permissions: {
    NAVIGATION: "Visibilidad del menú",
    CLUSTER: "Cluster",
    VAR: "VAR",
    CUSTOMER: "Usuarios finales",
    OPPORTUNITY: "Cotizaciones",
    WALLET: "Billeteras de usuarios finales",
    DOMAIN: "Sistemas de usuarios finales",
    GATEWAYS: "Gateway",
    CARD: "Tarjetas de recarga",
    PLANT_ACTIVATION: "Activaciones del sistema",
    RECHARGE_REQUEST: "Recargas del sistema",
    ACTIVATION_REQUEST: "Solicitudes de activación",
    EXPORT: "Exportar entidades",
    CREATE: "Crear nueva entidad",
    UPDATE: "Actualizar una entidad",
    MARK_LOST: "Marcar entidad como Perdida",
    MARK_WON: "Marcar entidad como Ganada",
    CANCEL: "Marcar entidad como Cancelada",
    CANCEL_DRAFT: "Marcar entidad como Cancelada cuando es borrador",
    CANCEL_ON_APPROVAL: "Marcar entidad como Cancelada cuando está en revisión",
    CANCEL_APPROVED: "Marcar entidad como Cancelada cuando está aprobada",
    MARK_TO_BE_APPROVED: "Marcar entidad para Aprobación",
    RECHARGE: "Recargar",
    SUBSCRIPTION_UPDATE: "Actualizar suscripciones",
    DELETE_REQUEST: "Enviar solicitud de eliminación",
    CREATION_REQUEST: "Enviar solicitud para crear nueva entidad",
    MANAGER_CREATE: "Crear administradores",
    MANAGER_UPDATE: "Actualizar administradores",
    INSTALLER_CREATE: "Crear instaladores",
    INSTALLER_UPDATE: "Actualizar instaladores",
    IMPERSONATE_WRITE: "Personificar administradores",
    IMPERSONATE_SUPPORT: "Personificar cuenta de soporte",
    ADDITIONAL_INFO_UPDATE: "Editar información adicional",
    CUSTOMERS: "Usuarios finales",
    PLANT_ACTIVATIONS: "Activaciones del sistema",
    RECHARGE_REQUESTS: "Recargas del sistema",
    ACTIVATION_REQUESTS: "Solicitudes de activación",
    CARDS: "Tarjetas de recarga",
    VAR_USERS: "Usuarios VAR",
    VAR_SETTINGS: "Configuraciones VAR",
    OPPORTUNITIES: "Cotizaciones",
    PRODUCTINFO: "Información de producto",
    USER_READ: "Ver usuarios",
    USER_UPDATE: "Actualizar un usuario",
    USER_CREATE: "Crear nuevo usuario",
    PLANT_THEME: "Cambiar tema del dominio",
    READ: "Leer valores de entidad",
    ORDERS_STATUS: "Estado de las órdenes",
    FILTER: "Filtrar entidades",
    VAR_MANAGEMENT: "Gestión de VAR",
    MARKETPLACES: "Mercados",
    DOMAINS: "Sistemas de usuarios finales",
    PRICELIST: "Lista de precios",
    SALESINFO: "Información de ventas",
    PLATFORM_USERS: "Gestión de usuarios",
    APPROVE: "Marcar entidad como Aprobada",
    REJECT: "Marcar entidad como Rechazada",
    EXTEND: "Extender",
    CONFIG: "Leer configuraciones de entidad",
    IRIS_ENABLE: "Habilitar 1RIS para el dominio",
    MARK_TO_PROMOTE: "Marcar entidad para Promoción",
    MARK_TO_INVOCE: "Marcar entidad para Facturación",
    DELETE: "Eliminar entidades",
    EDIT: "Editar entidades",
    SUBSCRIPTION_DELETE: "Eliminar suscripciones"
  },
  segmentTypes: {
    WORKING_SPACES_name: "Espacio de trabajo flexible",
    LIVING_name: "Vivienda flexible",
    RETAIL_name: "Venta al por menor flexible",
    UNIVERSITIES_name: "Universidades",
    LEISURE_name: "Ocio y hospitalidad",
    HOSPITAL_name: "Hospital",
    WORKING_SPACES_description: "Oficinas, coworking, centros de negocios",
    LIVING_description: "Residencial, cohousing, coliving, alojamiento estudiantil",
    RETAIL_description: "Retail multisucursal, centros comerciales, cadenas de tiendas",
    LEISURE_description: "Hoteles pequeños y medianos, alojamiento, centros deportivos",
    HOSPITAL_description: "Hospitales, residencias de ancianos y cuidado de adultos mayores"
  }
};

export default translations;