import { PaginatedDataDTO } from "@bottega52/commons-pagination";
import { Button, Card, Divider } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import AssignmentIndIcon from '@material-ui/icons/AssignmentIndOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import * as React from "react";
import { ConnectedProps, connect } from "react-redux";
import ReduxLanguage from '../../components/ReduxLanguage';
import { IState } from "../../redux/store";
import IClusterInDTO from "../../repository/jago/model/input/IClusterInDTO";
import IMarketplaceInDTO from "../../repository/jago/model/input/IMarketplaceInDTO";
import { IVarInDTO } from "../../repository/jago/model/input/IVarInDTO";
import AbilityProvider from "../../services/PermissionService/AbilityProvider";
import { PERMISSIONS, Roles } from "../../services/PermissionService/PermissionConstants";
import { GREEN } from "../../style/StyleConsts";
import NewspaperIconCustom from "../NewspaperIconCustom";
import Permission from "../Permission/Permission";
import _ from "lodash";

const styles = createStyles({
  container: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column',
    paddingBottom: 50,
  },
  headerContainer: {
    marginTop: 20,
    padding: 10,
    borderLeft: '3px solid #0D47A1',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginRight: 5,
    fontSize: 25,
  },
  title: {
    fontWeight: 'bold'
  },
  sectionHeader: {
    fontWeight: 'bold'
  },
  sectionContent: {

  },
  actionButton: {
    width: 'fit-content',
    color: 'white',
    marginLeft: 0,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 'bold',
    marginTop: 5
  },
  actionButtonText: {
    margin: 0,
    fontWeight: 'bold',
    marginLeft: 5,
    color: 'white'
  },
});

type IReduxProps = ConnectedProps<typeof connector>;

export interface IVarInfoDetailsViewProps extends WithStyles<typeof styles>{
  onOpenVarCertificate: () => void;
}

export interface IVarInfoDetailsViewState {
}

export type ComponentProps = IVarInfoDetailsViewProps & IReduxProps;
class VarInfoDetailsView extends React.Component<ComponentProps, IVarInfoDetailsViewState> {


  public render() {
    const { selectedVar, marketplaces, clusters, classes, onOpenVarCertificate } = this.props;
    const VAR = (selectedVar as IVarInDTO);
    const clustersData = clusters as PaginatedDataDTO<IClusterInDTO>;
    const clustersContent= clustersData.content ? clustersData.content:[]
    const marketplacesData = (marketplaces as PaginatedDataDTO<IMarketplaceInDTO>).content;
    const marketPlace = _.find(marketplacesData, marketplace => marketplace.id === VAR.marketplaceId);
    const varCluster = VAR.clusterId && clustersContent.filter(e=>e.id===VAR.clusterId).length>0 ? clustersContent.filter(e=> e.id === VAR.clusterId)[0] : undefined;
    console.log(VAR)
    return (
      <div className={classes.container}>
        <Card className={classes.headerContainer}>
          <div className={classes.header}>
            <div className={classes.headerInner}> 
              <div className={classes.headerTextContainer}>
                <h2 className={classes.title}>{VAR && VAR.name ? `${VAR.id} - ${VAR.name}` : ''}</h2>
              </div>
              {VAR.logoFileUrl ? (
                <img src={VAR.logoFileUrl} style={{ height: 'auto', width: 100, marginLeft: 10  }} alt="logo" />
              ) : null}
            </div>
            <div style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.VAR_READ}>
              <Button
                variant="contained"
                style={{ backgroundColor: 'darkcyan', marginRight: 20, marginTop: 5, height: 33 }}
                onClick={() => onOpenVarCertificate()}
              >
                <NewspaperIconCustom className="" style={{ color: 'white', width: 20 }} />
                <h5 className={classes.actionButtonText}><ReduxLanguage languageKey={"vars.downloadCertificate"} /></h5>
              </Button>  
            </Permission>
            {VAR.agreementFileUrl ? (
            <a href={VAR.agreementFileUrl}>
              <Button
                variant="contained"
                style={{ backgroundColor:'darkcyan' }}
                className={classes.actionButton}
              >
                <CloudDownloadIcon className={classes.icon}/>
                <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="vars.agreementFile" /></h5>
              </Button>
            </a>
          ):null}
          </div>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'forms.email'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.contactEmail || '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varVat'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.vat || '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varCluster'} />}</h3>
            {varCluster ? <h3><>[{varCluster.market}] - {varCluster.name}</></h3> : '---'}
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varPhone'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.contactPhone || '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varAddress'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.address || '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varCity'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.city || '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varCountry'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.country || '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varWebsite'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.website || '---'}</h3>
          </div>
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} roles={[Roles.ROLE_VASHO_SOFIA_ADMIN]}>
            <div style={{ marginTop: 15 }}>
              <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varVisibleOnSofiaWebsite'} />}</h3>
              <h3 className={classes.sectionContent}>{<ReduxLanguage languageKey={VAR.visibleOnSofiaWebsite ? 'forms.visible' : 'forms.notVisible'}/>}</h3>
            </div>
          </Permission>
          <Divider style={{ marginTop: 15 }} />
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{'SFDC ID'}</h3>
            <h3 className={classes.sectionContent}>{VAR.sfcdId}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'forms.customerMarketplace'} />}</h3>
            <h3 className={classes.sectionContent}>{marketPlace && marketPlace.name ? marketPlace.name : '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'forms.customerCompany'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.company || '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'activations.keyAccountManager'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.kamEmail || '---'}</h3>
          </div>
          <Divider style={{ marginTop: 15 }} />
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varSupportEmail'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.supportEmail || '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varSupportHours'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.supportHours || '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varCommercialContact'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.commercialRefName || '---'} - {VAR.commercialRefEmail} - {VAR.commercialRefPhone}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varTechContact'} />}</h3>
            <h3 className={classes.sectionContent}>{VAR.techRefName || '---'} - {VAR.techRefEmail} - {VAR.techRefPhone}</h3>
          </div>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    selectedVar: state.vars.selectedVar.data,
    marketplaces: state.credits.marketplaces.data,
    clusters: state.credits.clusters.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles (styles)(VarInfoDetailsView as any));