const translations = {
  language: "Langue",
  login: {
    welcome: "Bienvenue",
    ssoLogin: "Acc\u00E9der avec l\'e-mail de votre compte",
    detailsGeneric: "D\u00E9tails",
    customers: "Utilisateurs finaux",
      sendEmail: "Envoyer un e-mail",
      otpLogin: "Se connecter avec l\'e-mail",
      login: "Se connecter",
      changeEmail: "Utiliser une autre adresse e-mail",
      or: "OU"
    },
    domains: {
      customerAdminDeletionConfirm: "Voulez-vous supprimer cet utilisateur final ? L\'op\u00E9ration ne peut pas \u00EAtre annul\u00E9e",
      customerAdminDeletionSuccess: "Utilisateur final supprim\u00E9 avec succ\u00E8s",
      luckeyDomainsTitle: "Domaines",
      domainName: "Nom de domaine",
      domainDetails: "D\u00E9tails du domaine",
      domainConfigModifySuccess: "Configuration du domaine modifi\u00E9e et mise \u00E0 jour",
      domainConfigTitle: "Configurations - Activation/d\u00E9sactivation des fonctionnalit\u00E9s",
      domainConfigDescription: "Configuration du domaine au format JSON. Utilisez-la pour activer/d\u00E9sactiver les fonctionnalit\u00E9s du domaine",
      domainConfigGuide: "Guide de configuration du domaine",
      noUsersFoundTitle: "Liste d\'utilisateurs vide",
      domainUsersTitle: "Administrateurs du domaine",
      domainUsersDescription: "Impersonnez l\'un des gestionnaires d\'utilisateurs pour effectuer des op\u00E9rations de maintenance sur le domaine",
      domainUsersWarning: "ATTENTION : En vous faisant passer pour un utilisateur, vous pouvez apporter des modifications au syst\u00E8me, y compris irr\u00E9versibles. Utilisez la plus grande prudence",
      noUsersFoundDescription: "Aucun utilisateur trouv\u00E9 dans ce domaine",
      noDomainsFoundTitle: "Aucun domaine trouv\u00E9",
      noDomainFoundDescription: "La liste des domaines est vide. Si vous avez appliqu\u00E9 un filtre de recherche, essayez de le r\u00E9initialiser et r\u00E9essayez",
      impersonateSuccess: "Impersonation de l\'utilisateur r\u00E9ussie",
      domainSubsriptionTitle: "Abonnement du domaine",
      domainSubsriptionDescription: "Liste des abonnements du domaine : serrures, utilisateurs et expiration du domaine",
      expiration: "Expiration",
      totalNumber: "Nombre total",
      used: "\u00C9l\u00E9ments utilis\u00E9s",
      tooMuchElements: "AVERTISSEMENT : LE DOMAINE A D\u00C9PASS\u00C9 LA LIMITE D\'\u00C9L\u00C9MENTS TOTAUX",
      newDomain: "Nouveau domaine",
      externalReference: "R\u00E9f\u00E9rence externe",
      externalReferenceDetails: "Par exemple \"date-purchaseId\"",
      productDescription: "Le produit repr\u00E9sente la licence qui sera activ\u00E9e pour le nouveau domaine",
      productTitle: "Type d\'abonnement",
      extendDomain: "\u00C9tendre l\'abonnement du domaine",
      extendDomainDescription: "Prolongez votre domaine avec un add-on ou une cl\u00E9 de produit",
      domainInstallersTitle: "Installateurs du domaine",
      domainInstallersDescription: "Liste des utilisateurs installateurs sur les sites s\u00E9lectionn\u00E9s",
      domainDeletionTitle: "Supprimer le domaine",
      domainDeletionDescription: "Envoyer une demande de suppression de ce domaine",
      sendDeletionRequest: "Envoyer",
      domainDeletionConfirm: "Voulez-vous envoyer une demande de suppression de ce domaine ?",
      domainDeletionSuccess: "Demande envoy\u00E9e avec succ\u00E8s",
      customerDeletionTitle: "Supprimer l\'utilisateur final",
      customerDeletionConfirm: "Voulez-vous envoyer une demande de suppression de cet utilisateur final ?",
      customerDeletionSuccess: "Demande envoy\u00E9e avec succ\u00E8s",
      walletDeletionTitle: "Supprimer le portefeuille",
      walletDeletionConfirm: "Voulez-vous envoyer une demande de suppression de ce portefeuille ?",
      walletDeletionSuccess: "Demande envoy\u00E9e avec succ\u00E8s",
      verifyServiceStatus: "Statut du service",
      impersonateUser: "Impersonner l\'utilisateur",
      impersonate: "Impersonner",
      impersonateReadOnly: "Impersonner en mode lecture seule",
      domainAdditionalInfo: "Informations suppl\u00E9mentaires sur le domaine",
      domainAdditionalInfoDescription: "Fournissez des informations suppl\u00E9mentaires sur le domaine ici",
      additionalInfoSavedSuccessfully: "Notes suppl\u00E9mentaires enregistr\u00E9es avec succ\u00E8s",
      EASY: "Seulement Argo Locks",
      SMART: "Argo Locks + Gateway",
      ENTERPRISE: "V364 + Argo Locks",
      STANDARD: "Seulement V364 Locks",
      numberOfArgoLocks: "Argo",
      numberOfVegaLocks: "V364",
      numberOfGatewaysLocks: "Gateways",
      numberOfUsers: "Utilisateurs",
      numberOfLocks: "Cadenas intelligents",
      federatedDomain: "Domaine li\u00E9 \u00E0 : {{domain}}",
      federatedMasterDomain: "Domaine principal d\'un groupe",
      domainFederatedLicense: "Abonnement de site f\u00E9d\u00E9r\u00E9 : cet abonnement est unifi\u00E9 pour tous les domaines appartenant au domaine principal {{domain}}",
      federatedDomains: "Groupe de domaines",
      federatedDomainsDescription: "Le domaine appartient \u00E0 un groupe",
      installationMode: "Mode d\'installation",
      domainStandard: "Domaine h\u00E9rit\u00E9",
      domainWithCloudCredits: "Domaine avec crédits cloud",
      domainIrisRequired: "1RIS requis",
      domainIrisEnabled: "1RIS activ\u00E9",
      domainIrisEnable: "Activer 1RIS",
      domainIrisEnableConfirm: "Voulez-vous configurer 1RIS comme ACTIV\u00C9 pour ce domaine ?",
      domainsWithCloudCredits: "Domaines avec crédits cloud",
      domainsLegacy: "Domaines h\u00E9rit\u00E9s"
    },
    credits: {
      marketplaces: "March\u00E9s",
      searchMarketplace: "Rechercher dans le march\u00E9...",
      marketplaceName: "Nom du march\u00E9",
      luckeyMarketplaceTitle: "March\u00E9s Luckey",
      noMarketplaceFoundTitle: "Aucun march\u00E9 trouv\u00E9",
      noMarketplaceFoundDescription: "La liste des march\u00E9s est vide. Si vous avez appliqu\u00E9 un filtre de recherche, essayez de le r\u00E9initialiser et r\u00E9essayez",
      newMarketplace: "Nouveau march\u00E9",
      marketplaceCreationConfirm: "Voulez-vous cr\u00E9er le nouveau march\u00E9 ?",
      createMarketplace: "Cr\u00E9er le march\u00E9",
      marketplaceDetails: "D\u00E9tails du march\u00E9",
      editMarketplaceCreationConfirm: "Vous \u00EAtes sur le point de modifier les donn\u00E9es du march\u00E9. \u00CAtes-vous s\u00FBr de vouloir continuer ?",
      editMarketplace: "Modifier le march\u00E9",
      searchCustomer: "Rechercher un utilisateur final...",
      customerName: "Nom de l\'utilisateur final",
      luckeyCustomersTitle: "Utilisateurs finaux Luckey",
      noCustomerFindTitle: "Aucun utilisateur final trouv\u00E9",
      noCustomerFindDescription: "La liste des utilisateurs finaux est vide. Si vous avez appliqu\u00E9 un filtre de recherche, essayez de le r\u00E9initialiser et r\u00E9essayez",
      newCustomer: "Nouvel utilisateur final",
      customerCreationConfirm: "Voulez-vous cr\u00E9er le nouvel utilisateur final ?",
      newUser: "Nouvel utilisateur",
      userCreationConfirm: "Voulez-vous cr\u00E9er le nouvel utilisateur ?",
      createCustomer: "Cr\u00E9er un utilisateur final",
      customerDetails: "D\u00E9tails de l\'utilisateur final",
      editCreationConfirm: "Vous \u00EAtes sur le point de modifier les donn\u00E9es de l\'utilisateur final. \u00CAtes-vous s\u00FBr de vouloir continuer ?",
      editCustomer: "Modifier l\'utilisateur final",
      editCreationUserConfirm: "Vous \u00EAtes sur le point de modifier les donn\u00E9es de l\'utilisateur. \u00CAtes-vous s\u00FBr de vouloir continuer ?",
      editUser: "Modifier l\'utilisateur",
      customerWallets: "Portefeuilles de {{customerName}}",
      newWallet: "Nouveau portefeuille",
      walletCreationConfirm: "Voulez-vous cr\u00E9er un nouveau portefeuille pour l\'utilisateur final s\u00E9lectionn\u00E9 ?",
      createWallet: "Cr\u00E9er le portefeuille",
      editWallet: "Modifier le portefeuille",
      walletEditConfirm: "Voulez-vous modifier le portefeuille s\u00E9lectionn\u00E9 ?",
      noWalletFoundTitle: "Aucun portefeuille trouv\u00E9",
      noWalletFoundDescription: "Aucun portefeuille trouv\u00E9 pour l\'utilisateur final s\u00E9lectionn\u00E9",
      walletDetails: "D\u00E9tails du portefeuille",
      rechargeWallet: "Recharger",
      walletRechargeHistory: "Historique des recharges",
      rechargeRowTitle: "Cr\u00E9dits modifi\u00E9s",
      rechargeCreditsDescription: "Le portefeuille a \u00E9t\u00E9 recharg\u00E9 avec {{credits}} cr\u00E9dits",
      rechargeCardDetails: "D\u00E9tails de la recharge",
      systemParameters: "Liste des prix",
      systemSubscriptions: "Abonnements",
      systemSubscriptionsDescription: "Voici les abonnements du syst\u00E8me. Cr\u00E9ez-en de nouveaux ou assignez-les \u00E0 un domaine existant",
      newSubscription: "Nouvel abonnement",
      editSubscription: "Modifier l\'abonnement",
      noSystemSubscriptionFoundTitle: "Aucun abonnement trouv\u00E9",
      noSystemSubscriptionFoundDescription: "Il n\u2019y a aucun abonnement du syst\u00E8me pouvant \u00EAtre assign\u00E9 \u00E0 un domaine",
      products: "Abonnements h\u00E9rit\u00E9s",
      rechargeCards: "Cartes de recharge",
      noSystemCardFoundTitle: "Aucune carte de recharge trouv\u00E9e",
      noSystemCardFoundDescription: "Il n\u2019y a aucune carte de recharge dans le syst\u00E8me. Cr\u00E9ez-en de nouvelles pour commencer \u00E0 recharger les portefeuilles des utilisateurs finaux",
      newCard: "Nouvelle carte de recharge",
      cardStatusUsed: "Utilis\u00E9e",
      rechargeCardCreditsDetails: "Cette carte de recharge vaut {{credits}} cr\u00E9dits",
      cardStatusUnused: "Non utilis\u00E9e",
      cardStatusInactive: "D\u00E9sactiv\u00E9e",
      enableCard: "Activer",
      disabledCardTooltip: "La carte de recharge sera activ\u00E9e d\u00E8s que vous recevrez la facture ISEO associ\u00E9e",
      newTierSubscription: "Nouvel abonnement principal",
      pluginAndService: "Add-on et services",
      newPluginSubscription: "Nouvel add-on ou service",
      tierSubscription: "Abonnement principal",
      walletSubscriptions: "Abonnements",
      noWalletSubscriptionFoundTitle: "Aucun abonnement trouv\u00E9",
      noWalletSubscriptionFoundDescription: "Ce portefeuille n\u2019a aucun abonnement associ\u00E9",
      newWalletSubscription: "Associer un nouvel abonnement",
      editWalletSubscription: "Modifier l\u2019abonnement",
      newWalletPluginSubscription: "Associer un nouvel add-on ou service",
      walletConsumption: "Consommation",
      noWalletUsageFoundTitle: "Aucune donn\u00E9e trouv\u00E9e",
      noWalletUsageFoundDescription: "Aucune donn\u00E9e de consommation pour ce portefeuille",
      ACTIVATION: "Co\u00FBt d\u2019activation du domaine ou de l\u2019add-on",
      DAILY_USAGE: "Consommation quotidienne",
      creditConsumptionType: "Type de consommation",
      consumptionCreditsDescription: "Le portefeuille a consomm\u00E9 {{credits}}",
      consumptionRowTitle: "Consommation du portefeuille",
      walletPlants: "Domaines",
      noWalletPlantFoundTitle: "Aucun domaine trouv\u00E9",
      noWalletPlantFoundDescription: "Aucun domaine associ\u00E9 \u00E0 ce portefeuille",
      newWalletPlant: "Nouveau domaine",
      deleteWalletSubscription: "Supprimer l\u2019abonnement",
      editCard: "Modifier la carte de recharge",
      rechargeDetails: "D\u00E9tails de la recharge",
      exportWalletsUsageCSV: "Exporter le CSV de l\u2019utilisation des cr\u00E9dits",
      exportWalletsCreditUsageCSV: "Exporter le cr\u00E9dit du portefeuille",
      PENDING: "En attente",
      ACTIVE: "Actif",
      READY_TO_ACTIVATE: "Pr\u00EAt \u00E0 activer",
      walletStatus: "Statut du portefeuille",
      exportManagersCSV: "Exporter les gestionnaires",
      needCreditsToCreatePlant: "Vous devez disposer de cr\u00E9dits dans ce portefeuille pour cr\u00E9er un nouveau domaine"
    },
    sales: {
      hardwareSalesInfo: "Infos de vente de mat\u00E9riel",
      domain: "Domaine",
      hardwareId: "ID du mat\u00E9riel",
      hardwareName: "Nom du mat\u00E9riel",
      hardwareSerialNumber: "Num\u00E9ro de s\u00E9rie du mat\u00E9riel",
      hardwareModel: "Mod\u00E8le du mat\u00E9riel",
      hardwareVendor: "Ligne de produit du mat\u00E9riel",
      hardwareType: "Type de mat\u00E9riel",
      sfdcId: "SFDC-ID",
      iseoSelloutOrderId: "ID de commande de vente ISEO",
      iseoSelloutOrderDate: "Date de commande de vente ISEO",
      iseoSelloutInvoiceId: "ID de facture de vente ISEO",
      iseoSelloutInvoiceDate: "Date de facture de vente ISEO",
      sofiaCustRef: "R\u00E9f. client (processus Sofia)",
      unreconciledHardware: "Mat\u00E9riel non rapproch\u00E9",
      hardwareWithSalesInfo: "Mat\u00E9riel avec infos de vente",
      actions: "Actions",
      addSalesInfo: "Ajouter des infos de vente",
      editSalesInfo: "Modifier les infos de vente",
      iseoSelloutOrder: "Commande de vente ISEO",
      iseoSelloutInvoice: "Facture de vente ISEO",
      selectedSalesInfoError: "Attention ! La s\u00E9lection n\u2019est pas homog\u00E8ne. \u00CAtes-vous s\u00FBr de vouloir continuer ?"
    },
    errors: {
      deleteWalletSubscriptionError: "Une erreur est survenue lors de la modification de l\u2019abonnement. Veuillez r\u00E9essayer ou contacter notre \u00E9quipe de support",
      modifyVarOpportunityError: "Une erreur est survenue lors de la modification du devis. Veuillez r\u00E9essayer",
      error: "Erreur",
      loginFailed: "La session a expir\u00E9. Veuillez vous reconnecter",
      loginGoogleFailedTitle: "\u00C9chec de la connexion avec Google",
      loginGoogleFailed: "Assurez-vous d\u2019\u00EAtre connect\u00E9 \u00E0 votre compte Google ou essayez d\u2019ouvrir cette page dans une session Google Chrome avec votre compte GSuite de Sofia",
      loginJagoGoogleFailedTitle: "\u00C9chec de la connexion Jago Google",
      loginJagoGoogleFailed: "Assurez-vous d\u2019avoir un utilisateur dans Jago correspondant \u00E0 votre compte GSuite de Sofia et r\u00E9essayez",
      getDomainDetailsError: "Une erreur est survenue lors de la r\u00E9cup\u00E9ration de la configuration des domaines. Veuillez r\u00E9essayer ou contacter le support de Sofia",
      updateDomainConfigError: "Une erreur est survenue lors de l\u2019enregistrement de la configuration du site. Veuillez r\u00E9essayer ou contacter le support de Sofia",
      updatePlantThemeError: "Une erreur est survenue lors de l\u2019enregistrement du th\u00E8me du site. Veuillez r\u00E9essayer ou contacter le support de Sofia",
      impersonateDomainUserError: "Une erreur est survenue lors de l\u2019impersonation de l\u2019utilisateur. Veuillez v\u00E9rifier que l\u2019utilisateur est actif et r\u00E9essayer ou contacter le support de Sofia",
      createDomainError: "Une erreur est survenue lors de la cr\u00E9ation du nouveau domaine",
      addDomainPluginError: "Une erreur est survenue lors de l\u2019extension du domaine",
      addUserDomainError: "Impossible de cr\u00E9er l\u2019utilisateur. V\u00E9rifiez si l\u2019utilisateur existe d\u00E9j\u00E0 dans le domaine ou si le domaine dispose d\u2019un abonnement valide",
      errorSaveAdditionalInfo: "Impossible de mettre \u00E0 jour les informations sur le domaine s\u00E9lectionn\u00E9",
      createCustomerError: "Impossible de cr\u00E9er l\u2019utilisateur final. V\u00E9rifiez si l\u2019utilisateur existe d\u00E9j\u00E0 et r\u00E9essayez",
      createUserError: "Impossible de cr\u00E9er l\u2019utilisateur. V\u00E9rifiez si l\u2019utilisateur existe d\u00E9j\u00E0 et r\u00E9essayez",
      createVarUserError: "Impossible de cr\u00E9er l\u2019utilisateur VAR. V\u00E9rifiez si l\u2019utilisateur existe d\u00E9j\u00E0 et r\u00E9essayez",
      createPlantThemeError: "Impossible de cr\u00E9er le th\u00E8me du site. V\u00E9rifiez les donn\u00E9es et r\u00E9essayez",
      updateVarError: "Impossible de mettre \u00E0 jour. V\u00E9rifiez les donn\u00E9es et r\u00E9essayez",
      getCustomerDetailsError: "Impossible d\u2019obtenir les d\u00E9tails de l\u2019utilisateur final. Veuillez r\u00E9essayer",
      getVarDetailsError: "Impossible d\u2019obtenir les d\u00E9tails du VAR. Veuillez r\u00E9essayer",
      getPlantThemeDetailsError: "Impossible d\u2019obtenir les d\u00E9tails du th\u00E8me du site. Veuillez r\u00E9essayer",
      getProductInfoPostDetailsError: "Impossible d\u2019obtenir l\u2019article. Veuillez r\u00E9essayer",
      required: "Requis",
      deleteDomainRequestError: "Impossible d\u2019envoyer la demande. Veuillez r\u00E9essayer",
      deleteWalletRequestError: "Impossible d\u2019envoyer la demande. Veuillez r\u00E9essayer",
      deleteCustomerRequestError: "Impossible d\u2019envoyer la demande. Veuillez r\u00E9essayer",
      enableIrisDomainRequestError: "Impossible d\u2019envoyer la demande. Veuillez r\u00E9essayer",
      createCustomerWalletError: "Impossible de cr\u00E9er le nouveau portefeuille. Veuillez r\u00E9essayer",
      editCustomerWalletError: "Impossible de modifier le nouveau portefeuille. Veuillez r\u00E9essayer",
      getWalletDetailsError: "Impossible de r\u00E9cup\u00E9rer les d\u00E9tails du portefeuille. Veuillez r\u00E9essayer",
      openSystemParametersError: "Impossible d\u2019ouvrir les param\u00E8tres du syst\u00E8me. Veuillez r\u00E9essayer",
      createSubscriptionError: "Impossible de cr\u00E9er l\u2019abonnement. Veuillez r\u00E9essayer",
      createCardError: "Une erreur est survenue lors de la cr\u00E9ation de la carte de recharge. Veuillez r\u00E9essayer",
      createVarOpportunityError: "Une erreur est survenue lors de la cr\u00E9ation. Veuillez r\u00E9essayer",
      editVarOpportunityError: "Une erreur est survenue lors de l\u2019op\u00E9ration. Veuillez r\u00E9essayer",
      rechargeWalletError: "Une erreur est survenue lors de la recharge du portefeuille. Veuillez r\u00E9essayer",
      deleteCardError: "Une erreur est survenue lors de la suppression de la carte de recharge. Veuillez r\u00E9essayer",
      enableCardError: "Une erreur est survenue lors de la mise \u00E0 jour de la carte de recharge. Veuillez r\u00E9essayer",
      deletePlantThemeError: "Une erreur est survenue lors de la suppression. Veuillez r\u00E9essayer",
      deleteVarUserError: "Une erreur est survenue lors de la suppression. Veuillez r\u00E9essayer",
      deleteVarUserErrorOpportunities: "Cet utilisateur est le propri\u00E9taire d\u2019au moins un devis, il ne peut donc pas \u00EAtre supprim\u00E9. Veuillez r\u00E9assigner les devis associ\u00E9s \u00E0 cet utilisateur et r\u00E9essayer.",
      deleteVarOpportunityError: "Une erreur est survenue lors de la suppression. Veuillez r\u00E9essayer",
      rejectVarOpportunityError: "Une erreur est survenue lors de la mise \u00E0 jour du devis. Veuillez r\u00E9essayer",
      approveVarOpportunityError: "Une erreur est survenue lors de la mise \u00E0 jour du devis. Veuillez r\u00E9essayer",
      syncsfdcidVarOpportunityError: "Une erreur est survenue lors de la mise \u00E0 jour du devis. Veuillez r\u00E9essayer",
      upgradeVarOpportunityError: "Une erreur est survenue lors de la mise \u00E0 jour du devis. Veuillez r\u00E9essayer",
      winVarOpportunityError: "Une erreur est survenue lors de la mise \u00E0 jour du devis. Veuillez r\u00E9essayer",
      loseVarOpportunityError: "Une erreur est survenue lors de la mise \u00E0 jour du devis. Veuillez r\u00E9essayer",
      deleteUserError: "Une erreur est survenue lors de la suppression. Veuillez r\u00E9essayer",
      createWalletSubscriptionError: "Une erreur est survenue lors de l\u2019association de l\u2019abonnement au portefeuille. Veuillez r\u00E9essayer",
      createWalletPlantError: "Une erreur est survenue lors de la cr\u00E9ation du domaine pour le portefeuille. Veuillez r\u00E9essayer",
      walletActionErrorNotEnoughCreditsTitle: "Cr\u00E9dits insuffisants",
      walletActionErrorNotEnoughCredits: "Le portefeuille ne dispose pas de cr\u00E9dits suffisants pour effectuer l\u2019op\u00E9ration. V\u00E9rifiez l\u2019op\u00E9ration et r\u00E9essayez",
      warningMinCreditsCard: "Attention ! Nombre minimum de cr\u00E9dits requis = {{credits}}",
      loginJagoOTPEmailFailedTitle: "Erreur d\u2019envoi de l\u2019e-mail",
      loginJagoOTPEmailFailed: "Une erreur est survenue lors de l\u2019envoi de l\u2019e-mail, r\u00E9essayez dans 5 minutes",
      loginJagoOTPFailedTitle: "Erreur de v\u00E9rification du code",
      loginJagoOTPFailed: "La v\u00E9rification du code d\u2019acc\u00E8s a \u00E9chou\u00E9. Veuillez v\u00E9rifier le code ou r\u00E9essayer dans 5 minutes.",
      addonV364missing: "Le complément V364 n\u2019a pas pu \u00EAtre trouv\u00E9 dans le syst\u00E8me, veuillez signaler cette erreur pour obtenir de l\u2019aide."
    },
    gateways: {
      createdBy: "Cr\u00E9\u00E9 par",
      noGatewayFoundTitle: "Aucun HyperGate trouv\u00E9",
      noGatewayFoundDescription: "La liste des HyperGates est vide. Si vous avez appliqu\u00E9 un filtre de recherche, essayez de le modifier et r\u00E9essayez",
      gatewayName: "Nom de HyperGate",
      luckeyGatewaysTitle: "HyperGates du syst\u00E8me",
      lastConnectionDate: "Derni\u00E8re connexion : {{ date }}",
      gatewaysDevices: "Appareils",
      gatewaysArtifacts: "Artefacts",
      gatewaysDeployments: "D\u00E9ploiements",
      HYPERGATE_PRO: "HyperGate Pro",
      HYPERGATE_ISEO: "HyperGate Lite",
      DONE: "Termin\u00E9",
      PENDING: "D\u00E9ploiement en attente",
      IN_PROGRESS: "D\u00E9ploiement en cours",
      ERROR: "Erreur",
      deploymentDetail: "D\u00E9tail du d\u00E9ploiement",
      noDeploymentDetailedFound: "Aucun HyperGate trouv\u00E9 pour le d\u00E9ploiement",
      noDeploymentDetailedFoundDescription: "La liste des HyperGates pour ce d\u00E9ploiement est vide",
      deviceId: "ID de l\'appareil",
      name: "Nom",
      gatewayVersion: "Version",
      iseoSdkVersion: "ISEO SDK",
      kernelVersion: "Version du noyau",
      serialNumber: "Num\u00E9ro de s\u00E9rie",
      noGatewaysFound: "Aucun HyperGate trouv\u00E9",
      noGatewaysFoundDescription: "La liste des HyperGates est vide. Essayez de modifier vos filtres de recherche et r\u00E9essayez",
      noDeploymentFound: "Aucun d\u00E9ploiement trouv\u00E9",
      noArtifactFound: "Aucun artefact trouv\u00E9",
      noDeploymentFoundDescription: "La liste des d\u00E9ploiements pour ce d\u00E9ploiement est vide. Si vous avez appliqu\u00E9 un filtre de recherche, essayez de le modifier et r\u00E9essayez",
      noArtifactFoundDescription: "La liste des artefacts pour ce d\u00E9ploiement est vide. Si vous avez appliqu\u00E9 un filtre de recherche, essayez de le modifier et r\u00E9essayez"
    },
    forms: {
      otp: 'OTP',
      notVisible: "Non visible",
      visible: "Visible",
      exportAllUsers: "Exporter les utilisateurs",
      montlyBurnRate: "{{credits}}/mois",
      nextRechargeForecast: "Prochaine recharge pr\u00E9vue avec la consommation actuelle ~{{date}}",
      lastUpdate: "Derni\u00E8re mise \u00E0 jour {{time}}",
      rowsPerPage: "Lignes par page",
      rolePermissions: "Permissions de r\u00F4le",
      permissionsDetails: "D\u00E9tails des permissions",
      clusterAndIseoCompany: "Cluster et entreprise ISEO",
      createCardSuccess: "Carte de recharge cr\u00E9\u00E9e avec succ\u00E8s",
      cardDetails: "D\u00E9tails de la carte",
      status: "Statut",
      customerInfo: "Infos client",
      details: "D\u00E9tails",
      costs: "Co\u00FBts",
      hostName: "Nom d\'h\u00F4te",
      domainType: "Type",
      user: "Utilisateur",
      logoutConfirmation: "Voulez-vous vraiment vous d\u00E9connecter ?",
      send: "Envoyer",
      askQuestion: "Nous contacter",
      ticketOpenSuccessfully: "Votre question a \u00E9t\u00E9 soumise \u00E0 notre \u00E9quipe ! Un de nos collaborateurs vous contactera sous peu.",
      ticketOpenError: "Une erreur est survenue lors de l\'envoi de la demande. Veuillez r\u00E9essayer",
      writeUsAQuestionDescription: "Vous avez une question ? N\'h\u00E9sitez pas \u00E0 nous \u00E9crire, et un de nos sp\u00E9cialistes produit sera pr\u00EAt \u00E0 vous aider pour toute demande.",
      deviceType: "Type d\'appareil",
      createNewMarketplace: "Nouveau march\u00E9",
      createNewCustomer: "Nouveau",
      firstName: "Pr\u00E9nom",
      lastName: "Nom",
      email: "E-mail",
      contactEmail: "E-mail de contact",
      open: "Ouvrir",
      add: "Ajouter",
      actions: "Actions",
      search: "Rechercher",
      remove: "Supprimer",
      error: "Erreur",
      copy: "Copier",
      cancel: "Annuler",
      confirm: "Confirmer",
      warning: "Attention",
      reset: "R\u00E9initialiser",
      close: "Fermer",
      save: "Enregistrer",
      delete: "Supprimer",
      deleteRequest: "Envoyer une demande de suppression",
      deleteAdminOnly: "Supprimer (admin)",
      export: "Exporter",
      spinnerLoading: "Chargement...",
      createNewDomain: "Cr\u00E9er un nouveau domaine",
      walletName: "Nom du portefeuille",
      domainName: "Nom de domaine",
      domainNamePreview: "Aper\u00E7u",
      domainFormTitle: "Cr\u00E9er un nouveau domaine Luckey",
      invalidDomainName: "Nom de domaine invalide. Pas d\'espaces, uniquement des caract\u00E8res minuscules, chiffres et tirets autoris\u00E9s",
      selectProduct: "S\u00E9lectionnez un type d\'abonnement",
      name: "Nom",
      lastname: "Nom de famille",
      createNewUser: "Cr\u00E9er un nouvel administrateur",
      selectRole: "S\u00E9lectionnez le r\u00F4le de l\'utilisateur",
      createUserForDomain: "Cr\u00E9er un nouvel utilisateur pour le domaine s\u00E9lectionn\u00E9",
      newUser: "Nouvel utilisateur",
      password: "Mot de passe",
      letUserChoosePassword: "Permettre \u00E0 l\'utilisateur de choisir son mot de passe : envoyer un lien de configuration par e-mail",
      domainCreateSuccess: "Domaine cr\u00E9\u00E9 avec succ\u00E8s",
      domainCreateUserAfterCreation: "Voulez-vous cr\u00E9er un utilisateur pour le nouveau domaine ?",
      domainCreationConfirm: "Voulez-vous cr\u00E9er le nouveau domaine ?",
      createDomain: "Cr\u00E9er le domaine",
      username: "Nom d\'utilisateur",
      domainExtendsConfirm: "Voulez-vous \u00E9tendre le domaine avec le produit s\u00E9lectionn\u00E9 ?",
      createNewInstaller: "Cr\u00E9er un nouvel installateur",
      createInstallerForDomain: "Cr\u00E9er un nouvel utilisateur installateur pour le domaine",
      deleteDomain: "Demander la suppression du domaine",
      active: "Actif",
      disabled: "D\u00E9sactiv\u00E9",
      minutes: "{{minutes}} minutes",
      selectSessionDurationPlaceholder: "Dur\u00E9e de la session",
      impersonateUserDescription: "Avant d\'impersonner l\'utilisateur dans le syst\u00E8me, nous vous informons que :",
      impersonateUserDescriptionOne: "Le d\u00E9but de la session et sa dur\u00E9e appara\u00EEtront dans les journaux du domaine",
      impersonateUserDescriptionTwo: "Chaque action effectu\u00E9e sera enregistr\u00E9e et appara\u00EEtront dans les journaux du syst\u00E8me",
      impersonateUserDescriptionThree: "Toute action effectu\u00E9e sur l\'implant aura un effet imm\u00E9diat et, dans certains cas, irr\u00E9versible",
      selectSessionDuration: "S\u00E9lectionnez la dur\u00E9e de la session de support",
      impersonateReadOnly: "Mode lecture seule",
      impersonateReadOnlyDescription: "Acc\u00E9dez au syst\u00E8me en mode lecture seule, vous pourrez consulter les donn\u00E9es sans les modifier",
      impersonateReadOnlyWarning: "Attention : La session de support et sa dur\u00E9e resteront visibles dans les journaux du syst\u00E8me",
      installationModeActive: "Actif",
      installationModeDisabled: "D\u00E9sactiv\u00E9",
      customerFormTitle: "Utilisateur final Luckey",
      varFormTitle: "Revendeur \u00E0 valeur ajout\u00E9e (VAR)",
      varFormAdminSection: "Section administration",
      varFormTitleVar: "Infos de l\u2019entreprise",
      plantThemeFormTitle: "Th\u00E8me de site Luckey",
      customerName: "Nom de l\'utilisateur final",
      invalidCustomerName: "Nom de l\'utilisateur final invalide",
      createNewWallet: "Nouveau portefeuille",
      whatsNewTitle: "Les nouveautés de Luckey !",
      whatsNewSubtitle: "D\u00E9couvrez les derni\u00E8res actualit\u00E9s sur Luckey et Sofia, partie d\'ISEO",
      notes: "Notes suppl\u00E9mentaires",
      cardCode: "Code de recharge",
      recharge: "Recharger",
      rechargeWalletFormDescription: "Rechargez les cr\u00E9dits de ce portefeuille en entrant le code de recharge. La recharge sera effective imm\u00E9diatement",
      subscriptionName: "Nom de l\'abonnement",
      subscriptionFixedPrice: "Co\u00FBt d\'activation (cr\u00E9dits)",
      subscriptionBurnRate: "Co\u00FBt r\u00E9current (cr\u00E9dits)",
      subscriptionVisible: "Visible sur le march\u00E9",
      createNewSubscriptionDescription: "Cr\u00E9ez un nouvel abonnement dans le syst\u00E8me. L\'abonnement sera disponible pour tous les domaines",
      subscriptionLogicDescription: "Indiquez quels \u00E9l\u00E9ments du syst\u00E8me seront comptabilis\u00E9s pour d\u00E9terminer les contraintes du syst\u00E8me",
      FIXED: "Ne pas imposer de contraintes sur les \u00E9l\u00E9ments",
      COUNT_USERS_LOCKS: "Limiter le nombre d\'utilisateurs et de cadenas du domaine",
      COUNT_LOCKS: "Limiter uniquement le nombre de cadenas du domaine",
      COUNT_ELEMENTS: "Limiter la somme des cadenas et des utilisateurs du domaine",
      maxLocks: "Nombre maximum de cadenas intelligents",
      maxUsers: "Nombre maximum d\'utilisateurs",
      maxElements: "Nombre maximum d\'\u00E9l\u00E9ments (utilisateurs+cadenas)",
      subscriptionLogic: "Logique de limitation des \u00E9l\u00E9ments",
      selectLogic: "S\u00E9lectionner",
      subscriptionMaxLocksDescription: "Ins\u00E9rez le nombre maximum de cadenas pouvant \u00EAtre install\u00E9s avec cet abonnement",
      subscriptionMaxUsersDescription: "Ins\u00E9rez le nombre maximum d\'utilisateurs pouvant \u00EAtre activ\u00E9s avec cet abonnement",
      subscriptionMaxElementsDescription: "Ins\u00E9rez le nombre maximum d\'\u00E9l\u00E9ments avec cet abonnement",
      subscriptionResource: "Ressource",
      cardQuantity: "Nombre de cr\u00E9dits",
      cardQuantityDescription: "Indique le nombre de cr\u00E9dits qui seront recharg\u00E9s lors de l\'utilisation de la carte de recharge",
      cardVar: "Assigner la carte de recharge au VAR",
      cardWallet: "Assigner la carte de recharge \u00E0 un portefeuille sp\u00E9cifique",
      cardNotes: "Notes suppl\u00E9mentaires",
      createCardConfirm: "\u00CAtes-vous s\u00FBr de vouloir cr\u00E9er la carte de recharge ?",
      createVarOpportunityConfirm: "\u00CAtes-vous s\u00FBr de vouloir cr\u00E9er ce nouveau devis ?",
      createSubscrptionConfirm: "\u00CAtes-vous s\u00FBr de vouloir cr\u00E9er le nouvel abonnement ?",
      rechargeWalletConfirm: "Vous \u00EAtes sur le point de recharger le portefeuille de cet utilisateur final avec de nouveaux cr\u00E9dits, souhaitez-vous continuer ? L\u2019op\u00E9ration est irr\u00E9versible",
      createdAt: "Date de cr\u00E9ation",
      copiedSuccess: "Copi\u00E9 !",
      logout: "D\u00E9connexion",
      deleteCardConfirm: "\u00CAtes-vous s\u00FBr de vouloir supprimer cette carte de recharge ? L\u2019op\u00E9ration est irr\u00E9versible",
      enableCardConfirm: "\u00CAtes-vous s\u00FBr de vouloir activer cette carte de recharge ? Une fois activ\u00E9e, cette carte pourra \u00EAtre utilis\u00E9e. L\u2019op\u00E9ration est irr\u00E9versible",
      subscriptionTierSelectionTitle: "Abonnement principal",
      subscriptionTierSelectionDescription: "En s\u00E9lectionnant l\'abonnement principal, le nouvel add-on héritera automatiquement de la logique de comptage de l\'abonnement principal",
      selectTier: "S\u00E9lectionner l\'abonnement",
      createPluginSubscriptionConfirm: "\u00CAtes-vous s\u00FBr de vouloir cr\u00E9er ce nouvel abonnement d\'add-on\/service ?",
      createWalletSubscriptionConfirm: "\u00CAtes-vous s\u00FBr de vouloir associer cet abonnement au portefeuille s\u00E9lectionn\u00E9 ? L\'abonnement sera activ\u00E9 pour tous les sites g\u00E9r\u00E9s via ce portefeuille",
      deleteWalletSubscriptionConfirm: "\u00CAtes-vous s\u00FBr de vouloir supprimer cet abonnement du portefeuille s\u00E9lectionn\u00E9 ? L\'abonnement sera supprim\u00E9 de tous les sites g\u00E9r\u00E9s via ce portefeuille",
      editSubscriptionConfirm: "\u00CAtes-vous s\u00FBr de vouloir modifier l\'abonnement ?",
      walletSelectSubscriptionTitle: "Abonnement \u00E0 activer",
      walletSelectSubscriptionDescription: "S\u00E9lectionnez l\'abonnement \u00E0 activer sur ce portefeuille. N\u2019oubliez pas que l\'abonnement sera \u00E9galement activ\u00E9 sur tous les syst\u00E8mes g\u00E9r\u00E9s via ce portefeuille",
      createWalletPlantConfirm: "\u00CAtes-vous s\u00FBr de vouloir cr\u00E9er le nouveau domaine pour le portefeuille s\u00E9lectionn\u00E9 ?",
      enableEmailForPlant: "Activer le service e-mail",
      enableEmailForPlantDescription: "Activez l\u2019envoi d\u2019e-mails pour ce syst\u00E8me (e-mail d\u2019activation, notifications, etc.)",
      enableIrisRequiredForPlant: "Exiger le service 1ris",
      enableIrisRequiredForPlantDescription: "Si s\u00E9lectionn\u00E9, le domaine sera pr\u00EAt\u00E9 \u00E0 inclure le service 1ris",
      cardSubscriptionsLinked: "Abonnements associ\u00E9s",
      cardSubscriptionsLinkedDescription: "Associez plusieurs add-ons ou services \u00E0 cette carte de recharge pour les activer une fois la carte utilis\u00E9e",
      mainTierDescription: "Associez un abonnement principal \u00E0 cette carte de recharge pour l\u2019activer une fois utilis\u00E9e",
      cardMarketplacesLinked: "March\u00E9 pour l\u2019association des licences",
      cardMarketplacesLinkedDescription: "S\u00E9lectionnez un march\u00E9 pour choisir les abonnements \u00E0 associer \u00E0 cette carte de recharge",
      sku: "SKU",
      customerData: "Donn\u00E9es de l\'utilisateur final",
      customerVat: "TVA",
      customerFiscalCode: "Code fiscal",
      customerCountry: "Pays",
      customerAddress: "Adresse",
      customerCity: "Ville",
      customerEmail: "E-mail",
      customerPhone: "T\u00E9l\u00E9phone",
      customerCrmId: "ID COMPTE SFDC",
      customerMarketplace: "March\u00E9",
      customerCertifiedEmail: "PEC (e-mail certifi\u00E9)",
      customerCluster: "Cluster",
      customerVar: "VAR",
      customerCompany: "Entreprise ISEO",
      customerPostalCode: "Code postal",
      customerPrincipalSubDivision: "Province, \u00C9tat, d\u00E9partement, etc.",
      iseoSalesOrderReference: "Commande de vente ISEO (ISEO \u2192 Client)",
      iseoSalesOrderDate: "Date de commande de vente ISEO",
      iseoPurchaseOrderReference: "Commande d\u2019achat ISEO (ISEO \u2192 Sofia)",
      iseoPurchaseOrderDate: "Date de commande d\u2019achat ISEO",
      sofiaSalesOrderReference: "Commande de vente Sofia (Sofia \u2192 ISEO)",
      sofiaSalesOrderDate: "Date de commande de vente Sofia",
      pickDate: "Choisir une date",
      crmSource: "Source d\u2019opportunit\u00E9 CRM",
      crmId: "Identifiant unique d\u2019opportunit\u00E9 CRM",
      sofiaProjectId: "Identifiant de r\u00E9f\u00E9rence du projet Sofia",
      crmLink: "URL vers l\u2019opportunit\u00E9 CRM",
      kamReference: "R\u00E9f\u00E9rence KAM",
      edit: "Modifier",
      editCardConfirm: "\u00CAtes-vous s\u00FBr de vouloir modifier la carte de recharge ?",
      editVarOpportunityConfirm: "\u00CAtes-vous s\u00FBr de vouloir modifier ce devis ?",
      insertEmailOTPLoginForm: "Entrez l\u2019e-mail de votre compte pour recevoir le code OTP et acc\u00E9der \u00E0 la plateforme",
      insertEmailOTPLoginFormDisclaimer: "Si l\u2019e-mail est autoris\u00E9, vous recevrez un code OTP pour acc\u00E9der",
      insertOTPOTPLoginForm: "Entrez le code OTP re\u00E7u sur l\u2019e-mail de votre compte",
      insertOTPOTPLoginFormDisclaimer: "Si vous n\u2019avez pas re\u00E7u le code apr\u00E8s 5 minutes, r\u00E9essayez",
      pickDateTo: "Choisissez la date jusqu\u2019\u00E0 :",
      pickDateFrom: "Choisissez la date \u00E0 partir de :",
      AUTOMATIC: "Activation automatique",
      MANUAL: "Activation manuelle",
      SEMI_AUTOMATIC: "Activation semi-automatique",
      AUTOMATIC_Description: "L\u2019extension peut \u00EAtre activ\u00E9e de mani\u00E8re ind\u00E9pendante depuis la boutique par l\u2019utilisateur. Les cr\u00E9dits d\u2019activation seront d\u00E9duits imm\u00E9diatement",
      MANUAL_Description: "Extension qui ne peut \u00EAtre activ\u00E9e qu\u2019apr\u00E8s une demande explicite de l\u2019utilisateur final. Les cr\u00E9dits seront d\u00E9duits manuellement apr\u00E8s approbation de la demande",
      SEMI_AUTOMATIC_Description: "Extension qui ne peut \u00EAtre activ\u00E9e qu\u2019apr\u00E8s une demande explicite de l\u2019utilisateur final. Les cr\u00E9dits d\u2019activation seront d\u00E9duits au moment de la demande",
      requestToQuote: "Devis demand\u00E9",
      requestToQuoteDescription: "Indiquez si l\u2019extension est soumise \u00E0 devis plut\u00F4t qu\u2019\u00E0 l\u2019utilisation directe des cr\u00E9dits",
      subscriptionCanBeDeactivated: "Peut \u00EAtre d\u00E9sactiv\u00E9 depuis le gestionnaire",
      subscriptionCanBeDeactivatedDescription: "Indiquez si le gestionnaire peut d\u00E9sactiver l\u2019abonnement depuis le Luckey Store",
      selectSegmentType: "S\u00E9lectionnez un segment",
      sofiaInvoiceNumber: "Num\u00E9ro de facture Sofia",
      sofiaInvoiceAmount: "Montant de la facture Sofia",
      sofiaInvoiceYear: "Ann\u00E9e de la facture Sofia",
      iseoInvoiceNumber: "Num\u00E9ro de facture ISEO",
      iseoInvoiceAmount: "Montant de la facture ISEO",
      iseoInvoiceYear: "Ann\u00E9e de la facture ISEO",
      freeOfCharge: "Gratuit",
      freeOfChargeReason: "Raison",
      currency: "Devise",
      sofiaInvoiceDate: "Date de facture",
      iseoInvoiceDate: "Date de facture",
      sofiaInvoiceRecipient: "Destinataire de la facture",
      iseoInvoiceRecipient: "Destinataire de la facture",
      serviceSupplementaryInvoice: "Facture suppl\u00E9mentaire de service",
      serviceSupplementaryInvoiceNumber: "Num\u00E9ro de facture",
      serviceSupplementaryInvoiceAmount: "Montant de la facture",
      serviceSupplementaryInvoiceDate: "Date de facture",
      serviceSupplementaryInvoiceRecipient: "Destinataire de la facture",
      iseoInvoiceIssuer: "\u00C9metteur de la facture",
      backdropClosing: "Voulez-vous vraiment fermer le formulaire sans enregistrer ?",
      roles: "R\u00F4les",
      selectBotType: "S\u00E9lectionner le type d'assistant",
      helperBot: "Assistant produit",
      commercialBot: "Assistant commercial",
      talkWithUs: 'Parlez-nous',
      noBotTypeSelected: 'Aucun assistant sélectionné',
    },
    activations: {
      getInfo: "(Obtenir plus d\u2019informations sur le processus)",
      createNewCustomer: "Cr\u00E9er un utilisateur final",
      createNewWallet: "Cr\u00E9er un portefeuille",
      createNewScratchcard: "Cr\u00E9er une carte de recharge",
      yearsString: "ann\u00E9es",
      monthsString: "mois",
      daysString: "jours",
      addNewActivationButton: "Ajouter une activation syst\u00E8me",
      home: "Accueil OD",
      activationRequests: "Activations syst\u00E8me",
      rechargeRequests: "Recharges de portefeuille",
      walletStatus: "Statut des commandes",
      noActivationsFoundTitle: "Aucune activation syst\u00E8me trouv\u00E9e",
      noActivationsFoundDescription: "Aucune activation syst\u00E8me dans le syst\u00E8me. Cr\u00E9ez-en de nouvelles pour commencer",
      selloutOrder: "Commande de vente",
      intragroupOrder: "Commande intragroupe",
      custref: "R\u00E9f. client",
      rejectedReason: "Raison du rejet",
      changeStatusToUnderReview: "Envoyer en r\u00E9vision",
      changeStatusToInvoiced: "Envoyer en factur\u00E9",
      changeStatusToCreated: "Envoyer en cr\u00E9\u00E9",
      changeStatusToRejected: "Rejeter la demande",
      changeStatusToProcessing: "Accepter la demande",
      changeStatusAdmin: "D\u00E9finir le statut (admin)",
      changeCustRef: "D\u00E9finir la r\u00E9f. client",
      search: "Rechercher...",
      resetFilters: "R\u00E9initialiser les filtres",
      statusSelection: "Tout statut",
      clusterSelection: "Tout cluster",
      statusDRAFT: "Brouillon",
      statusUNDER_REVIEW: "En r\u00E9vision",
      statusREJECTED: "Rejet\u00E9",
      statusPROCESSING: "En cours",
      statusCREATED: "Cr\u00E9\u00E9",
      statusINVOICED: "Factur\u00E9",
      statusACTIVE: "Actif",
      statusDELETED: "Supprim\u00E9",
      promote: "Supprimer",
      saveDraft: "Enregistrer en brouillon",
      customMarketplaceWarning: "L'utilisateur final n'est pas associ\u00E9 au march\u00E9 par d\u00E9faut. Vous devrez sp\u00E9cifier manuellement l'abonnement de base demand\u00E9 et les add-ons dans la section 'Notes'",
      subscriptionNotFoundError: "Abonnement non trouv\u00E9",
      deleteActivationConfirm: "\u00CAtes-vous s\u00FBr de vouloir supprimer cette activation syst\u00E8me ? L'op\u00E9ration est irr\u00E9versible",
      deleteActivationError: "Une erreur est survenue lors de la suppression de l'activation syst\u00E8me. Veuillez r\u00E9essayer",
      promoteActivationConfirm: "\u00CAtes-vous s\u00FBr de vouloir envoyer cette activation syst\u00E8me en r\u00E9vision ? La demande d'activation syst\u00E8me ne peut \u00EAtre modifi\u00E9e pendant la r\u00E9vision.",
      promoteActivationError: "Une erreur est survenue lors de l'envoi en r\u00E9vision de l'activation syst\u00E8me. Veuillez r\u00E9essayer",
      createdActivationConfirm: "\u00CAtes-vous s\u00FBr de vouloir passer cette activation syst\u00E8me au statut CR\u00C9\u00C9 ?",
      createdActivationError: "Une erreur est survenue lors de la mise \u00E0 jour du statut de l'activation syst\u00E8me. Veuillez r\u00E9essayer",
      invoicedActivationConfirm: "\u00CAtes-vous s\u00FBr de vouloir marquer cette activation syst\u00E8me comme factur\u00E9e ?",
      invoicedActivationError: "Une erreur est survenue lors de la mise \u00E0 jour du statut de l'activation syst\u00E8me. Veuillez r\u00E9essayer",
      editStatusActivationConfirm: "\u00CAtes-vous s\u00FBr de vouloir modifier le statut de cette activation syst\u00E8me ? L'op\u00E9ration est irr\u00E9versible",
      editStatusActivationError: "Une erreur est survenue lors de la mise \u00E0 jour du statut de l'activation syst\u00E8me. Veuillez r\u00E9essayer",
      rejectActivationConfirm: "Sp\u00E9cifiez la raison du rejet de cette demande.",
      rejectActivationError: "Une erreur est survenue lors du rejet de l'activation syst\u00E8me. Veuillez r\u00E9essayer",
      processingActivationConfirm: "\u00CAtes-vous s\u00FBr de vouloir accepter cette activation syst\u00E8me ?",
      processingActivationError: "Une erreur est survenue lors de l'acceptation de l'activation syst\u00E8me. Veuillez r\u00E9essayer",
      newFileName: "Sp\u00E9cifiez le nom du fichier",
      newFileExtensionError: "Le fichier a une extension non prise en charge",
      newFileDuplicateNameError: "Ce nom a d\u00E9j\u00E0 \u00E9t\u00E9 utilis\u00E9 pour un autre fichier. Changez le nom et r\u00E9essayez.",
      editCustrefConfirm: "Sp\u00E9cifiez la r\u00E9f. client pour cette demande.",
      editCustrefError: "Une erreur est survenue lors de la mise \u00E0 jour de la r\u00E9f. client, veuillez r\u00E9essayer",
      editActivation: "Modifier l'activation syst\u00E8me",
      newActivation: "Ajouter une activation syst\u00E8me",
      viewActivation: "Voir l'activation syst\u00E8me",
      newActivationError: "Une erreur est survenue lors de la cr\u00E9ation de cette activation syst\u00E8me. Veuillez r\u00E9essayer",
      editActivationError: "Une erreur est survenue lors de la mise \u00E0 jour de cette activation syst\u00E8me. Veuillez r\u00E9essayer",
      customerSearch: "Rechercher parmi les utilisateurs finaux existants",
      customerSearchEnterName: "Rechercher par nom...",
      customerSearchNoMatch: "Aucun utilisateur final trouv\u00E9...",
      customerProceedWithSelected: "Utiliser l'utilisateur final s\u00E9lectionn\u00E9",
      customerProceedWithNew: "Cr\u00E9er un nouvel utilisateur final",
      customerConfirmData: "Confirmer les donn\u00E9es de l'utilisateur final",
      customerData: "Donn\u00E9es du client",
      varData: "Donn\u00E9es du VAR",
      selectCustomerOrVar: "S\u00E9lectionnez la cible pour cette demande (utilisateur final ou VAR)",
      varSfdcId: "ID COMPTE SFDC",
      varName: "Nom",
      varSearch: "Rechercher parmi les VAR existants",
      varSearchEnterName: "Rechercher par nom...",
      varSearchNoMatch: "Aucun VAR trouv\u00E9...",
      varCustomerSearch: "Rechercher parmi les utilisateurs finaux du VAR",
      varProceedWithSelected: "Utiliser le VAR s\u00E9lectionn\u00E9",
      customerFormTitle: "Utilisateur final Luckey",
      customerName: "Nom de l'utilisateur final",
      customerVat: "TVA",
      customerFiscalCode: "Code fiscal",
      customerCountry: "Pays",
      customerAddress: "Adresse",
      customerCity: "Ville",
      customerEmail: "E-mail",
      customerPhone: "T\u00E9l\u00E9phone",
      customerCrmId: "ID COMPTE SFDC",
      customerMarketplace: "March\u00E9",
      customerCertifiedEmail: "PEC (e-mail certifi\u00E9)",
      customerCluster: "Cluster",
      customerVar: "VAR",
      customerCompany: "Entreprise ISEO",
      customerPostalCode: "Code postal",
      customerPrincipalSubDivision: "Province, \u00C9tat, d\u00E9partement, etc.",
      exportActivationsCSV: "Exporter en CSV",
      salesAndOrdersData: "Ventes \u0026 Commandes",
      basicData: "Informations sur le site",
      formEnterAnswer: "Votre r\u00E9ponse",
      formEnterAnswerGT0: "Nombre sup\u00E9rieur \u00E0 z\u00E9ro",
      formEnterAnswerFrom0To600: "Nombre entre 0 et 600",
      formEnterAnswerOwnerEmail: "E-mail du propri\u00E9taire",
      formEnterAnswerKamEmail: "E-mail du Key Account Manager",
      formEnterAnswerPmEmail: "E-mail du PM",
      formEnterAnswerSystemEngineerEmail: "E-mail de l'ing\u00E9nieur syst\u00E8me",
      optId: "ID opportunit\u00E9 (SFDC)",
      salesOrderConfirmationNumber: "Num\u00E9ro de confirmation de commande de vente (sell-out)",
      salesOrderConfirmationNumber_desc: "",
      selloutFiles: "Commande de vente PDF (hardware + software + services professionnels)",
      selloutFiles_desc: "N\u00E9cessaire pour suivre correctement les revenus de Blossom.",
      selloutFiles_desc2: "Limite de fichiers : 5  ~  Taille maximale de chaque fichier : 10MB  ~  Extensions autoris\u00E9es : ",
      selloutFilesAdd: "T\u00E9l\u00E9charger le fichier",
      selloutFilesDownload: "T\u00E9l\u00E9charger",
      selloutFilesReplace: "Remplacer",
      quotationToolFile: "Outil de devis (non obligatoire)",
      quotationToolFile_desc: "T\u00E9l\u00E9chargez le fichier de l'outil de devis utilis\u00E9 pour cette demande.",
      quotationToolFile_desc2: "Limite de fichiers : 1  ~  Taille maximale de chaque fichier : 10MB",
      purchaseOrderNumberSofia: "Num\u00E9ro de commande d'achat \u00E0 Sofia",
      baselineSubscription: "Abonnement de base Luckey",
      baselineSubscription_BASIC: "Luckey Basique",
      baselineSubscription_ENTERPRISE: "Luckey Enterprise (Standard)",
      baselineSubscription_ENGINE: "Luckey Engine",
      baselineSubscription_undefined: "Utiliser un abonnement personnalis\u00E9",
      subscriptionData: "Infos abonnement",
      noSubscriptionFound: "Aucun abonnement trouv\u00E9 pour le moment.",
      noSubscriptionFoundPleaseCheck: " Veuillez v\u00E9rifier les champs ",
      noSubscriptionFoundDescription: "La liste des abonnements est vide. Si vous avez appliqu\u00E9 un filtre de recherche, essayez de le r\u00E9initialiser et r\u00E9essayez",
      selectedSubscription: "Abonnement s\u00E9lectionn\u00E9 : ",
      plantName: "Nom du site",
      plantName_desc: "En minuscules, sans espaces et, si n\u00E9cessaire, s\u00E9par\u00E9 par des tirets - ex. nom-site-exemple",
      keyAccountManager: "Key Account Manager",
      keyAccountManager_desc: "Adresse e-mail du Key Account Manager",
      systemManagerEmails: "E-mails des administrateurs du syst\u00E8me (un ou plusieurs)",
      systemManagerEmails_desc: "Adresse e-mail de la personne charg\u00E9e de g\u00E9rer les permissions d'acc\u00E8s des utilisateurs sur la plateforme web",
      installerEmail: "E-mail de l'installateur (non obligatoire)",
      installerEmail_desc: "",
      pmEmail: "E-mail du PM (non obligatoire)",
      pmEmail_desc: "Doit appartenir \u00E0 @sofialocks.com ou @iseo.com",
      systemEngineerEmail: "E-mail de l'ing\u00E9nieur syst\u00E8me (non obligatoire)",
      systemEngineerEmail_desc: "Doit appartenir \u00E0 @sofialocks.com ou @iseo.com",
      numberOfElements: "Nombre d'\u00E9l\u00E9ments \u00E0 g\u00E9rer",
      numberOfElements_desc: "Les \u00E9l\u00E9ments sont la somme des : utilisateurs, cadenas et toutes les invitations actives",
      V364orF9000: "Cadenas V364\/1RIS ou cylindres F9000 ?",
      V364orF9000_desc: "Y a-t-il des cadenas V364\/1RIS ou des cylindres F9000 dans le syst\u00E8me ? Si oui, l'add-on associ\u00E9 sera ajout\u00E9 automatiquement",
      addons: "Add-ons \u0026 Plug-ins",
      addons_desc: "S\u00E9lectionnez les add-ons \u00E0 ajouter \u00E0 l'abonnement de base",
      credits: "Nombre de cr\u00E9dits",
      credits_desc: "Cr\u00E9dits vendus avec cette activation syst\u00E8me",
      expectedEnd: "Selon votre s\u00E9lection, vos cr\u00E9dits s'\u00E9puiseront dans : ",
      finalData: "Autres informations",
      notes: "Notes",
      notes_desc: "\u00C9crivez ici toute note suppl\u00E9mentaire concernant cette demande",
      notifications: "Notifications",
      notifications_desc: "Personnes \u00E0 notifier pour les \u00E9v\u00E9nements ; par d\u00E9faut, le propri\u00E9taire de la demande et le Key Account Manager associ\u00E9 seront notifi\u00E9s",
      editBaselineSubscriptionWarning: "\u00CAtes-vous s\u00FBr de vouloir changer l'abonnement de base ? Cela r\u00E9initialisera les add-ons s\u00E9lectionn\u00E9s dans le formulaire.",
      editBaselineSubscriptionError: "Une erreur est survenue, veuillez r\u00E9essayer",
      editNumberOfElementsWarning: "\u00CAtes-vous s\u00FBr de vouloir changer le nombre d'\u00E9l\u00E9ments ? Cela r\u00E9initialisera les add-ons disponibles dans le formulaire.",
      editNumberOfElementsError: "Une erreur est survenue, veuillez r\u00E9essayer",
      customerMarketplaceError: "L'utilisateur final s\u00E9lectionn\u00E9 n'est associ\u00E9 \u00E0 aucun march\u00E9. Veuillez contacter le support pour r\u00E9soudre le probl\u00E8me.",
      customerClusterError: "L'utilisateur final s\u00E9lectionn\u00E9 n'est associ\u00E9 \u00E0 aucun cluster. Veuillez contacter le support pour r\u00E9soudre le probl\u00E8me.",
      invoiceData: "Facture",
      invoiceFile: "Facture PDF *",
      invoiceFile_desc: "T\u00E9l\u00E9chargez la facture en PDF.",
      invoiceFile_desc2: "Limite de fichiers : 1  ~  Taille maximale de chaque fichier : 10MB",
      invoiceNumber: "Num\u00E9ro et date de facture de vente (XXXXXX - JJ\/MM\/AAAA)",
      invoiceNumberPlaceholder: "XXXXXX - JJ\/MM\/AAAA",
      setInvoiceData: "T\u00E9l\u00E9charger la facture",
      dataMissingCreated: "Sp\u00E9cifiez le num\u00E9ro de facture et le PDF pour continuer"
    },
    recharges: {
      addNewRechargeButton: "Ajouter une recharge de portefeuille",
      noRechargesFoundTitle: "Aucune recharge trouv\u00E9e",
      noRechargesFoundDescription: "Il n'y a aucune recharge de portefeuille dans le syst\u00E8me. Cr\u00E9ez-en de nouvelles pour commencer",
      customerAndWallet: "Utilisateur final et portefeuille",
      seeCustomer: "Infos",
      seeCustomerDetails: "Voir les d\u00E9tails de l'utilisateur final",
      selloutOrder: "Commande de vente",
      intragroupOrder: "Commande intragroupe",
      custref: "R\u00E9f. client",
      mode: "Type de renouvellement de contrat :",
      creditsSuggested: "Cr\u00E9dits sugg\u00E9r\u00E9s",
      creditsConfirmed: "Cr\u00E9dits confirm\u00E9s",
      rejectedReason: "Raison du rejet",
      deletedReason: "Raison de la suppression",
      createdAt: "Cr\u00E9\u00E9 le",
      updatedAt: "Mis \u00E0 jour le",
      customerSearch: "Rechercher parmi les utilisateurs finaux existants",
      customerSearchEnterName: "Rechercher par nom...",
      customerSearchNoMatch: "Aucun utilisateur final trouv\u00E9...",
      varSearch: "Rechercher parmi les VAR existants",
      varSearchEnterName: "Rechercher par nom...",
      varSearchNoMatch: "Aucun VAR trouv\u00E9...",
      varCustomerSearch: "Rechercher parmi les utilisateurs finaux du VAR",
      plantSearch: "S\u00E9lectionnez le site souhait\u00E9",
      plantSearchEnterName: "S\u00E9lectionner un site",
      plantSearchNoMatch: "Aucun site trouv\u00E9...",
      plantProceedWithSelected: "Utiliser le site\/portefeuille s\u00E9lectionn\u00E9",
      dataMissingTakenCharge: "Sp\u00E9cifiez les cr\u00E9dits confirm\u00E9s et la commande de vente pour continuer",
      dataMissingScratchcardCreated: "Sp\u00E9cifiez le num\u00E9ro de facture et le PDF pour continuer",
      changeStatusToSelloutOrderGenerated: "Envoyer en r\u00E9vision",
      changeStatusToInvoiced: "Envoyer en factur\u00E9",
      changeStatusToCreated: "Envoyer en cr\u00E9\u00E9",
      changeStatusToRejected: "Rejeter la demande",
      changeStatusToScratchcardCreated: "Recharge cr\u00E9\u00E9e",
      changeStatusToWalletRecharged: "Portefeuille recharg\u00E9",
      changeStatusAdmin: "D\u00E9finir le statut (admin)",
      createCard: "Cr\u00E9er une carte de recharge",
      changeCustRef: "D\u00E9finir la r\u00E9f. client",
      search: "Rechercher...",
      resetFilters: "R\u00E9initialiser les filtres",
      statusSelection: "Tout statut",
      clusterSelection: "Tout cluster",
      statusTO_BE_MANAGED: "\u00C0 gérer",
      statusTAKEN_CHARGE: "En cours de traitement",
      statusREQUEST_TO_DELETE: "Demande de suppression",
      statusSELLOUT_ORDER_GENERATED: "En r\u00E9vision",
      statusSCRATCHCARD_CREATED: "Recharge cr\u00E9\u00E9e",
      statusWALLET_RECHARGED: "Recharg\u00E9",
      statusREJECTED: "Rejet\u00E9",
      statusDELETED: "Supprim\u00E9",
      statusINVOICED: "Factur\u00E9",
      promote: "Supprimer",
      saveRequest: "Enregistrer la demande",
      createRequest: "Cr\u00E9er une recharge de portefeuille",
      takeCharge: "Prendre en charge",
      AUTO_RENEWABLE: "Renouvelable automatiquement",
      NOT_AUTO_RENEWABLE: "Non renouvelable automatiquement",
      deleteRechargeConfirm: "\u00CAtes-vous s\u00FBr de vouloir supprimer cette demande ? Veuillez sp\u00E9cifier la raison. L'op\u00E9ration est irr\u00E9versible",
      deleteAdminRechargeConfirm: "\u00CAtes-vous s\u00FBr de vouloir supprimer d\u00E9finitivement cette demande ? L'op\u00E9ration est irr\u00E9versible",
      deleteRechargeError: "Une erreur est survenue lors de la suppression de la demande. Veuillez r\u00E9essayer",
      takechargeRechargeConfirm: "Veuillez sp\u00E9cifier le type de renouvellement pour traiter cette demande",
      takechargeRechargeDirectConfirm: "\u00CAtes-vous s\u00FBr de vouloir prendre en charge cette demande ?",
      takechargeRechargeError: "Une erreur est survenue lors de la prise en charge de la demande. Veuillez r\u00E9essayer",
      promoteRechargeConfirm: "\u00CAtes-vous s\u00FBr de vouloir envoyer cette demande en r\u00E9vision ? La demande ne pourra pas \u00EAtre modifi\u00E9e pendant la r\u00E9vision.",
      promoteRechargeError: "Une erreur est survenue lors de l'envoi en r\u00E9vision de la demande. Veuillez r\u00E9essayer",
      createdRechargeConfirm: "\u00CAtes-vous s\u00FBr de vouloir passer cette demande au statut CR\u00C9\u00C9 ?",
      createdRechargeError: "Une erreur est survenue lors de la mise \u00E0 jour du statut de la demande. Veuillez r\u00E9essayer",
      invoicedRechargeConfirm: "\u00CAtes-vous s\u00FBr de vouloir marquer cette demande comme factur\u00E9e ?",
      invoicedRechargeError: "Une erreur est survenue lors de la mise \u00E0 jour du statut de la demande. Veuillez r\u00E9essayer",
      editStatusRechargeConfirm: "\u00CAtes-vous s\u00FBr de vouloir modifier le statut de cette demande ? L'op\u00E9ration est irr\u00E9versible",
      editStatusRechargeError: "Une erreur est survenue lors de la mise \u00E0 jour du statut de la demande. Veuillez r\u00E9essayer",
      rejectRechargeConfirm: "Sp\u00E9cifiez la raison du rejet de cette demande.",
      rejectRechargeError: "Une erreur est survenue lors du rejet de la demande. Veuillez r\u00E9essayer",
      scratchcardCreatedRechargeConfirm: "\u00CAtes-vous s\u00FBr de vouloir accepter cette demande et confirmer que la carte de recharge a \u00E9t\u00E9 cr\u00E9\u00E9e ?",
      scratchcardCreatedRechargeError: "Une erreur est survenue lors de l'acceptation de la demande. Veuillez r\u00E9essayer",
      walletRechargedRechargeConfirm: "\u00CAtes-vous s\u00FBr de vouloir marquer cette demande comme termin\u00E9e et confirmer que le portefeuille a \u00E9t\u00E9 recharg\u00E9 ?",
      walletRechargedRechargeError: "Une erreur est survenue lors de la modification du statut de la demande. Veuillez r\u00E9essayer",
      newFileName: "Sp\u00E9cifiez le nom du fichier",
      newFileExtensionError: "Le fichier a une extension non prise en charge",
      newFileDuplicateNameError: "Ce nom a d\u00E9j\u00E0 \u00E9t\u00E9 utilis\u00E9 pour un autre fichier. Changez le nom et r\u00E9essayez.",
      editCustrefConfirm: "Sp\u00E9cifiez la r\u00E9f. client pour cette demande.",
      editCustrefError: "Une erreur est survenue lors de la mise \u00E0 jour de la r\u00E9f. client, veuillez r\u00E9essayer",
      editRecharge: "Modifier la recharge de portefeuille",
      newRecharge: "Ajouter une recharge de portefeuille",
      viewRecharge: "Voir la recharge de portefeuille",
      newRechargeError: "Une erreur est survenue lors de la cr\u00E9ation de cette demande. Veuillez r\u00E9essayer",
      editRechargeError: "Une erreur est survenue lors de la mise \u00E0 jour de cette demande. Veuillez r\u00E9essayer",
      exportRechargesCSV: "Exporter en CSV",
      basicData: "Param\u00E8tres de la recharge de portefeuille",
      ordersData: "Commandes",
      invoiceData: "Facture",
      formEnterAnswer: "Votre r\u00E9ponse",
      formEnterAnswerGT0: "Nombre sup\u00E9rieur \u00E0 z\u00E9ro",
      sfdcId: "ID opportunit\u00E9 (SFDC)",
      salesOrderConfirmationNumber: "Num\u00E9ro de confirmation de commande de vente (sell-out)",
      salesOrderConfirmationNumber_desc: "",
      selloutFiles: "Commande de vente PDF (hardware + software + services professionnels)",
      selloutFiles_desc: "N\u00E9cessaire pour suivre correctement les revenus de Blossom.",
      selloutFiles_desc2: "Limite de fichiers : 5  ~  Taille maximale de chaque fichier : 10MB  ~  Extensions autoris\u00E9es : ",
      selloutFilesAdd: "T\u00E9l\u00E9charger le fichier",
      selloutFilesDownload: "T\u00E9l\u00E9charger",
      selloutFilesReplace: "Remplacer",
      invoiceFile: "Facture PDF *",
      invoiceFile_desc: "T\u00E9l\u00E9chargez la facture en PDF.",
      invoiceFile_desc2: "Limite de fichiers : 1  ~  Taille maximale de chaque fichier : 10MB",
      invoiceNumber: "Num\u00E9ro de facture de vente (pour l'utilisateur final) *",
      purchaseOrderNumberSofia: "Num\u00E9ro de commande d'achat \u00E0 Sofia",
      orderContactEmail: "E-mail de contact de la commande"
    },
    vars: {
      varLogo: "Logo",
      setTrainingDate: "D\u00E9finir la date de formation",
      missingLastTrainingDateForCertificate: "Ce VAR n'a pas de date de formation d\u00E9finie. Veuillez d\u00E9finir une date de formation et r\u00E9essayer de g\u00E9n\u00E9rer le certificat",
      varLastTrainingData: "Derni\u00E8re date de formation",
      varShortDescription: "Br\u00E8ve description",
      varCity: "Ville",
      varCountry: "Pays",
      varWebsite: "Site web",
      varVisibleOnSofiaWebsite: "Visible sur la page des partenaires du site de Sofia",
      downloadCertificate: "T\u00E9l\u00E9charger le certificat",
      opportunityChangeOwnerMessage: "Veuillez sp\u00E9cifier le propri\u00E9taire de ce devis",
      noOpportunityHistoryFound: "Aucun historique de devis trouv\u00E9",
      noOpportunityHistoryFoundDescription: "Ce devis n'a aucun enregistrement historique",
      customers: "Clients",
      varsManagement: "VARs",
      varSettings: "Param\u00E8tres",
      varInfoPage: "Formation \u0026 Ressources",
      varUsersPage: "Utilisateurs",
      vars: "VAR",
      plantThemes: "Th\u00E8mes de site",
      editVarPersonalConfirm: "Vous \u00EAtes sur le point de modifier les informations de votre entreprise. \u00CAtes-vous s\u00FBr de vouloir continuer ?",
      editVarConfirm: "Vous \u00EAtes sur le point de modifier les donn\u00E9es du VAR. \u00CAtes-vous s\u00FBr de vouloir continuer ?",
      editVar: "Modifier le VAR",
      newVar: "Nouveau VAR",
      varCreationConfirm: "Voulez-vous cr\u00E9er le nouveau VAR ?",
      createVar: "Cr\u00E9er le VAR",
      noVarFindTitle: "Aucun VAR trouv\u00E9",
      noVarFindDescription: "La liste des VAR est vide. Si vous avez appliqu\u00E9 un filtre de recherche, essayez de le r\u00E9initialiser et r\u00E9essayez",
      noPlantThemeFindTitle: "Aucun th\u00E8me de site trouv\u00E9",
      noPlantThemeFindDescription: "La liste des th\u00E8mes de site est vide. Si vous avez appliqu\u00E9 un filtre de recherche, essayez de le r\u00E9initialiser et r\u00E9essayez",
      clusterSelection: "Tout cluster",
      varSelection: "Tout VAR",
      varName: "Nom",
      varAddress: "Adresse",
      varEmail: "E-mail de contact principal",
      varPhone: "T\u00E9l\u00E9phone",
      varCluster: "Cluster",
      varVat: "TVA",
      varTechContact: "Contact technique",
      varCommercialContact: "Contact commercial",
      varNameTech: "Nom du contact technique",
      varEmailTech: "E-mail du contact technique",
      varPhoneTech: "T\u00E9l\u00E9phone du contact technique",
      varNameComm: "Nom du contact commercial",
      varEmailComm: "E-mail du contact commercial",
      varPhoneComm: "T\u00E9l\u00E9phone du contact commercial",
      varSfdcid: "ID COMPTE SFDC",
      varSupportEmail: "E-mail de support",
      varSupportHours: "Horaires de support",
      varCompanyName: "Nom de l'entreprise",
      varCustomers: "Utilisateurs finaux du VAR",
      varUsers: "Utilisateurs du VAR",
      varOpportunities: "Devis du VAR",
      editPlantThemeConfirm: "Vous \u00EAtes sur le point de modifier les donn\u00E9es du th\u00E8me du site. \u00CAtes-vous s\u00FBr de vouloir continuer ?",
      editPlantTheme: "Modifier le th\u00E8me du site",
      newPlantTheme: "Nouveau th\u00E8me de site",
      plantThemeCreationConfirm: "Voulez-vous cr\u00E9er le nouveau th\u00E8me de site ?",
      editVarPersonal: "Modifier les informations de l'entreprise",
      plantThemeName: "Nom",
      plantThemeColour: "Couleur *",
      plantThemeColourDescription: "Couleur du th\u00E8me",
      plantThemeLogo: "Logo",
      plantThemeDefault: "Utiliser comme th\u00E8me par d\u00E9faut",
      logoFile: "Image du logo *",
      logoFile_desc: "Extensions de fichier autoris\u00E9es : ",
      logoFileAdd: "T\u00E9l\u00E9charger le fichier",
      logoFileDownload: "T\u00E9l\u00E9charger",
      logoFileReplace: "Remplacer",
      deletePlantThemeConfirm: "Voulez-vous vraiment supprimer ce th\u00E8me de site ? L'op\u00E9ration est irr\u00E9versible",
      plantThemeSearchEnterName: "S\u00E9lectionnez le th\u00E8me",
      plantThemeSearch: "S\u00E9lectionnez le th\u00E8me souhait\u00E9",
      domainThemeTitle: "Th\u00E8me de domaine personnalis\u00E9",
      domainThemeDescription: "D\u00E9finissez votre th\u00E8me personnalis\u00E9 sur ce domaine",
      plantThemeModifySuccess: "Th\u00E8me du site modifi\u00E9 et mis \u00E0 jour",
      cannotDeleteAssociatedTheme: "Impossible de supprimer un th\u00E8me associ\u00E9 \u00E0 un site.",
      plantThemeAssociatedPlants: "Sites associ\u00E9s \u00E0 ce th\u00E8me",
      newVarUser: "Nouvel utilisateur VAR",
      varUserCreationConfirm: "Voulez-vous cr\u00E9er le nouvel utilisateur VAR ?",
      createVarUser: "Cr\u00E9er l'utilisateur VAR",
      varUserFormTitle: "Utilisateur VAR",
      createNewVarUser: "Cr\u00E9er un nouvel utilisateur VAR",
      noVarUserFindTitle: "Aucun utilisateur VAR trouv\u00E9",
      noVarUserFindDescription: "La liste des utilisateurs VAR est vide",
      deleteVarUserConfirm: "Voulez-vous vraiment supprimer cet utilisateur VAR ? L'op\u00E9ration est irr\u00E9versible",
      deleteVarOpportunityConfirm: "Voulez-vous vraiment supprimer ce devis ? L'op\u00E9ration est irr\u00E9versible",
      rejectVarOpportunityConfirm: "Voulez-vous vraiment rejeter ce devis ? L'op\u00E9ration est irr\u00E9versible",
      approveVarOpportunityConfirm: "Voulez-vous vraiment approuver ce devis ? L'op\u00E9ration est irr\u00E9versible",
      syncsfdcidVarOpportunityConfirm: "Voulez-vous vraiment associer un ID SFDC \u00E0 ce devis ? L'op\u00E9ration est irr\u00E9versible",
      upgradeVarOpportunityConfirm: "Voulez-vous vraiment envoyer ce devis en r\u00E9vision ? L'op\u00E9ration est irr\u00E9versible",
      winVarOpportunityConfirm: "Voulez-vous vraiment marquer ce devis comme GAGN\u00C9 ? L'op\u00E9ration est irr\u00E9versible",
      lostVarOpportunityConfirm: "Voulez-vous vraiment marquer ce devis comme PERDU ? L'op\u00E9ration est irr\u00E9versible",
      deleteUserConfirm: "Voulez-vous vraiment supprimer cet utilisateur ? L'op\u00E9ration est irr\u00E9versible",
      varUserUsername: "Nom d\'utilisateur",
      varUserFirstname: "Pr\u00E9nom",
      varUserLastname: "Nom de famille",
      varUserEmail: "E-mail",
      varUsersList: "Utilisateurs",
      newFileExtensionError: "Le fichier a une extension non prise en charge",
      agreementFile: "Accord",
      opportunities: "Devis",
      opportunityManagement: "Infos devis",
      opportunityID: "ID",
      opportunityOpty: "ID OPTY SFDC",
      opportunityVar: "VAR",
      opportunityCustomer: "Utilisateur final",
      opportunityOwner: "Propri\u00E9taire du devis",
      opportunityName: "Nom du devis",
      opportunityValue: "Valeur",
      opportunityStatus: "Statut",
      opportunityActions: "Actions",
      opportunityDescription: "Description",
      opportunityNotes: "Notes",
      opportunityCrm: "CRM",
      noOpportunityFindTitle: "Aucun devis trouv\u00E9",
      noOpportunityFindDescription: "La liste des devis est vide",
      opportunityStatus_DRAFT: "Brouillon",
      opportunityStatus_ON_APPROVAL: "En r\u00E9vision",
      opportunityStatus_APPROVED: "Approuv\u00E9",
      opportunityStatus_CANCELLED: "Annul\u00E9",
      opportunityStatus_REJECTED: "Rejet\u00E9",
      opportunityStatus_WON: "Gagn\u00E9",
      opportunityStatus_LOST: "Perdu",
      opportunityFiles: "Fichiers joints",
      newOpportunity: "Nouveau devis",
      editOpportunity: "Modifier le devis",
      opportunitySyncSFDC: "Synchroniser avec SFDC",
      exportOpportunities: "Exporter en CSV",
      upgradeOpportunity: "Envoyer en r\u00E9vision",
      approveOpportunity: "Approuver",
      rejectOpportunity: "Rejeter",
      wonOpportunity: "Marquer comme GAGN\u00C9",
      lostOpportunity: "Marquer comme PERDU",
      CREATED: "Devis cr\u00E9\u00E9",
      VAR_USER_UPDATED: "Utilisateur VAR assign\u00E9 au devis mis \u00E0 jour",
      DESCRIPTION_UPDATED: "Description du devis mise \u00E0 jour",
      NAME_UPDATED: "Nom du devis mis \u00E0 jour",
      VALUE_UPDATED: "Valeur du devis mise \u00E0 jour",
      CURRENCY_UPDATED: "Devise du devis mise \u00E0 jour",
      OPTY_ID_UPDATED: "ID OPTY du devis mis \u00E0 jour",
      ISEO_CRM_ID_UPDATED: "ID SFDC du devis mis \u00E0 jour",
      DOCUMENTS_UPDATED: "Documents du devis mis \u00E0 jour",
      STATUS_ON_APPROVAL: "Devis envoy\u00E9 en r\u00E9vision",
      STATUS_APPROVED: "Devis approuv\u00E9",
      STATUS_WON: "Devis marqu\u00E9 comme gagn\u00E9",
      STATUS_LOST: "Devis marqu\u00E9 comme perdu",
      STATUS_DRAFT: "Devis marqu\u00E9 comme brouillon",
      STATUS_CANCELLED: "Devis marqu\u00E9 comme annul\u00E9",
      CANCELLED: "Devis annul\u00E9",
      STATUS_REJECTED: "Devis marqu\u00E9 comme rejet\u00E9",
      showOpportunityHistory: "Afficher l\'historique",
      opportunityHistory: "Historique du devis"
    },
    save: "Enregistrer",
    languages: {
      it: "Italien",
      en: "Anglais",
      de: "Allemand",
      fr: "Fran\u00E7ais",
      es: "Espagnol",
      nl: "N\u00E9erlandais",
      ITALIAN: "Italien",
      ENGLISH: "Anglais",
      GERMAN: "Allemand",
      FRENCH: "Fran\u00E7ais",
      SPANISH: "Espagnol",
      DUTCH: "N\u00E9erlandais",
      menuHeading: "Choisissez une langue"
    },
    productInfo: {
      software: "Logiciel",
      salesMaterial: "Mat\u00E9riel de vente",
      hardware: "Mat\u00E9riel",
      marketingMaterial: "Mat\u00E9riel marketing"
    },
    users: {
      usersManagement: "Gestion des utilisateurs",
      users: "Utilisateurs",
      noUserFindTitle: "Aucun utilisateur trouv\u00E9",
      noUserFindDescription: "La liste des utilisateurs est vide. Essayez de r\u00E9initialiser vos filtres de recherche s'il y en a",
      search: "Rechercher...",
      roleSelection: "R\u00F4le",
      clusterSelection: "Cluster",
      varSelection: "VAR",
      newUser: "Ajouter",
      userFirstname: "Pr\u00E9nom",
      userLastname: "Nom de famille",
      userEmail: "E-mail",
      userFormTitle: "Donn\u00E9es de l'utilisateur",
      editUserForm: "Modifier l'utilisateur",
      newUserForm: "Cr\u00E9er un utilisateur"
    },
    permissions: {
      NAVIGATION: "Visibilit\u00E9 du menu",
      CLUSTER: "Cluster",
      VAR: "VAR",
      CUSTOMER: "Utilisateurs finaux",
      OPPORTUNITY: "Devis",
      WALLET: "Portefeuilles d'utilisateurs finaux",
      DOMAIN: "Syst\u00E8mes d'utilisateurs finaux",
      GATEWAYS: "Gateway",
      CARD: "Cartes de recharge",
      PLANT_ACTIVATION: "Activations syst\u00E8me",
      RECHARGE_REQUEST: "Recharges syst\u00E8me",
      ACTIVATION_REQUEST: "Demandes d'activation",
      EXPORT: "Exporter des entit\u00E9s",
      CREATE: "Cr\u00E9er une nouvelle entit\u00E9",
      UPDATE: "Mettre \u00E0 jour une entit\u00E9",
      MARK_LOST: "Marquer l'entit\u00E9 comme Perdue",
      MARK_WON: "Marquer l'entit\u00E9 comme Gagn\u00E9e",
      CANCEL: "Marquer l'entit\u00E9 comme Annul\u00E9e",
      CANCEL_DRAFT: "Marquer l'entit\u00E9 comme Annul\u00E9e lorsqu'elle est en brouillon",
      CANCEL_ON_APPROVAL: "Marquer l'entit\u00E9 comme Annul\u00E9e lorsqu'elle est en r\u00E9vision",
      CANCEL_APPROVED: "Marquer l'entit\u00E9 comme Annul\u00E9e lorsqu'elle est approuv\u00E9e",
      MARK_TO_BE_APPROVED: "Marquer l'entit\u00E9 pour approbation",
      RECHARGE: "Recharger",
      SUBSCRIPTION_UPDATE: "Mettre \u00E0 jour les abonnements",
      DELETE_REQUEST: "Envoyer une demande de suppression",
      CREATION_REQUEST: "Envoyer une demande de cr\u00E9ation de nouvelle entit\u00E9",
      MANAGER_CREATE: "Cr\u00E9er des administrateurs",
      MANAGER_UPDATE: "Mettre \u00E0 jour des administrateurs",
      INSTALLER_CREATE: "Cr\u00E9er des installateurs",
      INSTALLER_UPDATE: "Mettre \u00E0 jour des installateurs",
      IMPERSONATE_WRITE: "Impersonner les administrateurs",
      IMPERSONATE_SUPPORT: "Impersonner le compte support",
      ADDITIONAL_INFO_UPDATE: "Modifier les informations suppl\u00E9mentaires",
      CUSTOMERS: "Utilisateurs finaux",
      PLANT_ACTIVATIONS: "Activations syst\u00E8me",
      RECHARGE_REQUESTS: "Recharges syst\u00E8me",
      ACTIVATION_REQUESTS: "Demandes d'activation",
      CARDS: "Cartes de recharge",
      VAR_USERS: "Utilisateurs VAR",
      VAR_SETTINGS: "Param\u00E8tres VAR",
      OPPORTUNITIES: "Devis",
      PRODUCTINFO: "Informations produit",
      USER_READ: "Voir les utilisateurs",
      USER_UPDATE: "Mettre \u00E0 jour un utilisateur",
      USER_CREATE: "Cr\u00E9er un nouvel utilisateur",
      PLANT_THEME: "Changer le th\u00E8me du domaine",
      READ: "Lire les valeurs de l'entit\u00E9",
      ORDERS_STATUS: "Statut des commandes",
      FILTER: "Filtrer les entit\u00E9s",
      VAR_MANAGEMENT: "Gestion des VAR",
      MARKETPLACES: "March\u00E9s",
      DOMAINS: "Syst\u00E8mes d'utilisateurs finaux",
      PRICELIST: "Liste de prix",
      SALESINFO: "Infos de vente",
      PLATFORM_USERS: "Gestion des utilisateurs",
      APPROVE: "Marquer l'entit\u00E9 comme Approuv\u00E9e",
      REJECT: "Marquer l'entit\u00E9 comme Rejet\u00E9e",
      EXTEND: "\u00C9tendre",
      CONFIG: "Lire les configurations de l'entit\u00E9",
      IRIS_ENABLE: "Activer 1RIS pour le domaine",
      MARK_TO_PROMOTE: "Marquer l'entit\u00E9 pour Promotion",
      MARK_TO_INVOCE: "Marquer l'entit\u00E9 pour Facturation",
      DELETE: "Supprimer des entit\u00E9s",
      EDIT: "Modifier des entit\u00E9s",
      SUBSCRIPTION_DELETE: "Supprimer des abonnements"
    },
    segmentTypes: {
      WORKING_SPACES_name: "Espace de travail flexible",
      LIVING_name: "Habitat flexible",
      RETAIL_name: "Commerce de d\u00E9tail flexible",
      UNIVERSITIES_name: "Universit\u00E9s",
      LEISURE_name: "Loisirs et h\u00F4tellerie",
      HOSPITAL_name: "H\u00F4pital",
      WORKING_SPACES_description: "Bureaux, coworking, centres d'affaires",
      LIVING_description: "R\u00E9sidentiel, colocation, co-living, logement \u00E9tudiant",
      RETAIL_description: "Commerce multi-sites, centres commerciaux, cha\u00EEnes de magasins",
      LEISURE_description: "H\u00F4tels de petite et moyenne taille, h\u00E9bergement, centres sportifs",
      HOSPITAL_description: "H\u00F4pitaux, r\u00E9sidences pour personnes \u00E2g\u00E9es et soins aux personnes d\u00E9pendantes"
    }
  };
  
export default translations; 