import axios from 'axios';

const luckeyHelpCenterInstance = axios.create({
  timeout: 30000,
  baseURL: 'https://help-center.sofialocks.com',
});

export function fetchProductInfoPosts(tagId,languagePrexif) {
  return luckeyHelpCenterInstance.get(`/wp-json/wp/v2/posts?_fields=id,title,link,tags,excerpt,content&_embed&tags=${tagId}&per_page=100&orderby=modified&order=asc&lang=${languagePrexif}`);
}

export function fetchProductInfoArticle(itemId, languagePrexif) {
  return luckeyHelpCenterInstance.get(`/wp-json/wp/v2/posts?include[]=${itemId}&lang=${languagePrexif}`);
}