const translations = {
  language: "Taal",
  login: {
    welcome: 'Welkom',
    ssoLogin: 'Toegang met uw account-e-mail',
    detailsGeneric: 'Details',
    customers: 'Eindgebruikers',
    sendEmail: 'E-mail verzenden',
    otpLogin: 'Inloggen met e-mail',
    login: 'Inloggen',
    changeEmail: 'Ander e-mailadres gebruiken',
    or: 'OF'
  },
  domains: {
    customerAdminDeletionConfirm: 'Wilt u deze eindgebruiker verwijderen? De bewerking kan niet ongedaan worden gemaakt',
    customerAdminDeletionSuccess: 'Eindgebruiker succesvol verwijderd',
    luckeyDomainsTitle: 'Domeinen',
    domainName: 'Domeinnaam',
    domainDetails: 'Domeingegevens',
    domainConfigModifySuccess: 'Domeinconfiguratie gewijzigd en bijgewerkt',
    domainConfigTitle: 'Configuraties - Functies in- en uitschakelen',
    domainConfigDescription: 'Domeinconfiguratie JSON. Gebruik het om domeinfuncties in of uit te schakelen',
    domainConfigGuide: 'Gids voor domeinconfiguraties',
    noUsersFoundTitle: 'Gebruikerslijst leeg',
    domainUsersTitle: 'Domeinbeheerders',
    domainUsersDescription: 'Impersoneer een van de gebruikersbeheerders voor onderhoudsbewerkingen op het domein',
    domainUsersWarning: 'LET OP: Door gebruikers te imiteren kunt u wijzigingen in het systeem aanbrengen, zelfs onomkeerbare wijzigingen, wees uiterst voorzichtig',
    noUsersFoundDescription: 'Er is geen gebruiker gevonden in dit domein',
    noDomainsFoundTitle: 'Geen domeinen gevonden',
    noDomainFoundDescription: 'De domeinlijst is leeg. Als u een zoekfilter hebt ingesteld, probeer het dan opnieuw in te stellen en probeer het opnieuw',
    impersonateSuccess: 'Gebruiker imiteert succesvol',
    domainSubsriptionTitle: 'Abonnement van domein',
    domainSubsriptionDescription: 'Lijst met abonnementen van domein: vergrendelingen, gebruikers en domeinvervaldatum',
    expiration: 'Vervaldatum',
    totalNumber: 'Totaal aantal',
    used: 'Gebruikte elementen',
    tooMuchElements: 'WAARSCHUWING: DOMEIN HEEFT DE LIMIET VAN TOTAAL AANTAL ELEMENTEN OVERSCHREDEN',
    newDomain: 'Nieuw domein',
    externalReference: 'Externe referentie',
    externalReferenceDetails: 'Bijvoorbeeld "date-purchaseId"',
    productDescription: 'Het product vertegenwoordigt de licentie die wordt geactiveerd voor het nieuwe domein',
    productTitle: 'Abonnementstype',
    extendDomain: 'Breidt domeinabonnement uit',
    extendDomainDescription: 'Breid uw domein uit met een add-on of productsleutel',
    domainInstallersTitle: 'Domeininstallateurs',
    domainInstallersDescription: 'Lijst met installateursgebruikers op de geselecteerde installaties',
    domainDeletionTitle: 'Domein verwijderen',
    domainDeletionDescription: 'Verstuur een verzoek om dit domein te verwijderen',
    sendDeletionRequest: 'Verzenden',
    domainDeletionConfirm: 'Wilt u een verzoek verzenden om dit domein te verwijderen?',
    domainDeletionSuccess: 'Verzoek succesvol verzonden',
    customerDeletionTitle: 'Eindgebruiker verwijderen',
    customerDeletionConfirm: 'Wilt u een verzoek verzenden om deze eindgebruiker te verwijderen?',
    customerDeletionSuccess: 'Verzoek succesvol verzonden',
    walletDeletionTitle: 'Portemonnee verwijderen',
    walletDeletionConfirm: 'Wilt u een verzoek verzenden om deze portemonnee te verwijderen?',
    walletDeletionSuccess: 'Aanvraag succesvol verzonden',
    verifyServiceStatus: 'Servicestatus',
    impersonateUser: 'Gebruiker imiteren',
    impersonate: 'Impersonate',
    impersonateReadOnly: 'Impersonate in alleen-lezenmodus',
    domainAdditionalInfo: 'Domeininfo',
    domainAdditionalInfoDescription: 'Geef hier aanvullende domeininformatie',
    additionalInfoSavedSuccessfully: 'Aanvullende notities succesvol opgeslagen',
    EASY: 'Alleen Argo-vergrendelingen',
    SMART: 'Argo-vergrendelingen + gateway',
    ENTERPRISE: 'V364 + Argo-vergrendelingen',
    STANDARD: 'Alleen V364-vergrendelingen',
    numberOfArgoLocks: 'Argo',
    numberOfVegaLocks: 'V364',
    numberOfGatewaysLocks: 'Gateways',
    numberOfUsers: 'Gebruikers',
    numberOfLocks: 'Smart Locks',
    federatedDomain: 'Domein gekoppeld aan: {{domein}}',
    federatedMasterDomain: 'Masterdomein van een groep',
    domainFederatedLicense: 'Federated plant-abonnement: dit abonnement is geünificeerd voor alle domeinen die behoren tot het hoofddomein {{domein}}',
    federatedDomains: 'Groep domeinen',
    federatedDomainsDescription: 'Domein behoort tot een groep',
    installationMode: 'Installatiemodus',
    domainStandard: 'Legacydomein',
    domainWithCloudCredits: 'Cloudcreditsdomein',
    domainIrisRequired: '1RIS vereist',
    domainIrisEnabled: '1RIS ingeschakeld',
    domainIrisEnable: '1RIS inschakelen',
    domainIrisEnableConfirm: 'Wilt u 1RIS op dit domein als INGESCHAKELD instellen?',
    domainsWithCloudCredits: 'Cloud credits domeinen',
    domainsLegacy: 'Legacy domeinen',
  },
  credits: {
    marketplaces: 'Marktplaatsen',
    searchMarketplace: 'Zoek marktplaats...',
    marketplaceName: 'Marktplaatsnaam',
    luckeyMarketplaceTitle: 'Marktplaatsen Luckey',
    noMarketplaceFoundTitle: 'Geen marktplaats gevonden',
    noMarketplaceFoundDescription: 'De marktplaatslijst is leeg. Als u een zoekfilter hebt ingesteld, probeer deze dan opnieuw in te stellen en probeer het opnieuw',
    newMarketplace: 'Nieuwe marktplaats',
    marketplaceCreationConfirm: 'Wilt u de nieuwe marktplaats maken?',
    createMarketplace: 'Marktplaats maken',
    marketplaceDetails: 'Marktplaatsgegevens',
    editMarketplaceCreationConfirm: 'U staat op het punt de marktplaatsgegevens te bewerken. Weet u zeker dat u wilt doorgaan?',
    editMarketplace: 'Marketplace bewerken',
    searchCustomer: 'Eindgebruiker zoeken...',
    customerName: 'Naam van de eindgebruiker',
    luckeyCustomersTitle: 'Luckey eindgebruikers',
    noCustomerFindTitle: 'Geen eindgebruikers gevonden',
    noCustomerFindDescription: 'De lijst met eindgebruikers is leeg. Als u een zoekfilter hebt ingesteld, probeer deze dan opnieuw in te stellen en probeer het opnieuw',
    newCustomer: 'Nieuwe eindgebruiker',
    customerCreationConfirm: 'Wilt u de nieuwe eindgebruiker aanmaken?',
    newUser: 'Nieuwe gebruiker',
    userCreationConfirm: 'Wilt u de nieuwe gebruiker aanmaken?',
    createCustomer: 'Eindgebruiker aanmaken',
    customerDetails: 'Gegevens eindgebruiker',
    editCreationConfirm: 'U staat op het punt de eindgebruikersgegevens te bewerken. Weet u zeker dat u wilt doorgaan?',
    editCustomer: 'Eindgebruiker bewerken',
    editCreationUserConfirm: 'U staat op het punt de gebruikersgegevens te bewerken. Weet u zeker dat u wilt doorgaan?',
    editUser: 'Gebruiker bewerken',
    customerWallets: '{{customerName}}\'s Wallets',
    newWallet: 'Nieuwe Wallet',
    walletCreationConfirm: 'Wilt u een nieuwe wallet aanmaken voor de geselecteerde eindgebruiker?',
    createWallet: 'Portemonnee aanmaken',
    editWallet: 'Portemonnee bewerken',
    walletEditConfirm: 'Wilt u de geselecteerde wallet bewerken?',
    noWalletFoundTitle: 'Geen wallet gevonden',
    noWalletFoundDescription: 'Geen wallet gevonden voor de geselecteerde eindgebruiker',
    walletDetails: 'Portemonnee-details',
    rechargeWallet: 'Opwaarderen',
    walletRechargeHistory: 'Opwaardeergeschiedenis',
    rechargeRowTitle: 'Credits gewijzigd',
    rechargeCreditsDescription: 'Portemonnee is opgeladen met {{credits}} credits',
    rechargeCardDetails: 'Opwaardeergegevens',
    systemParameters: 'Prijslijst',
    systemSubscriptions: 'Abonnementen',
    systemSubscriptionsDescription: 'Dit zijn de abonnementen in het systeem. Maak nieuwe aan of wijs ze toe aan een bestaand domein',
    newSubscription: 'Nieuw abonnement',
    editSubscription: 'Abonnement bewerken',
    noSystemSubscriptionFoundTitle: 'Geen abonnement gevonden',
    noSystemSubscriptionFoundDescription: 'Er is geen systeemabonnement dat kan worden toegewezen aan een domein',
    products: 'Legacy Subscriptions',
    rechargeCards: 'Opwaardeerkaarten',
    noSystemCardFoundTitle: 'Geen opwaardeerkaarten gevonden',
    noSystemCardFoundDescription: 'Er zijn geen opwaardeerkaarten in het systeem. Maak nieuwe om de wallets van eindgebruikers op te laden',
    newCard: 'Nieuwe opwaardeerkaart',
    cardStatusUsed: 'Gebruikt',
    rechargeCardCreditsDetails: 'Deze opwaardeerkaart is {{credits}} credits waard',
    cardStatusUnused: 'Niet gebruikt',
    cardStatusInactive: 'Uitgeschakeld',
    enableCard: 'Inschakelen',
    disabledCardTooltip: 'De opwaardeerkaart wordt ingeschakeld zodra u de bijbehorende ISEO-factuur ontvangt',
    newTierSubscription: 'Nieuw hoofdabonnement',
    pluginAndService: 'Add-on en services',
    newPluginSubscription: 'Nieuwe add-on of service',
    tierSubscription: 'Hoofdabonnement',
    walletSubscriptions: 'Abonnementen',
    noWalletSubscriptionFoundTitle: 'Geen abonnement gevonden',
    noWalletSubscriptionFoundDescription: 'Deze muur heeft geen abonnement dat eraan gekoppeld is',
    newWalletSubscription: 'Nieuw abonnement koppelen',
    editWalletSubscription: 'Abonnement bewerken',
    newWalletPluginSubscription: 'Nieuwe add-on of service koppelen',
    walletConsumption: 'Consumptie',
    noWalletUsageFoundTitle: 'Geen gegevens gevonden',
    noWalletUsageFoundDescription: 'Er zijn geen verbruiksgegevens voor deze wallet',
    ACTIVATION: 'Activeringskosten voor domein of add-on',
    DAILY_USAGE: 'Dagelijks verbruik',
    creditConsumptionType: 'Verbruikstype',
    consumptionCreditsDescription: 'De wallet heeft {{credits}} verbruikt',
    consumptionRowTitle: 'Walletverbruik',
    walletPlants: 'Domeinen',
    noWalletPlantFoundTitle: 'Geen domein gevonden',
    noWalletPlantFoundDescription: 'Er zijn geen domeinen gekoppeld aan deze wallet',
    newWalletPlant: 'Nieuw domein',
    deleteWalletSubscription: 'Abonnement verwijderen',
    editCard: 'Opwaardeerkaart bewerken',
    rechargeDetails: 'Opwaardeergegevens',
    exportWalletsUsageCSV: 'Exporteer CSV-kredietgebruik',
    exportWalletsCreditUsageCSV: 'Exporteer Wallet-krediet',
    PENDING: 'In behandeling',
    ACTIVE: 'Actief',
    READY_TO_ACTIVATE: 'Klaar om te activeren',
    walletStatus: 'Wallet-status',
    exportManagersCSV: 'Exporteer Managers',
    needCreditsToCreatePlant: 'Je hebt credits in deze portemonnee nodig om een ​​nieuw domein te creëren',
  },
  sales: {
    hardwareSalesInfo: 'Verkoopinfo',
    domain: 'Domein',
    hardwareId: 'HW-ID',
    hardwareName: 'HW-naam',
    hardwareSerialNumber: 'HW-serienummer',
    hardwareModel: 'HW-model',
    hardwareVendor: 'HW-productlijn',
    hardwareType: 'HW-type',
    sfdcId: 'SFDC-ID',
    iseoSelloutOrderId: 'ISEO-uitverkooporder-ID',
    iseoSelloutOrderDate: 'ISEO-uitverkooporderdatum',
    iseoSelloutInvoiceId: 'ISEO-uitverkoopfactuur-ID',
    iseoSelloutInvoiceDate: 'ISEO-uitverkoopfactuurdatum',
    sofiaCustRef: 'Klantref. (Sofia-proces)',
    unreconciledHardware: 'Niet afgestemde hardware',
    hardwareWithSalesInfo: 'Hardware met verkoopinfo',
    actions: 'Acties',
    addSalesInfo: 'Verkoopinfo toevoegen',
    editSalesInfo: 'Verkoopinfo bewerken',
    iseoSelloutOrder: 'ISEO-uitverkooporder',
    iseoSelloutInvoice: 'ISEO-uitverkoopfactuur',
    selectedSalesInfoError: 'Waarschuwing! De selectie is niet homogeen. Weet u zeker dat u wilt doorgaan?',
  },
  errors: {
    deleteWalletSubscriptionError: 'Er is een fout opgetreden bij het wijzigen van het abonnement. Probeer het opnieuw of neem contact op met ons ondersteuningsteam',
    modifyVarOpportunityError: 'Er is een fout opgetreden bij het bewerken van de offerte. Probeer het opnieuw',
    error: 'Fout',
    loginFailed: 'Sectie verlopen. Meld u opnieuw aan',
    loginGoogleFailedTitle: 'Google-aanmelding mislukt',
    loginGoogleFailed: 'Zorg ervoor dat u bent aangemeld bij uw Google-account of probeer deze pagina te openen in een Google Chrome-sessie met uw Sofia GSuite-account',
    loginJagoGoogleFailedTitle: 'Jago-aanmelding mislukt',
    loginJagoGoogleFailed: 'Zorg ervoor dat u een gebruiker in Jago hebt die overeenkomt met uw Sofia GSuite-account en probeer het opnieuw',
    getDomainDetailsError: 'Er is een fout opgetreden bij het ophalen van de domeinconfiguratie. Probeer het opnieuw of neem contact op met Sofia Support',
    updateDomainConfigError: 'Er is een fout opgetreden bij het opslaan van de plantconfiguratie. Probeer het opnieuw of neem contact op met Sofia Support',
    updatePlantThemeError: 'Er is een fout opgetreden bij het opslaan van het plantthema. Probeer het opnieuw of neem contact op met Sofia Support',
    impersonateDomainUserError: 'Er is een fout opgetreden bij het imiteren van de gebruiker. Controleer of de gebruiker actief is en probeer het opnieuw of neem contact op met Sofia Support',
    createDomainError: 'Er is een fout opgetreden bij het maken van het nieuwe domein',
    addDomainPluginError: 'Er is een fout opgetreden bij het uitbreiden van het domein',
    addUserDomainError: 'Kan gebruiker niet aanmaken. Controleer of de gebruiker al bestaat in het domein of of het domein een geldig abonnement heeft',
    errorSaveAdditionalInfo: 'Kan info over het geselecteerde domein niet bijwerken',
    createCustomerError: 'Kan eindgebruiker niet aanmaken. Controleer of de eindgebruiker al bestaat en probeer het opnieuw',
    createUserError: 'Kan gebruiker niet aanmaken. Controleer of de gebruiker al bestaat en probeer het opnieuw',
    createVarUserError: 'Kan VAR-gebruiker niet aanmaken. Controleer of de gebruiker al bestaat en probeer het opnieuw',
    createPlantThemeError: 'Kan plantenthema niet aanmaken. Controleer gegevens en probeer het opnieuw',
    updateVarError: 'Kan niet updaten. Controleer gegevens en probeer het opnieuw',
    getCustomerDetailsError: 'Kan eindgebruikergegevens niet ophalen. Probeer het opnieuw',
    getVarDetailsError: 'Kan VAR-gegevens niet ophalen. Probeer het opnieuw',
    getPlantThemeDetailsError: 'Kan plantenthemagegevens niet ophalen. Probeer het opnieuw',
    getProductInfoPostDetailsError: 'Kan artikel niet ophalen. Probeer het opnieuw',
    required: 'Vereist',
    deleteDomainRequestError: 'Kan het verzoek niet verzenden. Probeer het opnieuw',
    deleteWalletRequestError: 'Kan het verzoek niet verzenden. Probeer het opnieuw',
    deleteCustomerRequestError: 'Kan het verzoek niet verzenden. Probeer het opnieuw',
    enableIrisDomainRequestError: 'Kan het verzoek niet verzenden. Probeer het opnieuw',
    createCustomerWalletError: 'Kan de nieuwe wallet niet aanmaken. Probeer het opnieuw',
    editCustomerWalletError: 'Kan de nieuwe wallet niet bewerken. Probeer het opnieuw',
    getWalletDetailsError: 'Kan de walletgegevens niet ophalen. Probeer het opnieuw',
    openSystemParametersError: 'Kan de systeemparameters niet openen. Probeer het opnieuw',
    createSubscriptionError: 'Kan het abonnement niet aanmaken. Probeer het opnieuw',
    createCardError: 'Er is een fout opgetreden bij het aanmaken van de opwaardeerkaart. Probeer het opnieuw',
    createVarOpportunityError: 'Er is een fout opgetreden tijdens het aanmaken. Probeer het opnieuw',
    editVarOpportunityError: 'Er is een fout opgetreden tijdens de bewerking. Probeer het opnieuw',
    rechargeWalletError: 'Er is een fout opgetreden tijdens het opladen van de portemonnee. Probeer het opnieuw',
    deleteCardError: 'Er is een fout opgetreden tijdens het verwijderen van de opwaardeerkaart. Probeer het opnieuw',
    enableCardError: 'Er is een fout opgetreden tijdens het updaten van de opwaardeerkaart. Probeer het opnieuw',
    deletePlantThemeError: 'Er is een fout opgetreden tijdens het verwijderen. Probeer het opnieuw',
    deleteVarUserError: 'Er is een fout opgetreden tijdens het verwijderen. Probeer het opnieuw',
    deleteVarUserErrorOpportunities: 'Deze gebruiker is de eigenaar van ten minste één offerte, dus deze kan niet worden verwijderd. Wijs de offertes die aan deze gebruiker zijn gekoppeld opnieuw toe en probeer het opnieuw.',
    deleteVarOpportunityError: 'Er is een fout opgetreden bij het verwijderen. Probeer het opnieuw',
    rejectVarOpportunityError: 'Er is een fout opgetreden bij het bijwerken van de offerte. Probeer het opnieuw',
    approveVarOpportunityError: 'Er is een fout opgetreden bij het bijwerken van de offerte. Probeer het opnieuw',
    syncsfdcidVarOpportunityError: 'Er is een fout opgetreden bij het bijwerken van de offerte. Probeer het opnieuw',
    upgradeVarOpportunityError: 'Er is een fout opgetreden bij het bijwerken van de offerte. Probeer het opnieuw',
    winVarOpportunityError: 'Er is een fout opgetreden bij het bijwerken van de offerte. Probeer het opnieuw',
    loseVarOpportunityError: 'Er is een fout opgetreden bij het bijwerken van de offerte. Probeer het opnieuw',
    deleteUserError: 'Er is een fout opgetreden bij het verwijderen. Probeer het opnieuw',
    createWalletSubscriptionError: 'Er is een fout opgetreden bij het koppelen van het abonnement aan de wallet. Probeer het opnieuw',
    createWalletPlantError: 'Er is een fout opgetreden bij het maken van het domein voor de wallet. Probeer het opnieuw',
    walletActionErrorNotEnoughCreditsTitle: 'Niet genoeg tegoed',
    walletActionErrorNotEnoughCredits: 'De wallet heeft niet genoeg tegoed om de bewerking uit te voeren. Controleer de bewerking die u uitvoert en probeer het opnieuw',
    warningMinCreditsCard: 'Waarschuwing! Minimaal aantal vereiste tegoeden = {{credits}}',
    loginJagoOTPEmailFailedTitle: 'Fout bij verzenden e-mail',
    loginJagoOTPEmailFailed: 'Er is een fout opgetreden bij het verzenden van de e-mail, probeer het over 5 minuten opnieuw',
    loginJagoOTPFailedTitle: 'Fout bij verificatie van code',
    loginJagoOTPFailed: 'Verificatie van de toegangscode is mislukt. Controleer de code of probeer het opnieuw na 5 minuten.',
    addonV364missing: 'De V364-add-on kon niet in het systeem worden gevonden. Meld deze fout om hulp te krijgen.',
  },
  gateways: {
    createdBy: 'Gemaakt door',
    noGatewayFoundTitle: 'Geen HyperGates gevonden',
    noGatewayFoundDescription: 'De lijst met HyperGates is leeg. Als u een zoekfilter hebt ingesteld, probeer het dan te wijzigen en probeer het opnieuw',
    gatewayName: 'HyperGate-naam',
    luckeyGatewaysTitle: 'HyerGates van het systeem',
    lastConnectionDate: 'Laatste verbinding: {{ date }}',
    gatewaysDevices: 'Apparaten',
    gatewaysArtifacts: 'Artefacten',
    gatewaysDeployments: 'Implementaties',
    HYPERGATE_PRO: 'HyperGate Pro',
    HYPERGATE_ISEO: 'HyperGate Lite',
    DONE: 'Voltooid',
    PENDING: 'Implementatie in behandeling',
    IN_PROGRESS: 'Implementatie in uitvoering',
    ERROR: 'Fout',
    deploymentDetail: 'Implementatiedetail',
    noDeploymentDetailedFound: 'Geen gateways gevonden voor implementatie',
    noDeploymentDetailedFoundDescription: 'De gatewaylijst voor deze implementatie is leeg',
    deviceId: 'Apparaat-ID',
    name: 'Naam',
    gatewayVersion: 'Versie',
    iseoSdkVersion: 'ISEO SDK',
    kernelVersion: 'Kernelversie',
    serialNumber: 'Serienummer',
    noGatewaysFound: 'Geen gateways gevonden',
    noGatewaysFoundDescription: 'De gatewaylijst is leeg. Probeer uw zoekfilters te wijzigen en probeer het opnieuw',
    noDeploymentFound: 'Geen implementatie gevonden',
    noArtifactFound: 'Geen artefact gevonden',
    noDeploymentFoundDescription: 'De implementatielijst voor deze implementatie is leeg. Als u een zoekfilter hebt ingesteld, probeer het dan te wijzigen en probeer het opnieuw',
    noArtifactFoundDescription: 'De artefactenlijst voor deze implementatie is leeg. Als u een zoekfilter hebt ingesteld, probeer het dan te wijzigen en probeer het opnieuw',
  },
  forms: {
    otp: 'OTP',
    notVisible: 'Niet zichtbaar',
    visible: 'Zichtbaar',
    exportAllUsers: 'Gebruikers exporteren',
    montlyBurnRate: '{{credits}}/maand',
    nextRechargeForecast: 'Verwachte volgende lading met huidig ​​verbruik ~{{date}}',
    lastUpdate: 'Laatste update {{time}}',
    rowsPerPage: 'Rijen per pagina',
    rolePermissions: 'Rolmachtigingen',
    permissionsDetails: 'Machtigingsdetails',
    clusterAndIseoCompany: 'Cluster en ISEO-bedrijf',
    createCardSuccess: 'Opwaardeerkaart succesvol aangemaakt',
    cardDetails: 'Kaartgegevens',
    status: 'Status',
    customerInfo: 'Klantgegevens',
    details: 'Details',
    costs: 'Kosten',
    hostName: 'Hostnaam',
    domainType: 'Type',
    user: 'Gebruiker',
    logoutConfirmation: 'Weet u zeker dat u wilt uitloggen?',
    send: 'Verzenden',
    askQuestion: 'Neem contact met ons op',
    ticketOpenSuccessfully: 'Uw vraag is verzonden naar ons team! Een van onze leden neemt binnenkort contact met u op.',
    ticketOpenError: 'Er is een fout opgetreden bij het verzenden van het verzoek. Probeer het opnieuw',
    writeUsAQuestionDescription: 'Hebt u een vraag? Schrijf ons gerust, en een van onze productspecialisten staat klaar om u te helpen met al uw vragen.',
    deviceType: 'Apparaattype',
    createNewMarketplace: 'Nieuwe marktplaats',
    createNewCustomer: 'Nieuw',
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    email: 'E-mail',
    contactEmail: 'Contact-e-mail',
    open: 'Openen',
    add: 'Toevoegen',
    actions: 'Acties',
    search: 'Zoeken',
    remove: 'Verwijderen',
    error: 'Fout',
    copy: 'Kopiëren',
    cancel: 'Annuleren',
    confirm: 'Bevestigen',
    warning: 'Waarschuwing',
    reset: 'Resetten',
    close: 'Sluiten',
    save: 'Opslaan',
    delete: 'Verwijderen',
    deleteRequest: 'Verwijderingsverzoek verzenden',
    deleteAdminOnly: 'Verwijderen (beheerder)',
    export: 'Exporteren',
    spinnerLoading: 'Laden...',
    createNewDomain: 'Nieuw domein maken',
    walletName: 'Portemonneenaam',
    domainName: 'Domeinnaam',
    domainNamePreview: 'Voorbeeld',
    domainFormTitle: 'Nieuw Luckey-domein maken',
    invalidDomainName: 'Ongeldige domeinnaam. Geen spaties, alleen kleine letters, cijfers en streepjes toegestaan',
    selectProduct: 'Selecteer een abonnementstype',
    name: 'Voornaam',
    lastname: 'Achternaam',
    createNewUser: 'Maak nieuwe Manager',
    selectRole: 'Selecteer gebruikersrol',
    createUserForDomain: 'Maak een nieuwe gebruiker voor het geselecteerde domein',
    newUser: 'Nieuwe gebruiker',
    password: 'Wachtwoord',
    letUserChoosePassword: 'Laat gebruiker wachtwoord kiezen: stuur wachtwoordinstellingslink naar gebruikers-e-mail',
    domainCreateSuccess: 'Domein succesvol aangemaakt',
    domainCreateUserAfterCreation: 'Wilt u een gebruiker aanmaken voor het nieuwe domein?',
    domainCreationConfirm: 'Wilt u het nieuwe domein aanmaken?',
    createDomain: 'Domein aanmaken',
    username: 'Gebruikersnaam',
    domainExtendsConfirm: 'Wilt u het domein uitbreiden met de geselecteerde product?',
    createNewInstaller: 'Nieuwe installer maken',
    createInstallerForDomain: 'Nieuwe installer-gebruiker maken voor het domein',
    deleteDomain: 'Domeinverwijdering aanvragen',
    active: 'Actief',
    disabled: 'Uitgeschakeld',
    minutes: '{{minutes}} minuten',
    selectSessionDurationPlaceholder: 'Sessieduur',
    impersonateUserDescription: 'Voordat u zich voordoet als de gebruiker in het systeem, informeren wij u over het volgende:',
    impersonateUserDescriptionOne: 'Het begin van de sessie en de duur ervan worden weergegeven in de domeinlogboeken',
    impersonateUserDescriptionTwo: 'Elke actie die u uitvoert op het implantaat heeft een onmiddellijk en in sommige gevallen onomkeerbaar effect',
    selectSessionDuration: 'Selecteer de duur van de ondersteuningssessie',
    impersonateReadOnly: 'Alleen-lezenmodus',
    impersonateReadOnlyDescription: 'Open het systeem in alleen-lezenmodus, u kunt de systeemgegevens bekijken, maar niet wijzigen',
    impersonateReadOnlyWarning: 'Let op: de supportsessie en de duur ervan zijn nog steeds zichtbaar in de systeemlogboeken',
    installationModeActive: 'Actief',
    installationModeDisabled: 'Uitgeschakeld',
    customerFormTitle: 'Luckey End-user',
    varFormTitle: 'Value Added Reseller (VAR)',
    varFormAdminSection: 'Admin-sectie',
    varFormTitleVar: 'Bedrijfsinfo',
    plantThemeFormTitle: 'Luckey Plant Theme',
    customerName: 'Eindgebruikersnaam',
    invalidCustomerName: 'Eindgebruikersnaam niet geldig',
    createNewWallet: 'Nieuwe portemonnee',
    whatsNewTitle: 'Luckey what\'s new!',
    whatsNewSubtitle: 'Ontdek het laatste nieuws over Luckey en Sofia, onderdeel van ISEO',
    notes: 'Aanvullende opmerkingen',
    cardCode: 'Oplaadcode',
    recharge: 'Opwaarderen',
    rechargeWalletFormDescription: 'Vul de credits van deze wallet op door de top up code in te voeren. De opwaardering wordt direct van kracht',
    subscriptionName: 'Abonnementsnaam',
    subscriptionFixedPrice: 'Activeringskosten (credits)',
    subscriptionBurnRate: 'Terugkerende kosten (credits)',
    subscriptionVisible: 'Zichtbaar in marketplace',
    createNewSubscriptionDescription: 'Maak een nieuw abonnement aan in het systeem. Het abonnement is beschikbaar voor alle domeinen',
    subscriptionLogicDescription: 'Geeft aan welke elementen van het systeem worden geteld om de systeembeperkingen te bepalen',
    FIXED: 'Plaats geen beperkingen op elementen',
    COUNT_USERS_LOCKS: 'Beperk het aantal gebruikers en domeinvergrendelingen',
    COUNT_LOCKS: 'Beperk alleen het aantal domeinvergrendelingen',
    COUNT_ELEMENTS: 'Beperk de som van vergrendelingen en domeingebruikers',
    maxLocks: 'Maximaal aantal slimme vergrendelingen',
    maxUsers: 'Maximaal aantal gebruikers',
    maxElements: 'Maximaal aantal elementen (gebruikers+vergrendelingen)',
    subscriptionLogic: 'Elementlimietlogica',
    selectLogic: 'Selecteren',
    subscriptionMaxLocksDescription: 'Voer het maximale aantal vergrendelingen in dat met dit abonnement kan worden geïnstalleerd',
    subscriptionMaxUsersDescription: 'Voer het maximale aantal gebruikers die met dit abonnement geactiveerd kunnen worden',
    subscriptionMaxElementsDescription: 'Voeg het maximale aantal elementen in met dit abonnement',
    subscriptionResource: 'Resource',
    cardQuantity: 'Aantal credits',
    cardQuantityDescription: 'Geeft het aantal credits aan dat wordt opgeladen bij gebruik van de opwaardeerkaart',
    cardVar: 'Opwaardeerkaart toewijzen aan VAR',
    cardWallet: 'Opwaardeerkaart toewijzen aan specifieke portemonnee',
    cardNotes: 'Aanvullende notities',
    createCardConfirm: 'Weet u zeker dat u de opwaardeerkaart wilt aanmaken?',
    createVarOpportunityConfirm: 'Weet u zeker dat u deze nieuwe offerte wilt aanmaken?',
    createSubscrptionConfirm: 'Weet u zeker dat u het nieuwe abonnement wilt aanmaken?',
    rechargeWalletConfirm: 'U staat op het punt de portemonnee van deze eindgebruiker op te laden met nieuwe credits. Wilt u doorgaan? Bewerking kan niet ongedaan worden gemaakt',
    createdAt: 'Aanmaakdatum',
    copySuccess: 'Gekopieerd!',
    logout: 'Uitloggen',
    deleteCardConfirm: 'Weet u zeker dat u deze opwaardeerkaart wilt verwijderen? Bewerking kan niet ongedaan worden gemaakt',
    enableCardConfirm: 'Weet u zeker dat u deze opwaardeerkaart wilt inschakelen? Zodra deze kaart is ingeschakeld, kan deze worden gebruikt. Bewerking kan niet ongedaan worden gemaakt',
    subscriptionTierSelectionTitle: 'Hoofdabonnement',
    subscriptionTierSelectionDescription: 'Door het hoofdabonnement te selecteren, neemt de nieuwe add-on automatisch de tellogica van het hoofdabonnement over',
    selectTier: 'Abonnement selecteren',
    createPluginSubscriptionConfirm: 'Weet u zeker dat u deze nieuwe add-on/serviceabonnement wilt maken?',
    createWalletSubscriptionConfirm: 'Weet u zeker dat u dit abonnement wilt koppelen aan de geselecteerde wallet? Abonnement wordt geactiveerd voor alle planten die door deze wallet worden beheerd',
    deleteWalletSubscriptionConfirm: 'Weet u zeker dat u dit abonnement wilt verwijderen uit de geselecteerde wallet? Abonnement wordt verwijderd van alle planten die door deze wallet worden beheerd',
    editSubscriptionConfirm: 'Weet u zeker dat u het abonnement wilt bewerken?',
    walletSelectSubscriptionTitle: 'Abonnement om te activeren',
    walletSelectSubscriptionDescription: 'Selecteer het abonnement dat u op deze wallet wilt activeren. Onthoud dat het abonnement ook wordt geactiveerd in alle systemen die via deze wallet worden beheerd',
    createWalletPlantConfirm: 'Weet u zeker dat u het nieuwe domein voor de geselecteerde wallet wilt aanmaken?',
    enableEmailForPlant: 'E-mailservice inschakelen',
    enableEmailForPlantDescription: 'Het verzenden van e-mails voor dit systeem inschakelen (activeringsmail, melding, enz.)',
    enableIrisRequiredForPlant: '1ris-service vereisen',
    enableIrisRequiredForPlantDescription: 'Als dit is geselecteerd, wordt het domein voorbereid om 1ris-service op te nemen',
    cardSubscriptionsLinked: 'Gekoppelde abonnementen',
    cardSubscriptionsLinkedDescription: 'Koppel meerdere add-ons of services aan deze top-upkaart om ze te activeren zodra de kaart is gebruikt',
    mainTierDescription: 'Koppel een hoofdabonnement aan deze top-upkaart om deze te activeren zodra de kaart is gebruikt',
    cardMarketplacesLinked: 'Marktplaats voor licentiekoppeling',
    cardMarketplacesLinkedDescription: 'Selecteer een marktplaats om de abonnementen te kiezen die aan deze opwaardeerkaart moeten worden gekoppeld',
    sku: 'SKU',
    customerData: 'Gegevens van eindgebruiker',
    customerVat: 'BTW',
    customerFiscalCode: 'Fiscale code',
    customerCountry: 'Land',
    customerAddress: 'Adres',
    customerCity: 'Plaats',
    customerEmail: 'E-mail',
    customerPhone: 'Telefoon',
    customerCrmId: 'SFDC ACCOUNT-ID',
    customerMarketplace: 'Marktplaats',
    customerCertifiedEmail: 'PEC (gecertificeerd e-mailadres)',
    customerCluster: 'Cluster',
    customerVar: 'VAR',
    customerCompany: 'ISEO-bedrijf',
    customerPostalCode: 'Postcode',
    customerPrincipalSubDivision: 'Provincie, staat, provincie, etc.',
    iseoSalesOrderReference: 'ISEO-verkooporder (ISEO -> klant)',
    iseoSalesOrderDate: 'ISEO-verkooporderdatum',
    iseoPurchaseOrderReference: 'ISEO-inkooporder (ISEO -> Sofia)',
    iseoPurchaseOrderDate: 'ISEO-inkooporderdatum',
    sofiaSalesOrderReference: 'Sofia-verkooporder (Sofia -> ISEO)',
    sofiaSalesOrderDate: 'Sofia-verkooporderdatum',
    pickDate: 'Kies datum',
    crmSource: 'CRM-kansbron',
    crmId: 'Unieke CRM-kansidentificatie',
    sofiaProjectId: 'Sofia-projectreferentie-identificatie',
    crmLink: 'URL naar CRM-kans',
    kamReference: 'KAM-referentie',
    edit: 'Bewerken',
    editCardConfirm: 'Weet u zeker dat u de top-upkaart wilt bewerken?',
    editVarOpportunityConfirm: 'Weet u zeker dat u deze offerte wilt bewerken?',
    insertEmailOTPLoginForm: 'Voer uw account-e-mailadres in om de OTP-code te ontvangen en toegang te krijgen tot het platform',
    insertEmailOTPLoginFormDisclaimer: 'Als het e-mailadres is geautoriseerd, ontvangt u een OTP-code om toegang te krijgen',
    insertOTPOTPLoginForm: 'Voer de ontvangen OTP-code in op uw account-e-mailadres',
    insertOTPOTPLoginFormDisclaimer: 'Als u de code na 5 minuten nog niet hebt ontvangen, herhaalt u de handeling',
    pickDateTo: 'Kies datum tot:',
    pickDateFrom: 'Kies datum van',
    AUTOMATIC: 'Automatische activering',
    MANUAL: 'Handmatige activering',
    SEMI_AUTOMATIC: 'Semi-automatische activering',
    AUTOMATIC_Description: 'De extensie kan onafhankelijk van de winkel door de gebruiker worden geactiveerd. Activeringscredits worden direct afgeschreven',
    MANUAL_Description: 'Extensie die alleen kan worden geactiveerd na een expliciet verzoek van de eindgebruiker. De credits worden handmatig afgeschreven na goedkeuring van het verzoek',
    SEMI_AUTOMATIC_Description: 'Extensie die alleen kan worden geactiveerd na een expliciet verzoek van de eindgebruiker. De activeringscredits worden afgetrokken op het moment van de aanvraag',
    requestToQuote: 'Aangevraagde offerte',
    requestToQuoteDescription: 'Geef aan of de extensie onderhevig is aan een offerte in plaats van direct gebruik van credits (bijv. integraties en aanpassingen)',
    subscriptionCanBeDeactivated: 'Kan worden uitgeschakeld via de manager',
    subscriptionCanBeDeactivatedDescription: 'Geef aan of de manager het abonnement kan uitschakelen via Luckey Store',
    selectSegmentType: 'Selecteer segment',
    sofiaInvoiceNumber: 'Sofia Factuurnummer',
    sofiaInvoiceAmount: 'Sofia Factuurbedrag',
    sofiaInvoiceYear: 'Sofia Factuurjaar',
    iseoInvoiceNumber: 'ISEO Factuurnummer',
    iseoInvoiceAmount: 'ISEO Factuurbedrag',
    iseoInvoiceYear: 'ISEO Factuurjaar',
    freeOfCharge: 'Gratis',
    freeOfChargeReason: 'Reden',
    currency: 'Valuta',
    sofiaInvoiceDate: 'Factuurdatum',
    iseoInvoiceDate: 'Factuurdatum',
    sofiaInvoiceRecipient: 'Factuurontvanger',
    iseoInvoiceRecipient: 'Factuurontvanger',
    serviceSupplementaryInvoice: 'Aanvullende factuurservice',
    serviceSupplementaryInvoiceNumber: 'Factuurnummer',
    serviceSupplementaryInvoiceAmount: 'Factuurbedrag',
    serviceSupplementaryInvoiceDate: 'Factuurdatum',
    serviceSupplementaryInvoiceRecipient: 'Factuurontvanger',
    iseoInvoiceIssuer: 'Factuuruitgever',
    backdropClosing: 'Weet u zeker dat u het formulier wilt sluiten zonder op te slaan?',
    roles: 'Rollen',
    selectBotType: 'Selecteer Assistent Type',
    helperBot: 'Product Assistent',
    commercialBot: 'Commerciële Assistent',
    talkWithUs: 'Praat met ons',
    noBotTypeSelected: 'Geen assistent type geselecteerd',
  },
  activations: {
    getInfo: '(Meer informatie over het proces)',
    createNewCustomer: 'Eindgebruiker aanmaken',
    createNewWallet: 'Portemonnee aanmaken',
    createNewScratchcard: 'Opwaardeerkaart aanmaken',
    yearsString: 'jaren',
    monthsString: 'maanden',
    daysString: 'dagen',
    addNewActivationButton: 'Systeemactivering toevoegen',
    home: 'OD Home',
    activationRequests: 'Systeemactivering',
    rechargeRequests: 'Opwaarderen portemonnee',
    walletStatus: 'Orderstatus',
    noActivationsFoundTitle: 'Geen systeemactiveringen gevonden',
    noActivationsFoundDescription: 'Er zijn geen systeemactiveringen in het systeem. Maak nieuwe om te beginnen',
    selloutOrder: 'Uitverkooporder',
    intragroupOrder: 'Intragroeporder',
    custref: 'Cust. Ref.',
    rejectedReason: 'Reden voor afwijzing',
    changeStatusToUnderReview: 'Verzenden ter beoordeling',
    changeStatusToInvoiced: 'Verzenden naar gefactureerd',
    changeStatusToCreated: 'Verzenden naar gemaakt',
    changeStatusToRejected: 'Aanvraag afwijzen',
    changeStatusToProcessing: 'Aanvraag accepteren',
    changeStatusAdmin: 'Status instellen (beheerder)',
    changeCustRef: 'Klant instellen. Ref.',
    search: 'Zoeken...',
    resetFilters: 'Filters resetten',
    statusSelection: 'Elke staat',
    clusterSelection: 'Elke cluster',
    statusDRAFT: 'Concept',
    statusUNDER_REVIEW: 'Wordt beoordeeld',
    statusREJECTED: 'Afgewezen',
    statusPROCESSING: 'Verwerken',
    statusCREATED: 'Aangemaakt',
    statusINVOICED: 'Gefactureerd',
    statusACTIVE: 'Actief',
    statusDELETED: 'Verwijderd',
    promote: 'Verwijderd',
    saveDraft: 'Opslaan als concept',
    customMarketplaceWarning: 'De eindgebruiker is niet gekoppeld aan de standaardmarktplaats. U moet handmatig het gevraagde basislijnabonnement en de add-ons opgeven in de sectie \'Notities\'',
    subscriptionNotFoundError: 'Abonnement niet gevonden',
    deleteActivationConfirm: 'Weet u zeker dat u deze systeemactivering wilt verwijderen? De bewerking kan niet ongedaan worden gemaakt',
    deleteActivationError: 'Er is een fout opgetreden bij het verwijderen van de systeemactivering. Probeer het opnieuw',
    promoteActivationConfirm: 'Weet u zeker dat u deze systeemactivering wilt verzenden om te bekijken? Het verzoek tot systeemactivering kan niet worden bewerkt terwijl het wordt beoordeeld.',
    promoteActivationError: 'Er is een fout opgetreden bij het promoten van de systeemactivering. Probeer het opnieuw',
    createdActivationConfirm: 'Weet u zeker dat u deze systeemactivering wilt verzenden naar de status CREATED?',
    createdActivationError: 'Er is een fout opgetreden bij het bijwerken van de status van de systeemactivering. Probeer het opnieuw',
    invoicedActivationConfirm: 'Weet u zeker dat u deze systeemactivering wilt instellen als gefactureerd?',
    invoicedActivationError: 'Er is een fout opgetreden bij het bijwerken van de status van de systeemactivering. Probeer het opnieuw',
    editStatusActivationConfirm: 'Weet u zeker dat u de status van deze systeemactivering wilt bewerken? De bewerking kan niet ongedaan worden gemaakt',
    editStatusActivationError: 'Er is een fout opgetreden bij het bijwerken van de status van de systeemactivering. Probeer het opnieuw',
    rejectActivationConfirm: 'Geef de reden op voor het afwijzen van dit verzoek.',
    rejectActivationError: 'Er is een fout opgetreden bij het afwijzen van de systeemactivering. Probeer het opnieuw',
    processingActivationConfirm: 'Weet u zeker dat u deze systeemactivering wilt accepteren?',
    processingActivationError: 'Er is een fout opgetreden bij het accepteren van de systeemactivering. Probeer het opnieuw',
    newFileName: 'Geef de naam van het bestand op',
    newFileExtensionError: 'Het bestand heeft een niet-ondersteunde extensie',
    newFileDuplicateNameError: 'Deze naam is al gebruikt voor een ander bestand. Wijzig de naam en herhaal de bewerking.',
    editCustrefConfirm: 'Geef de Cust. Ref. voor deze aanvraag op.',
    editCustrefError: 'Er is een fout opgetreden bij het bijwerken van de Cust. Ref., probeer het opnieuw',
    editActivation: 'Systeemactivering bewerken',
    newActivation: 'Systeemactivering toevoegen',
    viewActivation: 'Systeemactivering weergeven',
    newActivationError: 'Er is een fout opgetreden bij het maken van deze systeemactivering. Probeer het opnieuw',
    editActivationError: 'Er is een fout opgetreden bij het bijwerken van deze systeemactivering. Probeer het opnieuw',
    customerSearch: 'Zoeken tussen bestaande eindgebruikers',
    customerSearchEnterName: 'Zoeken op naam...',
    customerSearchNoMatch: 'Geen eindgebruikers gevonden...',
    customerProceedWithSelected: 'Gebruik geselecteerde eindgebruiker',
    customerProceedWithNew: 'Nieuwe eindgebruiker maken',
    customerConfirmData: 'Bevestig eindgebruikersgegevens',
    customerData: 'Klantgegevens',
    varData: 'VAR-gegevens',
    selectCustomerOrVar: 'Selecteer het doel voor deze aanvraag (eindgebruiker of VAR)',
    varSfdcId: 'SFDC ACCOUNT-ID',
    varName: 'Naam',
    varSearch: 'Zoeken tussen bestaande VAR\'s',
    varSearchEnterName: 'Zoeken op naam...',
    varSearchNoMatch: 'Geen VAR gevonden...',
    varCustomerSearch: 'Zoeken tussen bestaande Eindgebruikers van VAR\'s',
    varProceedWithSelected: 'Gebruik geselecteerde VAR',
    customerFormTitle: 'Luckey End-user',
    customerName: 'Eindgebruikersnaam',
    customerVat: 'BTW',
    customerFiscalCode: 'Fiscale code',
    customerCountry: 'Land',
    customerAddress: 'Adres',
    customerCity: 'Stad',
    customerEmail: 'E-mail',
    customerPhone: 'Telefoon',
    customerCrmId: 'SFDC ACCOUNT-ID',
    customerMarketplace: 'Marktplaats',
    customerCertifiedEmail: 'PEC (gecertificeerd e-mailadres)',
    customerCluster: 'Cluster',
    customerCompany: 'ISEO-bedrijf',
    customerPostalCode: 'Postcode',
    customerPrincipalSubDivision: 'Provincie, staat, provincie, enz.',
    exportActivationsCSV: 'CSV exporteren',
    salesAndOrdersData: 'Verkoop en bestellingen',
    basicData: 'Fabrieksgegevens',
    formEnterAnswer: 'Uw antwoord',
    formEnterAnswerGT0: 'Getal groter dan nul',
    formEnterAnswerFrom0To600: 'Nummer tussen 0 en 600',
    formEnterAnswerOwnerEmail: 'E-mailadres eigenaar',
    formEnterAnswerKamEmail: 'E-mailadres Key Account Manager',
    formEnterAnswerPmEmail: 'PM-mailadres',
    formEnterAnswerSystemEngineerEmail: 'E-mailadres System Engineer',
    optId: 'Opportunity ID (SFDC)',
    salesOrderConfirmationNumber: 'Bevestigingsnummer verkooporder (uitverkoop)',
    salesOrderConfirmationNumber_desc: '',
    selloutFiles: 'PDF-uitverkooporder (hardware + software + professionele services)',
    selloutFiles_desc: 'Nodig om Blossom-inkomsten correct bij te houden.',
    selloutFiles_desc2: 'Limiet bestandsnummer: 5 ~ Limiet enkele bestandsgrootte: 10 MB ~ Toegestane bestandsextensies: ',
    selloutFilesAdd: 'Bestand uploaden',
    selloutFilesDownload: 'Downloaden',
    selloutFilesReplace: 'Vervangen',
    quoteToolFile: 'Offertetool (niet verplicht)',
    quoteToolFile_desc: 'Upload het offertetoolbestand dat voor deze aanvraag is gebruikt.',
    quoteToolFile_desc2: 'Bestandsnummerlimiet: 1 ~ Limiet voor enkele bestandsgrootte: 10 MB',
    purchaseOrderNumberSofia: 'Aankoopordernummer naar Sofia',
    baselineSubscription: 'Baseline Luckey-abonnement',
    baselineSubscription_BASIC: 'Luckey Basic',
    baselineSubscription_ENTERPRISE: 'Luckey Enterprise (Standard)',
    baselineSubscription_ENGINE: 'Luckey Engine',
    baselineSubscription_undefined: 'Gebruik aangepast abonnement',
    subscriptionData: 'Abonnementsinfo',
    noSubscriptionFound: 'Nog geen abonnement gevonden.',
    noSubscriptionFoundPleaseCheck: 'Controleer de velden ',
    noSubscriptionFoundDescription: 'Abonnementslijst is leeg. Als u een zoekfilter hebt ingesteld, probeer deze dan opnieuw in te stellen en probeer het opnieuw',
    selectedSubscription: 'Geselecteerd abonnement:',
    plantName: 'Systeemnaam',
    plantName_desc: 'Kleine letters, zonder spaties en, indien nodig, gescheiden door streepjes - bijv. example-plant-name',
    keyAccountManager: 'Key Account Manager',
    keyAccountManager_desc: 'E-mailadres van de Key Account Manager',
    systemManagerEmails: 'E-mailadres van de systeembeheerder (één of meer dan één)',
    systemManagerEmails_desc: 'E-mailadres van de persoon die verantwoordelijk is voor het beheren van toegangsrechten voor gebruikers van het webbeheerplatform',
    installerEmail: 'E-mailadres van de installateur (niet verplicht)',
    installerEmail_desc: '',
    pmEmail: 'PM-e-mailadres (niet verplicht)',
    pmEmail_desc: 'Moet behoren tot @sofialocks.com of @iseo.com',
    systemEngineerEmail: 'Systeem Engineer email (niet verplicht)',
    systemEngineerEmail_desc: 'Moet behoren tot @sofialocks.com of @iseo.com',
    numberOfElements: 'Aantal elementen om te beheren',
    numberOfElements_desc: 'Elementen zijn de som van: gebruikers, sloten en alle actieve uitnodigingen',
    V364orF9000: 'V364/1RIS-sloten of F9000-cilinders?',
    V364orF9000_desc: 'Zijn er V364/1RIS-sloten of F9000-cilinders in het systeem? Als dat zo is, wordt de bijbehorende add-on automatisch toegevoegd.',
    addons: 'Add-ons & Plug-ins',
    addons_desc: 'Selecteer de add-ons die u wilt toevoegen aan het basisabonnement',
    credits: 'Aantal credits',
    credits_desc: 'Credits verkocht met deze systeemactivering',
    expectedEnd: 'Volgens uw selectie raakt u door uw credits heen in:',
    finalData: 'Overige informatie',
    notes: 'Notities',
    notes_desc: 'Schrijf hier eventuele aanvullende opmerkingen over dit verzoek',
    notifications: 'Meldingen',
    notifications_desc: 'Personen die op de hoogte moeten worden gesteld voor gebeurtenissen; standaard worden de eigenaar van het verzoek en de bijbehorende Key Account Manager op de hoogte gesteld',
    editBaselineSubscriptionWarning: 'Weet u zeker dat u het basisabonnement wilt wijzigen? Hiermee worden de geselecteerde add-ons van het formulier gereset.',
    editBaselineSubscriptionError: 'Er is een fout opgetreden, probeer het opnieuw',
    editNumberOfElementsWarning: 'Weet u zeker dat u het aantal elementen wilt wijzigen? Hiermee worden de beschikbare add-ons van het formulier gereset.',
    editNumberOfElementsError: 'Er is een fout opgetreden, probeer het opnieuw',
    customerMarketplaceError: 'De geselecteerde eindgebruiker heeft geen gekoppelde marktplaats. Neem contact op met de ondersteuning om het probleem op te lossen.',
    customerClusterError: 'De geselecteerde eindgebruiker heeft geen gekoppelde cluster. Neem contact op met de ondersteuning om het probleem op te lossen.',
    invoiceData: 'Factuur',
    invoiceFile: 'Factuur PDF *',
    invoiceFile_desc: 'Upload de factuur PDF.',
    invoiceFile_desc2: 'Bestandsnummerlimiet: 1 ~ Limiet voor enkele bestandsgrootte: 10 MB',
    invoiceNumber: 'Uitverkoopfactuurnummer en datum (XXXXXX - DD/MM/JJJJ)',
    invoiceNumberPlaceholder: 'XXXXXX - DD/MM/JJJJ',
    setInvoiceData: 'Upload factuur',
    dataMissingCreated: 'Geef factuurnummer en PDF op om door te gaan',
  },
  recharges: {
    addNewRechargeButton: 'Voeg portemonnee-opwaardering toe',
    noRechargesFoundTitle: 'Geen opwaarderingen gevonden',
    noRechargesFoundDescription: 'Er zijn geen portemonnee-opwaarderingen in het systeem. Maak nieuwe om te beginnen',
    customerAndWallet: 'Eindgebruiker en portemonnee',
    seeCustomer: 'Info',
    seeCustomerDetails: 'Bekijk eindgebruikersgegevens',
    selloutOrder: 'Uitverkooporder',
    intragroupOrder: 'Intragroepsorder',
    custref: 'Klant Ref.',
    mode:'Contractvernieuwingstype:',
    creditsSuggested:'Credits suggested',
    creditsConfirmed:'Credits confirmed',
    rejectedReason: 'Rejected reason',
    deletedReason: 'Deleted reason',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
    customerSearch: 'Zoeken tussen bestaande eindgebruikers',
    customerSearchEnterName: 'Zoeken op naam...',
    customerSearchNoMatch: 'Geen eindgebruikers gevonden...',
    varSearch: 'Zoeken tussen bestaande VAR\'s',
    varSearchEnterName: 'Zoeken op naam...',
    varSearchNoMatch: 'Geen VAR gevonden...',
    varCustomerSearch: 'Zoeken tussen bestaande VAR\'s',
    plantSearch: 'Selecteer de gewenste plant',
    plantSearchEnterName: 'Selecteer plant',
    plantSearchNoMatch: 'Geen plant gevonden...',
    plantProceedWithSelected: 'Gebruik geselecteerde plant/wallet',
    dataMissingTakenCharge: 'Geef bevestigde credits en uitverkoop op om door te gaan',
    dataMissingScratchcardCreated: 'Geef factuurnummer en PDF op om door te gaan',
    changeStatusToSelloutOrderGenerated: 'Verzenden ter beoordeling',
    changeStatusToInvoiced: 'Verzenden naar gefactureerd',
    changeStatusToCreated: 'Verzenden naar gemaakt',
    changeStatusToRejected: 'Verzoek afwijzen',
    changeStatusToScratchcardCreated: 'Opwaarderen gemaakt',
    changeStatusToWalletRecharged: 'Wallet opgeladen',
    changeStatusAdmin: 'Status instellen (beheerder)',
    createCard: 'Opwaardeerkaart maken',
    changeCustRef: 'Klant instellen Ref.',
    search: 'Zoeken...',
    resetFilters: 'Filters resetten',
    statusSelection: 'Elke staat',
    clusterSelection: 'Elke cluster',
    statusTO_BE_MANAGED: 'Wordt beheerd',
    statusTAKEN_CHARGE: 'Accepteren',
    statusREQUEST_TO_DELETE: 'Verzoek om te verwijderen',
    statusSELLOUT_ORDER_GENERATED: 'Wordt beoordeeld',
    statusSCRATCHCARD_CREATED: 'Opwaarderen aangemaakt',
    statusWALLET_RECHARGED: 'Opwaarderen',
    statusREJECTED: 'Afgewezen',
    statusDELETED: 'Verwijderd',
    statusINVOICED: 'Gefactureerd',
    promote: 'Verwijderd',
    saveRequest: 'Verzoek opslaan',
    createRequest: 'Creëer portemonnee-opwaardering',
    takeCharge: 'Accepteren',
    AUTO_RENEWABLE: 'Automatisch verlengbaar',
    NOT_AUTO_RENEWABLE: 'NIET automatisch verlengbaar',
    deleteRechargeConfirm: 'Weet u zeker dat u dit verzoek wilt verwijderen? Geef de reden op. De bewerking kan niet ongedaan worden gemaakt',
    deleteAdminRechargeConfirm: 'Weet u zeker dat u dit verzoek permanent wilt verwijderen? De bewerking kan niet ongedaan worden gemaakt',
    deleteRechargeError: 'Er is een fout opgetreden bij het verwijderen van het verzoek. Probeer het opnieuw',
    takechargeRechargeConfirm: 'Geef het type verlenging op om door te gaan met dit verzoek',
    takechargeRechargeDirectConfirm: 'Weet u zeker dat u dit verzoek wilt overnemen?',
    takechargeRechargeError: 'Er is een fout opgetreden bij het overnemen van het verzoek. Probeer het opnieuw',
    promoteRechargeConfirm: 'Weet u zeker dat u dit verzoek wilt verzenden om te beoordelen? Het verzoek kan niet worden bewerkt terwijl het wordt beoordeeld.',
    promoteRechargeError: 'Er is een fout opgetreden bij het promoten van het verzoek. Probeer het opnieuw',
    createdRechargeConfirm: 'Weet u zeker dat u dit verzoek naar de status CREATED wilt sturen?',
    createdRechargeError: 'Er is een fout opgetreden bij het bijwerken van de status van het verzoek. Probeer het opnieuw',
    invoicedRechargeConfirm: 'Weet u zeker dat u dit verzoek wilt instellen als gefactureerd?',
    invoicedRechargeError: 'Er is een fout opgetreden bij het bijwerken van de status van het verzoek. Probeer het opnieuw',
    editStatusRechargeConfirm: 'Weet u zeker dat u de status van dit verzoek wilt bewerken? De bewerking kan niet ongedaan worden gemaakt',
    editStatusRechargeError: 'Er is een fout opgetreden bij het bijwerken van de status van het verzoek. Probeer het opnieuw',
    rejectRechargeConfirm: 'Geef de reden op voor het afwijzen van dit verzoek.',
    rejectRechargeError: 'Er is een fout opgetreden bij het afwijzen van het verzoek. Probeer het opnieuw',
    scratchcardCreatedRechargeConfirm: 'Weet u zeker dat u dit verzoek wilt accepteren en wilt bevestigen dat de opwaardeerkaart is aangemaakt?',
    scratchcardCreatedRechargeError: 'Er is een fout opgetreden bij het accepteren van het verzoek. Probeer het opnieuw',
    walletRechargedRechargeConfirm: 'Weet u zeker dat u dit verzoek wilt instellen als voltooid en wilt bevestigen dat de portemonnee is opgeladen?',
    walletRechargedRechargeError: 'Er is een fout opgetreden in de wijziging van de status van de portemonnee. Om dit te bevestigen',
    newFileName: 'Geef de naam van het bestand op',
    newFileExtensionError: 'Het bestand heeft een niet-ondersteunde extensie',
    newFileDuplicateNameError: 'Deze naam is al gebruikt voor een ander bestand. Wijzig de naam en herhaal de bewerking.',
    editCustrefConfirm: 'Geef de Cust. Ref. op.',
    editCustrefError: 'Er is een fout opgetreden bij het bijwerken van de Cust. Ref., probeer het opnieuw',
    editRecharge: 'Bewerk portemonnee-opwaardering',
    newRecharge: 'Voeg portemonnee-opwaardering toe',
    viewRecharge: 'Bekijk portemonnee-opwaardering',
    newRechargeError: 'Er is een fout opgetreden bij het maken van deze aanvraag. Probeer het opnieuw',
    editRechargeError: 'Er is een fout opgetreden bij het bijwerken van deze aanvraag. Probeer het opnieuw',
    exportRechargesCSV: 'CSV exporteren',
    basicData: 'Parameters voor opwaarderen portemonnee',
    ordersData: 'Orders',
    invoiceData: 'Factuur',
    formEnterAnswer: 'Uw antwoord',
    formEnterAnswerGT0: 'Nummer groter dan nul',
    sfdcId: 'Opportunity ID (SFDC)',
    salesOrderConfirmationNumber: 'Bevestigingsnummer verkooporder (uitverkoop)',
    salesOrderConfirmationNumber_desc: '',
    selloutFiles: 'PDF-order uitverkoop (hardware + software + professionele services)',
    selloutFiles_desc: 'Nodig om Blossom-inkomsten correct bij te houden.',
    selloutFiles_desc2: 'Limiet bestandsnummer: 5 ~ Limiet enkele bestandsgrootte: 10 MB ~ Toegestane bestandsextensies: ',
    selloutFilesAdd: 'Uploaden bestand',
    selloutFilesDownload: 'Downloaden',
    selloutFilesReplace: 'Vervangen',
    invoiceFile: 'Factuur PDF *',
    invoiceFile_desc: 'Upload de factuur PDF.',
    invoiceFile_desc2: 'Bestandsnummerlimiet: 1 ~ Limiet voor enkele bestandsgrootte: 10 MB',
    invoiceNumber: 'Uitverkoopfactuurnummer (aan de eindgebruiker) *',
    purchaseOrderNumberSofia: 'Aankoopordernummer aan Sofia',
    orderContactEmail: 'E-mailadres voor contact met bestelling',
  },
  vars: {
    varLogo: 'Logo',
    setTrainingDate: 'Trainingsdatum instellen',
    missingLastTrainingDateForCertificate: 'Voor deze VAR is geen trainingsdatum ingesteld. Stel een trainingsdatum in en probeer het certificaat opnieuw te genereren',
    varLastTrainingData: 'Laatste trainingsdatum',
    varShortDescription: 'Korte beschrijving',
    varCity: 'Stad',
    varCountry: 'Land',
    varWebsite: 'Website',
    varVisibleOnSofiaWebsite: 'Zichtbaar op de partnerspagina van de Sofia-website',
    downloadCertificate: 'Certificaat downloaden',
    opportunityChangeOwnerMessage: 'Geef de eigenaar van deze offerte op',
    noOpportunityHistoryFound: 'Geen offertegeschiedenis gevonden',
    noOpportunityHistoryFoundDescription: 'Deze offerte heeft geen historische records',
    customers: 'Klanten',
    varsManagement: 'VAR\'s',
    varSettings: 'Instellingen',
    varInfoPage: 'Training & Resources',
    varUsersPage: 'Gebruikers',
    vars: 'VAR',
    plantThemes: 'Systeemthema\'s',
    editVarPersonalConfirm: 'U staat op het punt uw bedrijfsgegevens te bewerken. Weet u zeker dat u wilt doorgaan?',
    editVarConfirm: 'U staat op het punt de VAR-gegevens te bewerken. Weet u zeker dat u wilt doorgaan?',
    editVar: 'VAR bewerken',
    newVar: 'Nieuwe VAR',
    varCreationConfirm: 'Wilt u de nieuwe VAR maken?',
    createVar: 'VAR maken',
    noVarFindTitle: 'Geen VAR gevonden',
    noVarFindDescription: 'De VAR-lijst is leeg. Als u een zoekfilter hebt ingesteld, probeer deze dan opnieuw in te stellen en probeer het opnieuw',
    noPlantThemeFindTitle: 'Geen plantenthema gevonden',
    noPlantThemeFindDescription: 'De lijst met plantenthema\'s is leeg. Als u een zoekfilter hebt ingesteld, probeer het dan opnieuw in te stellen en probeer het opnieuw',
    clusterSelection: 'Elke cluster',
    varSelection: 'Elke VAR',
    varName: 'Naam',
    varAddress: 'Adres',
    varEmail: 'E-mailadres hoofdcontactpersoon',
    varPhone: 'Telefoon',
    varCluster: 'Cluster',
    varVat: 'BTW',
    varTechContact: 'Technisch contact',
    varCommercialContact: 'Commercieel contact',
    varNameTech: 'Naam technisch contact',
    varEmailTech: 'E-mailadres technisch contact',
    varPhoneTech: 'Telefoon technisch contact',
    varNameComm: 'Naam commercieel contact',
    varEmailComm: 'E-mailadres commercieel contact',
    varPhoneComm: 'Telefoon commercieel contact',
    varSfdcid: 'SFDC ACCOUNT ID',
    varSupportEmail: 'Ondersteunings-e-mail',
    varSupportHours: 'Ondersteuningsopeningstijden',
    varCompanyName: 'Bedrijfsnaam',
    varCustomers: 'VAR-eindgebruikers',
    varUsers: 'VAR-gebruikers',
    varOpportunities: 'VAR-offertes',
    editPlantThemeConfirm: 'U staat op het punt de plantenthemagegevens te bewerken. Weet u zeker dat u wilt doorgaan?',
    editPlantTheme: 'Plantenthema bewerken',
    newPlantTheme: 'Nieuw plantenthema',
    plantThemeCreationConfirm: 'Wilt u het nieuwe plantenthema maken?',
    editVarPersonal: 'Bedrijfsinfo bewerken',
    plantThemeName: 'Naam',
    plantThemeColour: 'Kleur *',
    plantThemeColourDescription: 'Themakleur',
    plantThemeLogo: 'Logo',
    plantThemeDefault: 'Gebruiken als standaardthema',
    logoFile: 'Logo-afbeelding *',
    logoFile_desc: 'Toegestane bestandsextensies:',
    logoFileAdd: 'Bestand uploaden',
    logoFileDownload: 'Downloaden',
    logoFileReplace: 'Vervangen',
    deletePlantThemeConfirm: 'Weet u zeker dat u dit plantenthema wilt verwijderen? Bewerking kan niet ongedaan worden gemaakt',
    plantThemeSearchEnterName: 'Selecteer thema',
    plantThemeSearch: 'Selecteer het gewenste thema',
    domainThemeTitle: 'Aangepast domeinthema',
    domainThemeDescription: 'Stel uw aangepaste thema in op dit domein',
    plantThemeModifySuccess: 'Plantthema gewijzigd en bijgewerkt',
    cannotDeleteAssociatedTheme: 'Kan een thema dat aan een plant is gekoppeld, niet verwijderen.',
    plantThemeAssociatedPlants: 'Planten gekoppeld aan dit thema',
    newVarUser: 'Nieuwe VAR-gebruiker',
    varUserCreationConfirm: 'Wilt u de nieuwe VAR-gebruiker maken?',
    createVarUser: 'VAR-gebruiker maken',
    varUserFormTitle: 'VAR-gebruiker',
    createNewVarUser: 'Nieuwe VAR-gebruiker maken',
    noVarUserFindTitle: 'Geen VAR-gebruikers Gevonden',
    noVarUserFindDescription: 'De lijst met VAR-gebruikers is leeg',
    deleteVarUserConfirm: 'Weet u zeker dat u deze VAR-gebruiker wilt verwijderen? De bewerking kan niet ongedaan worden gemaakt',
    deleteVarOpportunityConfirm: 'Weet u zeker dat u deze offerte wilt verwijderen? De bewerking kan niet ongedaan worden gemaakt',
    rejectVarOpportunityConfirm: 'Weet u zeker dat u deze offerte wilt afwijzen? De bewerking kan niet ongedaan worden gemaakt',
    approveVarOpportunityConfirm: 'Weet u zeker dat u deze offerte wilt goedkeuren? De bewerking kan niet ongedaan worden gemaakt',
    syncsfdcidVarOpportunityConfirm: 'Weet u zeker dat u een SFDC-ID aan deze offerte wilt koppelen? De bewerking kan niet ongedaan worden gemaakt',
    upgradeVarOpportunityConfirm: 'Weet u zeker dat u deze offerte naar revisie wilt sturen? De bewerking kan niet ongedaan worden gemaakt',
    winVarOpportunityConfirm: 'Weet u zeker dat u deze offerte wilt instellen als GEWONNEN? Bewerking kan niet ongedaan worden gemaakt',
    lostVarOpportunityConfirm: 'Weet u zeker dat u deze offerte wilt instellen als VERLOREN? Bewerking kan niet ongedaan worden gemaakt',
    deleteUserConfirm: 'Weet u zeker dat u deze gebruiker wilt verwijderen? Bewerking kan niet ongedaan worden gemaakt',
    varUserUsername: 'Gebruikersnaam',
    varUserFirstname: 'Voornaam',
    varUserLastname: 'Achternaam',
    varUserEmail: 'E-mail',
    varUsersList: 'Gebruikers',
    newFileExtensionError: 'Het bestand heeft een niet-ondersteunde extensie',
    agreementFile: 'Overeenkomst',
    opportunities: 'Quotations',
    opportunityManagement: 'Quotation info',
    opportunityID: 'ID',
    opportunityOpty: 'SFDC OPTY ID',
    opportunityVar: 'VAR',
    opportunityCustomer: 'Eindgebruiker',
    opportunityOwner: 'Quotation Owner',
    opportunityName: 'Quotation name',
    opportunityValue: 'Value',
    opportunityStatus: 'Status',
    opportunityActions: 'Acties',
    opportunityDescription: 'Beschrijving',
    opportunityNotes: 'Notities',
    opportunityCrm: 'CRM',
    noOpportunityFindTitle: 'Geen offertes',
    noOpportunityFindDescription: 'De lijst met offertes is leeg',
    opportunityStatus_DRAFT: 'Concept',
    opportunityStatus_ON_APPROVAL: 'Wordt herzien',
    opportunityStatus_APPROVED: 'Goedgekeurd',
    opportunityStatus_CANCELLED: 'Geannuleerd',
    opportunityStatus_REJECTED: 'Afgewezen',
    opportunityStatus_WON: 'Gewonnen',
    opportunityStatus_LOST: 'Verloren',
    opportunityFiles: 'Bijlagen',
    newOpportunity: 'Nieuwe offerte',
    editOpportunity: 'Offerte bewerken',
    opportunitySyncSFDC: 'Synchroniseren met SFDC',
    exportOpportunities: 'CSV exporteren',
    upgradeOpportunity: 'Verzenden ter beoordeling',
    approveOpportunity: 'Goedkeuren',
    rejectOpportunity: 'Afwijzen',
    wonOpportunity: 'Instellen als GEWONNEN',
    lostOpportunity: 'Instellen als VERLOREN',
    CREATED: 'Offerte gemaakt',
    VAR_USER_UPDATED: 'Offerte VAR toegewezen gebruiker bijgewerkt',
    DESCRIPTION_UPDATED: 'Offertebeschrijving bijgewerkt',
    NAME_UPDATED: 'Offertenaam bijgewerkt',
    VALUE_UPDATED: 'Offertewaarde bijgewerkt',
    CURRENCY_UPDATED: 'Offertevaluta bijgewerkt',
    OPTY_ID_UPDATED: 'Offerteoptie-id bijgewerkt',
    ISEO_CRM_ID_UPDATED: 'Offerte SFDC-id bijgewerkt',
    DOCUMENTS_UPDATED: 'Offertedocumenten bijgewerkt',
    STATUS_ON_APPROVAL: 'Offerte verzonden ter goedkeuring',
    STATUS_APPROVED: 'Offerte goedgekeurd',
    STATUS_WON: 'Offerte gemarkeerd als gewonnen',
    STATUS_LOST: 'Offerte gemarkeerd als verloren',
    STATUS_DRAFT: 'Offerte gemarkeerd als concept',
    STATUS_CANCELLED: 'Offerte gemarkeerd als geannuleerd',
    CANCELLED: 'Offerte geannuleerd',
    STATUS_REJECTED: 'Offerte gemarkeerd als afgewezen',
    showOpportunityHistory: 'Geschiedenis weergeven',
    opportunityHistory: 'Offertegeschiedenis'
  },
  save: "Opslaan",
  languages: {
    it: "Italiaans",
    en: "Engels",
    de: "Duits",
    fr: "Frans",
    es: "Spaans",
    nl: "Nederlands",
    ITALIAANS: "Italiaans",
    ENGELS: "Engels",
    DUITS: "Duits",
    FRANS: "Frans",
    SPAANS: "Spaans",
    NEDERLANDS: "Nederlands",
    menuHeading: "Kies een taal",
  },
  productInfo: {
    software: 'Software',
    salesMaterial: 'Verkoopmateriaal',
    hardware: 'Hardware',
    marketingMaterial: 'Marketingmateriaal',
  },
  users: {
    usersManagement: 'Gebruikersbeheer',
    users: 'Gebruikers',
    noUserFindTitle: 'Geen gebruikers gevonden',
    noUserFindDescription: 'De gebruikerslijst is leeg. Probeer uw zoekfilters opnieuw in te stellen indien aanwezig',
    zoeken: 'Zoeken...',
    rolSelectie: 'Rol',
    clusterSelectie: 'Cluster',
    varSelectie: 'VAR',
    newUser: 'Toevoegen',
    userFirstname: 'Voornaam',
    userLastname: 'Achternaam',
    userEmail: 'E-mail',
    userFormTitel: 'Gebruikersgegevens',
    editUserForm: 'Gebruiker bewerken',
    newUserForm: 'Gebruiker maken',
  },
  toestemmingen: {
    NAVIGATIE: 'Menu zichtbaarheid',
    CLUSTER: 'Cluster',
    VAR: 'VAR',
    KLANT: 'Eindgebruikers',
    KANS: 'Offertes',
    WALLET: 'Eindgebruikers wallets',
    DOMEIN: 'Eindgebruikers systemen',
    GATEWAYS: 'Gateway',
    KAART: 'Opwaardeerkaarten',
    PLANT_ACTIVATION: 'Systeemactivaties',
    RECHARGE_REQUEST: 'Systeemopwaarderingen',
    ACTIVATION_REQUEST: 'Activeringsverzoeken',
    EXPORT: 'Entiteiten exporteren',
    CREATE: 'Nieuwe entiteit maken',
    UPDATE: 'Entiteit bijwerken',
    MARK_LOST: 'Entiteit markeren als verloren',
    MARK_WON: 'Entiteit markeren als gewonnen',
    CANCEL: 'Entiteit markeren als annuleren',
    CANCEL_DRAFT: 'Markeer entiteit als geannuleerd bij concept',
    CANCEL_ON_APPROVAL: 'Markeer entiteit als geannuleerd bij revisie',
    CANCEL_APPROVED: 'Markeer entiteit als geannuleerd bij goedkeuring',
    MARK_TO_BE_APPROVED: 'Markeer entiteit als goedgekeurd',
    RECHARGE: 'Opladen',
    SUBSCRIPTION_UPDATE: 'Update abonnementen',
    DELETE_REQUEST: 'Verzoek om te verwijderen verzenden',
    CREATION_REQUEST: 'Verzoek om nieuwe entiteit te maken verzenden',
    MANAGER_CREATE: 'Maak beheerders',
    MANAGER_UPDATE: 'Update beheerders',
    INSTALLER_CREATE: 'Maak installatieprogramma\'s',
    INSTALLER_UPDATE: 'Update installatieprogramma\'s',
    IMPERSONATE_WRITE: 'Impersonate administrators',
    IMPERSONATE_SUPPORT: 'Impersonate support account',
    ADDITIONAL_INFO_UPDATE: 'Extra informatie bewerken',
    CUSTOMERS: 'Eindgebruikers',
    PLANT_ACTIVATIONS: 'Systeemactivaties',
    RECHARGE_REQUESTS: 'Systeemopwaarderingen',
    ACTIVATION_REQUESTS: 'Activeringsverzoeken',
    CARDS: 'Opwaardeerkaarten',
    VAR_USERS: 'VAR-gebruikers',
    VAR_SETTINGS: 'VAR-instellingen',
    OPPORTUNITIES: 'Offertes',
    PRODUCTINFO: 'Productinformatie',
    USER_READ: 'Gebruikers bekijken',
    USER_UPDATE: 'Gebruiker bijwerken',
    USER_CREATE: 'Nieuwe gebruiker maken',
    PLANT_THEME: 'Domeinthema wijzigen',
    READ: 'Entiteitswaarden lezen',
    ORDERS_STATUS: 'Orderstatus',
    FILTER: 'Entiteiten filteren',
    VAR_MANAGEMENT: 'VAR-beheer',
    MARKETPLACES: 'Marktplaatsen',
    DOMAINS: 'Eindgebruikerssystemen',
    PRICELIST: 'Prijslijst',
    SALESINFO: 'Verkoopinfo',
    PLATFORM_USERS: 'Gebruikersbeheer',
    APPROVE: 'Markeer entiteit als goedgekeurd',
    REJECT: 'Markeer entiteit als afgewezen',
    EXTEND: 'Uitbreiden',
    CONFIG: 'Lees entiteitconfiguraties',
    IRIS_ENABLE: '1RIS inschakelen voor domein',
    MARK_TO_PROMOTE: 'Markeer entiteit om te promoten',
    MARK_TO_INVOCE: 'Markeer entiteit om te factureren',
    DELETE: 'Entiteiten verwijderen',
    EDIT: 'Entiteiten bewerken',
    SUBSCRIPTION_DELETE: 'Abonnementen verwijderen',
    },
    segmentTypes: {
    WORKING_SPACES_name: 'Flexibele werkruimte',
    LIVING_name: 'Flexibel wonen',
    RETAIL_name: 'Flexibele detailhandel',
    UNIVERSITIES_name: 'Universiteiten',
    LEISURE_name: 'Vrije tijd en gastvrijheid',
    HOSPITAL_name: 'Ziekenhuis',
    WORKING_SPACES_description: 'Kantoren, coworking, zakencentra',
    LIVING_description: 'Residentieel, cohousing, coliving, studentenhuisvesting',
    RETAIL_description: 'Multi-site detailhandel, winkelcentra, winkelketens',
    LEISURE_description: 'Kleine en middelgrote hotels, accommodatie, sportcentra',
    HOSPITAL_description: 'Ziekenhuizen, seniorenhuisvesting en ouderenzorg',
  }
}


export default translations; 