import { PaginatedDataDTO, PaginationDTO, PaginationQueryParamsDTO } from '@bottega52/commons-pagination';
import { Button, IconButton } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Edit, SearchOutlined } from '@material-ui/icons';
import LicenseIcon from "@material-ui/icons/BookmarkBorder";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import WarningIcon from '@material-ui/icons/Warning';
import { Stack } from '@mui/material';
import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import _ from 'lodash';
import 'moment/locale/it';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Select, { SingleValue } from 'react-select';
import EditSubscriptionForm from '../../components/Forms/EditSubscriptionForm';
import NewTierSubscriptionForm from '../../components/Forms/NewTierSubscriptionForm';
import SubscriptionDetailsView from '../../components/MarketplaceSubscriptions/SubscriptionDetailsView';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import ReduxLanguage from '../../components/ReduxLanguage';
import * as CreditsActions from '../../redux/credits/credits.actions';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import IMarketplaceInDTO from '../../repository/jago/model/input/IMarketplaceInDTO';
import { ISubscriptionInDTO } from '../../repository/jago/model/input/ISubscriptionInDTO';
import { SubscriptionLogicEnum } from '../../repository/jago/model/input/ISubscriptionLogicEnum';
import { IEditSubscriptionOutDTO, ISubscriptionOutDTO } from '../../repository/jago/model/output/ISubscriptionOutDTO';
import { GREEN } from '../../style/StyleConsts';
import translations from '../../translations/i18next';
import Utils from '../../utils/Utils';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  titleText: {
    marginRight: 10,
    marginLeft: 10,
    fontWeight: 'bold',
  },
  header: {
    backgroundColor: 'white',
    width: '100%',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  },
  tableContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: 'calc(100vh - 140px)',
    width: '100%'
  },
  rowHeaderInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

type IReduxProps = ConnectedProps<typeof connector>;

interface SubscriptionFilters {
  version?: number;
}

export interface ISubscriptionsPageState {
  ancorEl: EventTarget & HTMLButtonElement | null;
  filterMarketplace?: IMarketplaceInDTO;
  filters: SubscriptionFilters;
  rowsPerPage: number;
  isLoading: boolean;
}
export interface ISubscriptionsPageProps extends WithStyles<typeof styles> {
}

export type ComponentProps = ISubscriptionsPageProps & IReduxProps;

class SubscriptionsPage extends React.Component<ComponentProps, ISubscriptionsPageState> {
  private tableColumns: GridColumns<ISubscriptionInDTO>;
  constructor(props: ComponentProps) {
    super(props);
    const mkt = props.marketplaces as Partial<PaginatedDataDTO<ISubscriptionInDTO>>;
    this.state = {
      ancorEl: null,
      rowsPerPage: 50,
      filterMarketplace: mkt.content && mkt.content.length > 0 ? mkt.content[0] : undefined,
      filters: {
        version: 2,
      },
      isLoading: false,
    };
  }
  
  public handleMenuOpen(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({ ancorEl: event.currentTarget });
  }

  public handleMenuClose() {
    this.setState({ ancorEl: null });
  }

  public async componentDidMount() {
    const { dispatch } = this.props;
    let { filterMarketplace, filters, rowsPerPage } = this.state;
    this.setState({ isLoading: true });
    if (!filterMarketplace) {
      const marketplaces = await dispatch<any>(CreditsActions.fetchMarketplaces());
      filterMarketplace = marketplaces.content && marketplaces.content.length > 0 ? marketplaces.content[0] : undefined;
      this.setState({ filterMarketplace});
    }
    
    if (!filterMarketplace) {
      return;
    }
    try {
      await dispatch<any>(CreditsActions.fetchMarketplaceSubscriptions(filterMarketplace.id, { page: 0, pageSize: rowsPerPage, ...filters }));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      dispatch<any>(ModalsActions.showModal(`ERROR_OPEN_PARAMETERS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.openSystemParametersError',
        }
      }));
    }
  }

  public async onFetchMarkeplacesSubscriptionsPerPage(marketplaceId: number | null, page: number) {
    const { dispatch } = this.props;
    const { filters, rowsPerPage } = this.state;

    if (!marketplaceId) {
      return;
    }
    const params: PaginationQueryParamsDTO = { page, pageSize: rowsPerPage, ...filters };
    try {
      this.setState({ isLoading: true });
      await dispatch<any>(CreditsActions.fetchMarketplaceSubscriptions(marketplaceId, params));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  }


  public onOpenNewTierSubscriptionForm() {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`TIER_SUBSCRIPTION_FORM_VIEW`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
      modalProps: {
        content: (
          <NewTierSubscriptionForm
            onCreateTierSubscription={subscription => this.onCreateTierSubscriptionRequest(subscription)}
          />
        ),
        titleMessageKey: 'credits.newTierSubscription',
      }
    }));
  }

  public onCreateTierSubscriptionRequest(subscription: ISubscriptionOutDTO) {
    const { dispatch } = this.props;
    const { filterMarketplace } = this.state;
    if (!filterMarketplace) {
      return;
    }

    dispatch<any>(ModalsActions.showModal(`CREATE_SUBSCRIPTION_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.newSubscription',
        successMessageKey: 'forms.createSubscrptionConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreateTierSubscriptionConfirm(filterMarketplace.id, subscription),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_SUBSCRIPTION_CONFIRM_MODAL`)),
      }
    }));
  }

  openEditSubscriptionForm(subscriptionToEdit: ISubscriptionInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`TIER_SUBSCRIPTION_FORM_VIEW`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
      modalProps: {
        content: (
          <EditSubscriptionForm
            subscription={subscriptionToEdit}
            onEditSubscription={subscription => this.onEditSubscriptionRequest(subscriptionToEdit, subscription)}
          />
        ),
        titleMessageKey: 'credits.editSubscription',
      }
    }));
  }

  onEditSubscriptionRequest(subscriptionToEdit: ISubscriptionInDTO, subscription: IEditSubscriptionOutDTO) {
    const { dispatch } = this.props;
    const { filterMarketplace } = this.state;
    if (!filterMarketplace) {
      return;
    }

    dispatch<any>(ModalsActions.showModal(`CREATE_SUBSCRIPTION_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.editSubscription',
        successMessageKey: 'forms.editSubscriptionConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.editSubscriptionConfirm(subscriptionToEdit, subscription),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_SUBSCRIPTION_CONFIRM_MODAL`)),
      }
    }));
  }

  async editSubscriptionConfirm(subscriptionToEdit: ISubscriptionInDTO, subscription: IEditSubscriptionOutDTO) {
    const { dispatch, systemSubscriptions } = this.props;

    const { filterMarketplace, filters, rowsPerPage } = this.state;
    if (!filterMarketplace) {
      return;
    }

    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(CreditsActions.editMarketplaceSubscription(filterMarketplace.id, subscriptionToEdit.id, subscription));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('TIER_SUBSCRIPTION_FORM_VIEW'));
      dispatch<any>(ModalsActions.hideModal('CREATE_SUBSCRIPTION_CONFIRM_MODAL'));
      if (Utils.isPluginSubscription(subscriptionToEdit)) {
        if (systemSubscriptions.selectedMainTier?.id) {
          dispatch<any>(CreditsActions.fetchMarketplaceMainTierSubscriptions(filterMarketplace.id, systemSubscriptions.selectedMainTier.id, { page: 0, pageSize: 50 }));
        }
      } else {
        dispatch<any>(CreditsActions.fetchMarketplaceSubscriptions(filterMarketplace.id, { page: 0, pageSize: rowsPerPage, ...filters }));
      }
      
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_SUBSCRIPTION_CREATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createSubscriptionError',
        }
      }));
    }
  }

  public async onCreateTierSubscriptionConfirm(marketplaceId: number, subscription: ISubscriptionOutDTO) {
    const { dispatch } = this.props;
    const { filters, rowsPerPage } = this.state;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(CreditsActions.createMarketplaceSubscription(marketplaceId, subscription));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('TIER_SUBSCRIPTION_FORM_VIEW'));
      dispatch<any>(ModalsActions.hideModal('CREATE_SUBSCRIPTION_CONFIRM_MODAL'));
      dispatch<any>(CreditsActions.fetchMarketplaceSubscriptions(marketplaceId, { page: 0, pageSize: rowsPerPage, ...filters }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_SUBSCRIPTION_CREATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createSubscriptionError',
        }
      }));
    }
  }

  async onChangeMarketplaceFilter(marketplace: SingleValue<IMarketplaceInDTO>) {
    const { dispatch } = this.props;
    this.setState({ filterMarketplace: marketplace ? marketplace : undefined, isLoading: true }, async () => {
      const { filterMarketplace, filters, rowsPerPage } = this.state;
      if (!filterMarketplace) {
        return;
      }
      try {
        await dispatch<any>(CreditsActions.fetchMarketplaceSubscriptions(filterMarketplace.id, { page: 0, pageSize: rowsPerPage, ...filters }));  
        this.setState({ isLoading: false });
      } catch (error) {
        this.setState({ isLoading: false });
      }
    });
  }

  async onChangeFilter(key: keyof SubscriptionFilters, value: any) {
    const { dispatch } = this.props;

    const { filters, rowsPerPage } = this.state;
    this.setState({ filters: { ...filters, [key]: value }, isLoading: true }, async () => {
      const { filterMarketplace, filters: updatedFilters } = this.state;
      try {
        await dispatch<any>(CreditsActions.fetchMarketplaceSubscriptions(filterMarketplace.id, { page: 0, pageSize: rowsPerPage, ...updatedFilters }));
        this.setState({ isLoading: false });
      } catch (error) {
        this.setState({ isLoading: false });
      }
    });
  }

  public handleChangeRowsPerPage(pageSize: number) {
    const { dispatch } = this.props;
    const { filters, filterMarketplace } = this.state;
    this.setState({ rowsPerPage: pageSize, isLoading: true }, async () => {
      const params: PaginationQueryParamsDTO = { page: 0, pageSize, ...filters };
      try {
        await dispatch<any>(CreditsActions.fetchMarketplaceSubscriptions(filterMarketplace.id, params));
        this.setState({ isLoading: false });
      } catch (error) {
        this.setState({ isLoading: false });
      }
    });
  }

  async onRowClick(subscription: ISubscriptionInDTO) {
    const { dispatch } = this.props;
    const { filterMarketplace } = this.state;
    if (!filterMarketplace) {
      return;
    }
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      dispatch<any>(CreditsActions.CreditsActions.setSelectedMarketplaceMainTier(subscription));
      await dispatch<any>(CreditsActions.fetchMarketplaceMainTierSubscriptions(filterMarketplace.id, subscription.id, { pageSize: 50 }));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`OPERATIONAL_CUSTOMER_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          onClose: () => dispatch<any>(CreditsActions.CreditsActions.setSelectedMarketplaceMainTier({})),
          content: (
            <SubscriptionDetailsView
              selectedMarketplace={filterMarketplace}
              onEditSubscription={(sub) => this.openEditSubscriptionForm(sub)}
            />
          ),
          titleMessageKey: 'credits.customerDetails',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_CUSTOMER_DETAILS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.getCustomerDetailsError',
        }
      }));
    }
  }

  render() {
    const { marketplaces, systemSubscriptions, classes } = this.props;
    const { filterMarketplace, filters } = this.state;
    this.tableColumns = [
      {
        field: 'id',
        headerName: 'Id',
        type: 'string',
        sortable: false,
        filterable: false,
        width: 80,
        renderCell: (params: GridRenderCellParams<any>) => {
          const subscription = params.row;
          return (
            <div>
              <h3 style={{ margin: 0, fontSize: 13, whiteSpace: 'normal' }}>{subscription.id}</h3>
            </div>
        )}
      },
      {
        field: 'name',
        headerName: translations.t('gateways.name'),
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 400,
        renderCell: (params: GridRenderCellParams<any>) => {
          const subscription = params.row;
          const isDomainRow = subscription.resource === 'DOMAIN';
          return (
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <h4 style={{ marginLeft: 0, fontWeight: 'bold', whiteSpace: 'normal' }}>{subscription.sku ? `${subscription.sku} - ` : ''}{subscription.name}</h4>
                <IconButton onClick={(e) => {
                  e.stopPropagation();
                  this.openEditSubscriptionForm(subscription);
                }}>
                  <Edit style={{ fontSize: 15 }} />
                </IconButton>
              </div>
              <div style={{ display: 'flex', alignItems: 'center '}}>
                {isDomainRow ? (
                <div className={classes.rowHeaderInnerContainer} style={{ marginRight: 10 }}>
                  <LicenseIcon style={{ color: GREEN, fontSize: 15 }} />
                  <h4 style={{ margin: 0, marginLeft: 5 }}>{<ReduxLanguage languageKey="credits.tierSubscription" />}</h4>
                </div>
              ) : null}
              {!subscription.visible ? 
                <VisibilityOffIcon style={{ fontSize: 15 }} />
              : null}
              </div>
            </div>
          )
        }
      },
      {
        field: 'burnRate',
        headerName: translations.t('forms.costs'),
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 250,
        renderCell: (params: GridRenderCellParams<any>) => {
          const subscription = params.row;
          return (
            <div>
              <h4><span style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="forms.subscriptionFixedPrice" /></span>: {Utils.formatCreditsIn(subscription?.fixedPrice)}</h4>
              <h4><span style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="forms.subscriptionBurnRate" /></span>: {Utils.formatCreditsIn(subscription?.burnRate)}</h4>
            </div>
          )
        }
      },
      {
        field: 'logic',
        headerName: translations.t('forms.subscriptionLogic'),
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 220,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => {
          const subscription = params.row;
          return (
            <div>
            <h4>
              <span style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="forms.subscriptionLogic" /></span>: <ReduxLanguage languageKey={`forms.${subscription.logic}`} />
            </h4>
            {subscription.logic === SubscriptionLogicEnum.COUNT_ELEMENTS ? (
              <>
                <h4><span style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="forms.maxElements" /></span>: {subscription?.maxElements}</h4>
              </>
            ) : null}
            {(subscription.logic === SubscriptionLogicEnum.COUNT_USERS_LOCKS) ? (
              <>
                <h4><span style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="forms.maxUsers" /></span>: {subscription?.maxUsers}</h4>
                <h4><span style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="forms.maxLocks" /></span>: {subscription?.maxLocks}</h4>
              </>
            ) : null}
            {(subscription.logic === SubscriptionLogicEnum.COUNT_LOCKS) ? (
              <>
                <h4><span style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="forms.maxLocks" /></span>: {subscription?.maxLocks}</h4>
              </>
            ) : null} 
            </div>
          )
        }
      },

    ];
    const systemSubscriptionsData = systemSubscriptions.data as PaginatedDataDTO<ISubscriptionInDTO>;
    return (
      <div>
        <div className={classes.header}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Select
              options={(marketplaces as PaginatedDataDTO<IMarketplaceInDTO>).content}
              placeholder={<ReduxLanguage languageKey={'credits.marketplaces'} />}
              getOptionLabel={(mktplace) => mktplace.name}
              isOptionSelected={(el) => el.id === filterMarketplace?.id}
              onChange={(e) => this.onChangeMarketplaceFilter(e)}
              value={filterMarketplace}
              styles={{
                menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                control: (styles) => ({ ...styles, minHeight: 55 }),
              }}
            />
            <Select
              key={`version__${filters.version}`}
              options={_.map([1,2], (v) => ({ label: String(v), value: v }))}
              isOptionSelected={(el) => el.value === filters.version}
              value={{ label: String(filters.version), value: filters.version }}
              getOptionLabel={(o) => o.label}
              placeholder={<ReduxLanguage languageKey={'forms.version'} />}
              onChange={(e) => this.onChangeFilter('version', e.value)}
              styles={{
                menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                control: (styles) => ({ ...styles, minHeight: 55, minWidth: 200, marginLeft: 10, marginRight: 10 }),
              }} 
            />
            <Button
              onClick={() => { this.handleMenuClose(); this.onOpenNewTierSubscriptionForm() }}
              variant="contained"
              style={{  backgroundColor: '#5AC0B1', margin: 'auto', marginLeft: 10, width: 'fit-content', height: 45, color: 'white', fontWeight: 'bold' }}>
                <ReduxLanguage languageKey="forms.newTierSubscription" />
            </Button>
          </div>
        </div>
        <div className={classes.tableContainer}>
          <DataGrid
            getRowId={(d) => d.id}
            rows={systemSubscriptionsData.content || []}
            columns={this.tableColumns}
            rowHeight={150}
            loading={this.state.isLoading}
            onPageSizeChange={(pageSize) => this.handleChangeRowsPerPage(pageSize)}
            onPageChange={page => this.onFetchMarkeplacesSubscriptionsPerPage(filterMarketplace?.id, page)}
            page={systemSubscriptionsData.pagination ? (systemSubscriptionsData.pagination as PaginationDTO).number : 0}
            rowCount={systemSubscriptionsData.pagination ? (systemSubscriptionsData.pagination as PaginationDTO).totalElements : 0}
            pageSize={this.state.rowsPerPage}
            paginationMode="server"
            onRowClick={row => this.onRowClick(row.row)}
            componentsProps={{
              pagination: {
                labelRowsPerPage: translations.t('forms.rowsPerPage')
              }
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <SearchOutlined style={{ alignSelf: 'center' }} />
                    <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='activations.noSubscriptionFound' /></h3>
                    <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='activations.noSubscriptionFoundDescription' /></h4>
                  </div>
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='activations.noSubscriptionFound' /></h3>
                  <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='activations.noSubscriptionFoundDescription' /></h4>
                </Stack>
              )
            }}
            sx={{
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    systemSubscriptions: state.credits.marketplaceSubscriptions,
    marketplaces: state.credits.marketplaces.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(SubscriptionsPage as any));