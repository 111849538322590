

import { CircularProgress, Divider } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { Domain, PowerSettingsNew, SecurityRounded } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import WarningIcon from '@material-ui/icons/Warning';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import logo from '../../assets/logos/logoVashoWhite.png';
import LanguageSelector from '../../components/LanguageSelector';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import NewspaperIconCustom from '../../components/NewspaperIconCustom';
import Permission from '../../components/Permission/Permission';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as UserActions from '../../redux/user/user.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import { isMobileBrowser } from '../../repository/utils';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { Roles } from '../../services/PermissionService/PermissionConstants';
import { LocalStorageRepository } from '../../services/StorageService/LocalStorageRepository';
import { LanguageTypes } from '../../translations/LanguageTypes';
import { detectBrowserLanguage } from '../../translations/utils';
import { googleLogout } from "@react-oauth/google";
import { GREEN_MAIN } from "../../style/StyleConsts";
import { getRoleReadableName, VAR_USERS_ROLES } from "../../utils/Utils";

const theme = createTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiAppBar: {
      // Name of the rule
      colorPrimary: {
        // Some CSS
        backgroundColor: '#282c34',
      },
    },
  },
});

type IReduxProps = ConnectedProps<typeof connector>;

export interface IAppHeaderProps extends IReduxProps, RouteComponentProps<any> {
  handleDrawerToggle: () => void;
}
export interface IAppHeaderState {
  whatsNewLoading: boolean;
}
export class AppHeader extends React.Component<IAppHeaderProps, IAppHeaderState> {

  constructor(props: IAppHeaderProps) {
    super(props);
    this.state = {
      whatsNewLoading: false,
    }
  }
  
  public async componentDidMount() {
    const { dispatch } = this.props;
    const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
    if (isVar) {
      try {
        this.setState({ whatsNewLoading: true })
        await dispatch<any>(SettingsActions.fetchWhatsNewArticles());
        this.setState({ whatsNewLoading: false })
      }
      catch (error) {
        console.log(error)
      }
    }
    
  }

  public onOpenWhatsNew() {
    const { dispatch, whatsNewContent } = this.props;
    
    dispatch<any>(SettingsActions.resetWhatsNewHasUpdate());
    try {
      const lastUpdate = _.first(whatsNewContent) && _.first(whatsNewContent).date;
      localStorage.setItem('lastWhatsNewUpdate', lastUpdate);
    }
    catch (error) {
      console.log(error)
    }
    
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_WHATS_NEW_MODAL`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
      modalProps: {
        content: (
          <div className="" style={{ top: 0, zoom: '100%', padding: 0, margin: 0 }}>
            <div className="" style={{ padding: 0, paddingBottom: 70 }}>
              {_.map(whatsNewContent, article => (
                <>
                {article && article.preview ? (
                  <div className="hover-container" style={{ paddingBottom: 60 }} onClick={() => window.open(article.link)}>
                    <div style={{ padding: 20, paddingBottom: 0 }}>
                      <h3 style={{ margin: 0, fontWeight: 'bold', color: '#3f3f3f', marginBottom: 5 }}>{article.renderedTitle ? article.renderedTitle : article.title}</h3>
                      {article.date ? <h4>{moment(article.date).format('LL')}</h4> : null}
                    </div>
                    <div className="storeContentContainer" style={{ maxHeight: 600, padding: 25, paddingLeft: 20, paddingTop: 10, marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: article.preview }} />
                    {article && article.previewImage ? <img style={{ minHeight: 150, width: '45%', margin: 'auto', display: 'flex' }} src={article.previewImage} /> : null}
                  </div>
                ): null}
                <Divider style={{ zIndex: 100 }} />
                </>
              ))}
            </div>
          </div>
        ),
        titleMessageKey: 'forms.whatsNewTitle',
        subtitleMessageKey: 'forms.whatsNewSubtitle',
      }
    }));
  }

  onLogoutRequest() {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`LOGOUT_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'forms.logoutConfirmation',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onLogoutConfirm(),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`LOGOUT_CONFIRM_MODAL`)),
      }
    }));
  }

  onLogoutConfirm() {
    const { dispatch, history } = this.props;
    dispatch<any>(ModalsActions.hideModal(`LOGOUT_CONFIRM_MODAL`))
    googleLogout();
    dispatch<any>(UserActions.userLogout());
    history.push('/home');
  }

  render() {
    const { handleDrawerToggle, VAR, whatsNewHasUpdate, userData } = this.props;
    const { whatsNewLoading } = this.state
    const currentVar = (VAR as IVarInDTO)

    const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
    const userRole = userData.authorities && !_.isEmpty(userData.authorities) && _.first(userData.authorities) && getRoleReadableName(_.first(userData.authorities),isVar) ? getRoleReadableName(_.first(userData.authorities),isVar) : null;

    return (
      <ThemeProvider theme={theme}>
      <AppBar position="fixed" color="primary" style={{ zIndex: 1300, backgroundColor: GREEN_MAIN }}>
        <Toolbar>
          {isMobileBrowser() ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => handleDrawerToggle()}
            >
              <MenuIcon />
            </IconButton>
          ) : null}
          <div style={{ display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'row', width:'100%'}}>
              <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }}>
                <img src={logo} style={{ width: 140 }} alt="logo" />
                {process.env.REACT_APP_VERSION && (
                  <h4 style={{ textAlign: 'center', marginRight: 15, fontWeight: 'bold', fontSize: 12, marginBottom: 0, color: 'white', alignSelf: 'flex-end' }}>
                    {`${process.env.REACT_APP_VERSION}`}
                  </h4>
                )}
              </div>
              <span style={{flexGrow:1}}></span>
              {isVar ? (
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 0, alignItems: 'center', marginBottom:8, marginRight:30 }}>
                  {whatsNewLoading ? (
                    <CircularProgress style={{color:'white', height:20, width: 20, marginRight: 10}} />
                  ) : null}
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor:whatsNewLoading?'default':'pointer', opacity:whatsNewLoading?'0.7':'1' }}
                    onClick={()=>{if(!whatsNewLoading) this.onOpenWhatsNew()}}>
                    <NewspaperIconCustom className={""} style={{ color: 'white', width: 27, marginRight: 10 }} />
                    <h4 style={{ color: 'white', margin: 0, marginTop: 0, fontWeight: 'bold' }}>{'What\'s new!'}</h4>
                    <div style={{ width: 15, height: 15, marginLeft: 5, borderRadius: 20, backgroundColor: 'gold', opacity:whatsNewHasUpdate?1:0 }} />
                  </div>
                </div>
              ) : null}
              {isVar && currentVar && !_.isEmpty(currentVar) ? (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10, marginRight: 15 }}>
                  <Domain style={{ marginRight: 5 }} />
                    <h4>{currentVar.name}</h4>
                </div>
              ) : null}
              <div style={{ marginRight: 20, marginBottom: 6 }}>
                <h4 style={{ margin: 0, marginBottom: 5, fontWeight: 'bold' }}>{userData.user_name}</h4>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <h5 style={{ margin: 0, marginRight: 5, fontSize: 11 }}>{userRole}</h5>
                  <SecurityRounded style={{ fontSize: 12 }}/>
                </div>
              </div>
              <LanguageSelector/>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => this.onLogoutRequest()}
              >
              <PowerSettingsNew />
            </IconButton>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      </ThemeProvider>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    userData: state.user.userData,
    VAR: state.vars.VAR.data,
    whatsNewContent: state.settings.whatsNew.content,
    whatsNewHasUpdate: state.settings.whatsNew.hasUpdate,
  };
}

const connector = connect(mapStateToProps);

export default connector(withRouter(AppHeader));